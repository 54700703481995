import { Component, OnInit } from "@angular/core";
import { Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

//Models
import {
  Lote,
  Estado,
  Item,
  Bandeja,
} from "src/app/models/entities/entity.index";
//Constants
import { FECHA, MODULOS, RANGO_FECHA } from "src/app/constants/config.constant";
// Interfaces
import { IAdvancedSearchFilters } from "src/app/models/interfaces/common/advanced-search-filters.interface";
//Services
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { SbcService } from "src/app/services/common/sbc.service";
import { UtilService } from "src/app/services/common/util.service";
import { MESSAGE, TIEMPO_MENSAJE } from "src/app/constants/message.constant";
import { ValidationService } from "src/app/services/common/validation.service";
import { BandejaTicketsService } from "src/app/services/business/bandeja-tickets.service";

@Component({
  selector: "app-advanced-search",
  templateUrl: "./advanced-search.component.html",
  styleUrls: ["./advanced-search.component.scss"],
})
export class AdvancedSearchComponent implements OnInit {
  @Input("filters") filters: IAdvancedSearchFilters;
  private lotes: Lote[];
  bandejas: any = [];
  private estados: Item[];
  private formatoFechaControl: string = FECHA.FORMATO_VISUAL;
  formGroup: FormGroup;

  constructor(
    private controlService: ControlService,
    private userService: UserService,
    private sbcService: SbcService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private validationService: ValidationService,
    private bandejaTicketService: BandejaTicketsService
  ) { }

  ngOnInit() {
    this.lotes = this.userService.user.Lotes;
    this.sbcService.estados.forEach((estado: Estado) => {
      if (estado.Modulo === this.filters.moduloEstado) {
        this.estados = estado.Estados;
        if (estado.Modulo === MODULOS.TICKET) {
          this.estados.forEach((item) => {
            if (item.Nombre == "Todos") {
              item.Id = -1;
            }
          });
        }

        return false;
      }
    });
    this.configurarFormulario();
    if (this.userService.user.Profiles.includes("Guardia") || this.userService.user.Profiles.includes("Administrativo")) {
      this.obtenerBandejas();
    }
  }

  private obtenerBandejas() {
    this.bandejaTicketService.ObtenerBandejasByUser().then((resp: []) => {
      resp.forEach((element) => {
        this.bandejas.push(element);
      });
    });
  }

  private validar(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const fechaDesde = this.formGroup.value.desde;
      const fechaHasta = this.formGroup.value.hasta;
      if (this.validationService.rango_fecha_correcto(fechaDesde, fechaHasta)) {
        resolve(true);
      } else {
        this.controlService.mostrar_toast(
          MESSAGE.ERROR.MENSAJE_ERROR_RANGO_FECHAS,
          TIEMPO_MENSAJE
        );
        resolve(false);
      }
    });
  }

  private configurarFormulario() {
    const fechaDesde = this.utilService.obtener_fecha_diferencial(
      new Date(),
      RANGO_FECHA.ACCESO.DESCUENTO_HORAS_DESDE
    );
    const fechaHasta = this.utilService.obtener_fecha_diferencial(
      new Date(),
      RANGO_FECHA.ACCESO.DESCUENTO_HORAS_HASTA
    );

    let loteValue = null;

    if (this.userService.user.Profiles.includes('Administrativo') || this.userService.user.Profiles.includes('Guardia')) {
      loteValue = "";
    } else if (this.lotes && this.lotes.length > 0) {
      if (this.filters.selectedLote) {
        loteValue = this.filters.selectedLote;
      } else {
        loteValue = this.lotes[0].Id;
      }
    }
    this.formGroup = this.formBuilder.group({
      lotes: [loteValue],
      filtro: ["", Validators.nullValidator],
      estados: [this.estados ? this.estados[0].Id : ""],
      bandejas: [""],
      desde: [fechaDesde],
      hasta: [fechaHasta],
      nombre: ["", Validators.nullValidator],
      apellido: ["", Validators.nullValidator],
      numeroDocumento: ["", Validators.nullValidator],
      nombreContiene: [null, Validators.nullValidator],
      bandejasTicket: [""],
    });
  }

  closePopover() {
    this.controlService.cerrarPopOver();
  }

  async checkData() {
    if (this.filters.showFromToDate) {
      const rangoFechaResult: boolean = await this.validar();
      if (rangoFechaResult) {
        this.controlService.cerrarPopOver(this.formGroup.value);
      }
    } else {
      this.controlService.cerrarPopOver(this.formGroup.value);
    }
  }
}
