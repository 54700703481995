import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

// Módulos
import { ComponentsSharedModule } from "src/app/components/components-shared.module";

// Componentes
import {
  HeaderComponent,
  PhotoUploadComponent,
  FullNewComponent,
  AdvancedSearchComponent,
  DashboardModalComponent,
  CountdownComponent,
} from "src/app/components/mobile/component.mobile.index";
import { SegmentToolbarComponent } from "src/app/components/shared/components.shared.index";

@NgModule({
  declarations: [
    HeaderComponent,
    PhotoUploadComponent,
    FullNewComponent,
    AdvancedSearchComponent,
    DashboardModalComponent,
    CountdownComponent,
  ],
  exports: [
    HeaderComponent,
    SegmentToolbarComponent,
    PhotoUploadComponent,
    FullNewComponent,
    AdvancedSearchComponent,
    DashboardModalComponent,
    CountdownComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    ComponentsSharedModule,
    FormsModule,
    ReactiveFormsModule,
  ],
})
export class ComponentsMobileModule {}
