import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

// Páginas
import { EncuestaAbiertaModalPage } from "./encuesta-abierta-modal.page";

// Módulos
import { ComponentsMobileModule } from "src/app/components/components-mobile.module";

@NgModule({
  imports: [CommonModule, FormsModule, IonicModule, ComponentsMobileModule],
  declarations: [EncuestaAbiertaModalPage],
})
export class EncuestaAbiertaModalPageModule {}
