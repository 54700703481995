import { Injectable } from "@angular/core";

// Servicios
import { DeviceService } from "./device.service";
import { SbcService } from "./sbc.service";

// Plugins
import { AppVersion } from "@ionic-native/app-version/ngx";

// Clases
import { ResponseApp } from "src/app/models/entities/common/response.entity";

// Constantes
import { MESSAGE } from "src/app/constants/message.constant";
import { APP, environment } from "src/app/constants/constants.index";
import { PATH_URL } from "src/app/constants/url.constant";

@Injectable({
  providedIn: "root",
})
export class ValidationService {
  constructor(
    private deviceService: DeviceService,
    private sbcService: SbcService,
    private appVersion: AppVersion
  ) { }

  /**
   * Determina si el rango de fecha es válido
   * @param fechaDesde Fecha inicial
   * @param fechaHasta Fecha final
   * @param incluirHora Determina si en la validación se tiene en cuenta o no la hora
   */
  rango_fecha_correcto(
    fechaDesde: Date | string,
    fechaHasta: Date | string,
    incluirHora: boolean = false
  ): boolean {
    if (typeof fechaDesde === "string") {
      fechaDesde = new Date(fechaDesde);
    }
    if (typeof fechaHasta === "string") {
      fechaHasta = new Date(fechaHasta);
    }

    if (!incluirHora) {
      return (
        this.obtener_cantidad_dias(fechaDesde) <=
        this.obtener_cantidad_dias(fechaHasta)
      );
    }

    return fechaDesde <= fechaHasta;
  }

  /**
   * Compara dos fechas considerándo sólo los días (no el horario) y devuelve si el rango es correcto
   * @param fecha1 Fecha inicial
   * @param fecha2 Fecha final
   */
  equal_fecha_solo_en_dias(fecha1: Date, fecha2: Date) {
    // Nos quedamos con los días completos pasados desde el 1 de enero de 1970
    const dia1 = this.obtener_cantidad_dias(fecha1);
    const dia2 = this.obtener_cantidad_dias(fecha2);

    // comparamos los días
    return dia1 === dia2;
  }

  /**
   * Obtiene la cantidad de días de una fecha
   * @param fecha Fecha de la que se quiere obtener el total de días desde el 1 de enero de 1970
   */
  obtener_cantidad_dias(fecha: Date) {
    const dayInMillis = 24 * 3600000;

    // Nos quedamos con los días completos pasados desde el 1 de enero de 1970
    return Math.floor(fecha.getTime() / dayInMillis);
  }

  validar_condiciones_app(): Promise<ResponseApp> {
    return this.version_correcta().then((resp: ResponseApp) => {
      // Si la versión es correcta, valido bloqueo de app
      if (resp.Status) {
        return this.app_bloqueada();
      } else {
        return resp;
      }
    });
  }

  version_correcta() {
    return new Promise<ResponseApp>((resolve, reject) => {
      if (this.deviceService.isWeb || environment.APP.MOCK_MOBILE) {
        resolve(this.resolver_version_web());
      } else {
        return this.resolver_version_mobile().then((resp: ResponseApp) =>
          resolve(resp)
        );
      }
    });
  }

  app_bloqueada() {
    return new Promise<ResponseApp>((resolve, reject) => {
      // this.sbcService.versionInfo.CurrentAppMobileVersionMessage = MESSAGE.INFO.MENSAJE_APP_BLOQUEADA.MENSAJE;
      const resp = new ResponseApp();
      if (
        this.sbcService.versionInfo.CurrentAppMobileVersionMessage != null &&
        this.sbcService.versionInfo.CurrentAppMobileVersionMessage !== ""
      ) {
        resp.Status = true;
        resp.StatusDescription =
          this.sbcService.versionInfo.CurrentAppMobileVersionMessage;
        resp.Data = `${this.deviceService.isWeb
          ? PATH_URL.WEB.BLOQUEO.DEFAULT
          : PATH_URL.MOBILE.BLOQUEO.DEFAULT
          }?msg=${resp.StatusDescription}&edit=${false}`;
      } else {
        resp.Status = false;
      }

      resolve(resp);
    });
  }

  public async getAppVersion(): Promise<string | null> {
    let appVersion = await this.appVersion.getVersionNumber();

    if (appVersion) {
      appVersion = appVersion.split(".").join("");
      return appVersion;
    } else {
      return null;
    }
  }
  private async resolver_version_mobile() {
    const resp = new ResponseApp();
    return this.appVersion
      .getVersionNumber()
      .then((version: string) => {
        const versionAndroid =
          this.sbcService.versionInfo.CurrentAppMobileVersionAndroid.split(
            "."
          ).join("");
        const versionIOS =
          this.sbcService.versionInfo.CurrentAppMobileVersionIOS.split(
            "."
          ).join("");
        const version_number = version.split(".").join("");
        console.log("this.deviceService.isAndroid && versionAndroid > version_number)", this.deviceService.isAndroid && versionAndroid >= version_number);
        console.log(`${versionAndroid} > ${version_number}`);
        console.log("this.deviceService.isIos && versionIOS > version_number", this.deviceService.isIos && versionIOS >= version_number);
        console.log(`${versionIOS} > ${version_number}`);
        if (
          (this.deviceService.isAndroid && versionAndroid > version_number) ||
          (this.deviceService.isIos && versionIOS > version_number)
        ) {
          resp.Status = false;
          resp.StatusDescription = MESSAGE.ALERT.MENSAJE_APP_DESACTUALIZADA;
          resp.Data = `${PATH_URL.MOBILE.BLOQUEO.DEFAULT}?msg=${resp.StatusDescription
            }&edit=${true}`;
        } else {
          resp.Status = true;
        }

        return resp;
      })
      .catch((error) => {
        resp.StatusDescription = JSON.stringify(error);
        resp.Status = false;
        return resp;
      });
  }

  private resolver_version_web() {
    // this.sbcService.versionInfo.CurrentAppMobileVersionWeb = '1';
    const resp = new ResponseApp();
    if (
      this.sbcService.versionInfo.CurrentAppMobileVersionWeb !== APP.VERSION.WEB
    ) {
      resp.Status = false;
      resp.StatusDescription = MESSAGE.ALERT.MENSAJE_APP_DESACTUALIZADA;
      resp.Data = `${environment.APP.MOCK_MOBILE
        ? PATH_URL.MOBILE.BLOQUEO.DEFAULT
        : PATH_URL.WEB.BLOQUEO.DEFAULT
        }?msg=${resp.StatusDescription}&edit=${true}`;
    } else {
      resp.Status = true;
    }

    return resp;
  }
}
