export class TelefonoContacto {
  Id: string = null;
  IdLoteHabitante: string = null;
  Tipo: string = null;
  Orden: number = null;
  IdLote: string = null;
  IdPersona: string = null;
  DescripcionPersona: string = null;
  DescripcionTipo: string = null;
  NumeroTelefono: string = null;
}
