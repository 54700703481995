import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

// Servicios
import { DeviceService } from "./device.service";
import { SecurityService } from "../security/security.service";
import { ControlService } from "./control.service";

// Constantes
import { PATH_URL } from "src/app/constants/url.constant";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class RouteService {
  constructor(
    private deviceService: DeviceService,
    private securityService: SecurityService,
    private controlService: ControlService,
    private router: Router
  ) { }

  /**
   * Resuelve la ruta cuando el token expira
   */
  resolver_redireccion_con_token_expirado() {
    let urlRedirect: string;
    if (this.deviceService.isWeb && !environment.APP.MOCK_MOBILE) {
      urlRedirect = PATH_URL.WEB.LOGIN.DEFAULT;
    } else if (this.securityService.contiene_codigo_barrio()) {
      this.controlService.cerrar_modal();
      urlRedirect = PATH_URL.MOBILE.LOGIN.INDEX;
    } else {
      urlRedirect = PATH_URL.MOBILE.LOGIN.DEFAULT;
      this.controlService.cerrar_modal();
    }

    this.router.navigate([urlRedirect]);
  }

  resolver_redireccion_por_acceso_restringido(urlRedirect?: string) {
    if (this.deviceService.isWeb) {
      urlRedirect = urlRedirect ? urlRedirect : PATH_URL.WEB.LOGIN.DEFAULT;
    } else if (this.securityService.contiene_codigo_barrio()) {
      urlRedirect = urlRedirect ? urlRedirect : PATH_URL.MOBILE.LOGIN.INDEX;
      this.controlService.cerrar_modal();
    }

    this.router.navigate([urlRedirect]);
  }

  redireccionar(path: string, data?: any) {
    console.log("Entró a redireccionar 54", path);
    if (!data) {
      this.router.navigate([path]);
    } else {
      this.router.navigate([path], { state: data });
    }
  }
}
