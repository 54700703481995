import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";

// Páginas
import { ImagenModalMultaPage } from "./imagen-modal.page";

// Módulos
import { ComponentsMobileModule } from "src/app/components/components-mobile.module";
import { PipesModule } from "src/app/pipes/pipes.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ComponentsMobileModule,
    PipesModule,
  ],
  declarations: [ImagenModalMultaPage],
})
export class ImagenModalMultaPageModule {}
