import { Component, ViewChild, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { IonContent, IonInfiniteScroll } from "@ionic/angular";

// Interfaces
import {
  IPagination,
  IModal,
  IAdvancedSearchFilters,
} from "src/app/models/interfaces/interfaces.index";

// Constantes
import {
  MESSAGE,
  TIEMPO_MENSAJE,
  SCROLL_FAB_HEIGHT,
  ENABLED_SCROLL_EVENT,
  RANGO_FECHA,
  FECHA,
  PAGINATOR,
  MODULOS,
  POPOVER_STYLES,
} from "src/app/constants/constants.index";

// Clases
import {
  Acceso,
  Lote,
  FormModel,
  Autorizado,
} from "src/app/models/entities/entity.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { AccesoService } from "src/app/services/business/acceso.service";
import { UtilService } from "src/app/services/common/util.service";
import { ValidationService } from "src/app/services/common/validation.service";
import { DeviceService } from "src/app/services/common/device.service";

// Páginas
import { AgregarAutorizadoPage } from "src/app/pages/mobile/seguridad/autorizado/agregar/agregar-autorizado.page";

// Enums
import {
  EnumTipoAcceso,
  EnumFormaAcceso,
} from "src/app/models/enums/tipo.enum";

// COmponentes
import { AdvancedSearchComponent } from "src/app/components/mobile/component.mobile.index";

@Component({
  selector: "app-acceso",
  templateUrl: "./acceso.page.html",
  styleUrls: ["./acceso.page.scss"],
})
export class AccesoPage implements OnInit {
  private paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  mostrarFabButton = false;
  mostrarFabButtonApartirDe = SCROLL_FAB_HEIGHT;
  habilitarEventosScroll = ENABLED_SCROLL_EVENT;
  mostrarInfiniteScrollEn = PAGINATOR.SIZE;

  formatoFechaControl: string = FECHA.FORMATO_VISUAL;
  mostrarFiltro = false;
  mostrarButtonLimpiar = false;
  formGroup: FormGroup;
  modelo: FormModel;
  mostrarMensajeSinItem = false;
  accesos: Acceso[] = [];
  expandidos: any[] = [];

  loteSeleccionado: Lote;
  lotes: Lote[];
  formaAcceso = EnumFormaAcceso;

  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private userService: UserService,
    private accesoService: AccesoService,
    private utilService: UtilService,
    private validationService: ValidationService,
    private deviceService: DeviceService
  ) { }

  ngOnInit() {
    this.lotes = this.userService.user.Lotes;
    this.configurar_formulario();
    this.filtrar();
  }

  ionViewWillLeave() {
    this.limpiar_filtro(true);
  }

  filtrar() {
    this.validar().then((result: boolean) => {
      if (result) {
        this.limpiar_filtro();
        this.resolver_vista_acceso();
        this.mostrarButtonLimpiar = true;
      }
    });
  }

  resolver_vista_acceso(event?) {
    if (event) {
      this.paginacion.pagina += 1;
      this.cargar_accesos(event);
    } else {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.cargar_accesos(event);
      });
    }
  }

  limpiar_filtro(inicializarForm: boolean = false) {
    this.accesos = [];
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.accesoService.limpiar();
    this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    this.mostrarMensajeSinItem = false;
    this.mostrarButtonLimpiar = false;

    if (inicializarForm) {
      this.configurar_formulario();
    }
  }

  informar_scroll(event) {
    if (this.deviceService.isIos) {
      return;
    }

    this.content
      .getScrollElement()
      .then(
        (el) =>
        (this.mostrarFabButton =
          el.scrollTop > this.mostrarFabButtonApartirDe)
      );
  }

  volver_al_inicio() {
    this.content.scrollToTop();
  }

  toggle(exceptuando: number) {
    this.expandidos.forEach((item, index: number) => {
      if (index === exceptuando) {
        this.expandidos[index].expandido = !this.expandidos[index].expandido;
      } else {
        this.expandidos[index].expandido = false;
      }
    });
  }

  agregar_autorizado(autorizado: Autorizado) {
    const modal: IModal = {
      component: AgregarAutorizadoPage,
      componentProps: { lote: this.loteSeleccionado, autorizado: autorizado },
      returnData: true,
    };

    this.controlService.mostrar_modal(modal).then((info: any) => {
      this.filtrar();
    });
  }

  es_ingreso(acceso: Acceso) {
    return acceso.Tipo === EnumTipoAcceso.Ingreso;
  }

  doRefresh(event) {
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.accesoService.limpiar();
    this.accesoService
      .obtener_accesos(this.loteSeleccionado, this.modelo, this.paginacion)
      .then((resp: Acceso[]) => {
        this.accesos = resp;
        this.controlaFindePagina();
        event.target.complete();
      })
      .catch((error) => {
        event.target.complete();
      });
  }

  limpiar_fecha_hasta() {
    this.formGroup.controls["hasta"].setValue(this.formGroup.value.hasta);
  }

  private cargar_accesos(event?) {
    this.cargar_valores_del_formulario_al_modelo();
    this.accesoService
      .obtener_accesos(this.loteSeleccionado, this.modelo, this.paginacion)
      .then((resp: Acceso[]) => {
        if (
          resp == null ||
          this.accesoService.paginacionActual.cantidad === 0
        ) {
          this.controlService.habilitar_infinite_scroll(event, false);
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_SIN_DATOS,
            TIEMPO_MENSAJE
          );
        } else {
          this.accesos = resp;
          console.log("this.accesos", this.accesos);
          this.controlService.completar_infinite_scroll(event);
          this.sincronizar_accesos_expandidos();
        }
      })
      .catch((error) => {
        this.controlService.habilitar_infinite_scroll(event, false);
        this.controlService.mostrar_toast_con_error(error, false);
      })
      .finally(() => {
        this.controlaFindePagina();
        this.mostrarMensajeSinItem = this.accesos.length === 0;
        if (event == null) {
          this.controlService.ocultar_loading();
        }
      });
  }

  private configurar_formulario() {
    this.inicializar_controles_formulario();

    this.formGroup = this.formBuilder.group({
      lote: [this.modelo.Lote, Validators.required],
      desde: [this.modelo.FechaDesde],
      hasta: [this.modelo.FechaHasta],
      nombre: [this.modelo.Nombre, Validators.nullValidator],
      apellido: [this.modelo.Apellido, Validators.nullValidator],
      numeroDocumento: [this.modelo.Dni, Validators.nullValidator],
    });

    this.formGroup.controls["desde"].setValidators([Validators.required]);
    this.formGroup.controls["hasta"].setValidators([
      Validators.required,
      this.validar_rango_fecha.bind(this.formGroup, this.validationService),
    ]);
  }

  private inicializar_controles_formulario() {
    const fechaDesde = this.utilService.obtener_fecha_diferencial(
      new Date(),
      RANGO_FECHA.ACCESO.DESCUENTO_HORAS_DESDE
    );
    const fechaHasta = this.utilService.obtener_fecha_diferencial(
      new Date(),
      RANGO_FECHA.ACCESO.DESCUENTO_HORAS_HASTA
    );

    this.modelo = new FormModel();
    this.modelo.Lote = this.lotes[0].Id;
    this.modelo.FechaDesde = fechaDesde;
    this.modelo.FechaHasta = fechaHasta;
    this.modelo.Nombre = "";
    this.modelo.Apellido = "";
    this.modelo.Dni = "";
  }

  private cargar_valores_del_formulario_al_modelo() {
    this.modelo.Lote = this.formGroup.value.lote;
    this.modelo.FechaDesde =
      this.utilService.convertir_fecha_a_formato_yyyy_MM_dd(
        new Date(this.formGroup.value.desde)
      );
    this.modelo.FechaHasta =
      this.utilService.convertir_fecha_a_formato_yyyy_MM_dd(
        new Date(this.formGroup.value.hasta)
      );
    this.modelo.Nombre =
      this.formGroup.value.nombre === "" ? ";" : this.formGroup.value.nombre;
    this.modelo.Apellido =
      this.formGroup.value.apellido === ""
        ? ";"
        : this.formGroup.value.apellido;
    this.modelo.Dni =
      this.formGroup.value.numeroDocumento === ""
        ? ";"
        : this.formGroup.value.numeroDocumento.trim();
    this.loteSeleccionado = this.lotes.filter(
      (lote: Lote) => lote.Id === this.modelo.Lote
    )[0];
  }

  private validar_rango_fecha(
    validationService: ValidationService,
    control: FormControl
  ): { [s: string]: boolean } {
    const form: any = this;
    const fechaDesde = form.controls["desde"];
    const fechaHasta = form.controls["hasta"];

    if (
      !validationService.rango_fecha_correcto(
        fechaDesde.value,
        fechaHasta.value
      )
    ) {
      return { range: true };
    }

    return null;
  }

  private validar() {
    const promesa = new Promise((resolve, reject) => {
      const fechaDesde = this.formGroup.value.desde;
      const fechaHasta = this.formGroup.value.hasta;
      if (this.validationService.rango_fecha_correcto(fechaDesde, fechaHasta)) {
        resolve(true);
      } else {
        this.controlService.mostrar_toast(
          MESSAGE.ERROR.MENSAJE_ERROR_RANGO_FECHAS,
          TIEMPO_MENSAJE
        );
        resolve(false);
      }
    });

    return promesa;
  }

  private sincronizar_accesos_expandidos() {
    this.accesos.forEach((acceso: Acceso) => {
      this.expandidos.push({ expandido: false });
    });
  }

  open_advanced_search() {
    const componentProps: { filters: IAdvancedSearchFilters } = {
      filters: {
        showLoteFilter: true,
        showFromToDate: true,
        showDataPerson: true,
      },
    };
    this.controlService
      .mostrarPopOver({
        component: AdvancedSearchComponent,
        componentProps,
        cssClass: POPOVER_STYLES.ADVANZED_SEARCH_MODAL,
        backdropDismiss: false,
      })
      .then((data) => {
        if (data) {
          this.formGroup.controls["lote"].setValue(data.lotes);
          this.formGroup.controls["desde"].setValue(data.desde);
          this.formGroup.controls["hasta"].setValue(data.hasta);
          this.formGroup.controls["nombre"].setValue(data.nombre);
          this.formGroup.controls["apellido"].setValue(data.apellido);
          this.formGroup.controls["numeroDocumento"].setValue(
            data.numeroDocumento
          );
          this.filtrar();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  controlaFindePagina() {
    let maxPages = Math.ceil(
      this.accesoService.paginacionActual.cantidad / this.paginacion.cantidad
    );
    if (maxPages == this.paginacion.pagina)
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, false);
    else
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    //console.log('total en db:' + this.accesoService.paginacionActual.cantidad +', maxPages: ' + maxPages + ' , paginaActual: ' + this.paginacion.pagina)
  }
}
