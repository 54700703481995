export const STORAGE = {
  KEY: {
    CATALOGO: "catalogo",
    PRIMERA_INSTALACION: "primera_instalacion",
    CODIGO_BARRIO: "codigo_barrio",
    TOKEN: "token",
    EXPIRA: "expira",
    PERFIL: "usuario",
    DEVICE_ID: "deviceId",
    ONESIGNAL_ID: "oneSignalId",
    DATA: {
      ESTADOS: "estados",
      TIPO_AUTORIZADO: "tipos_autorizados",
      TIPO_RELACION: "tipos_relaciones",
    },
    USER_CREDENTIALS: "user_credentials",
    NEIGHBORHOOD_ICON: "neighborhood_icon",
    DELETE_CACHE: "delete_cache"
  },
};
