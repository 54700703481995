export const HEADER = {
  CONTENT_TYPE: {
    KEY: "Content-Type",
    VALUE: "application/json; charset=utf-8",
  },
  ACCESS_CONTROL_ALLOW_METHODS: {
    KEY: "Access-Control-Allow-Methods",
    VALUE: "GET",
  },
};
