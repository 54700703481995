import { Injectable } from "@angular/core";
// Clases
import { SettingApp } from "src/app/models/entities/entity.index";


// Servicios
import { RestService } from "./rest.service";

//import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { HTTP } from '@ionic-native/http/ngx';



interface Coords {
  latitude: string;
  longitude: string;
};

@Injectable({
  providedIn: "root",
})

export class GoogleMapsService {
  config: any;
  configServer: SettingApp;

  constructor(
    private restService: RestService,
    private http: HTTP
  ) { }

  /**
   * Obtiene las configuraciones propias del archivo config.json que es local a la app
   */
  async calculateDistance(originCoords: Coords, destinationCoords: Coords) {
    const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${originCoords.latitude},${originCoords.longitude}&destinations=${destinationCoords.latitude},${destinationCoords.longitude}&units=imperial&key=AIzaSyC8Cm36yyJHRe1qlaQRUhx5NwBEVl3HAB0&libraries=places&mode=driving`;

    return new Promise<string | null>((resolve, reject) => {
      this.http.get(url, null, null).then((data: any) => {
        console.log("data", data);
        const result = JSON.parse(data.data);
        const distance = result.rows[0].elements[0].duration.text
        if (distance) {
          resolve(distance);
        } else {
          resolve(null);
        }
      });
      /*this.restService.get(url).subscribe((data: any) => {
        const distance = data.rows[0].elements[0].duration.text
        if (distance) {
          resolve(distance);
        } else {
          resolve(null);
        }
      });*/
    });
  }
}
