import { Injectable } from "@angular/core";

// Clases
import {
  Persona,
  Lote,
  ResponseApp,
} from "src/app/models/entities/entity.index";

// Servicios
import { CatalogoService } from "./catalogo.service";
import { RestService } from "../common/rest.service";
import { UtilService } from "../common/util.service";
import { SecurityService } from "../security/security.service";
import { ExceptionService } from "../common/exception.service";

// Interfaces
import { IDictionary } from "src/app/models/interfaces/interfaces.index";
import { map } from "rxjs/operators";

// Constantes
import { IMG_APP } from "src/app/constants/config.constant";

@Injectable({
  providedIn: "root",
})
export class PersonaService {
  persona: Persona;

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private securityService: SecurityService,
    private exceptionService: ExceptionService
  ) { }

  // obtenerPersona
  async obtener_persona(documento: string): Promise<Persona> {
    const url = `${this.catalogoService.catalogo}ObtenerPersona/`;
    const options: IDictionary[] = [
      { key: "{numeroDocumento}", value: documento },
      { key: "{codigoBarrio}", value: this.securityService.codigoBarrio },
    ];

    return new Promise<Persona>((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Persona>((resp: any) => {
            this.exceptionService.resolver_error(resp);
            this.persona = this.utilService.mapear_propiedades(
              resp.Data,
              new Persona()
            );
            this.persona.UrlImagen = this.utilService.resolver_url_imagen(
              this.persona.UrlImagen
            );
            this.persona.UrlImagen =
              this.persona.UrlImagen == null || this.persona.UrlImagen === ""
                ? IMG_APP.PERFIL
                : this.persona.UrlImagen;

            return this.persona;
          })
        )
        .subscribe(
          (data: Persona) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  //**agregarPersona */
  async crear_persona(persona: Persona, lote: Lote) {
    const url = `${this.catalogoService.catalogo}AgregarPersona`;
    const body = {
      token: this.securityService.token,
      idLote: lote.Id,
      persona,
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp.Data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }
}
