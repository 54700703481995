export class Bandeja {
  Id: string = null;
  Comentarios: Comentario[] = [];
  Contenido: string = null;
  Estado: number = 0;
  EstadoDescripcion: null;
  FechaCreacion: null;
  Leido: number = 0;
  Nombre: string = null;
  Numero: null;
  Tipo: string = null;
  Titulo: string = null;
}

export class Ticket {
  Id: string = null;
  Numero: string = null;
  Titulo: string = null;
  Contenido: string = null;
  Estado: number = 0;
  Leido: number = 0;
  EstadoDescripcion: string = null;
  FechaCreacion: Date | any = null;
  Comentarios: Comentario[] = null;
  Bandeja: string = null;
  IdBandeja: string = null;
  Imagen: string = null;
  latitud?: number;
  longitud?: number;
}

export class Comentario {
  Id: string = null;
  Usuario: string = null;
  Comentario: string = null;
  Fecha: Date | string = null;
  urlImagen: string = null;
}
