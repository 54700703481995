import { Component, ViewChild, OnInit } from "@angular/core";
import { IonContent, IonInfiniteScroll } from "@ionic/angular";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Geolocation } from "@ionic-native/geolocation/ngx";

// Constantes
import {
  SCROLL_FAB_HEIGHT,
  ENABLED_SCROLL_EVENT,
  MODULOS,
  PAGINATOR,
} from "src/app/constants/config.constant";
import {
  MESSAGE,
  POPOVER_STYLES,
  TIEMPO_MENSAJE,
} from "src/app/constants/constants.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { TicketService } from "src/app/services/business/ticket.service";
import { SbcService } from "src/app/services/common/sbc.service";
import { DeviceService } from "src/app/services/common/device.service";
import { LoteService } from "src/app/services/business/lote.service";

// Clases
import {
  Lote,
  Ticket,
  Estado,
  Item,
  Bandeja,
  ResponseApp,
} from "src/app/models/entities/entity.index";

// Interfaces
import {
  IPagination,
  IModal,
  IAdvancedSearchFilters,
} from "src/app/models/interfaces/interfaces.index";

// Enums
import { EnumEstadoLeido } from "src/app/models/enums/estado.enum";

// Páginas
import { DetalleTicketPage } from "./detalle/detalle.page";
import { AgregarTicketPage } from "./agregar/agregar.page";

// Components
import { AdvancedSearchComponent } from "src/app/components/mobile/component.mobile.index";
import { BandejaTicketsService } from "src/app/services/business/bandeja-tickets.service";

@Component({
  selector: "app-ticket",
  templateUrl: "./ticket.page.html",
  styleUrls: ["./ticket.page.scss"],
})
export class TicketPage implements OnInit {
  private paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  mostrarFabButton = false;
  mostrarFabButtonApartirDe = SCROLL_FAB_HEIGHT;
  habilitarEventosScroll = ENABLED_SCROLL_EVENT;
  mostrarInfiniteScrollEn = PAGINATOR.SIZE;

  mostrarFiltro = false;
  mostrarButtonLimpiar = false;
  formGroup: FormGroup;
  mostrarMensajeSinItem = false;
  tickets: Ticket[] = [];

  loteSeleccionado: Lote;
  lotes: Lote[];

  estadoSeleccionado: Item;
  estados: Item[];
  user: any;
  bandejas: any = [];
  bandejaSeleccionada: Bandeja = null;
  ubicacion = { latitud: 0, longitud: 0 };
  esGuardia: boolean;
  esAdministrativo: boolean;
  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private userService: UserService,
    private ticketService: TicketService,
    private sbcService: SbcService,
    private deviceService: DeviceService,
    private bandejaTicketService: BandejaTicketsService,
    private geolocation: Geolocation,
    private loteService: LoteService
  ) { }

  ngOnInit() {
    this.user = this.userService.user;
    if (!this.user.Profiles.includes("Guardia")) {
      this.lotes = this.userService.user.Lotes;
    }
    this.sbcService.estados.forEach((estado: Estado) => {
      if (estado.Modulo === MODULOS.TICKET) {
        this.estados = estado.Estados;
        this.estados.forEach((item) => {
          if (item.Nombre == "Todos") {
            item.Id = -1;
          }
        });
        return false;
      }
    });

    this.configurar_formulario();
    this.filtrar();
    if (this.user.Profiles.includes("Guardia") || this.user.Profiles.includes("Administrativo")) {
      this.obtenerBandejas();
    }
  }

  async ionViewWillEnter(): Promise<void> {
    this.user = this.userService.user;
    this.esGuardia = this.userService.user.Profiles.includes("Guardia");
    this.esAdministrativo = this.userService.user.Profiles.includes("Administrativo");
  }

  private obtenerBandejas() {
    this.bandejaTicketService.ObtenerBandejasByUser().then((resp: []) => {
      resp.forEach((element) => {
        this.bandejas.push(element);
      });
      this.obtenerTickets(null);
    });
  }

  filtrar() {
    this.limpiar_filtro();
    this.setear_lote_seleccionado();
    this.setear_estado_seleccionado();
    this.setear_bandeja_seleccionado();
    this.resolver_vista_ticket();
    this.mostrarButtonLimpiar = true;
  }

  resolver_vista_ticket(event?) {
    if (event) {
      this.paginacion.pagina += 1;
      if (this.user.Profiles.includes("Guardia") || this.user.Profiles.includes("Administrativo")) {
        let bandeja = this.getBandeja(this.formGroup.value.bandejas);
        this.obtenerTickets(event);
      } else {
        this.cargar_tickets(event);
      }
    } else {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        if (this.user.Profiles.includes("Guardia") || this.user.Profiles.includes("Administrativo")) {
          let bandeja = this.getBandeja(this.formGroup.value.bandejas);
          this.obtenerTickets(event);
        } else {
          this.cargar_tickets(event);
        }
      });
    }
  }

  private getBandeja(idBandeja) {
    let bandeja = this.bandejas.find((bandeja) => bandeja.Id == idBandeja);

    return bandeja;
  }

  limpiar_filtro() {
    this.tickets = [];
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.ticketService.limpiar();
    this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    this.mostrarButtonLimpiar = false;
    this.mostrarMensajeSinItem = false;
  }

  async mostrar_agregar_ticket() {
    const modal: IModal = { component: AgregarTicketPage, returnData: true };

    this.controlService.mostrar_modal(modal).then((info: any) => {
      this.filtrar();
    });
  }

  async mostrar_detalle(ticket: Ticket) {

    const allBandejas = await this.bandejaTicketService.ObtenerBandejas();

    const modal: IModal = {
      component: DetalleTicketPage,
      componentProps: {
        ticketInput: ticket,
        bandejas: allBandejas,
        bandejaTicketService: this.bandejaTicketService,
        user: this.user
      },
      returnData: true,
    };

    this.controlService.mostrar_modal(modal).then((info: any) => {
      this.filtrar();
    });
  }

  informar_scroll(event) {
    if (this.deviceService.isIos) {
      return;
    }

    this.content
      .getScrollElement()
      .then(
        (el) =>
        (this.mostrarFabButton =
          el.scrollTop > this.mostrarFabButtonApartirDe)
      );
  }

  volver_al_inicio() {
    this.content.scrollToTop();
  }

  ticket_leido(ticket: Ticket) {
    return ticket.Leido === EnumEstadoLeido.Leida;
  }

  doRefresh(event) {
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.ticketService.limpiar();
    this.ticketService
      .obtener_tickets(
        this.loteSeleccionado,
        this.estadoSeleccionado,
        this.paginacion,
        this.bandejaSeleccionada
      )
      .then((resp: Ticket[]) => {
        this.tickets = resp;
        this.controlaFindePagina();
        event.target.complete();
      })
      .catch((error) => {
        event.target.complete();
      });
  }

  private cargar_tickets(event?) {
    this.ticketService
      .obtener_tickets(
        this.loteSeleccionado,
        this.estadoSeleccionado,
        this.paginacion,
        this.bandejaSeleccionada
      )
      .then((resp: Ticket[]) => {
        if (
          resp == null ||
          this.ticketService.paginacionActual.cantidad === 0
        ) {
          this.controlService.habilitar_infinite_scroll(event, false);
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_SIN_DATOS,
            TIEMPO_MENSAJE
          );
        } else {
          this.tickets = this.tickets.concat(resp);
          this.controlService.completar_infinite_scroll(event);
        }
      })
      .catch((error) => {
        this.controlService.habilitar_infinite_scroll(event, false);
        this.controlService.mostrar_toast_con_error(error, false);
      })
      .finally(() => {
        this.controlaFindePagina();
        this.mostrarMensajeSinItem = this.tickets.length === 0;
        if (event == null) {
          this.controlService.ocultar_loading();
        }
      });
  }

  private obtenerTickets(event?) {
    this.ticketService
      .obtener_tickets(
        this.loteSeleccionado,
        this.estadoSeleccionado,
        this.paginacion,
        this.bandejaSeleccionada
      )
      .then((resp: Ticket[]) => {
        if (
          resp == null ||
          this.ticketService.paginacionActual.cantidad === 0
        ) {
          this.controlService.habilitar_infinite_scroll(event, false);
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_SIN_DATOS,
            TIEMPO_MENSAJE
          );
        } else {
          this.tickets = this.tickets.concat(resp);
          this.controlService.completar_infinite_scroll(event);
        }
      })
      .catch((error) => {
        this.controlService.habilitar_infinite_scroll(event, false);
        this.controlService.mostrar_toast_con_error(error, false);
      })
      .finally(() => {
        this.controlaFindePagina();
        this.mostrarMensajeSinItem = this.tickets.length === 0;
        if (event == null) {
          this.controlService.ocultar_loading();
        }
      });
  }

  private configurar_formulario() {

    const validations = {
      lotes: [this.lotes && this.lotes.length > 0 ? this.lotes[0].Id : "", Validators.required],
      estados: [this.estados && this.estados.length > 0 ? this.estados[0].Id : "", Validators.required],
    };
    if (this.user.Profiles.includes("Guardia") || this.user.Profiles.includes("Administrativo")) {
      validations["bandejas"] = [null, Validators.required];
    }

    this.formGroup = this.formBuilder.group(validations);
  }

  private setear_lote_seleccionado() {
    if (this.lotes && this.lotes.length > 0) {
      this.loteSeleccionado = this.lotes.filter(
        (lote: Lote) => lote.Id === this.formGroup.value.lotes
      )[0];
    } else {
      this.loteSeleccionado = this.formGroup.value.lotes;
    }
  }

  private setear_estado_seleccionado() {
    this.estadoSeleccionado = this.estados.filter(
      (estado: Item) => estado.Id === this.formGroup.value.estados
    )[0];
  }

  private setear_bandeja_seleccionado() {
    if (this.formGroup.value.bandejas) {
      this.bandejaSeleccionada = this.bandejas.filter(
        (bandeja: Bandeja) => bandeja.Id === this.formGroup.value.bandejas
      )[0];
    }
  }

  open_advanced_search() {
    let componentProps: { filters: IAdvancedSearchFilters };
    if (this.esGuardia || this.esAdministrativo) {
      componentProps = {
        filters: {
          showLoteGuardiaFilter: true,
          showEstadoFilter: true,
          showBandejaFilter: true,
          moduloEstado: MODULOS.TICKET,
        },
      };
    } else {
      componentProps = {
        filters: {
          showLoteFilter: true,
          showEstadoFilter: true,
          moduloEstado: MODULOS.TICKET,
        },
      };
    }

    this.controlService
      .mostrarPopOver({
        component: AdvancedSearchComponent,
        componentProps,
        cssClass: POPOVER_STYLES.ADVANZED_SEARCH_MODAL,
        backdropDismiss: false,
      })
      .then(async (data) => {
        if (data) {
          this.formGroup.controls["estados"].setValue(data.estados);
          if (data.bandejas) {
            this.formGroup.controls["bandejas"].setValue(data.bandejas);
          }
          if (this.user.Profiles.includes("Guardia") || this.user.Profiles.includes("Administrativo")) {
            if (data.lotes != "") {
              let loteSeleccionado = await this.obtener_info_lote(data.lotes);
              this.formGroup.controls["lotes"].setValue(loteSeleccionado["Id"]);
            } else {
              this.formGroup.controls["lotes"].setValue(null);
            }
            this.filtrar();
          } else {
            this.formGroup.controls["lotes"].setValue(data.lotes);
            this.filtrar();
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  edit_bandeja_ticket(ticket: any) {
    const componentProps: { filters: IAdvancedSearchFilters } = {
      filters: {
        cambiarBandeja: true,
      },
    };
    this.controlService
      .mostrarPopOver({
        component: AdvancedSearchComponent,
        componentProps,
        cssClass: POPOVER_STYLES.ADVANZED_SEARCH_MODAL,
        backdropDismiss: false,
      })
      .then(async (data) => {
        if (data) {
          await this.geolocation
            .getCurrentPosition()
            .then(async (resp) => {
              this.ubicacion.latitud = resp.coords.latitude;
              this.ubicacion.longitud = resp.coords.longitude;
              ticket.latitud = this.ubicacion.latitud;
              ticket.longitud = this.ubicacion.longitud;
              ticket.IdBandeja = data.bandejasTicket;
              this.controlService
                .mostrarLoading(MESSAGE.LOADING.DEFAULT)
                .then(() => {
                  this.bandejaTicketService
                    .TicketMobileUpdate(ticket, data.bandejasTicket, 0)
                    .then((resp: ResponseApp) => {
                      this.controlService.mostrar_alert({
                        header:
                          MESSAGE.INFO.MENSAJE_TICKET.TICKET_GUARDADO.TITULO,
                        message: `El ticket ${resp.Data} ha sido guardado`,
                        backdropDismiss: true,
                        buttons: [
                          {
                            text: MESSAGE.INFO.MENSAJE_TICKET.OK,
                            role: "cancel",
                          },
                        ],
                      });
                    })
                    .catch((error) => {
                      this.controlService.mostrar_alert({
                        header:
                          MESSAGE.INFO.MENSAJE_TICKET.TICKET_NO_GUARDADO.TITULO,
                        message: `El ticket no ha sido guardado. Revise los datos.`,
                        backdropDismiss: true,
                        buttons: [
                          {
                            text: MESSAGE.INFO.MENSAJE_TICKET.OK,
                            role: "cancel",
                          },
                        ],
                      });
                    })
                    .finally(() => {
                      this.controlService.ocultar_loading();
                    });
                });
            })
            .catch((error) => {
              console.log("Error getting location", error);
            });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async obtener_info_lote(loteNumero: string) {
    return this.loteService
      .LoteMobileByNumeroGet(loteNumero)
      .then((resp: Lote[]) => {
        return resp;
      });
  }
  //controlamos si llegó al final de la paginación para deshabilitar el Infinite Scroll
  controlaFindePagina() {
    let maxPages = Math.ceil(
      this.ticketService.paginacionActual.cantidad / this.paginacion.cantidad
    );
    if (maxPages == this.paginacion.pagina)
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, false);
    else
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    //console.log('total en db:' + this.ticketService.paginacionActual.cantidad +', maxPages: ' + maxPages + ' , paginaActual: ' + this.paginacion.pagina)
  }

  parseLotes(lotes: Lote[]): string {
    return lotes.map((lote) => lote.Numero).join(", ");
  }

  markAllAsRead(): void {
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.ticketService
        .marcarTodoLeido()
        .then(() => {
          this.paginacion.pagina = PAGINATOR.PAGE_INIT;
          this.ticketService.limpiar();
          this.ticketService
            .obtener_tickets(
              this.loteSeleccionado,
              this.estadoSeleccionado,
              this.paginacion,
              this.bandejaSeleccionada
            )
            .then((resp: Ticket[]) => {
              this.tickets = resp;
              this.controlaFindePagina();
            });
        })
        .catch((error) => {
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    });
  }
}
