import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Servicios
import { LoteService } from "src/app/services/business/lote.service";
import { CatalogoService } from "../business/catalogo.service";
import { SecurityService } from "./security.service";
import { RestService } from "../common/rest.service";
import { StorageService } from "../common/storage.service";
import { UtilService } from "../common/util.service";
import { DeviceService } from "../common/device.service";
import { PushNotificationService } from "../common/push-notification.service";
import { ExceptionService } from "../common/exception.service";

// Clases
import {
  Lote,
  Usuario,
  Perfil,
  ResponseApp,
} from "src/app/models/entities/entity.index";

// Constantes
import { STORAGE } from "src/app/constants/constants.index";
import { rejects } from "assert";

@Injectable({
  providedIn: "root",
})
export class UserService {
  private lotes: Lote[] = [];
  user: Usuario = new Usuario();

  constructor(
    private loteService: LoteService,
    private catalogoService: CatalogoService,
    private securityService: SecurityService,
    private restService: RestService,
    private storageService: StorageService,
    private utilService: UtilService,
    private deviceService: DeviceService,
    private pushNotificationService: PushNotificationService,
    private exceptionService: ExceptionService
  ) { }

  async cargar_datos_perfil(perfil?: Usuario | Perfil) {
    return new Promise((resolve, reject) => {
      this.storageService.cargar(STORAGE.KEY.PERFIL).then((result: string) => {
        if (perfil) {
          console.log("perfil en cargar_datos_perfil 50", perfil);
          this.loteService.obtener_lotes().then((lotes: Lote[]) => {
            this.user = this.utilService.mapear_propiedades(
              perfil,
              new Usuario(),
              true
            );
            this.user.DeviceId = this.deviceService.uuid;
            this.user.OneSignalId = this.pushNotificationService.oneSignalId.value;
            this.lotes = lotes;
            this.user.Lotes = this.lotes;
            console.log("this.user en cargar_datos_perfil 61", this.user);
            this.storageService.guardar({
              key: STORAGE.KEY.PERFIL,
              value: JSON.stringify(this.user),
            });
            resolve(true);
            return;
          });
        } else if (result) {
          console.log("user del json", JSON.parse(result));
          this.user = JSON.parse(result);
          console.log("user de userService", this.user);
          resolve(true);
        } else {
          resolve(false);
        }
      });
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  //**guardar */
  guardar(perfil: Perfil) {
    const url = `${this.catalogoService.catalogo}MobileUserUpdate`;
    const body = {
      token: this.securityService.token,
      mobileUser: perfil,
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            console.log(resp);
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            this.cargar_datos_perfil(perfil);
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async cambiar_password(passwordNuevo: string, confirmacionPassword: string) {
    const url = `${this.catalogoService.catalogo}ChangePassword`;
    const body = {
      token: this.securityService.token,
      password: passwordNuevo,
      confirmPassword: confirmacionPassword,
    };

    return new Promise((resolve, reject) => {
      this.restService
        .put(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            console.log(resp);
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            this.user.PrimerIngreso = false;
            this.cargar_datos_perfil(this.user);
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async ImageIsBiometricValid(imageBase64: string) {
    const url = `${this.catalogoService.catalogo}MobileUserImageIsBiometricValid`;
    const body = {
      token: this.securityService.token,
      imageBase64: imageBase64,
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp.Status);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }
}
