import { Persona } from "./persona.entity";

export class Observacion {
  Id: string = null;
  IdLote: string = null;
  Observacion: string = null;
  Persona: Persona = null;
  VigenciaDesde?: Date | any = null;
  VigenciaHasta?: Date | any = null;
}
