import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";
import * as moment from "moment";

// Componentes
import { MascotaColor, MascotaEspecie, MascotaTamano } from "src/app/models/entities/business/mascota.entity";
import { FileItem } from "src/app/models/entities/entity.index";

// Interfaces
import { FileService } from "src/app/services/common/file.service";

export interface DialogData {
  mascota: null | any;
  especies: MascotaEspecie[];
  colores: MascotaColor[];
  tamanos: MascotaTamano[];
}

@Component({
  selector: "app-mascota-form",
  templateUrl: "./mascota-form.component.html",
  styleUrls: ["./mascota-form.component.scss"],
})

export class MascotaFormComponent implements OnInit {

  nombre: string = null;
  identificacion: string = null;
  idEspecie: string = null;
  idRaza: string = null;
  idTamano: number = null;
  idColor: number = null;
  archivos: FileItem[] = [];
  archivosCertificado: FileItem[] = [];
  mascotaImage: string = null;
  certificadoImage: string = null;
  certificadoVencimiento: any = null;

  constructor(
    public dialogRef: MatDialogRef<MascotaFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialog: MatDialog,
    private fileService: FileService,
  ) { }


  ngOnInit() {
    if (this.data.mascota) {
      this.nombre = this.data.mascota.Nombre;
      this.identificacion = this.data.mascota.Identificacion;
      this.idColor = this.data.mascota.IdColor;
      this.idEspecie = this.data.mascota.IdEspecie;
      this.idRaza = this.data.mascota.IdRaza;
      this.idTamano = this.data.mascota.Tamano;
    }
  }


  getRazas() {
    if (this.idEspecie !== null) {
      const idEspecie = this.idEspecie;
      return this.data.especies.find(item => item.Id === idEspecie).Razas;
    } else {
      return [];
    }
  }

  setEspecieId(event) {
    this.idEspecie = event.value;
  }
  setRazaId(event) {
    this.idRaza = event.value;
  }
  setTamanoId(event) {
    this.idTamano = event.value;
  }
  setColorId(event) {
    this.idColor = event.value;
  }
  setNombre(event) {
    this.nombre = event.target.value;
  }
  setIdentificacion(event) {
    this.identificacion = event.target.value;
  }
  async setVencimientoCertificado(event: any) {
    console.log(moment(event.value._d).format('YYYYMMDD'));
    this.certificadoVencimiento = moment(event.value._d).format('YYYYMMDD');
  }

  ajustar_imagenes(event: FileItem[]) {
    if (event && event.length > 0 && event[0].archivo) {
      this.mascotaImage = this.fileService.obtener_urlBase64_con_prefijo(
        event[0].urlBase64
      );
    }
  }

  ajustar_imagen_certificado(event: FileItem[]) {
    if (event && event.length > 0 && event[0].archivo) {
      this.certificadoImage = this.fileService.obtener_urlBase64_con_prefijo(
        event[0].urlBase64
      );
    }
  }

  async obtener_imagen_seleccionada() {
    if (this.archivos.length > 0) {
      const url = await this.fileService.convert_to_base64(
        this.archivos[0],
        true
      );
      return url;
    }

    return null;
  }

  async obtener_imagen_certificado_seleccionada() {
    if (this.archivosCertificado.length > 0) {
      const url = await this.fileService.convert_to_base64(
        this.archivosCertificado[0],
        true
      );
      return url;
    }

    return null;
  }

  focusPicker(picker: any) {
    picker.open();
  }


  formDisabled(): boolean {
    return !this.nombre ||
      !this.identificacion ||
      !this.idEspecie ||
      !this.idRaza ||
      !this.idColor ||
      this.idTamano === null ||
      this.archivos.length === 0;
  }

  close() {
    this.dialogRef.close();
  }

  async onClick() {
    const mascota = {
      Id: "00000000-0000-0000-0000-000000000000",
      Nombre: this.nombre,
      Identificacion: this.identificacion,
      IdEspecie: this.idEspecie,
      IdRaza: this.idRaza,
      IdColor: this.idColor,
      Tamano: this.idTamano,
      FotografiaBase64: await this.obtener_imagen_seleccionada(),
      VacunaVencimiento: this.certificadoVencimiento,
      VacunaFotoBase64: await this.obtener_imagen_certificado_seleccionada(),
    };

    this.dialogRef.close({ mascota });
  }
}