import { Component, OnInit } from "@angular/core";
import { Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

// Interfaces
import { IAdvancedSearchFilters } from "src/app/models/interfaces/common/advanced-search-filters.interface";
import { IIdentity } from "src/app/models/interfaces/interfaces.index";
//Services
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { SbcService } from "src/app/services/common/sbc.service";
import { UtilService } from "src/app/services/common/util.service";
import { ValidationService } from "src/app/services/common/validation.service";
import { SegmentService } from "src/app/services/common/segment.service";
import { EnumTipoSegmento } from "src/app/models/enums/tipo.enum";

@Component({
  selector: "app-dashboard-modal",
  templateUrl: "./dashboard-modal.component.html",
  styleUrls: ["./dashboard-modal.component.scss"],
})
export class DashboardModalComponent implements OnInit {
  @Input("filters") filters: IAdvancedSearchFilters;
  segmentosHomeAgrupados: IIdentity[] = [];
  segmentosMensajeAgrupados: IIdentity[] = [];
  segmentosSeguridadAgrupados: IIdentity[] = [];
  segmentosMensaje: IIdentity[];
  segmentosSeguridad: IIdentity[];
  segmentType = EnumTipoSegmento;
  formGroup: FormGroup;
  segments = [];

  constructor(
    private controlService: ControlService,
    private utilService: UtilService,
    private segmentService: SegmentService,
    private router: Router
  ) {
    const filteredSegmentsHome = this.segmentService.segmentosHome.filter(
      ({ id }) => id !== "dashboard"
    );
    this.segmentosHomeAgrupados = this.utilService.agrupar_array(filteredSegmentsHome, 4);
    this.segmentosMensajeAgrupados = this.utilService.agrupar_array(this.segmentService.segmentosMensaje, 4);
    this.segmentosSeguridadAgrupados = this.utilService.agrupar_array(this.segmentService.segmentosSeguridad, 4);

    this.segments = [
      {
        type: EnumTipoSegmento.Home,
        title: "Información",
        data: this.segmentosHomeAgrupados,
      },
      {
        type: EnumTipoSegmento.Mensaje,
        title: "Actividad",
        data: this.segmentosMensajeAgrupados,
      },
      {
        type: EnumTipoSegmento.Seguridad,
        title: "Seguridad",
        data: this.segmentosSeguridadAgrupados,
      },
    ];
  }

  ngOnInit() { }

  showItem(id: string): boolean {
    //excluimos los items de detalleNoticia y novedades de la app de
    const items = ["detalleNoticia", "onboarding"];

    // si el id no es ninguno de los de la lista, entonces se muestra
    return !items.includes(id);
  }

  navigateTo(segmentType: EnumTipoSegmento, option: IIdentity): void {
    this.router.navigate([option.path]);
    setTimeout(() => {
      this.segmentService.actualSegment.next({
        type: segmentType,
        segment: option,
      });
      this.controlService.cerrar_modal();
    }, 10);
  }
}
