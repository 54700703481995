import { Component } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { SafeUrl } from "@angular/platform-browser";
import { MenuController } from "@ionic/angular";

// Clases
import { Usuario, ResponseApp } from "src/app/models/entities/entity.index";

// Constantes
import {
  MESSAGE,
  TIEMPO_MENSAJE,
  MENU,
  PATH_URL,
} from "src/app/constants/constants.index";
import { STORAGE } from "src/app/constants/storage.constant";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { AuthService } from "src/app/services/security/auth.service";
import { UtilService } from "src/app/services/common/util.service";
import { UserService } from "src/app/services/security/user.service";
import { SbcService } from "src/app/services/common/sbc.service";
import { StorageService } from "src/app/services/common/storage.service";
import { DeviceService } from "src/app/services/common/device.service";
import { PushNotificationService } from "src/app/services/common/push-notification.service";
import { SecurityService } from "src/app/services/security/security.service";
import { SegmentService } from "src/app/services/common/segment.service";
import { MenuService } from "src/app/services/common/menu.service";

// Interfaces
import {
  IModal,
  IIdentity,
  ICredentials,
} from "src/app/models/interfaces/interfaces.index";

// Enums
import { EnumTipoMenu } from "src/app/models/enums/tipo.enum";

// Páginas
import { ChangePasswordPage } from "src/app/pages/mobile/perfil/change-password/change-password.page";
import { PreNoticiaPage } from "src/app/pages/mobile/pre-holder/pre-noticia/pre-noticia.page";
import { ForgotMyPasswordPage } from "./forgot-my-password/forgot-my-password.page";

//Plugins
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { MobileService } from 'src/app/services/common/mobile.service';
import { Subscription } from 'rxjs';

@Component({
  selector: "app-login",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
})
export class LoginPage {
  icono: string | SafeUrl = null;
  mostrarPassword: boolean = false;
  formGroup: FormGroup;
  usuario: Usuario;
  urls: IIdentity[] = MENU.MOBILE.LOGIN;
  mostrarHuellaFaceID = false;
  credentialsInStorage: ICredentials = { username: "", password: "" };
  faioIsAvailable: boolean;
  credentialsAreInvalid = false;
  primerInstalacion = true;
  oneSignalId: string;

  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private auth: AuthService,
    private userService: UserService,
    private sbcService: SbcService,
    private router: Router,
    private storage: StorageService,
    private deviceService: DeviceService,
    private pushNotificationService: PushNotificationService,
    private securityService: SecurityService,
    private segmentService: SegmentService,
    private menuService: MenuService,
    private faio: FingerprintAIO,
    private menuController: MenuController,
    private storageService: StorageService
  ) {
    this.configurar_formulario();
    this.checkFaioIsAvailable();
  }

  async ionViewWillEnter() {
    this.menuController.enable(false);

    if (this.securityService.codigoBarrio) {
      this.sbcService
        .resolver_menu(EnumTipoMenu.Login)
        .subscribe((resp: string | IIdentity[]) => {
          if (typeof resp !== "string") {
            console.log(resp);
            this.urls = this.menuService.reemplazar_menu(this.urls, resp);
          }
        });

      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(
        () => {
          this.sbcService
            .resolver_icono()
            .subscribe((icono: string | SafeUrl) => {
              this.icono = icono;
              this.storage.guardar({ key: STORAGE.KEY.NEIGHBORHOOD_ICON, value: JSON.stringify(this.icono) });

              this.pushNotificationService.oneSignalId.subscribe((oneSignalId) => {
                console.log("oneSignalIdRespuesta", oneSignalId);
                this.oneSignalId = oneSignalId;
                if (oneSignalId) {
                  this.controlService.ocultar_loading();
                }
              });
              // this.storage.guardar({
              //   key: STORAGE.KEY.NEIGHBORHOOD_ICON,
              //   value: JSON.stringify(this.icono),
              // });
              // this.controlService.ocultar_loading();

              let navbar = document.getElementById("navbar");
              if (navbar) navbar.removeAttribute("style");

              let content = document.getElementById("content");
              if (content) content.removeAttribute("style");

              this.storage
                .cargar(STORAGE.KEY.USER_CREDENTIALS)
                .then((resp: string) => {
                  if (resp) {
                    resp = JSON.parse(this.storage.currentValue);
                    this.mostrarHuellaFaceID = true;
                    this.credentialsInStorage.username = resp["username"];
                    this.credentialsInStorage.password = resp["password"];
                  }
                });
            });
        },
        (error) => {
          this.controlService.mostrar_toast_con_error(error, false);
          this.controlService.ocultar_loading();
        }
      );
    }
  }

  ionViewDidLeave() {
    this.menuController.enable(true);
  }

  showHardlockIcon(): boolean {
    if (this.securityService.codigoBarrio === "stabarbara") return false;

    return true;
  }

  checkFaioIsAvailable() {
    this.faio
      .isAvailable()
      .then((resp) => {
        this.faioIsAvailable = true;
      })
      .catch((error) => {
        console.log("error", error);
        this.faioIsAvailable = false;
      });
  }

  loginHuellaFaceID() {
    this.faio
      .show({
        disableBackup: true,
        fallbackButtonTitle: "Use Backup",
      })
      .then((result: any) => {
        this.formGroup.controls["username"].setValue(
          this.credentialsInStorage.username
        );
        this.formGroup.controls["password"].setValue(
          this.credentialsInStorage.password
        );
        this.signIn();
      })
      .catch((error: any) => {
        if (error.code == -111 || error.code == -108) {
          this.mostrarHuellaFaceID = !this.mostrarHuellaFaceID;
          this.formGroup.controls["username"].setValue(null);
          this.formGroup.controls["password"].setValue(null);
        }
      });
  }

  loginWithAnotherUser() {
    this.mostrarHuellaFaceID = !this.mostrarHuellaFaceID;
  }

  signIn() {
    if (this.formGroup.valid) {
      this.pasar_form_a_modelo();
      this.controlService
        .mostrarLoading(MESSAGE.LOADING.DEFAULT)
        .then(() => {
          this.auth
            .login(this.usuario)
            .then((resp: ResponseApp) => {
              if (resp.StatusDescription) {
                this.controlService.mostrar_alert({
                  message: resp.StatusDescription,
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: MESSAGE.ALERT.PERFIL.OK,
                      handler: () => { },
                    },
                  ],
                });
              }

              this.storage.cargar(STORAGE.KEY.PERFIL).then(() => { });

              let data = JSON.stringify(this.formGroup.value);
              this.storage.guardar({
                key: STORAGE.KEY.USER_CREDENTIALS,
                value: data,
              });

              this.segmentService.actualizar_configuración_segmentos();
              this.credentialsAreInvalid = false;
              this.inicializar_info_app();
            })
            .catch((error) => {
              this.credentialsAreInvalid = true;
              this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
              this.controlService.ocultar_loading();
            });
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    }
  }

  cambiar_vista_password() {
    this.mostrarPassword = !this.mostrarPassword;

    return this.mostrarPassword;
  }

  private configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
  }

  private pasar_form_a_modelo() {
    this.usuario = new Usuario();
    const userName = this.formGroup.value.username;
    const password = this.formGroup.value.password;
    this.usuario.DeviceId = this.deviceService.uuid;
    this.usuario.OneSignalId = this.oneSignalId;
    this.usuario.UserName = userName;
    this.usuario.Password = password;
  }

  private inicializar_info_app() {
    this.sbcService.cargar_datos_aplicacion();
    this.resolver_redireccion(this.userService.user);
  }

  private resolver_redireccion(usuario: Usuario) {
    if (usuario.PrimerIngreso) {
      this.mostrar_modal_cambio_password();
    } else {
      this.redireccionar();
    }
  }

  private async mostrar_modal_cambio_password() {
    const modal: IModal = { component: ChangePasswordPage, returnData: true };

    this.controlService.mostrar_modal(modal).then(() => {
      this.redireccionar();
    });
  }

  async getPrimeraInstalacion() {
    return new Promise((resolve, reject) => {
      this.storageService
        .cargar(STORAGE.KEY.PRIMERA_INSTALACION)
        .then((primeraInstalacion: string) => {
          try {
            if (primeraInstalacion) {
              this.primerInstalacion = JSON.parse(primeraInstalacion) === true;
              resolve(primeraInstalacion);
            } else {
              resolve(false);
            }
          } catch (error) {
            reject(error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  private async redireccionar() {
    await this.getPrimeraInstalacion();
    if (this.primerInstalacion && !this.userService.user.Profiles.includes('Guardia')) {
      this.router.navigate([PATH_URL.MOBILE.ONBOARDING.DEFAULT]);
    } else {
      this.router.navigate([this.segmentService.obtener_route_habilitado()]);
    }
  }

  forgotMyPassword(): void {
    const componente = ForgotMyPasswordPage;
    const modal: IModal = { component: componente };

    this.controlService.mostrar_modal(modal);
  }
}
