import { Injectable, EventEmitter } from "@angular/core";
import { map } from "rxjs/operators";

// Servicios
import { RestService } from "../common/rest.service";
import { CatalogoService } from "./catalogo.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";

// Constantes
import { IDictionary } from "src/app/models/interfaces/interfaces.index";

// Clases
import { DashboardItem } from "src/app/models/entities/business/dashboardItem";

@Injectable({
  providedIn: "root",
})
export class DashboardService {
  dashboardItems = new EventEmitter<DashboardItem[]>();

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private exceptionService: ExceptionService
  ) {}

  async get_dashboard_items(
    loteId: string = "00000000-0000-0000-0000-000000000000"
  ): Promise<DashboardItem[] | string> {
    const url = `${this.catalogoService.catalogo}DashboardGetNoLeidos/`;
    const options: IDictionary[] = [{ key: "{idLote}", value: loteId }];

    return this.get_dashboard_result(url, true, false, options);
  }

  private async get_dashboard_result(
    url: string,
    token: boolean,
    withBarrio: boolean,
    options: IDictionary[]
  ) {
    return new Promise<DashboardItem[]>((resolve, reject) => {
      this.restService
        .get(url, token, withBarrio, options)
        .pipe(
          map<any, DashboardItem[]>((resp: any) => {
            console.log(resp);
            this.exceptionService.resolver_error(resp);
            const dashboardItems = this.utilService.mapear_propiedades(
              resp.Data,
              new DashboardItem()
            );
            this.dashboardItems.next(dashboardItems);

            return dashboardItems;
          })
        )
        .subscribe(
          (data: DashboardItem[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }
}
