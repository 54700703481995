import { Component, OnInit, Input } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";

// Interfaces
import { IModal } from "src/app/models/interfaces/interfaces.index";

// Clases
import {
  AutorizadoDelLote,
  FormModel,
  Item,
  Lote,
  LoteAutorizado,
  ResponseApp,
  Dia,
} from "src/app/models/entities/entity.index";

// Constantes
import { RANGO_FECHA, FECHA } from "src/app/constants/config.constant";
import { AutorizadoService } from "src/app/services/business/autorizado.service";
import { ControlService } from "src/app/services/common/control.service";
import { SbcService } from "src/app/services/common/sbc.service";
import { UtilService } from "src/app/services/common/util.service";
import { ValidationService } from "src/app/services/common/validation.service";
import { MESSAGE, TIEMPO_MENSAJE } from "src/app/constants/constants.index";
// Páginas
import { ObservacionAutorizadoPage } from "../observacion/observacion-autorizado.page";

@Component({
  selector: "app-detalle-autorizado",
  templateUrl: "./detalle-autorizado.page.html",
  styleUrls: ["./detalle-autorizado.page.scss"],
})
export class DetalleAutorizadoPage implements OnInit {
  @Input() lote: Lote;
  @Input() autorizado: AutorizadoDelLote;
  anioMin: number = RANGO_FECHA.ACCESO.NUEVO.ANIO_MINIMO;
  anioMax: number = RANGO_FECHA.ACCESO.NUEVO.ANIO_MAXIMO;
  formatoFechaControl: string = FECHA.FORMATO_VISUAL;
  formGroup: FormGroup;
  modelo: FormModel;
  relaciones: Item[] = [];
  tiposAutorizados: Item[];

  constructor(
    private formBuilder: FormBuilder,
    private autorizadoService: AutorizadoService,
    private controlService: ControlService,
    private sbcService: SbcService,
    private utilService: UtilService,
    private validationService: ValidationService
  ) {}

  ngOnInit() {
    if (this.autorizado.IdTipoRelacion === 8) {
      // es de tipo invitado
      let item = new Item();
      item.Id = 8;
      item.Nombre = "Invitado Qr";
      this.relaciones.push(item);
    } else {
      this.relaciones = this.sbcService.tiposRelaciones;
    }

    this.tiposAutorizados = this.sbcService.tiposAutorizaciones;
    this.configurar_formulario();
  }

  cerrar_modal() {
    this.controlService.cerrar_modal(this.autorizado);
  }

  limpiar_fecha_desde() {
    (<FormControl>this.formGroup.controls["desde"]).setValue("");
  }

  limpiar_fecha_hasta() {
    (<FormControl>this.formGroup.controls["hasta"]).setValue("");
  }

  actualizar_autorizado() {
    this.cargar_valores_del_formulario_al_modelo();
    this.validar().then((result: boolean) => {
      if (result) {
        const autorizado = this.mapear_autorizacion();
        this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
          this.autorizadoService
            .actualizar_autorizacion(autorizado, this.lote)
            .then((resp: ResponseApp) => {
              this.controlService.mostrar_toast(
                MESSAGE.INFO.MENSAJE_CAMBIOS_GUARDADOS,
                TIEMPO_MENSAJE
              );
            })
            .catch((error) => {
              this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
            })
            .finally(() => {
              this.controlService.ocultar_loading();
              this.cerrar_modal();
            });
        });
      } else {
        this.controlService.mostrar_toast(
          MESSAGE.ALERT.MENSAJE_VALIDACIONES_INCORRECTAS,
          TIEMPO_MENSAJE
        );
      }
    });
  }

  observaciones_autorizado() {
    const modal: IModal = {
      component: ObservacionAutorizadoPage,
      componentProps: { lote: this.lote, autorizado: this.autorizado },
      returnData: true,
    };

    this.controlService.mostrar_modal(modal).then((info: any) => {
      //  this.filtrar();
    });
  }

  eliminar_autorizado() {
    this.controlService.mostrar_alert({
      header: MESSAGE.ALERT.MENSAJE_AUTORIZADO.ELIMINAR_AUTORIZACION.TITULO,
      message: MESSAGE.ALERT.MENSAJE_AUTORIZADO.ELIMINAR_AUTORIZACION.PREGUNTA,
      backdropDismiss: false,
      buttons: [
        {
          text: MESSAGE.ALERT.MENSAJE_AUTORIZADO.ELIMINAR_AUTORIZACION.BUTTONS
            .CANCELAR,
          role: "cancel",
        },
        {
          text: MESSAGE.ALERT.MENSAJE_AUTORIZADO.ELIMINAR_AUTORIZACION.BUTTONS
            .ACEPTAR,
          handler: () => {
            this.eliminar_autorizacion();
          },
        },
      ],
    });
  }

  private mapear_autorizacion(): AutorizadoDelLote {
    this.autorizado.IdTipoRelacion = this.modelo.TipoRelacion.Id;
    this.autorizado.IdTipoAutorizado = this.modelo.TipoAutorizacion.Id;
    this.autorizado.FechaDesde = this.modelo.FechaDesde;
    this.autorizado.FechaHasta = this.modelo.FechaHasta;

    if (this.autorizado.Dias.length === 0) {
      for (let i = 0; i < 8; i++) {
        const diaAutorizado = new Dia();
        diaAutorizado.Dia = i + 1;
        diaAutorizado.HoraDesde = "000000";
        diaAutorizado.HoraHasta = "235959";
        diaAutorizado.Permitido = true;
        this.autorizado.Dias.push(diaAutorizado);
      }
    }

    return this.autorizado;
  }

  private configurar_formulario() {
    this.inicializar_controles_formulario();

    this.formGroup = this.formBuilder.group({
      tipoRelacion: [this.modelo.TipoRelacion.Id, Validators.required],
      tipoAutorizado: [this.modelo.TipoAutorizacion.Id, Validators.required],
      desde: [this.modelo.FechaDesde],
      hasta: [this.modelo.FechaHasta],
    });

    this.formGroup.controls["desde"].setValidators([Validators.required]);
    this.formGroup.controls["hasta"].setValidators([
      Validators.required,
      this.validar_rango_fecha.bind(this.formGroup, this.validationService),
    ]);

    if (this.autorizado.IdTipoRelacion === 8) {
      this.formGroup.disable();
    }
  }

  private inicializar_controles_formulario() {
    const fechaDesde = this.utilService.convert_ticks_to_date_ISO(
      Number(this.autorizado.FechaDesde)
    );
    const fechaHasta = this.utilService.convert_ticks_to_date_ISO(
      Number(this.autorizado.FechaHasta)
    );

    this.modelo = new FormModel();
    this.modelo.TipoRelacion = this.relaciones.filter(
      (relacion: Item) => relacion.Id === this.autorizado.IdTipoRelacion
    )[0];
    this.modelo.TipoAutorizacion = this.tiposAutorizados.filter(
      (autorizacion: Item) =>
        autorizacion.Id === this.autorizado.IdTipoAutorizado
    )[0];
    this.modelo.FechaDesde = fechaDesde;
    this.modelo.FechaHasta = fechaHasta;
    this.modelo.Nombre = this.autorizado.Nombre;
    this.modelo.Apellido = this.autorizado.Apellido;
    this.modelo.Dni = this.autorizado.NumeroDocumento;
  }

  private cargar_valores_del_formulario_al_modelo() {
    this.modelo.TipoRelacion = this.relaciones.filter(
      (relacion: Item) => relacion.Id === this.formGroup.value.tipoRelacion
    )[0];
    this.modelo.TipoAutorizacion = this.tiposAutorizados.filter(
      (tiposAutorizaciones: Item) =>
        tiposAutorizaciones.Id === this.formGroup.value.tipoAutorizado
    )[0];
    this.modelo.FechaDesde = this.utilService.convertir_date_a_ticks(
      this.formGroup.value.desde
    );
    this.modelo.FechaHasta = this.utilService.convertir_date_a_ticks(
      this.formGroup.value.hasta
    );
  }

  private validar_rango_fecha(
    validationService: ValidationService,
    control: FormControl
  ): { [s: string]: boolean } {
    const form: any = this;
    const fechaDesde = form.controls["desde"];
    const fechaHasta = form.controls["hasta"];

    if (
      !validationService.rango_fecha_correcto(
        fechaDesde.value,
        fechaHasta.value
      )
    ) {
      return { range: true };
    }

    return null;
  }

  private validar() {
    const promesa = new Promise((resolve, reject) => {
      const fechaDesde = this.formGroup.value.desde;
      const fechaHasta = this.formGroup.value.hasta;

      // Valido que los campos fechas tengas algún valor
      if (fechaDesde !== "" && fechaHasta !== "") {
        // Valido rango de fechas
        if (
          this.validationService.rango_fecha_correcto(fechaDesde, fechaHasta)
        ) {
          resolve(true);
          return;
        }
      }

      this.controlService.mostrar_toast(
        MESSAGE.ERROR.MENSAJE_ERROR_RANGO_FECHAS,
        TIEMPO_MENSAJE
      );
      resolve(false);
    });

    return promesa;
  }

  private eliminar_autorizacion() {
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.autorizadoService
        .eliminar_autorizacion(this.autorizado, this.lote)
        .then((resp: ResponseApp) => {
          this.controlService.mostrar_toast(
            MESSAGE.INFO.MENSAJE_CAMBIOS_GUARDADOS,
            TIEMPO_MENSAJE
          );
          this.controlService.cerrar_modal();
        })
        .catch((error) => {
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    });
  }
}
