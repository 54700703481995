export class Encuesta {
  Id: string = null;
  Numero: string = null;
  Titulo: string = null;
  PermiteContestar: number = 0;
  EsVotacion: number = 0;
  FechaCierre: Date = null;
  FechaPublicacion: Date = null;

  Preguntas: Pregunta[] = [];
  Resultado: Resultado[] | any = [];
}

export class Pregunta {
  Id: string = null;
  Descripcion: string = null;
  LimiteRespuestas: number = 0;
  Numero: number = 0;
  TextoAdicional: string = null;

  Respuestas: Respuesta[] = [];
}

export class Respuesta {
  Id: string = null;
  Descripcion: string = null;
  Seleccionada: number = 0;
}

export class Resultado {
  IdEncuesta: string = null;
  Encuesta: Encuesta = null;
  Numero: string = null;
  Titulo: string = null;
  CantidadLotesQueContestaron: number = 0;
  FechaCierre: Date = null;
  FechaPublicacion: Date = null;

  ResultadoPreguntas: ResultadoPregunta[] = [];
}

export class ResultadoPregunta {
  IdEncuestaPregunta: string = null;
  EncuestaPregunta: Pregunta = null;
  Numero: number = 0;
  Descripcion: string = null;
  CantidadRespuestasPorPregunta: number = 0;
  TextoAdicional: string = null;

  ResultadoRespuestas: ResultadoRespuesta[] = [];
}

export class ResultadoRespuesta {
  IdEncuestaPreguntaRespuesta: string = null;
  EncuestaPreguntaRespuesta: ResultadoPregunta = null;
  Descripcion: string = null;
  CantidadRespuestas: number = 0;
  Porcentaje: number = 0;
}
