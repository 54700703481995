export class Persona {
  Id: string = null;
  Nombre: string = null;
  Apellido: string = null;
  Documento: string = null;
  UrlImagen: string = null;
  PuedeModificarImagen: boolean = false;
  FechaHoraModificacion: Date | number = null;
  ValidarImagen: boolean = false;
}
