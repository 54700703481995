import { Component, ViewChild, OnInit } from "@angular/core";
import { IonContent } from "@ionic/angular";
import { Router } from "@angular/router";

// Constantes
import { ELEMENTOS_PARA_NO_AFECTAR_TABS } from "src/app/constants/config.constant";
import { MENU } from "src/app/constants/menu.constant";

// Enums
import { EnumTipoMenu, EnumTipoSegmento } from "src/app/models/enums/tipo.enum";

// Interfaces
import { IIdentity } from "src/app/models/interfaces/interfaces.index";

// Servicios
import { StyleService } from "src/app/services/common/style.service";
import { SbcService } from "src/app/services/common/sbc.service";
import { MenuService } from "src/app/services/common/menu.service";
import { UserService } from "src/app/services/security/user.service";
import { EmergencyPage } from "../page.mobile.index";
import { ControlService } from "src/app/services/common/control.service";
import { SegmentService } from "src/app/services/common/segment.service";

@Component({
  selector: "app-tabs",
  templateUrl: "tabs.page.html",
  styleUrls: ["tabs.page.scss"],
})
export class TabsPage implements OnInit {
  @ViewChild(IonContent) contentPrincipal: IonContent;
  elementosParaNoAfectarTabs = ELEMENTOS_PARA_NO_AFECTAR_TABS;
  ocultar = false;
  tabs: IIdentity[] = MENU.MOBILE.TABS;
  segmentTypes = EnumTipoSegmento;
  user: any;
  esGuardia = false;
  esAdministrativo = false;
  esHabitante = false;

  constructor(
    private styleService: StyleService,
    private sbcService: SbcService,
    private menuService: MenuService,
    private userService: UserService,
    private controlService: ControlService,
    private router: Router,
    private segmentService: SegmentService
  ) { }

  ngOnInit() {
    this.user = this.userService.user;
    console.log("this.userService.user", this.userService.user);
    this.esGuardia = this.userService.user.Profiles.includes('Guardia');
    this.esAdministrativo = this.userService.user.Profiles.includes('Administrativo');
    this.esHabitante = !this.esGuardia && !this.esAdministrativo;
    console.log("this.esGuardia", this.esGuardia);
    console.log("this.esAdministrativo", this.esAdministrativo);
    this.sbcService
      .resolver_menu(EnumTipoMenu.Home)
      .subscribe((menuConfig: string | IIdentity[]) => {
        if (typeof menuConfig !== "string") {
          const menuConfigArray = this.menuService.convert_menu_to_array(
            menuConfig,
            EnumTipoSegmento.Tabs
          );
          this.tabs = this.menuService.reemplazar_menu(
            this.tabs,
            menuConfigArray
          );
        }
      });

    console.log("entró al onInit tabsPage 64");
    this.styleService.resolver_vista();
  }

  ionViewDidEnter() {
    console.log("ionViewDidEnter");
    console.log("this.userService.user", this.userService.user);
    this.esGuardia = this.userService.user.Profiles.includes('Guardia');
    this.esAdministrativo = this.userService.user.Profiles.includes('Administrativo');
    this.esHabitante = !this.esGuardia && !this.esAdministrativo;
  }

  afectar_tabs(event: any) {
    if (this.contentPrincipal) {
      this.aplicar_efecto_tabs(event);
    }
  }

  mostrar_tabs(event) {
    this.ocultar = false;
    setTimeout(
      () => (document.querySelector("ion-tab-bar").style.display = "flex"),
      500
    );
  }

  ocultar_tab(tab: string) {
    const menu = this.menuService.buscar_item_por_propiedad(
      this.tabs,
      tab,
      "id"
    );
    return menu && menu.hide;
  }

  private aplicar_efecto_tabs(event: any) {
    if (this.validar_elementos_aplicables(event.target)) {
      if (this.ocultar) {
        setTimeout(
          () => (document.querySelector("ion-tab-bar").style.display = "flex"),
          500
        );
        this.ocultar = false;
      } else {
        this.ocultar = true;
        setTimeout(
          () => (document.querySelector("ion-tab-bar").style.display = "none"),
          500
        );
      }
    }
  }

  openEmergencyPage() {
    const componente = EmergencyPage;
    const modal = { component: componente };

    this.controlService.mostrar_modal(modal);
  }

  enableEmergencyButton(): boolean {
    return this.userService.user.EmergenciasHabilitadas;
  }

  navigateTo(segmentType: EnumTipoSegmento, page: string): void {
    let option: IIdentity = null;
    switch (page) {
      case "dashboard":
        option = this.segmentService.segmentosHome[0];
        break;
      case "autorizaciones":
        option = this.segmentService.segmentosSeguridad.find(({ id }) => id === 'autorizado');
        break;
      case "reservas":
        option = this.segmentService.segmentosHome.find(
          (segment) => segment.id === page
        );
        break;
      case "lote":
        option = this.segmentService.segmentosSeguridad.find(({ id }) => id === 'lote');
        break;
      default:
        break;
    }

    this.router.navigate([option.path]);
    setTimeout(() => {
      this.segmentService.actualSegment.next({
        type: segmentType,
        segment: option,
      });
    }, 500);
  }

  private validar_elementos_aplicables(target) {
    if (this.elementosParaNoAfectarTabs.length === 0) {
      return true;
    }

    // Si el elemento target es alguno de los elementos que no deben afectar a la barra de tabs cuando se hace click sobre él
    return (
      this.elementosParaNoAfectarTabs.filter(
        (elemento) =>
          target.tagName.toUpperCase().indexOf(elemento.toUpperCase()) >= 0
      ).length === 0
    );
  }
}
