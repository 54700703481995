import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { MenuController } from "@ionic/angular";

// Páginas
import {
  ChangePasswordPage,
  PerfilPage,
  ContactsPage,
  MascotasPage,
} from "src/app/pages/mobile/page.mobile.index";
import { EmergencyPage } from "src/app/pages/mobile/emergency/emergency.page";

// Componentes
import { AccountComponent } from "src/app/components/shared/components.shared.index";

// Interfaces
import { IModal } from "src/app/models/interfaces/interfaces.index";

// Constantes
import { PATH_URL } from "src/app/constants/url.constant";

// Servicios
import { AuthService } from "../../../services/security/auth.service";
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  @Input() title = "";
  @Input() showBack = false;
  @Input() showMenu = true;
  @Input() routeBack = "/";
  @Input() showProfile = true;
  @Input() showAction = false;
  @Input() isModal = false;
  @Input() iconAction = "close";
  @Output() cierreModal = new EventEmitter();
  @Output() ejecutarAction = new EventEmitter();

  constructor(
    private controlService: ControlService,
    private authService: AuthService,
    private router: Router,
    private menuController: MenuController,
    private userService: UserService
  ) { }

  ngOnInit() { }

  mostrarPopOver(control) {
    this.controlService
      .mostrarPopOver({ control, component: AccountComponent })
      .then((data: any) => {
        this.resolver_opcion(data);
      });
  }

  openSideMenu() {
    this.menuController.toggle();
  }

  ordenar_cierre_modal(event) {
    this.cierreModal.emit(this.isModal);
  }

  mostrarAction() {
    this.ejecutarAction.emit();
  }

  private resolver_opcion(data: any) {
    const opcion = data ? data.opcion : "";
    switch (opcion) {
      case "1":
        this.redireccionar_al_perfil();
        break;
      case "2":
        this.redireccionar_al_cambio_password();
        break;
      case "3":
        this.redireccionar_a_contactos();
        break;
      case "4":
        this.mostrar_modal_mascotas();
        break;
      case "5":
        this.redireccionar_al_login();
      default:
        break;
    }
  }

  private redireccionar_al_perfil() {
    this.mostrar_perfil();
  }

  private redireccionar_al_cambio_password() {
    this.mostrar_modal_cambio_password();
  }

  private redireccionar_a_contactos() {
    this.mostrar_modal_contactos();
  }

  private redireccionar_al_login() {
    this.authService.logout();
    this.router.navigate([PATH_URL.MOBILE.LOGIN.INDEX]);
  }

  private async mostrar_perfil() {
    const modal: IModal = { component: PerfilPage, returnData: true };

    this.controlService.mostrar_modal(modal);
  }

  private async mostrar_modal_cambio_password() {
    const modal: IModal = { component: ChangePasswordPage, returnData: true };

    this.controlService.mostrar_modal(modal);
  }

  private async mostrar_modal_contactos() {
    const modal: IModal = { component: ContactsPage, returnData: true };

    this.controlService.mostrar_modal(modal);
  }

  private async mostrar_modal_mascotas() {
    const modal: IModal = { component: MascotasPage, returnData: true };

    this.controlService.mostrar_modal(modal);
  }


  openEmergencyPage() {
    const componente = EmergencyPage;
    const modal: IModal = { component: componente };

    this.controlService.mostrar_modal(modal);
  }
}
