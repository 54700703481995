import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CapitalizePipe } from "./capitalize/capitalize.pipe";
import { TicksToDatePipe } from "./date/ticks-to-date.pipe";
import { Iso8601ToDatePipe } from "./date/iso8601-to-date.pipe";
import { NoimagePipe } from "./image/noimage.pipe";
import { TruncateTextPipe } from "./truncate-text/truncate-text.pipe";
import { RangePipe } from "./range/range.pipe";
import { ImageSanitizerPipe } from "./image/imageSanitizer.pipe";

@NgModule({
  declarations: [
    CapitalizePipe,
    TicksToDatePipe,
    Iso8601ToDatePipe,
    NoimagePipe,
    TruncateTextPipe,
    RangePipe,
    ImageSanitizerPipe,
  ],
  exports: [
    CapitalizePipe,
    TicksToDatePipe,
    Iso8601ToDatePipe,
    NoimagePipe,
    TruncateTextPipe,
    RangePipe,
    ImageSanitizerPipe,
  ],
  imports: [CommonModule],
})
export class PipesModule { }
