import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Clases
import { Lote, ResponseApp } from "src/app/models/entities/entity.index";

// Interfaces
import { IDictionary, IPagination } from "src/app/models/interfaces/interfaces.index";

// Servicios
import { CatalogoService } from "./catalogo.service";
import { RestService } from "../common/rest.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";
import { SecurityService } from "../security/security.service";
import Mascota, { MascotaColor, MascotaEspecie, MascotaTamano } from "src/app/models/entities/business/mascota.entity";
import { ItemElement } from "src/app/models/clases/ItemElement";

@Injectable({
  providedIn: "root",
})
export class MascotaService {
  private paginacion: IPagination = { pagina: 1, cantidad: 10 };
  private itemElement = new ItemElement<Mascota>();

  mascotas: Mascota[] = [];
  paginacionActual: IPagination = { pagina: 1, cantidad: 10 };

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private exceptionService: ExceptionService,
    private securityService: SecurityService
  ) { }


  // obtenerNotificaciones
  async obtenerMascotasByLote(
    lote: Lote,
    paginacion: IPagination = this.paginacion
  ) {
    const url = `${this.catalogoService.catalogo}MascotasObtenerByLote/`;

    const options: IDictionary[] = [
      { key: "{idLote}", value: lote.Id },
      { key: "{codigo_barrio}", value: this.securityService.codigoBarrio },
      { key: "{pagina}", value: paginacion.pagina.toString() },
      { key: "{cantidad}", value: paginacion.cantidad.toString() },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Mascota[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;

            const notificaciones = this.utilService.mapear_propiedades(
              resp.Data,
              new Mascota()
            );
            this.mascotas = this.itemElement.refrescar(
              notificaciones,
              this.mascotas
            );

            this.mascotas.forEach((mascota: Mascota) => {
              mascota.UrlImagen = this.utilService.resolver_url_imagen(
                mascota.UrlImagen
              );
            });

            return this.mascotas;
          })
        )
        .subscribe(
          (data: Mascota[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async obtenerEspecies() {
    const url = `${this.catalogoService.catalogo}MascotaObtenerEspecies/`;

    return new Promise<MascotaEspecie[]>((resolve, reject) => {
      this.restService
        .get(url, true, false)
        .pipe(
          map<any, MascotaEspecie[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);
            const mascotaEspecies = this.utilService.mapear_propiedades(
              resp.Data,
              new MascotaEspecie()
            );
            return mascotaEspecies;
          })
        )
        .subscribe(
          (data: MascotaEspecie[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async obtenerColores(): Promise<MascotaColor[]> {
    const url = `${this.catalogoService.catalogo}MascotaObtenerColores/`;

    return new Promise<MascotaColor[]>((resolve, reject) => {
      this.restService
        .get(url, true, false)
        .pipe(
          map<any, MascotaColor[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);
            const mascotaColores = this.utilService.mapear_propiedades(
              resp.Data,
              new MascotaColor()
            );
            return mascotaColores;
          })
        )
        .subscribe(
          (data: MascotaColor[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async obtenerMascotaTamanos() {
    const url = `${this.catalogoService.catalogo}MascotaObtenerTamanos`;

    return new Promise<MascotaTamano[]>((resolve, reject) => {
      this.restService
        .get(url, false, false)
        .pipe(
          map<any, MascotaTamano[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);
            const mascotaTamanos = this.utilService.mapear_propiedades(
              resp.Data,
              new MascotaTamano()
            );
            return mascotaTamanos;
          })
        )
        .subscribe(
          (data: MascotaTamano[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async guardar_mascota(data: any, isForUpdate = false) {
    const url = `${this.catalogoService.catalogo}${isForUpdate ? 'MascotaRenovarVacuna' : 'MascotaAgregar'}`;

    const body = {
      token: this.securityService.token,
      ...data
    };


    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            console.log(resp);
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  limpiar() {
    this.mascotas = [];
  }

}
