import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";

// Páginas
import { AgregarReservaCamionetaPage } from "./agregar.page";

// Módulos
import { ComponentsMobileModule } from "src/app/components/components-mobile.module";
import { Ionic4DatepickerModule } from "@logisticinfotech/ionic4-datepicker";

@NgModule({
  imports: [
    Ionic4DatepickerModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ComponentsMobileModule,
  ],
  declarations: [AgregarReservaCamionetaPage],
})
export class AgregarReservaCamionetaPageModule { }
