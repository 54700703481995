import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { MatTableDataSource } from "@angular/material";

// Clases
import { FileItem } from "src/app/models/entities/entity.index";

// Interfaces
import { IFileItem } from "src/app/models/interfaces/interfaces.index";

@Component({
  selector: "app-fileupload",
  templateUrl: "./fileupload.component.html",
  styleUrls: ["./fileupload.component.scss"],
})
export class FileuploadComponent implements OnInit {
  @Output() obtenerArchivos: EventEmitter<FileItem[]> = new EventEmitter();
  @Input() archivos: FileItem[] = [];
  @Input() maxArchivos = 10;
  @Input() onlyImageFiles = true;
  @Input() title = null;
  estaSobreElemento = false;
  dataSource: MatTableDataSource<FileItem>;
  encabezados = [];

  constructor() { }

  ngOnInit() {
    // Para los casos en que se envíen archivos al componente para inicializar
    if (this.archivos.length > 0) {
      this.recargar_grilla();
      this.obtenerArchivos.emit(this.archivos);
    }

    if (this.onlyImageFiles) {
      this.encabezados = ["nombre_archivo", "size", "progreso", "actions"];
    } else {
      this.encabezados = ["nombre_archivo", "size", "actions"];
    }
  }

  actualizar_datos(event: IFileItem | any, esInputFile: boolean = false) {
    // Sólo aplica cuando se arrastra un archivo
    // En caso de los input file no
    if (esInputFile) {
      this._extraerArchivos(event.srcElement.files);
    } else {
      this.estaSobreElemento = event.mouseSobre;
    }

    this.recargar_grilla();
    this.obtenerArchivos.emit(event.archivos);
  }

  limpiarArchivo(archivo: FileItem) {
    let posicion = 0;
    this.archivos.every((file: FileItem, index: number) => {
      if (file.nombreArchivo === archivo.nombreArchivo) {
        posicion = index;
        return false;
      }

      return true;
    });

    this.archivos.splice(posicion, 1);
    this.recargar_grilla();
  }

  limpiarArchivos() {
    this.archivos = [];
    this.recargar_grilla();
  }

  private recargar_grilla(datos?: FileItem[]) {
    this.dataSource = new MatTableDataSource();
    this.dataSource.data = datos && datos.length > 0 ? datos : this.archivos;
  }

  private _extraerArchivos(archivosLista: FileList) {
    // tslint:disable-next-line: forin
    for (const propiedad in Object.getOwnPropertyNames(archivosLista)) {
      const archivoTemporal = archivosLista[propiedad];
      if (this._archivoPuedeSerCargado(archivoTemporal)) {
        const archivoNuevo = new FileItem(archivoTemporal);
        this.archivos.push(archivoNuevo);
      }
    }
  }

  // Validaciones
  private _archivoPuedeSerCargado(archivo: File): boolean {
    if (
      !this._archivoYaFueDroppeado(archivo.name) &&
      this.onlyImageFiles &&
      this._esImagen(archivo.type) &&
      !this._superaLimiteMaximoCarga()
    ) {
      return true;
    } else if (
      !this.onlyImageFiles &&
      !this._superaLimiteMaximoCarga() &&
      this.esPdf(archivo) &&
      !this._archivoYaFueDroppeado(archivo.name) &&
      !this._esImagen(archivo.type)
    ) {
      return true;
    } else {
      return false;
    }
  }

  esPdf(archivo: File): boolean {
    return archivo.name.toLowerCase().includes("pdf");
  }

  private _archivoYaFueDroppeado(nombreArchivo: string): boolean {
    for (const archivo of this.archivos) {
      if (archivo.nombreArchivo.toUpperCase() === nombreArchivo.toUpperCase()) {
        console.log("El archivo " + nombreArchivo + " ya está agregado");
        return true;
      }
    }

    return false;
  }

  private _esImagen(tipoArchivo: string): boolean {
    return tipoArchivo === "" || tipoArchivo === undefined
      ? false
      : tipoArchivo.startsWith("image");
  }

  private _superaLimiteMaximoCarga() {
    return this.archivos.length >= this.maxArchivos;
  }
}
