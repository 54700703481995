import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SafeUrl } from "@angular/platform-browser";
import { SbcService } from "src/app/services/common/sbc.service";
import { ControlService } from "src/app/services/common/control.service";
import { MESSAGE } from "src/app/constants/message.constant";
import { AuthService } from "src/app/services/security/auth.service";
import { ResponseApp } from "src/app/models/entities/entity.index";

@Component({
  selector: "app-forgot-my-password",
  templateUrl: "./forgot-my-password.page.html",
  styleUrls: ["./forgot-my-password.page.scss"],
})
export class ForgotMyPasswordPage implements OnInit {
  formGroup: FormGroup;
  icono: string | SafeUrl = null;
  messageText: string;

  constructor(
    private formBuilder: FormBuilder,
    private sbcService: SbcService,
    private controlService: ControlService,
    private auth: AuthService
  ) {
    this.prepareForm();
    this.sbcService.resolver_icono().subscribe((icono: string | SafeUrl) => {
      this.icono = icono;
    });
  }

  ngOnInit() {}

  prepareForm() {
    this.formGroup = this.formBuilder.group({
      userName: ["", [Validators.required]],
      documento: ["", [Validators.required]],
      email: ["", [Validators.required, Validators.email]],
    });
  }

  recoverPassword() {
    this.controlService
      .mostrarLoading(MESSAGE.RECOVERY_PASSWORD.ENVIANDO)
      .then(() => {
        this.auth
          .recovery_password(this.formGroup.value)
          .then((resp: ResponseApp) => {
            this.messageText =
              resp.Status == "OK"
                ? MESSAGE.RECOVERY_PASSWORD.OK
                : MESSAGE.RECOVERY_PASSWORD.ERROR;
            this.controlService.ocultar_loading();

            this.controlService.mostrar_alert({
              header: "Recuperar contraseña",
              message: this.messageText,
              buttons: [
                {
                  text: "Ok",
                  handler: () => {
                    if (resp.Status == "OK") {
                      this.closeModal();
                    }
                  },
                },
              ],
            });
          })
          .catch((error) => {
            console.log("error", error);
          });
      });
  }

  closeModal() {
    this.controlService.cerrar_modal();
  }
}
