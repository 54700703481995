export const PATH_URL = {
  /*---------------------------------------------------------> MOBILE */
  MOBILE: {
    DEFAULT: "/",
    TABS: {
      HOME: {
        DEFAULT: "/home",
        SEGMENTS: {
          DASHBOARD: {
            DEFAULT: "/tabs/home/dashboard",
            OUTLET: "/tabs/home/(dashboard:dashboard)",
          },
          NOTICIAS: {
            DEFAULT: "/tabs/home/noticia",
            OUTLET: "/tabs/home/(noticia:noticia)",
          },
          ENCUESTAS: {
            DEFAULT: "/tabs/home/encuesta",
            OUTLET: "/tabs/home/(encuesta:encuesta)",
          },
          DOCUMENTOS: {
            DEFAULT: "/tabs/home/documento",
            OUTLET: "/tabs/home/(documento:documento)",
          },
          TELEFONOS: {
            DEFAULT: "/tabs/home/telefono",
            OUTLET: "/tabs/home/(telefono:telefono)",
          },
          RESERVAS: {
            DEFAULT: "/tabs/home/reserva",
            OUTLET: "/tabs/home/(reserva:reserva)",
          },
          CAMIONETA: {
            DEFAULT: "/tabs/home/camioneta",
            OUTLET: "/tabs/home/(camioneta:camioneta)",
          },
          DETALLE_NOTICIA: { DEFAULT: "/tabs/home/detalleNoticia" },
          COMO_LLEGO: {
            DEFAULT: "/tabs/home/comoLlego",
            OUTLET: "/tabs/home/(comoLlego:comoLlego)",
          }
        },
      },
      MENSAJE: {
        DEFAULT: "/mensaje",
        SEGMENTS: {
          TICKET: {
            DEFAULT: "/tabs/mensaje/ticket",
            OUTLET: "/tabs/mensaje/(ticket:ticket)",
          },
          TICKET_BANDEJA: {
            DEFAULT: "/tabs/mensaje/ticket",
            OUTLET: "/tabs/mensaje/(ticket:ticket)",
          },
          EXPENSA: {
            DEFAULT: "/tabs/mensaje/expensa",
            OUTLET: "/tabs/mensaje/(expensa:expensa)",
          },
          PAGO_EXPENSAS: {
            DEFAULT: "/tabs/mensaje/pagoExpensas",
            OUTLET: "/tabs/mensaje/(pagoExpensas:pagoExpensas)",
          },
          BANDEJA_TICKETS: {
            DEFAULT: "/tabs/mensaje/bandeja",
            OUTLET: "/tabs/mensaje/(bandejaTicket:bandejaTicket)",
          },
          DOCUMENTOSAF: {
            DEFAULT: "/tabs/mensaje/documentoAF",
            OUTLET: "/tabs/mensaje/(documentoAF:documentoAF)",
          },
          MULTA: {
            DEFAULT: "/tabs/mensaje/multa",
            OUTLET: "/tabs/mensaje/(multa:multa)",
          },
          NOTIFICACION: {
            DEFAULT: "/tabs/mensaje/notificacion",
            OUTLET: "/tabs/mensaje/(notificacion:notificacion)",
          },
          CORRESPONDENCIA: {
            DEFAULT: "/tabs/mensaje/correspondencia",
            OUTLET: "/tabs/mensaje/(correspondencia:correspondencia)",
          },
        },
      },
      SEGURIDAD: {
        DEFAULT: "/seguridad",
        SEGMENTS: {
          ACCESO: {
            DEFAULT: "/tabs/seguridad/acceso",
            OUTLET: "/tabs/seguridad/(acceso:acceso)",
          },
          AUTORIZADO: {
            DEFAULT: "/tabs/seguridad/autorizado",
            OUTLET: "/tabs/seguridad/(autorizado:autorizado)",
          },
          INVITACION: {
            DEFAULT: "/tabs/seguridad/invitacion",
            OUTLET: "/tabs/seguridad/(invitacion:invitacion)",
          },
          INVITACION_NAUTICA: {
            DEFAULT: "/tabs/seguridad/invitacionNautica",
            OUTLET: "/tabs/seguridad/(invitacionNautica:invitacionNautica)",
          },
          CAMARA: {
            DEFAULT: "/tabs/seguridad/camara",
            OUTLET: "/tabs/seguridad/(camara:camara)",
          },
          EGRESO_CAMION: {
            DEFAULT: "/tabs/seguridad/egresoCamion",
            OUTLET: "/tabs/seguridad/(egresoCamion:egresoCamion)",
          },
          // LOTE: { DEFAULT: "/tabs/seguridad/lote" },
          BUSCADOR: { DEFAULT: "/tabs/seguridad/buscador" },
          LOTE: {
            DEFAULT: "/tabs/seguridad/lote",
            OUTLET: "/tabs/seguridad/(lote:lote)",
          },
          TEST: {
            DEFAULT: "/tabs/seguridad/test",
            OUTLET: "/tabs/seguridad/(test:test)",
          },
        },
      },
    },
    BLOQUEO: {
      DEFAULT: "/bloqueo",
    },
    LOGIN: {
      DEFAULT: "/barrio",
      INDEX: "/login",
    },
    ONBOARDING: {
      DEFAULT: "/onboarding",
    },

  },

  /*---------------------------------------------------------> WEB */
  WEB: {
    DEFAULT: "/index",
    HOME: {
      DEFAULT: "/index",
      SEGMENTS: {
        NOTICIAS: {
          DEFAULT: "/index/noticia",
          AGREGAR: {
            DEFAULT: "/index/noticia/nuevo",
          },
        },
        ENCUESTAS: { DEFAULT: "/index/encuesta" },
        DOCUMENTOS: { DEFAULT: "/index/documento" },
        RESERVAS: { DEFAULT: "/index/reserva" },
      },
    },
    MENSAJE: {
      DEFAULT: "/index",
      SEGMENTS: {
        TICKET: {
          DEFAULT: "/index/ticket",
          AGREGAR: {
            DEFAULT: "/index/ticket/nuevo",
          },
        },
        EXPENSA: { DEFAULT: "/index/expensa" },
        PAGO_EXPENSAS: { DEFAULT: "/index/pagoExpensas" },
        DOCUMENTOSAF: { DEFAULT: "/index/documentoAF" },
        MULTA: { DEFAULT: "/index/multa" },
        NOTIFICACION: { DEFAULT: "/index/notificacion" },
        BANDEJA_TICKETS: {
          DEFAULT: "/index/bandejaTickets",
          AGREGAR: {
            DEFAULT: "/index/bandejaTickets/nuevo",
          },
        },
        WEB_INQUILINOS: { DEFAULT: "/index/inquilinos" },
      },
    },
    SEGURIDAD: {
      DEFAULT: "/index",
      SEGMENTS: {
        ACCESO: { DEFAULT: "/index/acceso" },
        AUTORIZADO: {
          DEFAULT: "/index/autorizado",
          AGREGAR: {
            DEFAULT: "/index/autorizado/nuevo",
          },
          OBSERVACIONES: { DEFAULT: "/index/autorizado/observaciones" },
          PREREQUISITOS: { DEFAULT: "/index/autorizado/pre-requisitos" },
        },
        CAMARA: { DEFAULT: "/index/camara" },
      },
    },
    PERFIL: {
      DEFAULT: "/index/perfil",
      SEGMENTS: {
        EDITAR: { DEFAULT: "/index/perfil" },
        PASSWORD: { DEFAULT: "/index/perfil/password" },
        CONTACTS: { DEFAULT: "/index/perfil/contacts" },
        MASCOTAS: { DEFAULT: "/index/perfil/mascotas" },
      },
    },
    BLOQUEO: {
      DEFAULT: "TODO: hay que agregar una página de bloqueo para la web",
    },
    LOGIN: {
      DEFAULT: "/login-web",
      SEGMENTS: {
        RECOVERY_PASSWORD: "/login-web/recovery",
      },
    },
  },
};

/*---------------------------------------------------------> ANDROID */
export const PATH_ANDROID = {
  VIEWER: "https://docs.google.com/viewer?url={0}&embedded=true",
};

/*---------------------------------------------------------> CONFIG */
export const PATH_CONFIG = {
  CONFIG_URL: "assets/config/config.sbc.json",
  SPLASH_DEFAULT_URL: "assets/resources/barriotest/splash.svg",
  ICON_DEFAULT_URL: "assets/resources/barriotest/icon.png",
};
