import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Interfaces
import {
  IPagination,
  IDictionary,
} from "src/app/models/interfaces/interfaces.index";

// Constantes
import { PAGINATOR } from "src/app/constants/config.constant";

// Clases
import { Camara } from "src/app/models/entities/entity.index";
import { ItemElement } from "src/app/models/clases/ItemElement";

// Servicios
import { CatalogoService } from "./catalogo.service";
import { RestService } from "../common/rest.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";

@Injectable({
  providedIn: "root",
})
export class CamaraService {
  private paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };
  private itemElement = new ItemElement<Camara>();

  camaras: Camara[] = [];
  paginacionActual: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private exceptionService: ExceptionService
  ) {}

  //*obtenerCamarasSeguridad */
  async obtener_camaras_de_seguridad(
    paginacion: IPagination = this.paginacion
  ) {
    const url = `${this.catalogoService.catalogo}ObtenerCamarasSeguridad/`;
    const options: IDictionary[] = [
      { key: "{pagina}", value: paginacion.pagina.toString() },
      { key: "{cantidad}", value: paginacion.cantidad.toString() },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Camara[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;

            const camaras = this.utilService.mapear_propiedades(
              resp.Data,
              new Camara()
            );
            camaras.forEach((camara: Camara) => {
              camara.Url = this.utilService.resolver_url_imagen(camara.Url);
              if (camara.Url.indexOf("?") === -1) {
                camara.Url += "?";
              }

              camara.Url += "random=" + Math.random();
            });

            this.camaras = this.itemElement.refrescar(camaras, this.camaras);

            return this.camaras;
          })
        )
        .subscribe(
          (data: Camara[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  limpiar() {
    this.camaras = [];
  }
}
