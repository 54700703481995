import { environment } from "src/environments/environment";

//**----------------------------------------------->  APP */
export const APP = {
  NAME: "SBC",
  APP_ID: {
    ANDROID: environment.APP_ID_ANDROID,
    IOS: environment.APP_ID_IOS ? environment.APP_ID_IOS : "1239093611",
    ONE_SIGNAL: {
      ANDROID: {
        APP_ID: environment.ONE_SIGNAL_APP_ID,
        FIREBASE_PROJECT_NUMBER: "916063096969",
      },
      IOS: {},
      GENERAL: {
        TIPO_MENSAJE: ["acceso"],
      },
    },
  },
  VERSION: {
    ANDROID: "3.1.1",
    IOS: "3.1.1",
    WEB: "",
  },
  POLITICA_PRIVACIDAD:
    "https://sites.google.com/view/sbc-politica-privacidad/politicas-privacidad",
};

//**----------------------------------------------->  SETTINGS */
export const SCROLL_FAB_HEIGHT = 500;
export const ENABLED_SCROLL_EVENT = true;
export const INTERVAL = {
  AUTOCOMPLETE: 500,
  REFRESH: 30000,
  SOLICITUD_ACCESO: 1000,
};
export const TEMPORIZADOR = {
  LOADING: 10000,
};

export const REPLACE_IMG = {
  INICIAL: "VerFoto",
  FINAL: "Foto",
};

export const ELEMENTOS_PARA_NO_AFECTAR_TABS = [
  "ion-datetime",
  "ion-icon",
  "ion-tab-button",
  "ion-button",
  "ion-select",
  "input",
  "ion-label",
  "ion-segment-button",
];

export const EMPTY_PARAMS = {
  AF: "%20",
};

export const DOCUMENT_TYPES = {
  EXP: "EXP",
};

//**----------------------------------------------->  EXTENSIONES DISPONIBLES */
export const IMG_EXTENSION_SOPORTADA = [
  ".jpg",
  ".png",
  ".jpeg",
  ".aspx",
  ".svg",
  ".gif",
];

//**----------------------------------------------->  Url perfil */
export const IMG_APP = {
  PERFIL: "assets/img/usuario/avatardefault.svg",
  CALIDAD: {
    RESIZE_QUALITY: false,
    MAXBYTES: 49078,
    RESIZE: {
      WIDTH: 75,
      HEIGHT: 75,
    },
  },
};

//**----------------------------------------------->  INTERNACIONALIZACIÓN */
export const PAGINATOR = {
  FIRST_PAGE_LABEL: "Primer Página",
  LAST_PAGE_LABEL: "Última Página",
  ITEMS_PER_PAGE_LABEL: "{0} por página",
  PREVIOUS_PAGE_LABEL: "Anterior",
  NEXT_PAGE_LABEL: "Siguiente",
  PAGE_SIZE_OPTIONS: [5, 10, 25, 100],
  PAGE_INIT: 1,
  SIZE: 10,
  SIZE_EXTENDED: 100,
};

export const ALERT = {
  BUTTONS: {
    ACEPTAR: "Aceptar",
    CANCELAR: "Cancelar",
    POLITICAS_PRIVACIDAD: "Políticas de privacidad",
  },
};

export const CALENDARIO = {
  LOCALE: "es",
  MOMENT_DATE_FORMATS: {
    parse: {
      dateInput: "D/MM/YYYY",
    },
    display: {
      dateInput: "DD/MM/YYYY",
      monthYearLabel: "MMMM Y",
      dateA11yLabel: "LL",
      monthYearA11yLabel: "MMMM Y",
    },
  },
};

//**----------------------------------------------->  MÓDULOS DE LOS ESTADOS */
export const MODULOS = {
  ACTA: "ACTA",
  TICKET: "TICKET",
  NOTIFICACION: "NOTIFICACION",
  ENCUESTA: "ENCUESTA",
};

//**----------------------------------------------->  FECHAS */
export const RANGO_FECHA = {
  ACCESO: {
    DESCUENTO_HORAS_DESDE: 171, //-> 7 Días
    DESCUENTO_HORAS_HASTA: 3,
    NUEVO: {
      DESCUENTO_HORAS_DESDE: 3,
      DESCUENTO_HORAS_HASTA: 3,
      ANIO_MINIMO: 2016,
      ANIO_MAXIMO: 2030,
    },
  },
};

export const FECHA = {
  FORMATO_VISUAL: "DD/MM/YYYY",
};

//**----------------------------------------------->  FORMULARIOS */
export const FORMS = {
  CAMBIO_PASSWORD: {
    PASSWORD: {
      MIN_LENGTH: 4,
    },
  },
};
