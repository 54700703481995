import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
// Módulos
import { ComponentsMobileModule } from "src/app/components/components-mobile.module";

import { AgregarInvitacionPage } from "./agregar-invitacion.page";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    ComponentsMobileModule,
  ],
  declarations: [AgregarInvitacionPage],
  providers: [DatePipe],
})
export class AgregarInvitacionPageModule {}
