import { Component, Input, ViewChildren, QueryList } from "@angular/core";
import { IonCheckbox, IonRadio } from "@ionic/angular";

// Clases
import {
  Encuesta,
  Pregunta,
  Respuesta,
  Lote,
  ResponseApp,
} from "src/app/models/entities/entity.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { EncuestaService } from "src/app/services/business/encuesta.service";
import { ExceptionService } from "src/app/services/common/exception.service";

// Constantes
import { MESSAGE, TIEMPO_MENSAJE } from "src/app/constants/constants.index";

@Component({
  selector: "app-encuesta-abierta-modal",
  templateUrl: "./encuesta-abierta-modal.page.html",
  styleUrls: ["./encuesta-abierta-modal.page.scss"],
})
export class EncuestaAbiertaModalPage {
  @Input() lote: Lote;
  @Input() encuesta: Encuesta;
  @ViewChildren(IonCheckbox) checks: QueryList<IonCheckbox>;
  slideOpts = {
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
  };

  constructor(
    private encuestaService: EncuestaService,
    private controlService: ControlService,
    private exceptionService: ExceptionService
  ) {}

  actualizar_encuesta() {
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.encuestaService
        .guardar_encuesta(this.encuesta, this.lote)
        .then((resp: ResponseApp) => {
          this.controlService.mostrar_toast(
            MESSAGE.INFO.MENSAJE_CAMBIOS_GUARDADOS,
            TIEMPO_MENSAJE
          );
        })
        .catch((error) => {
          const msg = this.exceptionService.obtener_mensaje(error);
          this.controlService.mostrar_toast(msg, TIEMPO_MENSAJE);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
          this.cerrar_modal();
        });
    });
  }

  cerrar_modal() {
    this.controlService.cerrar_modal(this.encuesta);
  }

  toggleCheckBox(preguntaId, respuestaId, checkbox) {
    let indexPregunta = 0;
    let indexRespuesta = 0;
    const pregunta = this.encuesta.Preguntas.filter(
      (pregunta: Pregunta, index: number) => {
        if (pregunta.Id === preguntaId) {
          indexPregunta = index;
          return pregunta;
        }
      }
    )[0];

    const respuesta = pregunta.Respuestas.filter(
      (respuesta: Respuesta, index: number) => {
        if (respuesta.Id === respuestaId) {
          indexRespuesta = index;
          return respuesta;
        }
      }
    )[0];

    respuesta.Seleccionada = checkbox.detail.checked ? 1 : 0;

    if (!this.validar_limite_pregunta_actual(pregunta)) {
      this.encuesta.Preguntas[indexPregunta].Respuestas[
        indexRespuesta
      ].Seleccionada = 0;
      this.checks.forEach((check: IonCheckbox) => {
        if (check.name === respuestaId) {
          check.checked = false;
          return false;
        }
      });
    }
  }

  toggleRadio(preguntaId, respuestaId, radio) {
    for (let i = 0; i < this.encuesta.Preguntas.length; i++) {
      if (this.encuesta.Preguntas[i].Id == preguntaId) {
        for (let j = 0; j < this.encuesta.Preguntas[i].Respuestas.length; j++) {
          if (this.encuesta.Preguntas[i].Respuestas[j].Id === respuestaId) {
            this.encuesta.Preguntas[i].Respuestas[j].Seleccionada = 1;
          } else {
            this.encuesta.Preguntas[i].Respuestas[j].Seleccionada = 0;
          }
        }
      }
    }
  }

  private validar_limite_pregunta_actual(pregunta: Pregunta): boolean {
    if (pregunta && pregunta.LimiteRespuestas > 0) {
      let cantidadSeleccionada = 0;

      for (let j = 0; j < pregunta.Respuestas.length; j++) {
        const respuesta: Respuesta = pregunta.Respuestas[j];

        if (respuesta.Seleccionada === 1) {
          cantidadSeleccionada++;
        }

        if (cantidadSeleccionada > pregunta.LimiteRespuestas) {
          const mensaje = MESSAGE.ALERT.MENSAJE_RESPUESTAS_MAXIMAS.replace(
            "{0}",
            pregunta.Descripcion
          ).replace("{1}", pregunta.LimiteRespuestas.toString());

          this.controlService.mostrar_toast(mensaje, 5000);
          return false;
        }
      }
    }

    return true;
  }
}
