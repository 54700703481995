import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

// Interfaces
import {
  IPagination,
  IDictionary,
} from "src/app/models/interfaces/interfaces.index";

// Clases
import {
  Bandeja,
  Ticket,
  Lote,
  ResponseApp,
  Item,
} from "src/app/models/entities/entity.index";
import { ItemElement } from "src/app/models/clases/ItemElement";

// Servicios
import { CatalogoService } from "./catalogo.service";
import { RestService } from "../common/rest.service";
import { UtilService } from "../common/util.service";
import { SecurityService } from "../security/security.service";
import { ExceptionService } from "../common/exception.service";
import { UserService } from "../security/user.service";

@Injectable({
  providedIn: "root",
})
export class BandejaTicketsService {
  private paginacion: IPagination = { pagina: 1, cantidad: 10 };
  private itemElement = new ItemElement<Bandeja>();

  bandejas: Bandeja[] = [];
  paginacionActual: IPagination = { pagina: 1, cantidad: 10 };

  public formGroup: FormGroup;

  public tickets: any = [];

  constructor(
    private formBuilder: FormBuilder,
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private securityService: SecurityService,
    private exceptionService: ExceptionService,
    private userService: UserService
  ) { }

  public async ObtenerBandejas(nombre: string = null, tipo: string = "0") {
    this.bandejas = [];
    //      Donde:
    //nombre = es para filtrar por nombre, al dejar null es como “”
    //tipo = tipo de  Bandeja. 0 = Todas, 1 = Usuario, 2 = Grupos

    const url = `${this.catalogoService.catalogo}ObtenerBandejas/`;
    console.log({ url });

    const options: IDictionary[] = [
      { key: '{nombre}', value: nombre },
      { key: '{tipo}', value: tipo },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Bandeja[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            const bandejas = this.utilService.mapear_propiedades(
              resp.Data,
              new Bandeja()
            );
            this.bandejas = this.itemElement.refrescar(bandejas, this.bandejas);

            return this.bandejas;
          })
        )
        .subscribe(
          (data: Bandeja[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  public async ObtenerBandejasByUser() {
    this.bandejas = [];

    const url = `${this.catalogoService.catalogo}ObtenerBandejasByUser/`;

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true)
        .pipe(
          map<any, Bandeja[]>((resp: any) => {
            console.log(resp);
            this.exceptionService.resolver_error(resp);

            const bandejas = this.utilService.mapear_propiedades(
              resp.Data,
              new Bandeja()
            );
            this.bandejas = this.itemElement.refrescar(bandejas, this.bandejas);

            return this.bandejas;
          })
        )
        .subscribe(
          (data: Bandeja[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  public async ObtenerTickets_v1(
    bandeja: Bandeja,
    loteNumero: string = null,
    estado: number = 0,
    paginacion: IPagination = this.paginacion
  ) {
    const url = `${this.catalogoService.catalogo}ObtenerTickets_v1`;
    let bandejaNombre = "";
    if (bandeja) {
      if (bandeja.Nombre != "Todos") {
        bandejaNombre = bandeja.Nombre;
      }
    }
    const body = {
      token: this.securityService.token,
      idBarrio: this.securityService.codigoBarrio,
      ticketMobileFilter: {
        IdLote: null,
        IdBandeja: null,
        LoteNumero: loteNumero,
        BandejaNombre: bandejaNombre,
        IdEstado: estado != null ? estado : "",
        Titulo: "",
        FechaCreacionDesde: "",
        FechaCreacionHasta: "",
      },
      paginaActual: paginacion.pagina.toString(),
      cantxPagina: paginacion.cantidad.toString(),
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.paginacionActual.pagina = resp["PaginaActual"];
            this.paginacionActual.cantidad = resp["CntTotal"];
            let tickets = resp["Data"];

            tickets.forEach((ticket) => {
              ticket.Comentarios.forEach((comentario) => {
                comentario.UrlImagen = this.utilService.resolver_url_imagen(
                  comentario.UrlImagen
                );
              });
              if (this.tickets.length <= this.paginacionActual.cantidad) {
                this.tickets.push(ticket);
              }
            });

            this.exceptionService.resolver_error(resp);
            return this.tickets;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  public async TicketMobileSetComentario(
    ticket: Ticket,
    comentarioTicket: string,
    comentarioPrivado: boolean = false,
    image: string = null
  ) {
    const url = `${this.catalogoService.catalogo}TicketMobileSetComentario_v1`;
    const body = {
      token: this.securityService.token,
      idTicket: ticket.Id,
      comentario: comentarioTicket,
      privado: comentarioPrivado ? 1 : 0,
      imagen: image,
      idBandeja: null,
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  public async TicketMobileCreate_v1(
    ticket: Ticket,
    lotes,
    privado: boolean,
    image: string = null
  ) {
    const url = `${this.catalogoService.catalogo}TicketMobileCreate_v1`;
    const body = {
      token: this.securityService.token,
      lotes: lotes,
      titulo: ticket.Titulo,
      comentario: ticket.Contenido,
      privado: privado ? 1 : 0,
      imagen: image, //BASE64
      idBandeja: ticket.IdBandeja,
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  //si se desea cambia solo la bandeja, enviar el estado actual del ticket y la nueva bandeja
  //si se desea cambia solo el estado de ticket, enviar el nuevo estado del ticket
  //si se desea cambia solo el estado y la bandeja de ticket, enviar los nuevos valores (estado y bandeja)
  public async TicketMobileUpdate(
    ticket: Ticket,
    IdBandeja: string,
    estado: number
  ) {
    const url = `${this.catalogoService.catalogo}TicketMobileUpdate`;
    const body = {
      token: this.securityService.token,
      idTicket: ticket.Id,
      idBandeja: IdBandeja,
      idEstado: estado, //0-> en tratamiento; 1-> cerrado; 2-> cancelado
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  limpiar() {
    this.tickets = [];
  }
}
