import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { IonSlides, NavController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";

// Constantes
import { PATH_URL } from "src/app/constants/url.constant";

// Interfaces
import { IIdentity } from "src/app/models/interfaces/interfaces.index";

// Servicios
import { SegmentService } from "src/app/services/common/segment.service";
import { MenuService } from "src/app/services/common/menu.service";
import { EnumTipoMenu, EnumTipoSegmento } from "src/app/models/enums/tipo.enum";
import { SbcService } from "src/app/services/common/sbc.service";

@Component({
  selector: "app-seguridad",
  templateUrl: "seguridad.page.html",
  styleUrls: ["seguridad.page.scss"],
})
export class SeguridadPage {
  @ViewChild("slides") slider: IonSlides;
  back = "";
  segments: IIdentity[] = [];
  private backButtonSubscription: Subscription;

  constructor(
    public segmentService: SegmentService,
    private menuService: MenuService,
    private router: Router,
    private sbcService: SbcService,
    private platform: Platform,
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    this.backButtonSubscription = this.platform.backButton.subscribe(() => {
      this.navCtrl.pop();
    });
    this.segmentService.actualSegment.subscribe((selector: any) => {
      console.log("entró a actualSegment de seguridad");
      if (selector && selector.type && selector.segment) {
        console.log("línea 45");
        if (selector.type === EnumTipoSegmento.Seguridad) {
          console.log("línea 47");
          this.actualizar_segmento_seleccionado(selector.segment);
        }
      }
    });
  }

  ionViewDidEnter() {
    this.sbcService
      .resolver_menu(EnumTipoMenu.Home, EnumTipoSegmento.Seguridad)
      .subscribe((resp: string | IIdentity[]) => {
        if (typeof resp !== "string") {
          console.log("aquí seguridad");
          this.segments = this.menuService.reemplazar_menu(
            this.segmentService.segmentosSeguridad,
            resp
          );
          this.segments = this.menuService.obtener_menu_sin_opciones_ocultas(
            this.segments
          );
          this.slider.update();
          this.segmentService.cargar_segmentos_seguridad();
          this.back = this.obtener_segmento_anterior();
        }
      });
  }

  actualizar_segmento_seleccionado(segmentoSeleccionado: IIdentity) {
    this.segmentService.segmentoSeguridadSeleccionado = segmentoSeleccionado.id;
    const sliderActual =
      this.segmentService.segmentosSeguridad.indexOf(segmentoSeleccionado);

    console.log("sliderActual", sliderActual);
    console.log("segmentoSeleccionado", segmentoSeleccionado);
    this.slider.slideTo(sliderActual);
    this.router.navigate([segmentoSeleccionado.path]);
  }

  sincronizar_vista(event) {
    this.slider.getActiveIndex().then((index: number) => {
      const segmentoSeleccionado =
        this.segmentService.seleccionar_segmento_seguridad(index);
      this.router.navigate([segmentoSeleccionado.path]);
    });
  }

  ocultar_slide(segmento: string) {
    const menu = this.menuService.buscar_item_por_propiedad(
      this.segmentService.segmentosSeguridad,
      segmento,
      "id"
    );
    return menu && !menu.hide;
  }

  private obtener_segmento_anterior() {
    if (this.segmentService.segmentosMensaje.length > 0) {
      return this.segmentService.segmentosMensaje[0].path;
    }

    if (this.segmentService.segmentosHome.length > 0) {
      return this.segmentService.segmentosHome[0].path;
    }

    return PATH_URL.MOBILE.LOGIN.INDEX;
  }
}
