import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Interfaces
import { IDictionary } from "src/app/models/interfaces/interfaces.index";

// Clases
import { Style, ResponseApp } from "src/app/models/entities/entity.index";
import { ItemElement } from "src/app/models/clases/ItemElement";

// Enums
import {
  EnumTipoConfig,
  EnumContentType,
  EnumTipoApp,
} from "src/app/models/enums/tipo.enum";

// Constantes
import { environment } from "src/app/constants/constants.index";

// Servicios
import { SecurityService } from "../security/security.service";
import { ConfigService } from "./config.service";
import { RestService } from "./rest.service";
import { CatalogoService } from "../business/catalogo.service";
import { DeviceService } from "./device.service";
import { UtilService } from "./util.service";
import { FileService } from "./file.service";
import { ExceptionService } from "./exception.service";

@Injectable({
  providedIn: "root",
})
export class StyleService {
  private styles: Style[] = [];
  private itemElement = new ItemElement<Style>();

  constructor(
    private segurityService: SecurityService,
    private configService: ConfigService,
    private deviceService: DeviceService,
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private fileService: FileService,
    private exceptionService: ExceptionService
  ) {}

  /**
   * Resuelve el style de la app
   */
  async resolver_vista() {
    if (this.segurityService.codigoBarrio) {
      if (!this.configService.configServer) {
        await this.configService.cargar_configuracion_app_server();
      }

      const styles: Style[] = this.configService.configServer.Styles;
      if (styles && styles.length > 0) {
        this.resolver_configuracion(styles);
      }
    }
  }

  private async obtener_estilos() {
    const typeApp = this.deviceService.isWeb
      ? EnumTipoApp.Web
      : EnumTipoApp.Mobile;
    const url = `${this.catalogoService.catalogo}ObtenerStyleSettings/`;
    const options: IDictionary[] = [{ key: "{typeApp}", value: typeApp }];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, false, false, options)
        .pipe(
          map<any, Style[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            const styles = this.utilService.mapear_propiedades(
              resp.Data,
              new Style()
            );
            this.styles = this.itemElement.refrescar(styles, this.styles);

            console.log("styles", this.styles);
            return this.styles;
          })
        )
        .subscribe(
          (data: Style[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  private cargar_estilo(url: string, cssId: string) {
    const element = document.getElementById(cssId);
    if (!element) {
      const head = document.getElementsByTagName("head")[0];
      const link = document.createElement("link");
      link.id = cssId;
      link.rel = "stylesheet";
      link.type = "text/css";
      link.href = url;
      link.media = "all";
      head.appendChild(link);
    }
  }

  /**
   * Resuelve los detalles de configuración para el Style (local o remoto)
   * @param styles info de la configuración para cada estilo
   */
  private resolver_configuracion(styles: Style[]) {
    console.log("Styleservice 125, " + styles.length);
    const config = this.configService.config;
    styles.forEach((style: Style) => {
      if (style.Config === EnumTipoConfig.Local) {
        console.log("styleService if 130");

        const aplicarEstilo = config.BARRIOS.filter(
          (codigo: string) =>
            codigo.toLowerCase() ===
            this.segurityService.codigoBarrio.toLowerCase()
        )[0];

        const local: Style = config.STYLES.filter(
          (styleLocal: Style) =>
            styleLocal.StyleId.toLowerCase() === style.StyleId.toLowerCase()
        )[0];

        style.Url = local.Url.replace(
          config.KEY.CODIGO,
          this.segurityService.codigoBarrio
        );
        style.Enabled = aplicarEstilo && local.Enabled;
        console.log("styleService style 140", style);
        this.cargar_estilo(style.Url, style.StyleId);
      } else {
        console.log("styleService else 141");
        this.resolver_style_remoto(style).then((styleSetting: Style) => {
          style = styleSetting;
          this.cargar_estilo(style.Url, style.StyleId);
        });
      }
    });

    let navbar = document.getElementById("navbar");
    if (navbar) navbar.removeAttribute("style");

    let content = document.getElementById("content");
    if (content) content.removeAttribute("style");
  }

  /** Se encarga de sincronizar el style de la app de manera remota.
   * Si este ya se encuentra en el dispositivo, no lo vuelve a descargar
   * La configuración del style se toma desde el server
   * La única manera que se actualice el archivo es cambiando el nombre del style desde el server
   */
  private async resolver_style_remoto(style: Style) {
    console.log("Style style.Service 168", style);

    if (this.deviceService.isWeb || environment.APP.MOCK_MOBILE) {
      return style;
    } else {
      const fileName = this.utilService.get_last_value(style.Url);
      console.log("style.Service fileName 179", fileName);
      const existe = await this.fileService.file_existe(fileName);
      console.log("style.Service existe 179", existe);

      if (existe) {
        console.log("Mantiene el mismo style");
        //style.Url = await this.fileService.obtener_url_relativa(fileName);
      } else {
        const url = await this.fileService.download_native(
          style.Url,
          EnumContentType.CSS
        );
        style.Url = url; //await this.fileService.obtener_url_relativa(fileName);
        console.log("NO Mantiene el mismo style");
      }
    }

    console.log("style.Service style 179", style);
    return style;
  }
}
