export { environment } from "../../environments/environment";

export { IMG } from "./img.constant";
export { PATH_URL, PATH_ANDROID } from "./url.constant";
export { MESSAGE, TIEMPO_MENSAJE } from "./message.constant";
export { STORAGE } from "./storage.constant";
export { TEMPLATE } from "./template.constant";
export { HEADER } from "./header.constant";
export { MENU } from "./menu.constant";
export { POPOVER_STYLES } from "./popover-styles.constant";

export {
  SCROLL_FAB_HEIGHT,
  ENABLED_SCROLL_EVENT,
  ALERT,
  PAGINATOR,
  IMG_EXTENSION_SOPORTADA,
  MODULOS,
  RANGO_FECHA,
  FECHA,
  IMG_APP,
  CALENDARIO,
  INTERVAL,
  REPLACE_IMG,
  ELEMENTOS_PARA_NO_AFECTAR_TABS,
  FORMS,
  APP,
  EMPTY_PARAMS,
} from "./config.constant";
