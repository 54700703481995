import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ɵConsole,
} from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from "@angular/forms";
import { DatePipe } from "@angular/common";

// Clases
import {
  ReservaTurno,
  ReservaDestino,
  ReservaDestinoInstalacion,
  Lote,
  ResponseApp,
} from "src/app/models/entities/entity.index";

// Services
import { ReservaService } from "src/app/services/business/reserva.service";
import { ControlService } from "src/app/services/common/control.service";
import { UtilService } from "src/app/services/common/util.service";
import { ValidationService } from "src/app/services/common/validation.service";
import { UserService } from "src/app/services/security/user.service";

// Constantes
import { MESSAGE, FECHA, RANGO_FECHA } from "src/app/constants/constants.index";
import { TIEMPO_MENSAJE } from "src/app/constants/message.constant";

@Component({
  selector: "app-reserva",
  templateUrl: "./reserva.page.html",
  styleUrls: ["./reserva.page.scss"],
  providers: [DatePipe],
})
export class ReservaPage implements OnInit {
  destinosReservables: ReservaDestino[] = [];
  misReservas: ReservaTurno[] = [];
  turnosReservables: ReservaTurno[] = [];
  instalaciones: ReservaDestinoInstalacion[] = [];
  lotes: Lote[] = [];
  formGroup: FormGroup;
  anioMin = new Date().toISOString();
  anioMax: number = RANGO_FECHA.ACCESO.NUEVO.ANIO_MAXIMO;
  formatoFechaControl: string = FECHA.FORMATO_VISUAL;
  mostrarFiltro = true;
  cantidadReserva = 1;

  constructor(
    private reservaService: ReservaService,
    private controlService: ControlService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private datePipe: DatePipe,
    private utilService: UtilService
  ) {
    this.lotes = this.userService.user.Lotes;
  }

  ngOnInit() {
    this.configurar_formulario();
    this.obtener_destinos_reservas();
  }

  configurar_formulario(destinosReservables?: ReservaDestino) {
    const fechaPublicacion = this.utilService.obtener_fecha_diferencial(
      new Date()
    );

    if (!destinosReservables) {
      this.formGroup = this.formBuilder.group({
        lotes: [this.lotes[0].Id, Validators.required],
        actividades: [Validators.required],
        instalaciones: [Validators.required],
        fechaReserva: [fechaPublicacion, Validators.required],
        horarioReserva: [0, Validators.required],
      });
    } else {
      this.formGroup = this.formBuilder.group({
        lotes: [this.lotes[0].Id, Validators.required],
        actividades: [destinosReservables.Id, Validators.required],
        instalaciones: [
          destinosReservables.Instalaciones[0].Id,
          Validators.required,
        ],
        fechaReserva: [fechaPublicacion, Validators.required],
        horarioReserva: [0, Validators.required],
      });
    }
  }

  obtener_destinos_reservas() {
    this.reservaService
      .obtener_destinos_reservas()
      .then((resp: ReservaDestino[]) => {
        if (resp == null) {
          this.controlService.habilitar_infinite_scroll(event, false);
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_SIN_DATOS,
            TIEMPO_MENSAJE
          );
        } else {
          this.destinosReservables = resp;
          this.instalaciones = this.destinosReservables[0].Instalaciones;
        }
      })
      .catch((error) => {
        this.controlService.habilitar_infinite_scroll(event, false);
        this.controlService.mostrar_toast_con_error(error, false);
      })
      .finally(() => {
        if (event == null) {
          this.controlService.ocultar_loading();
        }
        this.configurar_formulario(this.destinosReservables[0]);
        this.reservas_por_lote(this.lotes[0].Id);
      });
  }

  actualizar_instalacion() {
    this.reservas_disponibles();
  }

  actualizar_actividad() {
    this.destinosReservables.forEach((destino) => {
      if (destino.Id == this.formGroup.value.actividades) {
        this.instalaciones = destino.Instalaciones;
        //(<FormControl>this.formGroup.controls['instalaciones']).setValue(destino.Instalaciones[0].Id);
        this.configurar_formulario(destino);
      }
    });

    this.reservaService.limpiarDestinosReservables();
    this.reservaService.limpiarTurnosReservables();

    this.reservas_disponibles();
  }

  reservas_por_lote(lote?: string) {
    this.reservaService.limpiarTurnosReservables();
    this.reservaService.limpiarMisReservas();

    const loteFormulario = this.formGroup.value.lotes;

    this.reservaService
      .ObtenerTurnosReservadosByLote(lote ? lote : loteFormulario)
      .then((resp) => {
        resp.forEach((turno) => {
          this.destinosReservables.forEach((destino) => {
            destino.Instalaciones.forEach((instalacion) => {
              if (turno.IdReservaDestinoInstalacionMobile == instalacion.Id) {
                turno.InstalacionDescripcion = instalacion.Descripcion;
              }
            });
          });
        });

        this.misReservas = resp;
      });

    this.reservas_disponibles();
  }

  reservas_disponibles() {
    this.turnosReservables = [];

    let fecha = this.formGroup.value.fechaReserva;
    let reservaDestino = this.formGroup.value.actividades;
    let reservaDestinoInstalacion = this.formGroup.value.instalaciones;

    fecha = fecha.split("T")[0].replace("-", "").replace("-", "");

    this.reservaService
      .obtener_turnos_reservables(
        fecha,
        reservaDestino,
        reservaDestinoInstalacion
      )
      .then((resp) => {
        this.reservaService.limpiarTurnosReservables();
        this.turnosReservables = resp;
      });
  }

  cancelar_reserva(reserva: ReservaTurno) {
    this.reservaService.cancelar_reserva(reserva).then((resp: ResponseApp) => {
      this.controlService.mostrar_toast("Reserva cancelada", TIEMPO_MENSAJE);
      this.reservas_por_lote();
    }).catch((error) => {
      this.controlService.mostrar_alert({
        header: MESSAGE.ALERT.MENSAJE_RESERVA.INFORMATIVO.TITULO,
        message: error,
        backdropDismiss: false,
        buttons: [
          {
            text: MESSAGE.ALERT.MENSAJE_RESERVA.INFORMATIVO.BUTTONS
              .ACEPTAR,
          },
        ],
      });
    });
  }

  handleLuminaria(reserva: ReservaTurno, encender: boolean) {
    this.reservaService.handleLuminaria(reserva, encender).then((resp) => {
      this.controlService.mostrar_alert({
        message: `¡Operación exitosa!`,
        buttons: [
          {
            text: MESSAGE.ALERT.MENSAJE_RESERVA.INFORMATIVO.BUTTONS
              .ACEPTAR,
          },
        ],
      });
    }).catch(error => {
      this.controlService.mostrar_alert({
        message: error,
        buttons: [
          {
            text: MESSAGE.ALERT.MENSAJE_RESERVA.INFORMATIVO.BUTTONS
              .ACEPTAR,
          },
        ],
      })
    });
  }


  crear_reserva() {
    let reservaId = this.formGroup.value.horarioReserva;
    let lotes = this.formGroup.value.lotes;

    if (typeof reservaId != "string" || reservaId == "") {
      this.controlService.mostrar_toast(
        "Por favor, seleccione un horario disponible.",
        TIEMPO_MENSAJE
      );
    } else {
      this.turnosReservables.forEach((turno) => {
        if (turno.Id == reservaId) {
          turno.Cupo = this.cantidadReserva;
          this.reservaService
            .crear_reserva(turno, lotes)
            .then((resp) => {
              this.formGroup.value.horarioReserva = 0;
              this.cantidadReserva = 1;
              this.controlService.mostrar_toast(
                "Reserva creada",
                TIEMPO_MENSAJE
              );
              if (turno.MensajeInformativo) {
                this.controlService.mostrar_alert({
                  header: MESSAGE.ALERT.MENSAJE_RESERVA.INFORMATIVO.TITULO,
                  message: turno.MensajeInformativo,
                  backdropDismiss: false,
                  buttons: [
                    {
                      text: MESSAGE.ALERT.MENSAJE_RESERVA.INFORMATIVO.BUTTONS
                        .ACEPTAR,
                    },
                  ],
                });
              }
              this.reservas_por_lote();
            })
            .catch((error) => {
              this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
            });
        }
      });
    }
  }

  async cancelacion_Reserva(reserva) {
    let pregunta = "";
    const resp = await this.reservaService.ObtenerMensajePreCancelacion(reserva);
    pregunta = resp.StatusDescription;

    if (!pregunta) {
      pregunta = MESSAGE.ALERT.MENSAJE_RESERVA.CANCELAR_RESERVA.PREGUNTA;
    }
    this.controlService.mostrar_alert({
      header: MESSAGE.ALERT.MENSAJE_RESERVA.CANCELAR_RESERVA.TITULO,
      message: pregunta,
      backdropDismiss: false,
      buttons: [
        {
          text: MESSAGE.ALERT.MENSAJE_RESERVA.CANCELAR_RESERVA.BUTTONS.CANCELAR,
          role: "cancel",
        },
        {
          text: MESSAGE.ALERT.MENSAJE_RESERVA.CANCELAR_RESERVA.BUTTONS.ACEPTAR,
          handler: () => {
            this.cancelar_reserva(reserva);
          },
        },
      ],
    });
  }

  setear_horario(horarioId: string) {
    if (this.formGroup.value.horarioReserva != horarioId) {
      (<FormControl>this.formGroup.controls["horarioReserva"]).setValue(
        horarioId
      );
      this.cantidadReserva = 1;
    }
  }

  setear_cantidad(event) {
    this.cantidadReserva = event.detail.value;
  }
}
