import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "capitalize",
})
export class CapitalizePipe implements PipeTransform {
  transform(value: any): string {
    let stringCapitalized = "";

    if (value) {
      let values = value.split(" ");

      for (let val of values) {
        stringCapitalized +=
          val.charAt(0).toUpperCase() + val.substr(1).toLowerCase();
        stringCapitalized += " ";
      }
    }

    return stringCapitalized;
  }
}
