import { Injectable } from '@angular/core';

// Plugins
import OneSignalPlugin from 'onesignal-cordova-plugin';


// Servicios
import { DeviceService } from "./device.service";
import { UtilService } from "./util.service";
import { ControlService } from "./control.service";
import { StorageService } from "./storage.service";
import { TicketService } from "../business/ticket.service";
import { NoticiaService } from "../business/noticia.service";
import { NotificacionService } from "../business/notificacion.service";
import { DocumentoService } from "../business/documento.service";

// Constantes
import {
  APP,
  environment,
  POPOVER_STYLES,
} from "src/app/constants/constants.index";
import { STORAGE } from "src/app/constants/storage.constant";

//Enums
import { EnumNotificationTypes } from "src/app/models/enums/tipo.enum";

// Clases
import {
  NotificacionOneSignal,
  Documento,
  Notificacion,
  Ticket,
  Noticia,
} from "src/app/models/entities/entity.index";

// Interfaces
import { IModal } from "src/app/models/interfaces/interfaces.index";

// Páginas
import { BehaviorSubject } from 'rxjs';
import { PushNotificationPage } from "../../pages/mobile/push-notification/push-notification.page";
import { NotificationDetailPage } from "../../pages/mobile/notification-detail/notification-detail.page";
import { DetalleTicketPage } from "src/app/pages/mobile/mensaje/ticket/detalle/detalle.page";
import { DetalleNoticiaPageNotification } from "src/app/pages/mobile/home/detalle-noticia/detalle-noticia.page";
import { InvitacionDetalle } from "../../models/entities/business/invitacion.entity";
import { DetalleInvitacionPage } from "src/app/pages/mobile/seguridad/invitacion/detalle/detalle-invitacion.page";

@Injectable({
  providedIn: "root",
})
export class PushNotificationService {
  //oneSignalId: string;
  notificaciones: Set<string> = new Set();

  oneSignalId = new BehaviorSubject<string>('');

  constructor(
    private deviceService: DeviceService,
    private utilService: UtilService,
    private controlService: ControlService,
    private storageService: StorageService,
    private documentoService: DocumentoService
  ) { }

  inicializar_oneSignal() {
    if (!this.deviceService.isWeb) {
      /*  this.oneSignal.startInit(
          APP.APP_ID.ONE_SIGNAL.ANDROID.APP_ID,
          APP.APP_ID.ONE_SIGNAL.ANDROID.FIREBASE_PROJECT_NUMBER
        );
  
        this.oneSignal.inFocusDisplaying(
          this.oneSignal.OSInFocusDisplayOption.Notification
        );
  
        this.oneSignal
          .handleNotificationReceived()
          .subscribe((notification: OSNotification) => {
            const notificacion: NotificacionOneSignal =
              this.utilService.mapear_propiedades(
                notification.payload,
                new NotificacionOneSignal()
              );
  
            if (
              !this.notificaciones.has(notificacion.notificationID) &&
              notificacion.additionalData != null
            ) {
              this.solveOneSignalNotification(notificacion);
              this.notificaciones.add(notificacion.notificationID);
            }
          });*/

      OneSignalPlugin.setAppId(APP.APP_ID.ONE_SIGNAL.ANDROID.APP_ID);

      OneSignalPlugin.promptForPushNotificationsWithUserResponse((accepted) => {
        console.log("User accepted notifications: " + accepted);
      });

      OneSignalPlugin.setNotificationOpenedHandler(((notification: any) => {
        console.log("notificación recibida: " + JSON.stringify(notification));
        const notificacion: NotificacionOneSignal = this.utilService.mapear_propiedades(notification.notification, new NotificacionOneSignal());

        console.log('Notificación  parseada' + JSON.stringify(notificacion));
        if (
          !this.notificaciones.has(notificacion.notificationId)
          && notificacion.additionalData != null) {

          this.solveOneSignalNotification(notificacion);
          this.notificaciones.add(notificacion.notificationId);
        }
      }));

      OneSignalPlugin.setNotificationWillShowInForegroundHandler((notification: any) => {
        console.log("response de notificacion setNotificationWillShowInForegroundHandler(): ", JSON.stringify(notification));
        console.log("notificación recibida: " + JSON.stringify(notification));
        const notificacion: NotificacionOneSignal = this.utilService.mapear_propiedades(notification.notification, new NotificacionOneSignal());

        console.log('Notificación  parseada' + JSON.stringify(notificacion));
        if (
          !this.notificaciones.has(notificacion.notificationId)
          && notificacion.additionalData != null) {

          this.solveOneSignalNotification(notificacion);
          this.notificaciones.add(notificacion.notificationId);
        }
      });



      /*console.log("Este es el app_id onesignal: " + APP.APP_ID.ONE_SIGNAL.ANDROID.APP_ID);
       console.log("Este es el firebase onesignal: " + APP.APP_ID.ONE_SIGNAL.ANDROID.FIREBASE_PROJECT_NUMBER);
       this.oneSignal.startInit(APP.APP_ID.ONE_SIGNAL.ANDROID.APP_ID, APP.APP_ID.ONE_SIGNAL.ANDROID.FIREBASE_PROJECT_NUMBER);
 
       this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
 
       this.oneSignal.handleNotificationReceived().subscribe((notification: OSNotification) => {
 
         const notificacion: NotificacionOneSignal = this.utilService.mapear_propiedades(notification.payload, new NotificacionOneSignal());
 
         console.log('Notificación' + JSON.stringify(notificacion));
         if (
           !this.notificaciones.has(notificacion.notificationID)
           && notificacion.additionalData != null) {
 
           this.solveOneSignalNotification(notificacion);
           this.notificaciones.add(notificacion.notificationID);
         }
       });
 
       this.oneSignal.handleNotificationOpened().subscribe((notification: OSNotificationOpenedResult) => {
 
         const notificacion: NotificacionOneSignal =
           this.utilService.mapear_propiedades(notification.notification.payload, new NotificacionOneSignal());
         console.log('Notificación' + JSON.stringify(notificacion));
         if (
           !this.notificaciones.has(notificacion.notificationID)
           && notificacion.additionalData != null) {
 
           //this.mostrar_modal(notificacion);
           this.solveOneSignalNotification(notificacion);
           this.notificaciones.add(notificacion.notificationID);
         }
       });
 
       this.oneSignal.endInit();*/
    }
  }

  async cargar_id_onesignal() {
    return new Promise((resolve, reject) => {
      if (this.deviceService.isWeb || environment.APP.MOCK_MOBILE) {
        resolve(true);
        return;
      }

      this.storageService.cargar(STORAGE.KEY.ONESIGNAL_ID)
        .then(async (result: string) => {
          console.log("onesignal del storage", JSON.stringify(STORAGE.KEY.ONESIGNAL_ID));
          if (result) {
            this.oneSignalId.next(this.storageService.currentValue);
          }
          else {
            console.log("entró a resolver_oneSignal_id");
            const oneSignalIdResult = await this.getUserId();
            console.log("respuesta getUserId: ", oneSignalIdResult);
          }
          resolve(true);
        });
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /*private async resolver_oneSignal_id() {

    this.getUserId();
    console.log("this.oneSignalId", this.oneSignalId);
    //this.storageService.guardar({ key: STORAGE.KEY.ONESIGNAL_ID, value: this.oneSignalId });

    /*this.oneSignal.getIds().then((ids) => {

      console.log("onesignal del plugin", JSON.stringify(ids));
      this.oneSignalId = ids.userId;
      this.storageService.guardar({
        key: STORAGE.KEY.ONESIGNAL_ID,
        value: this.oneSignalId,
      });

      resolve(this.oneSignalId);
    });

  }*/

  getUserIdTest() {
    return new Promise<any>((resolve, reject) => {
      OneSignalPlugin.getDeviceState((response) => {
        console.log('error obtenido en getDeviceState; ' + response);
        resolve(response.userId);
      });
    });
  }


  getUserId() {
    return new Promise<any>((resolve, reject) => {
      OneSignalPlugin.addSubscriptionObserver((state: any) => {
        console.log("Push Subscription state changed: " + JSON.stringify(state));
        if (state.to.userId) {
          // get player ID
          this.storageService.guardar({ key: STORAGE.KEY.ONESIGNAL_ID, value: state.to.userId });
          resolve(state.to.userId);
          this.oneSignalId.next(state.to.userId);
          console.log("Subscribed for OneSignal push notifications!")
        }
      });
    });
  }

  private solveOneSignalNotification(notification: NotificacionOneSignal) {
    switch (notification.additionalData.tipoMensaje) {
      case EnumNotificationTypes.acceso:
        this.mostrar_modal(notification);
        break;
      case EnumNotificationTypes.documentoDelLote:
        this.openDocument(notification);
        break;
      case EnumNotificationTypes.noticia:
        this.showNoticiaModal(notification);
        break;
      case EnumNotificationTypes.notificacion:
        this.showNotificationDetail(notification);
        break;
      case EnumNotificationTypes.ticket:
        this.showTicket(notification);
        break;
      case EnumNotificationTypes.invitacion:
        this.showInvitacion(notification);
        break;
      default:
        break;
    }
  }

  private async mostrar_modal(notificacion: NotificacionOneSignal) {
    const modal: IModal = {
      component: PushNotificationPage,
      componentProps: { pushNotification: notificacion },
      returnData: true,
    };

    this.controlService.mostrar_modal(modal);
  }

  private async openDocument(notification: NotificacionOneSignal) {
    const catalogo = await this.storageService.cargar(STORAGE.KEY.CATALOGO);
    const token = await this.storageService.cargar(STORAGE.KEY.TOKEN);
    this.documentoService
      .get_documentoLote(
        catalogo.toString(),
        token.toString(),
        notification.additionalData.id
      )
      .then((documento: Documento) => {
        this.utilService.openNewTab(documento.Url);
      })
      .catch((error) => {
        console.log("error openDocumento 177", error);
      });
  }

  public async showNoticiaModal(notification: NotificacionOneSignal) {
    const catalogo = await this.storageService.cargar(STORAGE.KEY.CATALOGO);
    const data = notification.additionalData;
    const news = new Noticia();
    news.Id = data.id;
    news.FechaPublicacion = parseInt(data.fechaPublicacion); //decirle a Leo que necesito en ticks
    news.Titulo = data.titulo;
    news.ContenidoIsHTML = parseInt(data.contenidoIsHTML);
    news.Contenido = data.contenido;
    news.Documentos = data.documentos.map((document) => ({
      Id: document.id,
      Descripcion: document.descripcion,
      Url: document.url,
      Numero: null,
      TextoAdicional: null,
      Tipo: null,
    }));
    news.UrlImagen = data.urlImagen;
    if (data.urlImagen && data.urlImagen !== "") {
      let urlImage = `${catalogo}${data.urlImagen}`;
      urlImage = urlImage.replace("MobileService.svc/rest/", "");
      urlImage = this.utilService.resolver_url_imagen(urlImage);
      news.UrlImagen = urlImage;
    }

    let icon = await this.storageService.cargar(STORAGE.KEY.NEIGHBORHOOD_ICON);
    if (icon && typeof icon === "string") {
      icon = JSON.parse(icon);
    }

    try {
      const modal: IModal = {
        component: DetalleNoticiaPageNotification,
        componentProps: { noticia: news, isNotification: true, icono: icon },
        cssClass: POPOVER_STYLES.FULL_NEW_MODAL,
        returnData: true,
      };
      this.controlService.mostrar_modal(modal);
    } catch (error) {
      console.log("error al abrir detalle de noticia: " + error);
    }
  }

  private async showNotificationDetail(osNotification: NotificacionOneSignal) {
    const data = osNotification.additionalData;
    const notification = new Notificacion();
    notification.Id = data.id;
    notification.FechaAlta = data.fechaCreacion;
    notification.Numero = data.numero;
    notification.Nombre = data.titulo;
    notification.Descripcion = data.descripcion;
    notification.DescripcionIsHTML = parseInt(data.descripcionIsHTML);
    notification.Documentos = data.documentos.map((document) => ({
      Id: document.id,
      Descripcion: document.descripcion,
      Url: document.url,
      Numero: null,
      TextoAdicional: null,
      Tipo: null,
    }));

    const modal: IModal = {
      component: NotificationDetailPage,
      componentProps: { notification: notification, isNotification: true },
      returnData: true,
    };
    this.controlService.mostrar_modal(modal);
  }

  private async showTicket(notification: NotificacionOneSignal) {
    const catalogo = await this.storageService.cargar(STORAGE.KEY.CATALOGO);
    const ticket = new Ticket();
    ticket.Id = notification.additionalData.id;
    ticket.Titulo = notification.additionalData.titulo;
    ticket.FechaCreacion = notification.additionalData.fechaCreacion;
    ticket.EstadoDescripcion = notification.additionalData.estado;
    ticket.Numero = notification.additionalData.numero;
    ticket.Estado = Number(notification.additionalData.estado);
    ticket.Bandeja = notification.additionalData.bandeja;
    ticket.Contenido = notification.additionalData.comentarios[0].comentario;
    ticket.Comentarios = notification.additionalData.comentarios.map(
      (comentario) => {
        let urlImagen = comentario.urlImagen;
        if (comentario.urlImagen && comentario.urlImagen !== "") {
          urlImagen = `${catalogo}${comentario.urlImagen}`;
          urlImagen = urlImagen.replace("MobileService.svc/rest/", "");
        }
        return {
          Id: comentario.id,
          Usuario: comentario.usuario,
          Comentario: comentario.comentario,
          Fecha: comentario.fecha,
          UrlImagen: urlImagen,
        };
      }
    );

    console.log("tick a enviar a DetalleTicketPage", ticket);
    const modal: IModal = {
      component: DetalleTicketPage,
      componentProps: { ticketInput: ticket },
      returnData: true,
    };
    this.controlService.mostrar_modal(modal);
  }

  private async showInvitacion(notification: NotificacionOneSignal) {
    const invitacion = new InvitacionDetalle();
    invitacion.Id = notification.additionalData.id;
    invitacion.Apellido = notification.additionalData.apellido;
    invitacion.Nombre = notification.additionalData.nombre;
    invitacion.Documento = notification.additionalData.documento;
    invitacion.Email = notification.additionalData.email;
    invitacion.MotivoRechazo = notification.additionalData.motivoRechazo;
    invitacion.FechaDesde = notification.additionalData.fechaInvitacionDesde;
    invitacion.FechaHasta = notification.additionalData.fechaInvitacionHasta;

    const modal: IModal = {
      component: DetalleInvitacionPage,
      componentProps: { invitacion: invitacion },
      returnData: true,
    };
    this.controlService.mostrar_modal(modal).then((info: any) => { });
  }
}
