import { Component, OnInit, Input } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";

// Clases
import {
  Noticia,
  Item,
  Categoria,
  FormAbmNoticiaModel,
  FileItem,
  ResponseApp,
} from "src/app/models/entities/entity.index";

// Constantes
import { FECHA, RANGO_FECHA } from "src/app/constants/config.constant";
import { MESSAGE, TIEMPO_MENSAJE } from "src/app/constants/constants.index";

// Servicios
import { NoticiaService } from "src/app/services/business/noticia.service";
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { SbcService } from "src/app/services/common/sbc.service";
import { ValidationService } from "src/app/services/common/validation.service";
import { UtilService } from "src/app/services/common/util.service";
import { FileService } from "src/app/services/common/file.service";

@Component({
  selector: "app-agregar",
  templateUrl: "./agregar.page.html",
  styleUrls: ["./agregar.page.scss"],
})
export class AgregarNoticiaPage implements OnInit {
  @Input() noticia: Noticia;
  @Input() titulo = "";
  archivos: FileItem[] = [];
  noticiaImage: string = null;
  canales: Item[] = [];
  categorias: Categoria[] = [];
  modelo: FormAbmNoticiaModel;
  esEdicion = false;
  formGroup: FormGroup;
  anioMin: number = RANGO_FECHA.ACCESO.NUEVO.ANIO_MINIMO;
  anioMax: number = RANGO_FECHA.ACCESO.NUEVO.ANIO_MAXIMO;
  formatoFechaControl: string = FECHA.FORMATO_VISUAL;

  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private sbcService: SbcService,
    private validationService: ValidationService,
    private utilService: UtilService,
    private fileService: FileService,
    private controlService: ControlService,
    private noticiaService: NoticiaService
  ) { }

  ngOnInit() {
    // Cuando vengo a editar obtengo la noticia a editar
    this.esEdicion = this.noticia != null;

    // Si no es administrador no puede ingresar
    if (!this.userService.user.Profiles.includes("Administrativo")) {
      this.cerrar_modal();
    } else {
      this.canales = this.sbcService.tiposCanal;
      this.categorias = this.sbcService.tiposCategoriasNoticia.filter(
        ({ Id }) => Id !== "todos"
      );
      this.agregar_categoria_default();
      this.configurar_formulario();
    }
  }

  cerrar_modal() {
    this.controlService.cerrar_modal();
  }

  async guardar() {
    const modelo = await this.obtener_modelo_actualizado();
    const noticia: Noticia = this.utilService.mapear_propiedades(
      modelo,
      new Noticia(),
      false
    );
    noticia.Categoria = this.obtener_categoria_seleccionada();

    //eliminamos la propiedad fechaHoraModificacion porque el servicio no lo necesita
    delete noticia.FechaHoraModificacion;

    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.noticiaService
        .guardar_noticia(noticia)
        .then((resp: ResponseApp) => {
          this.controlService.mostrar_toast(
            MESSAGE.INFO.MENSAJE_CAMBIOS_GUARDADOS,
            TIEMPO_MENSAJE
          );
          this.cerrar_modal();
        })
        .catch((error) => {
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    });
  }

  obtener_categoria_seleccionada(): Categoria {
    if (this.formGroup.value.categoria === "") {
      return null;
    }

    if (this.categorias.length > 0) {
      return this.categorias.filter(
        (categoria: Categoria) =>
          categoria.Id === this.formGroup.value.categoria
      )[0];
    }

    return null;
  }

  obtener_canal_seleccionado(): Item {
    return this.canales.filter(
      (canal: Item) => canal.Id === this.formGroup.value.canal
    )[0];
  }

  async obtener_imagen_seleccionada() {
    if (this.archivos.length > 0) {
      const url = await this.fileService.convert_to_base64(
        this.archivos[0],
        true
      );
      return url;
    }


    return null;
  }

  limpiar_fecha_cierre(vaciar: boolean = false) {
    this.formGroup.controls["fechaCierre"].setValue(
      vaciar ? null : this.modelo.FechaCierre
    );
  }

  limpiar_imagen() {
    this.archivos = [];
  }

  sincronizar_imagen(fileItem: { file: File, base64: string } | null) {
    this.noticiaImage = fileItem.base64;
  }

  private configurar_formulario() {
    console.log("entró a configurar formulario");
    this.inicializar_controles_formulario();

    this.formGroup = this.formBuilder.group({
      titulo: [this.modelo.Titulo, Validators.required],
      fechaPublicacion: [this.modelo.FechaPublicacion],
      fechaCierre: [this.modelo.FechaCierre],
      categoria: [this.modelo.Categoria],
      canal: [this.modelo.Canal, Validators.required],
      contenido: [this.modelo.Contenido, Validators.required],
      visible: [this.modelo.VisibleInHome],
    });

    this.formGroup.controls["fechaPublicacion"].setValidators([
      Validators.required,
    ]);
    this.formGroup.controls["fechaCierre"].setValidators([
      this.validar_rango_fecha.bind(this.formGroup, this.validationService),
    ]);
  }

  private inicializar_controles_formulario() {
    const fechaPublicacion = this.utilService.obtener_fecha_diferencial(
      new Date()
    );
    const fechaCierre = this.utilService.obtener_fecha_diferencial(new Date());

    this.modelo = new FormAbmNoticiaModel();
    if (!this.esEdicion) {
      this.modelo.Numero = "";
      this.modelo.Titulo = "";
      this.modelo.FechaPublicacion = fechaPublicacion;
      this.modelo.FechaCierre = fechaCierre;
      this.modelo.Categoria = this.categorias[0].Id;
      this.modelo.Canal = this.canales[0].Id;
      this.modelo.Contenido = "";
      this.modelo.VisibleInHome = 0;
      this.modelo.Imagen = null;
      this.modelo.UrlImagen = null;
    } else {
      this.modelo.Id = this.noticia.Id;
      this.modelo.Numero = this.noticia.Numero;
      this.modelo.Titulo = this.noticia.Titulo;
      this.modelo.FechaPublicacion = this.utilService
        .convert_ticks_to_date(Number(this.noticia.FechaPublicacion))
        .toISOString();
      this.modelo.FechaCierre = this.noticia.FechaCierre
        ? this.utilService
          .convert_ticks_to_date(Number(this.noticia.FechaCierre))
          .toISOString()
        : null;
      this.modelo.Categoria =
        this.noticia.Categoria && this.noticia.Categoria.Id
          ? this.noticia.Categoria.Id
          : this.categorias.length > 0
            ? this.categorias[0].Id
            : null;
      this.modelo.Canal = this.noticia.Canal
        ? this.noticia.Canal
        : this.canales[0].Id;
      this.modelo.Contenido = this.noticia.Contenido;
      this.modelo.VisibleInHome = this.noticia.VisibleInHome ? 1 : 0;

      // Si tengo una url remota para el recurso, la convierto para base64
      if (this.noticia.UrlImagen) {
        this.fileService
          .create_object_file_of_url(
            this.noticia.UrlImagen,
            this.noticia.Titulo
          )
          .then((archivo: any) => {
            const archivoNuevo = new FileItem(archivo);
            this.archivos.push(archivoNuevo);
            this.fileService
              .convert_to_base64(archivoNuevo, true)
              .then((url) => (this.modelo.Imagen = url));
          });
      }
    }
  }

  private async obtener_modelo_actualizado(): Promise<FormAbmNoticiaModel> {
    this.modelo.Numero = this.esEdicion ? this.noticia.Numero : "";
    this.modelo.Titulo = (
      this.formGroup.controls["titulo"] as FormControl
    ).value;
    this.modelo.FechaPublicacion = this.utilService.convertir_date_a_ticks(
      (this.formGroup.controls["fechaPublicacion"] as FormControl).value
    );
    this.modelo.FechaCierre = (
      this.formGroup.controls["fechaCierre"] as FormControl
    ).value;
    if (this.modelo.FechaCierre) {
      this.modelo.FechaCierre = this.utilService.convertir_date_a_ticks(
        this.modelo.FechaCierre
      );
    }

    const categoria = this.obtener_categoria_seleccionada();
    this.modelo.Categoria = categoria ? categoria.Id : null;
    this.modelo.Canal = this.obtener_canal_seleccionado().Id;
    this.modelo.VisibleInHome = (
      this.formGroup.controls["visible"] as FormControl
    ).value
      ? 1
      : 0;
    this.modelo.Contenido = (
      this.formGroup.controls["contenido"] as FormControl
    ).value;
    this.modelo.Imagen = this.noticiaImage; //await this.obtener_imagen_seleccionada();

    return this.modelo;
  }

  private validar_rango_fecha(
    validationService: ValidationService,
    control: FormControl
  ): { [s: string]: boolean } {
    const form: any = this;
    const fechaPublicacion = form.controls["fechaPublicacion"];
    const fechaCierre = form.controls["fechaCierre"];

    if (fechaPublicacion.value == null) {
      return { range: true };
    }

    if (
      fechaCierre.value &&
      !validationService.rango_fecha_correcto(
        fechaPublicacion.value,
        fechaCierre.value
      )
    ) {
      return { range: true };
    }

    return null;
  }

  private agregar_categoria_default() {
    const categoriaDefault = new Categoria("", "Sin especificar");
    if (this.categorias.length > 0) {
      const categoria = this.categorias.filter(
        (c: Categoria) => c.Id === ""
      )[0];
      if (!categoria) {
        this.categorias.unshift(categoriaDefault);
      }
    } else {
      this.categorias.unshift(categoriaDefault);
    }
  }
}
