import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";

// Módulos
// Angular Material
// import { MatMenuModule, MatButtonModule, MatCheckboxModule, MatIconModule,
//   MatToolbarModule, MatFormFieldModule, MatSelectModule, MatOptionModule,
//   MatSidenavModule, MatListModule, MatTooltipModule } from '@angular/material';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CdkTableModule } from "@angular/cdk/table";
import { CdkTreeModule } from "@angular/cdk/tree";
import {
  MatAutocompleteModule,
  MatBadgeModule,
  MatBottomSheetModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatDividerModule,
  MatExpansionModule,
  MatGridListModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSidenavModule,
  MatSliderModule,
  MatSlideToggleModule,
  MatSnackBarModule,
  MatSortModule,
  MatStepperModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
  MatFormFieldModule,
  MatOptionModule,
  MAT_DATE_LOCALE,
  MAT_DATE_FORMATS,
  DateAdapter,
  MatDateFormats,
} from "@angular/material";
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";

import { LayoutModule } from "@angular/cdk/layout";

import { ComponentsSharedModule } from "src/app/components/components-shared.module";

// Componentes
import {
  NavbarComponent,
  FileuploadComponent,
} from "src/app/components/web/component.web.index";
import { MascotaFormComponent } from "../pages/web/mensaje/inquilinos/mascota-form/mascota-form.component";
import { SegmentToolbarComponent } from "src/app/components/shared/components.shared.index";
import { AccordionComponent } from "src/app/components/shared/components.shared.index";

// Constantes
import { CALENDARIO } from "src/app/constants/config.constant";

// Directivas
import { DirectiveModule } from "../directives/directive.module";

const MOMENT_DATE_FORMATS: MatDateFormats = CALENDARIO.MOMENT_DATE_FORMATS;

@NgModule({
  declarations: [NavbarComponent, FileuploadComponent, MascotaFormComponent],
  exports: [
    NavbarComponent,
    FileuploadComponent,
    MascotaFormComponent,
    SegmentToolbarComponent,
    AccordionComponent,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    ScrollingModule,
    MatFormFieldModule,
    MatOptionModule,
    LayoutModule,
    MatMomentDateModule,
  ],
  imports: [
    RouterModule,
    CommonModule,
    IonicModule,
    ComponentsSharedModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    ScrollingModule,
    MatFormFieldModule,
    MatOptionModule,
    LayoutModule,
    MatMomentDateModule,
    DirectiveModule,
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: CALENDARIO.LOCALE },
    { provide: MAT_DATE_FORMATS, useValue: MOMENT_DATE_FORMATS },
    { provide: DateAdapter, useClass: MomentDateAdapter },
  ],
})
export class ComponentsWebModule { }
