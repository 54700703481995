import { Injectable, EventEmitter } from "@angular/core";

// Interfaces
import { IIdentity } from "src/app/models/interfaces/interfaces.index";

// Constantes
import { MENU } from "src/app/constants/menu.constant";
import { PATH_URL } from "src/app/constants/constants.index";

// Servicios
import { SbcService } from "./sbc.service";
import { MenuService } from "./menu.service";

// Enums
import { EnumTipoMenu, EnumTipoSegmento } from "src/app/models/enums/tipo.enum";

@Injectable({
  providedIn: "root",
})
export class SegmentService {
  segmentoHomeSeleccionado: string;
  segmentoMensajeSeleccionado: string;
  segmentoSeguridadSeleccionado: string;

  segmentosHome: IIdentity[] = MENU.MOBILE.HOME;

  segmentosMensaje: IIdentity[] = MENU.MOBILE.MENSAJE;

  segmentosSeguridad: IIdentity[] = MENU.MOBILE.SEGURIDAD;

  actualSegment = new EventEmitter<{
    type: EnumTipoSegmento;
    segment: IIdentity;
    data?: any;
  }>();
  segmentsWereUpdated = new EventEmitter<{
    segmentType: string;
    segments: IIdentity[];
  }>();

  constructor(
    private sbcService: SbcService,
    private menuService: MenuService
  ) { }

  cargar_segmentos_home() {
    this.segmentoHomeSeleccionado = this.segmentoHomeSeleccionado
      ? this.segmentoHomeSeleccionado
      : this.segmentosHome[0].id;
  }

  cargar_segmentos_mensaje() {
    this.segmentoMensajeSeleccionado = this.segmentoMensajeSeleccionado
      ? this.segmentoMensajeSeleccionado
      : this.segmentosMensaje[0].id;
  }

  cargar_segmentos_seguridad() {
    this.segmentoSeguridadSeleccionado = this.segmentoSeguridadSeleccionado
      ? this.segmentoSeguridadSeleccionado
      : this.segmentosSeguridad[0].id;
  }

  seleccionar_segmento_home(index: number): IIdentity {
    const segmentoSeleccionado = this.segmentosHome[index];
    this.segmentoHomeSeleccionado = segmentoSeleccionado.id.toLowerCase();

    return segmentoSeleccionado;
  }

  seleccionar_segmento_mensaje(index: number): IIdentity {
    const segmentoSeleccionado = this.segmentosMensaje[index];
    this.segmentoMensajeSeleccionado = segmentoSeleccionado.id.toLowerCase();
    return segmentoSeleccionado;
  }

  seleccionar_segmento_seguridad(index: number): IIdentity {
    const segmentoSeleccionado = this.segmentosSeguridad[index];
    this.segmentoSeguridadSeleccionado = segmentoSeleccionado.id.toLowerCase();

    return segmentoSeleccionado;
  }

  /**
   * Actualiza en base a la configuración del menú los segmentos
   */
  actualizar_configuración_segmentos() {
    this.sbcService
      .resolver_menu(EnumTipoMenu.Home, EnumTipoSegmento.Home)
      .subscribe((resp: string | IIdentity[]) => {
        if (typeof resp !== "string") {
          this.segmentosHome = this.menuService.reemplazar_menu(
            this.segmentosHome,
            resp
          );
          this.segmentosHome =
            this.menuService.obtener_menu_sin_opciones_ocultas(
              this.segmentosHome
            );
          this.segmentsWereUpdated.next({
            segmentType: EnumTipoSegmento.Home,
            segments: this.segmentosHome,
          });
        }
      });

    this.sbcService
      .resolver_menu(EnumTipoMenu.Home, EnumTipoSegmento.Mensaje)
      .subscribe((resp: string | IIdentity[]) => {
        if (typeof resp !== "string") {
          console.log("resolverMenu", resp);
          this.segmentosMensaje = this.menuService.reemplazar_menu(
            this.segmentosMensaje,
            resp
          );
          this.segmentosMensaje =
            this.menuService.obtener_menu_sin_opciones_ocultas(
              this.segmentosMensaje
            );
          this.segmentsWereUpdated.next({
            segmentType: EnumTipoSegmento.Mensaje,
            segments: this.segmentosMensaje,
          });
        }
      });

    this.sbcService
      .resolver_menu(EnumTipoMenu.Home, EnumTipoSegmento.Seguridad)
      .subscribe((resp: string | IIdentity[]) => {
        if (typeof resp !== "string") {
          console.log("test resp", resp);
          this.segmentosSeguridad = this.menuService.reemplazar_menu(
            this.segmentosSeguridad,
            resp
          );
          this.segmentosSeguridad =
            this.menuService.obtener_menu_sin_opciones_ocultas(
              this.segmentosSeguridad
            );
          this.segmentsWereUpdated.next({
            segmentType: EnumTipoSegmento.Seguridad,
            segments: this.segmentosSeguridad,
          });
        }
      });
  }

  /**
   * Obtiene el primer menú habilitado para ingresar
   */
  obtener_route_habilitado() {
    let routes = PATH_URL.MOBILE.LOGIN.INDEX;
    if (this.segmentosHome && this.segmentosHome.length > 0) {
      routes = this.segmentosHome[0].path;
    } else if (this.segmentosMensaje && this.segmentosMensaje.length > 0) {
      routes = this.segmentosMensaje[0].path;
    } else if (this.segmentosSeguridad && this.segmentosSeguridad.length > 0) {
      routes = this.segmentosSeguridad[0].path;
    }

    return routes;
  }

  public limpiar() {
    this.segmentosHome = MENU.MOBILE.HOME;

    this.segmentosMensaje = MENU.MOBILE.MENSAJE;

    this.segmentosSeguridad = MENU.MOBILE.SEGURIDAD;
  }

  /**
   * Obtiene el primer menú habilitado para ingresar del segmento
   */
  obtener_route_habilitado_por_segmento(tipoSegmento: EnumTipoSegmento) {
    let routes = null;
    if (tipoSegmento && tipoSegmento === EnumTipoSegmento.Home) {
      routes = this.segmentosHome[0].path;
    } else if (tipoSegmento && tipoSegmento === EnumTipoSegmento.Mensaje) {
      routes = this.segmentosMensaje[0].path;
    } else if (tipoSegmento && tipoSegmento === EnumTipoSegmento.Seguridad) {
      routes = this.segmentosSeguridad[0].path;
    }

    return routes;
  }
}
