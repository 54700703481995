export class Expensa {
  Id: string = null;
  Descripcion: string = null;
  Debe: number = 0;
  Haber: number = 0;
  Fecha: Date | any = null;
  IdLote: string = null;
  Saldo: number = null;
  Tipo: string = null;
}
