import { Component, OnInit, Input } from "@angular/core";

// Constantes
import { ControlService } from "src/app/services/common/control.service";
import { UtilService } from "src/app/services/common/util.service";
import { MESSAGE, TIEMPO_MENSAJE } from "src/app/constants/constants.index";
import { InvitacionDetalle } from "../../../../../models/entities/business/invitacion.entity";
import { InvitacionService } from "src/app/services/business/invitacion.service";
import { NavParams } from "@ionic/angular";

@Component({
  selector: "app-detalle-invitacion",
  templateUrl: "./detalle-invitacion.page.html",
  styleUrls: ["./detalle-invitacion.page.scss"],
})
export class DetalleInvitacionPage implements OnInit {
  @Input() invitacion: InvitacionDetalle;
  //invitacion: InvitacionDetalle;
  fechaDesde: string;
  fechaHasta: string;

  constructor(
    private navParams: NavParams,
    private invitacionService: InvitacionService,
    private controlService: ControlService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.configurar_formulario();
  }
  ionViewWillEnter() {
    this.configurar_formulario();
  }
  cerrar_modal() {
    this.controlService.cerrar_modal(this.invitacion);
  }

  private configurar_formulario() {
    this.fechaDesde = this.utilService.convertir_fecha_a_formato_dd_MM_yyyy(
      this.utilService.convert_ticks_to_date(
        Number(this.invitacion.FechaDesde)
      ),
      "/"
    );
    this.fechaHasta = this.utilService.convertir_fecha_a_formato_dd_MM_yyyy(
      this.utilService.convert_ticks_to_date(
        Number(this.invitacion.FechaHasta)
      ),
      "/"
    );
  }

  //verifica si existe alguna autorización que debe pisarse
  async preAprobacionInvitacion() {
    let pregunta = "";
    let resp: any;
    try {
      resp = await this.invitacionService.obtenerMensajePreAprobacion(
        this.invitacion
      );
      pregunta = resp.Data;
    } catch (error) {
      this.controlService.mostrar_toast_con_error(error, true);
      return;
    }

    if (!pregunta) {
      pregunta = MESSAGE.ALERT.MENSAJE_INVITACION.APROBAR_INVITACION.PREGUNTA;
    }
    this.controlService.mostrar_alert({
      header: MESSAGE.ALERT.MENSAJE_INVITACION.APROBAR_INVITACION.TITULO,
      message: pregunta,
      backdropDismiss: false,
      buttons: [
        {
          text: MESSAGE.ALERT.MENSAJE_INVITACION.APROBAR_INVITACION.BUTTONS
            .CANCELAR,
          role: "cancel",
        },
        {
          text: MESSAGE.ALERT.MENSAJE_INVITACION.APROBAR_INVITACION.BUTTONS
            .ACEPTAR,
          handler: () => {
            this.aprobarInvitacion();
          },
        },
      ],
    });
  }
  private async aprobarInvitacion() {
    this.invitacionService
      .aprobar_invitacion(this.invitacion)
      .then((resp) => {
        this.controlService.mostrar_toast(
          "Invitación aprobada",
          TIEMPO_MENSAJE
        );
        this.controlService.cerrar_modal();
      })
      .catch((error) => {
        this.controlService.mostrar_toast_con_error(error, true);
      })
      .finally(() => {
        this.controlService.ocultar_loading();
      });
  }
  preRechazoInvitacion() {
    this.controlService.mostrar_alert({
      header: MESSAGE.ALERT.MENSAJE_INVITACION.CANCELAR_INVITACION.TITULO,
      message: MESSAGE.ALERT.MENSAJE_INVITACION.CANCELAR_INVITACION.PREGUNTA,
      backdropDismiss: false,
      buttons: [
        {
          text: MESSAGE.ALERT.MENSAJE_INVITACION.CANCELAR_INVITACION.BUTTONS
            .CANCELAR,
          role: "cancel",
        },
        {
          text: MESSAGE.ALERT.MENSAJE_INVITACION.CANCELAR_INVITACION.BUTTONS
            .ACEPTAR,
          handler: () => {
            this.rechazarInvitacion();
          },
        },
      ],
    });
  }
  rechazarInvitacion() {
    this.invitacionService
      .eliminar_invitacion(this.invitacion)
      .then((resp) => {
        this.controlService.mostrar_toast(
          "Invitación cancelada",
          TIEMPO_MENSAJE
        );
        this.controlService.cerrar_modal();
      })
      .catch((error) => {
        this.controlService.mostrar_toast_con_error(error, true);
      })
      .finally(() => {
        this.controlService.ocultar_loading();
      });
  }
}
