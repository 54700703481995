// Se usa para la parte de Accesos
export class LoteAutorizado {
  IdAutorizado: string = null;
  Nombre: string = null;
  Apellido: string = null;
  FechaDesde: Date | number = 0;
  FechaHasta: Date | number = 0;
  IdTipoRelacion: number = 0;
  IdTipoAutorizado: number = 0;
  Dias: DiaAutorizado[] = [];
}

export class DiaAutorizado {
  Dia: number = 0;
  Permitido: boolean = false;
  HoraDesde: string = null;
  HoraHasta: string = null;
}

// Se usa para la parte de Autorizados
export class AutorizadoDelLote {
  Id: string = null;
  IdAutorizado: string = null;
  Nombre: string = null;
  Apellido: string = null;
  FechaDesde: Date | number = 0;
  FechaHasta: Date | number = 0;
  IdTipoRelacion: number = 0;
  DescripcionTipoRelacion: string = null;
  IdTipoAutorizado: number = 0;
  DescripcionTipoAutorizado: string = null;
  TipoDocumento: string = null;
  NumeroDocumento: string = null;
  UrlImagen: string = null;
  TarjetaNumero: string = null;
  EstadoTarjeta: boolean = null;
  Dias: Dia[] = [];
  PRQEstadoPonderado: string = "";
  PRQEstadoPonderadoColor: string = "";
  PRQPermitirNavegacion: boolean = false;
  PRQResponsable: string = "";
}

export class Prerequisito {
  DocumentacionURL: string;
  Estado: string;
  EstadoColor: string;
  FechaVencimiento: string;
  IdPersonaRequisito: string;
  LeyendaDocumentacion: null;
  LeyendaFechaVencimiento: string;
  LeyendaValor: string;
  Observaciones: null;
  RequiereDocumentacion: boolean;
  RequiereFechaVencimiento: boolean;
  RequiereValor: boolean;
  RequisitoNombre: string;
  Valor: string;
}

export class Dia extends DiaAutorizado {
  Id: string = null;
  DescripcionDia: string = null;
}

// Se usa para la parte de Notificaciones Push al momento de solicitar acceso
export class SolicitudAcceso {
  idAutorizacion: string = null;
  deviceId: string = null;
  autoriza: boolean;
}
