import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Servicios
import { RestService } from "../common/rest.service";
import { CatalogoService } from "./catalogo.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";
import { SecurityService } from "../security/security.service";

// Constantes
import { IDictionary } from "../../models/interfaces/interfaces.index";

// Clases
import {
  MPPaymentMethod,
  MPPaymentMethodIssuer,
  InfoACobrarByLote,
  loteCobranzaMedioPagoMobile,
  ResponseApp,
} from "../../models/entities/entity.index";
import { ItemElement } from "../../models/clases/ItemElement";

@Injectable({
  providedIn: "root",
})
export class PagoExpensasService {
  infoACobrar: InfoACobrarByLote;
  metodosPaymentMPP: MPPaymentMethod[] = [];
  metodosPaymentMPPIssuer: MPPaymentMethodIssuer[] = [];
  private itemElement = new ItemElement<MPPaymentMethod>();
  private itemElementIssuer = new ItemElement<MPPaymentMethodIssuer>();
  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private exceptionService: ExceptionService,
    private securityService: SecurityService
  ) {}

  public async obtener_info_a_cobrar(
    idLote: string
  ): Promise<InfoACobrarByLote> {
    const url = `${this.catalogoService.catalogo}ObtenerInfoACobrarByLote/`;
    const options: IDictionary[] = [{ key: "{idLote}", value: idLote }];
    return this.obtener_resultado_info_a_cobrar(url, true, false, options);
  }

  private async obtener_resultado_info_a_cobrar(
    url: string,
    token: boolean,
    idBarrio: boolean,
    options: IDictionary[]
  ) {
    return new Promise<InfoACobrarByLote>((resolve, reject) => {
      this.restService
        .get(url, token, idBarrio, options)
        .pipe(
          map<any, InfoACobrarByLote>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.infoACobrar = resp.Data;

            return this.infoACobrar;
          })
        )
        .subscribe(
          (data: InfoACobrarByLote) => {
            resolve(data);
          },
          (error) => {
            //this.limpiar();
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      throw error;
    });
  }

  public async Obtener_MPPayment_Methods(): Promise<MPPaymentMethod[]> {
    const url = `${this.catalogoService.catalogo}ObtenerMPPaymentMethods/`;

    return this.obtener_resultado_MPPayment_Methods(url, true, false);
  }

  private async obtener_resultado_MPPayment_Methods(
    url: string,
    token: boolean,
    idBarrio: boolean
  ) {
    return new Promise<MPPaymentMethod[]>((resolve, reject) => {
      this.restService
        .get(url, token, idBarrio)
        .pipe(
          map<any, MPPaymentMethod[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            const MPPaymentMethods = this.utilService.mapear_propiedades(
              resp.Data,
              new MPPaymentMethod()
            );
            this.metodosPaymentMPP = this.itemElement.refrescar(
              MPPaymentMethods,
              this.metodosPaymentMPP
            );

            return this.metodosPaymentMPP;
          })
        )
        .subscribe(
          (data: MPPaymentMethod[]) => {
            resolve(data);
          },
          (error) => {
            //this.limpiar();
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      throw error;
    });
  }

  public async obtener_MPPayment_Method_Issuers(
    idPaymentMethod: string
  ): Promise<MPPaymentMethodIssuer[]> {
    const url = `${this.catalogoService.catalogo}ObtenerMPPaymentMethodIssuers/`;
    const options: IDictionary[] = [
      { key: "{idPaymentMethod}", value: idPaymentMethod },
    ];
    return this.obtener_resultado_MPPayment_Method_Issuers(
      url,
      true,
      false,
      options
    );
  }

  private async obtener_resultado_MPPayment_Method_Issuers(
    url: string,
    token: boolean,
    idBarrio: boolean,
    options: IDictionary[]
  ) {
    return new Promise<MPPaymentMethodIssuer[]>((resolve, reject) => {
      this.restService
        .get(url, token, idBarrio, options)
        .pipe(
          map<any, MPPaymentMethodIssuer[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);
            this.metodosPaymentMPPIssuer = [];
            const MPPaymentMethodsIssuer = this.utilService.mapear_propiedades(
              resp.Data,
              new MPPaymentMethodIssuer()
            );
            this.metodosPaymentMPPIssuer = this.itemElementIssuer.refrescar(
              MPPaymentMethodsIssuer,
              this.metodosPaymentMPPIssuer
            );

            return this.metodosPaymentMPPIssuer;
          })
        )
        .subscribe(
          (data: MPPaymentMethodIssuer[]) => {
            resolve(data);
          },
          (error) => {
            //this.limpiar();
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      throw error;
    });
  }

  //**Crear LoteCobranza */
  async crear_LoteCobranza(
    loteCobranza: loteCobranzaMedioPagoMobile,
    lote: string
  ) {
    const url = `${this.catalogoService.catalogo}GenerarLoteCobranza`;

    const body = {
      token: this.securityService.token,
      idLote: lote,
      loteCobranzaMedioPagoMobile: loteCobranza,
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)

          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      //console.log(error);
      //throw error;
    }
  }
}
