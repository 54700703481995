import { Component, OnInit } from "@angular/core";
import { Platform } from "@ionic/angular";
import { CountdownComponent } from "src/app/components/mobile/component.mobile.index";
import { POPOVER_STYLES } from "src/app/constants/popover-styles.constant";
import { IEmergencyType } from "src/app/models/interfaces/interfaces.index";
import { ControlService } from "src/app/services/common/control.service";
import { EnumEmergencyTypes } from "src/app/models/enums/enums.index";
import { UserService } from "src/app/services/security/user.service";
import { Lote } from "src/app/models/entities/entity.index";
import { EmergencyService } from "src/app/services/business/emergency.service";
import { TIEMPO_MENSAJE } from "src/app/constants/message.constant";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { LocationAccuracy } from "@ionic-native/location-accuracy/ngx";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";

@Component({
  selector: "app-emergency",
  templateUrl: "./emergency.page.html",
  styleUrls: ["./emergency.page.scss"],
})
export class EmergencyPage implements OnInit {
  lotes: Lote[];
  alertIsProcessing = false;
  emergencyTypes: IEmergencyType[] = [
    {
      id: 1,
      description: "Alerta de incendio",
      icon: "flame",
      class: "btn fire-alert",
    },
    {
      id: 2,
      description: "Alerta de Seguridad",
      icon: "alert",
      class: "btn security-alert",
    },
    {
      id: 3,
      description: "Alerta de Médica",
      icon: "medkit",
      class: "btn medic-alert",
    },
    {
      id: 4,
      description: "Violencia de género",
      icon: "call",
      class: "btn genre-violence",
    },
  ];
  coords = { latitud: null, longitud: null };

  constructor(
    private emergencyService: EmergencyService,
    private controlService: ControlService,
    private userService: UserService,
    private platform: Platform,
    private androidPermissions: AndroidPermissions,
    private geolocation: Geolocation,
    private locationAccuracy: LocationAccuracy
  ) {
    this.lotes = this.userService.user.Lotes;
  }

  ngOnInit() {
    console.log("entró al ngOnINit");
  }

  async ionViewWillEnter(): Promise<void> {
    if (
      !this.platform.is("cordova") ||
      this.platform.is("ios") ||
      this.platform.is("ipad")
    ) {
      const options = { timeout: 10000, enableHighAccuracy: true, maximumAge: 3600 };
      await this.geolocation
        .getCurrentPosition(options)
        .then(
          (currentPosition) => {
            console.log("posición obtenida", currentPosition);
            this.coords = {
              latitud: currentPosition.coords.latitude,
              longitud: currentPosition.coords.longitude,
            }
          }
        )
        .catch((error) => {
          this.showGPSAlert();
          console.log(error);
        });
    } else {
      await this.requestLocation();
    }
  }

  async requestLocation(): Promise<boolean> {
    try {
      const hasPermission = await this.checkGPSPermission();
      if (hasPermission) {
        return await this.askToTurnOnGPS();
      } else {
        const permission = await this.requestGPSPermission();
        if (permission === "CAN_REQUEST" || permission === "GOT_PERMISSION") {
          return await this.askToTurnOnGPS();
        }
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async checkGPSPermission(): Promise<boolean> {
    try {
      if (this.platform.is("android")) {
        const permissions = await this.androidPermissions.checkPermission(
          this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION
        );
        if (permissions && permissions.hasPermission) return true;
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async requestGPSPermission(): Promise<string> {
    try {
      const canRequest = await this.locationAccuracy.canRequest();
      if (canRequest) {
        return "CAN_REQUEST";
      } else {
        const permissions = await this.androidPermissions.requestPermission(
          this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION
        );
        if (permissions && permissions.hasPermission) return "GOT_PERMISSION";
        return "DENIED_PERMISSION";
      }
    } catch (error) {
      console.log(error);
      return "DENIED_PERMISSION";
    }
  }

  async askToTurnOnGPS(): Promise<boolean> {
    try {
      const canRequest = await this.locationAccuracy.canRequest();
      if (!canRequest) return false;
      if (canRequest) {
        await this.locationAccuracy.request(
          this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY
        );
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  showGPSAlert() {
    this.controlService.mostrar_alert({
      header: "Emergencia",
      message:
        "Por favor, active el GPS para que podamos acceder a su ubicación correctamente",
      backdropDismiss: false,
      buttons: [
        {
          text: "Aceptar",
          role: "cancel",
        },
      ],
    });
  }

  async triggerAlarm(id: number) {
    this.alertIsProcessing = true;
    const emergencyType = this.emergencyTypes.find((type) => type.id === id);

    let coords;

    const options = { timeout: 10000, enableHighAccuracy: true, maximumAge: 3600 };
    try {
      const currentPosition = await this.geolocation.getCurrentPosition(options);
      coords = currentPosition.coords;
      this.coords = {
        latitud: currentPosition.coords.latitude,
        longitud: currentPosition.coords.longitude,
      };
    } catch (error) {
      console.log(error);
      this.coords = {
        latitud: null,
        longitud: null,
      };
    }

    const componentProps = {
      emergencyType,
    };

    this.controlService
      .mostrarPopOver({
        component: CountdownComponent,
        cssClass: POPOVER_STYLES.COUNTDOWN_MODAL,
        backdropDismiss: false,
        componentProps,
      })
      .then((emergencyType: IEmergencyType) => {
        if (emergencyType.id !== EnumEmergencyTypes.cancelAlert) {
          this.createEmergency(emergencyType);
        } else {
          this.alertIsProcessing = false;
        }
      })
      .catch((error) => {
        this.alertIsProcessing = false;
        console.log(error);
      });
  }

  closeModal() {
    this.controlService.cerrar_modal();
  }

  async createEmergency(emergencyType: IEmergencyType) {
    this.emergencyService
      .registerEmergency(
        this.lotes[0].Id,
        this.coords.latitud ? this.coords.latitud.toString() : null,
        this.coords.longitud ? this.coords.longitud.toString() : null,
        emergencyType.id.toString()
      )
      .then((resp) => {
        this.controlService.mostrar_toast("Emergencia creada", TIEMPO_MENSAJE);
        this.alertIsProcessing = false;
        this.closeModal();
      })
      .catch((error) => {
        this.alertIsProcessing = false;
        this.controlService.mostrar_toast_con_error(error, false);
      });
  }
}
