import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";

// Constantes
import {
  MESSAGE,
  TIEMPO_MENSAJE,
  FORMS,
  STORAGE,
} from "src/app/constants/constants.index";

// Clases
import { Usuario, ResponseApp } from "src/app/models/entities/entity.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UtilService } from "src/app/services/common/util.service";
import { UserService } from "src/app/services/security/user.service";
import { StorageService } from "src/app/services/common/storage.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.page.html",
  styleUrls: ["./change-password.page.scss"],
})
export class ChangePasswordPage implements OnInit {
  usuario: Usuario;
  formGroup: FormGroup;
  mostrarPassword = false;
  reglasValidacionPassword = FORMS.CAMBIO_PASSWORD.PASSWORD;

  constructor(
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private userService: UserService,
    private controlService: ControlService,
    private storage: StorageService
  ) {}

  ngOnInit() {
    this.usuario = this.userService.user;
    this.configurar_formulario();
  }

  cambiar_password() {
    const passwordNuevo = this.formGroup.controls.password.value;
    const confirmacionPassword = this.formGroup.controls.confirmPassword.value;
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.userService
        .cambiar_password(passwordNuevo, confirmacionPassword)
        .then((resp: ResponseApp) => {
          this.cambiar_password_storage(passwordNuevo);
          this.controlService.ocultar_loading();
          this.controlService.mostrar_toast(
            MESSAGE.INFO.MENSAJE_CAMBIOS_GUARDADOS,
            TIEMPO_MENSAJE
          );
          this.cerrar_modal();
        })
        .catch((error) => {
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
          this.controlService.ocultar_loading();
        });
    });
  }

  cambiar_password_storage(password) {
    this.storage.cargar(STORAGE.KEY.USER_CREDENTIALS).then((resp: string) => {
      if (resp) {
        resp = JSON.parse(this.storage.currentValue);
        resp["password"] = password;
        let data = JSON.stringify(resp);
        this.storage.guardar({
          key: STORAGE.KEY.USER_CREDENTIALS,
          value: data,
        });
      }
    });
  }

  cerrar_modal() {
    this.controlService.cerrar_modal();
  }

  cambiar_vista_password() {
    this.mostrarPassword = !this.mostrarPassword;

    return this.mostrarPassword;
  }

  limpiar() {
    this.configurar_formulario();
  }

  limpiar_confirmacion() {
    this.formGroup.controls["confirmPassword"].setValue("");
  }

  private configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      password: [""],
      confirmPassword: [""],
    });

    this.formGroup.controls["password"].setValidators([
      Validators.required,
      Validators.minLength(this.reglasValidacionPassword.MIN_LENGTH),
    ]);

    this.formGroup.controls["confirmPassword"].setValidators([
      Validators.required,
      Validators.minLength(this.reglasValidacionPassword.MIN_LENGTH),
      this.validar_password.bind(this.formGroup, this.utilService),
    ]);
  }

  private validar_password(
    utilService: UtilService,
    control: FormControl
  ): { [s: string]: boolean } {
    const form: any = this;
    const password = form.controls["password"];
    const confirmPassword = form.controls["confirmPassword"];

    if (password.value === "" || confirmPassword.value === "") {
      return null;
    }

    if (!utilService.equals(password.value, confirmPassword.value)) {
      return { compare: true };
    } else {
      return null;
    }
  }
}
