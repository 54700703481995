import { VersionApp } from "../business/version.entity";
import { Style } from "./style.entity";
import { SplashIcon, MenuSetting } from "../entity.index";

export class SettingApp {
  Shell: VersionApp = null;
  Styles: Style[] = [];
  Splash?: SplashIcon = null;
  Icon: SplashIcon = null;
  Menu: MenuSetting = null;
}
