import { Component, ViewChild } from "@angular/core";
import { DatePipe } from "@angular/common";
import { Router } from "@angular/router";
import { IonSlides } from "@ionic/angular";

// Services
import { ControlService } from "src/app/services/common/control.service";
import { DashboardService } from "src/app/services/business/dashboard.service";
import { UserService } from "src/app/services/security/user.service";
import { NoticiaService } from "src/app/services/business/noticia.service";

// Constantes
import { PAGINATOR, POPOVER_STYLES } from "src/app/constants/constants.index";
import {
  IIdentity,
  IPagination,
} from "src/app/models/interfaces/interfaces.index";
import { SegmentService } from "src/app/services/common/segment.service";
import { DashboardModalComponent } from "src/app/components/mobile/component.mobile.index";
import { DashboardItem } from "src/app/models/entities/business/dashboardItem";
import {
  EnumTipoCanal,
  EnumTipoSegmento,
} from "src/app/models/enums/tipo.enum";
import { Noticia } from "src/app/models/entities/entity.index";
import { SbcService } from "src/app/services/common/sbc.service";
import { UtilService } from "src/app/services/common/util.service";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.page.html",
  styleUrls: ["./dashboard.page.scss"],
  providers: [DatePipe],
})
export class DashboardPage {
  @ViewChild("slides") slider: IonSlides;
  slideOptions = {
    initialSlide: 0,
    slidesPerView: 1,
    speed: 500,
  };
  dashboardItems: DashboardItem[] = [];
  dashboardItemsAgrupados: DashboardItem[] = [];
  noticias: Noticia[] = [];
  paginacionNoticias: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: 10,
  };
  icono: string = null;
  dotsArray = [];
  actualSlide = 0;
  public intervalTimer;
  user: any;
  esGuardia: boolean;
  esAdministrativo: boolean;
  usuarioBackup: any;

  constructor(
    private controlService: ControlService,
    private segmentService: SegmentService,
    private userService: UserService,
    private dashboardService: DashboardService,
    private noticiaService: NoticiaService,
    private sbcService: SbcService,
    private utilService: UtilService,
    private router: Router
  ) { }

  async ionViewWillEnter() {
    await this.resolver_icono();
    this.getDashboardItems();
    this.user = this.userService.user;
    this.esGuardia = this.user.Profiles.includes("Guardia");
    this.esAdministrativo = this.user.Profiles.includes("Administrativo");
    this.dashboardService.dashboardItems.subscribe(async (items: any) => {
      if (items.some(({ Icon }) => Icon === 'ticket') &&
        (this.userService.user.Profiles.includes('Guardia') || this.userService.user.Profiles.includes('Administrativo'))) {
        items.find(({ Icon }) => Icon === 'ticket').Descripcion = "Bandeja de tickets";
      }
      this.dashboardItemsAgrupados = this.utilService.agrupar_array(items, 2);
    });
  }

  ionViewWillDidLeave() {
    clearInterval(this.intervalTimer);
  }

  ionViewDidLeave() {
    clearInterval(this.intervalTimer);
  }

  resolver_icono() {
    this.sbcService.resolver_icono().subscribe(
      (icono: string) => {
        this.icono = icono;
      },
      (error) => {
        this.controlService.mostrar_toast_con_error(error, false);
      }
    );
  }

  getDashboardItems(event?) {
    this.dashboardService
      .get_dashboard_items(
        this.userService.user.Lotes.length > 0
          ? this.userService.user.Lotes[0].Id
          : "00000000-0000-0000-0000-000000000000"
      )
      .then((resp: DashboardItem[]) => {
        this.dashboardItemsAgrupados = this.utilService.agrupar_array(resp, 2);
      })
      .catch((error) => {
        this.controlService.mostrar_toast_con_error(error, false);
      });

    this.noticiaService
      .obtener_publicaciones_pre_holder_por_canal(
        EnumTipoCanal.Mobile,
        { pagina: 1, cantidad: 5 }
      )
      .then((resp: Noticia[]) => {
        this.noticias = resp.slice(0, 5);
        this.dotsArray = this.noticias.map((x, i) => i);
        this.configSlides();
        if (event) {
          event.target.complete();
        }
      });
  }

  async onChangeSlide() {
    if (this.slider && this.slider !== undefined) {
      this.actualSlide = await this.slider.getActiveIndex();
    }
  }

  async configSlides() {
    if (!this.esGuardia) this.slider.startAutoplay();
  }

  openNews(noticia: Noticia) {
    try {
      const segment = this.segmentService.segmentosHome.find(
        ({ id }) => id === "detalleNoticia"
      );
      const data = {
        noticia: JSON.stringify(noticia),
        icono: JSON.stringify(this.icono),
      };
      this.segmentService.actualSegment.next({
        type: EnumTipoSegmento.Home,
        segment,
        data,
      });
    } catch (error) {
      console.log("error: " + error);
    }
  }

  navigateTo(dashboardItem: DashboardItem) {
    let type: EnumTipoSegmento;
    let segment: IIdentity;

    switch (dashboardItem.Descripcion) {
      case "Publicaciones":
        type = EnumTipoSegmento.Home;
        segment = this.segmentService.segmentosHome.find(
          ({ id }) => id === "noticias"
        );
        break;
      case "Tickets":
      case "Bandeja de tickets":
        type = EnumTipoSegmento.Mensaje;
        segment = this.segmentService.segmentosMensaje.find(
          ({ id }) => id === "ticket"
        );
        break;
      case "Infracciones":
        type = EnumTipoSegmento.Mensaje;
        segment = this.segmentService.segmentosMensaje.find(
          ({ id }) => id === "multa"
        );
        break;
      case "Liquidaciones":
        type = EnumTipoSegmento.Mensaje;
        segment = this.segmentService.segmentosMensaje.find(
          ({ id }) => id === "documentoAF"
        );
        break;
      case "Lote":
        type = EnumTipoSegmento.Home;
        segment = this.segmentService.segmentosMensaje.find(
          ({ id }) => id === "lote"
        );
        break;
    }

    this.router.navigate([segment.path]);
    setTimeout(() => {
      this.segmentService.actualSegment.next({ type, segment });
      this.controlService.cerrar_modal();
    }, 10);
  }

  showDashboardModal() {
    this.controlService
      .mostrar_modal({
        component: DashboardModalComponent,
        cssClass: POPOVER_STYLES.DASHBOARD_MODAL,
        backdropDismiss: true,
      })
      .then((data) => { })
      .catch((error) => {
        console.log(error);
      });
  }
}
