import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Clases
import { Categoria } from "src/app/models/entities/entity.index";
import { ItemElement } from "src/app/models/clases/ItemElement";

// Interfaces
import {
  IPagination,
  IDictionary,
} from "src/app/models/interfaces/interfaces.index";

// Servicios
import { CatalogoService } from "./catalogo.service";
import { RestService } from "../common/rest.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";

@Injectable({
  providedIn: "root",
})
export class CategoriaService {
  private paginacion: IPagination = { pagina: 1, cantidad: 100 };
  private itemElement = new ItemElement<Categoria>();

  categorias: Categoria[] = [];
  paginacionActual: IPagination = { pagina: 1, cantidad: 10 };

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private exceptionService: ExceptionService
  ) {}

  /**
   * Obtiene las categorías disponibles para las publicaciones
   * (Este servicio está en proceso de construcción hasta que el backend disponga las categorías a través de la api)
   */
  async obtener_catagorias(paginacion: IPagination = this.paginacion) {
    const url = `${this.catalogoService.catalogo}ObtenerPublicacionCategorias/`;
    const options: IDictionary[] = [
      { key: "{pagina}", value: paginacion.pagina.toString() },
      { key: "{cantidad}", value: paginacion.cantidad.toString() },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Categoria[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;

            const categorias = this.utilService.mapear_propiedades(
              resp.Data,
              new Categoria()
            );
            this.categorias = this.itemElement.refrescar(
              categorias,
              this.categorias
            );

            return this.categorias;
          })
        )
        .subscribe(
          (data: Categoria[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  limpiar() {
    this.categorias = [];
  }
}
