import { Component, Input } from "@angular/core";
import { NavParams } from "@ionic/angular";

// Servicios
import { ControlService } from "src/app/services/common/control.service";

@Component({
  selector: "app-imagen-modal-multa",
  templateUrl: "./imagen-modal.page.html",
  styleUrls: ["./imagen-modal.page.scss"],
})
export class ImagenModalMultaPage {
  @Input() urlImagen: string;
  urlImagenFromParams: string;

  constructor(
    private controlService: ControlService,
    private navParams: NavParams
  ) {}

  ionViewWillEnter() {
    //esto es para solucionar un bug que ocurre en Android y no muestra las imagenes
    //ocurre cuando usamos un input param directamente en el html
    this.urlImagenFromParams = this.navParams.get("urlImagen");
  }

  cerrar_modal() {
    this.controlService.cerrar_modal();
  }
}
