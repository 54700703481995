import { Injectable } from "@angular/core";

import { environment } from "src/environments/environment";

// Servicios
import { StorageService } from "../common/storage.service";
import { DeviceService } from "../common/device.service";

// Constantes
import { STORAGE } from "src/app/constants/constants.index";
import { Usuario } from "../../models/entities/business/usuario.entity";
import { IIdentity } from "../../models/interfaces/common/identity.interface";

@Injectable({
  providedIn: "root",
})
export class SecurityService {
  token = "";
  codigoBarrio = environment.NEIGHBORHOOD_CODE;

  constructor(
    private storageService: StorageService,
    private deviceService: DeviceService
  ) {
    this.cargar_codigo_barrio(this.codigoBarrio);
  }

  async cargar_codigo_barrio(codigoBarrio?: string) {
    return new Promise((resolve, reject) => {
      this.storageService
        .cargar(STORAGE.KEY.CODIGO_BARRIO)
        .then((codigo: string) => {
          try {
            if (codigoBarrio) {
              this.codigoBarrio = codigoBarrio;
              this.storageService.guardar({
                key: STORAGE.KEY.CODIGO_BARRIO,
                value: this.codigoBarrio,
              });
              resolve(true);
              return;
            }

            if (codigo) {
              this.codigoBarrio = codigo;
              resolve(true);
            } else {
              resolve(false);
            }
          } catch (error) {
            reject(error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  contiene_codigo_barrio() {
    return this.codigoBarrio != null && this.codigoBarrio !== "";
  }

  contiene_token() {
    return this.token != null && this.token !== "";
  }

  /**
   * Determina si el usuario tiene el perfil para acceder a la opción de menú
   * @param usuario Usuario logueado
   * @param menu Opción de menú
   */
  permitir_ingresar(usuario: any, menu: IIdentity) {
    if (this.deviceService.isWeb) {
      console.log({ usuario, menu });
      if (menu.owner === true && menu.forceToView === true) {
        // Lo ven ambos
        return true;
      }

      if (menu.owner === false && menu.forceToView === false) {
        // No lo ve ninguno
        return false;
      }

      if (menu.owner === false && menu.forceToView === true) {
        // Sólo lo ve el propietario
        return usuario.Profiles.includes('Administrativo') === false;
      }

      if (menu.owner === true) {
        // Lo ve sólo el admin
        return usuario.Profiles.includes('Administrativo');
      }

      return true;
    }
    if (menu.profiles) {

      if (menu.id === "autorizado") {
        console.log("menu", menu);
      }

      if (menu.id == "bandeja-ticket") {
        return false;
      }
      if (menu.id == "ticket") {
        return true;
      }
      return menu.profiles.some((item) => usuario.Profiles.includes(item));
    } else {
      if (menu.id == "bandeja-ticket") {
        return false;
      }
      if (menu.id == "ticket") {
        return true;
      }
      return true;
    }
    /*
    //si es socio y la opcion solo es para propietarios
    if (usuario.Socio && menu.owner) {
      return false;
    }

    //si es socio y la opcion solo es para propietarios
    if (usuario.Socio && !menu.owner) {
      return true;
    }
    
    
    
    //}
    /*if(!this.deviceService.isWeb){
        //si es socio y la opcion solo es para propietarios
        if(usuario.Socio && menu.owner){
          console.log("1 if");
          return false;
        }
        
        //si es socio y la opcion solo es para propietarios
        if(usuario.Socio && !menu.owner){
          console.log("2 if");
          return true;
        }
    }

    
    //Si es administrativo y la opción es "bandejaTickets", permite ver
    if (usuario.Administrativo && menu.id == "bandejaTickets") {
      return true;
    }

    //para los items de logistica
    //solo lo podrán ver los barrios que tengan el forcetoview y logisticItem en true
    //no discrimina roles
    if(menu.logisticItem) 
    {
      if(menu.forceToView)
      {
        return  true;
      }
      else
      {
        return false;
      }
    }
    

    // Si es administrador o la opción del menú fuerza a mostrar aunque no lo sea
    if (usuario.IsAdmin || menu.forceToView) {
      return true;
    }

    // Si el usuario no tiene asociado ningún perfil y es administrador
    if (
      (!usuario.Perfiles || usuario.Perfiles.length === 0) &&
      usuario.IsAdmin
    ) {

      return true;
    }

    // Si el usuario no tiene asociado ningún perfil y no es administrador
    // Valido si la opción del menú está forzada para mostrar aunque no sea admin
    if (
      (!usuario.Perfiles || usuario.Perfiles.length === 0) &&
      !usuario.IsAdmin
    ) {

      return menu.forceToView;
    }

    // Si el usuario tiene perfiles asociados, evalúo la configuración del menú
    if (menu.profiles && menu.profiles.length > 0) {

      return (
        menu.profiles.filter(
          (perfilConfig: string) =>
            usuario.Perfiles.filter(
              (perfil: string) =>
                perfil.toUpperCase() === perfilConfig.toUpperCase()
            ).length > 0
        ).length > 0
      );
    }

    // Si el usuario tiene perfil, pero el menú no, se considera habilitado
    return false;*/
  }
}
