import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Servicios
import { RestService } from "../common/rest.service";
import { CatalogoService } from "./catalogo.service";
import { UtilService } from "../common/util.service";
import { SecurityService } from "../security/security.service";
import { ExceptionService } from "../common/exception.service";

// Constantes
import { IPagination } from "src/app/models/interfaces/interfaces.index";
import { IDictionary } from "src/app/models/interfaces/interfaces.index";

// Clases
import {
  Encuesta,
  Lote,
  ResponseApp,
  Item,
} from "src/app/models/entities/entity.index";
import { ItemElement } from "src/app/models/clases/ItemElement";

@Injectable({
  providedIn: "root",
})
export class EncuestaService {
  private paginacion: IPagination = { pagina: 1, cantidad: 10 };
  private itemElement = new ItemElement<Encuesta>();

  encuestas: Encuesta[] = [];
  paginacionActual: IPagination = { pagina: 1, cantidad: 10 };

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private securityService: SecurityService,
    private exceptionService: ExceptionService
  ) {}

  //**encuestaMobileSave */
  async guardar_encuesta(encuesta: Encuesta, lote: Lote) {
    const url = `${this.catalogoService.catalogo}EncuestaMobileSave`;
    const body = {
      token: this.securityService.token,
      idLote: lote.Id,
      encuestaMobile: encuesta,
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            this.actualizar_encuesta_en_memoria(encuesta);
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  // ObtenerEncuestas
  async obtener_encuestas(
    lote: Lote,
    estado: Item,
    paginacion: IPagination = this.paginacion
  ) {
    const url = `${this.catalogoService.catalogo}ObtenerEncuestas/`;
    const options: IDictionary[] = [
      { key: "{idLote}", value: lote.Id },
      { key: "{estadoEncuesta}", value: estado.Id.toString() },
      { key: "{pagina}", value: paginacion.pagina.toString() },
      { key: "{cantidad}", value: paginacion.cantidad.toString() },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Encuesta[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;

            const encuestas = this.utilService.mapear_propiedades(
              resp.Data,
              new Encuesta()
            );
            this.encuestas = this.itemElement.refrescar(
              encuestas,
              this.encuestas
            );

            return this.encuestas;
          })
        )
        .subscribe(
          (data: Encuesta[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  limpiar_encuestas() {
    this.encuestas = [];
  }

  private actualizar_encuesta_en_memoria(encuestaActualizada: Encuesta) {
    this.encuestas.forEach((encuesta: Encuesta) => {
      if (encuesta.Id === encuestaActualizada.Id) {
        encuesta = encuestaActualizada;
        return false;
      }
    });
  }
}
