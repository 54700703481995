import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

// Clases
import {
  TelefonoContacto,
  Lote,
  ResponseApp,
} from "src/app/models/entities/entity.index";

// Enums
import { EnumTipoContacto } from "src/app/models/enums/tipo.enum";

// Constantes
import { MESSAGE, TIEMPO_MENSAJE } from "src/app/constants/constants.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { ContactoService } from "src/app/services/business/contacto.service";

@Component({
  selector: "app-contacts",
  templateUrl: "./contacts.page.html",
  styleUrls: ["./contacts.page.scss"],
})
export class ContactsPage implements OnInit {
  formGroup: FormGroup;
  telefonos: TelefonoContacto[] = [];
  loteSeleccionado: Lote;
  lotes: Lote[];

  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private userService: UserService,
    private contactoService: ContactoService
  ) { }

  ngOnInit() {
    this.lotes = this.userService.user.Lotes;
    this.configurar_formulario();
    this.cargar_telefonos();
  }

  filtrar() {
    this.limpiar_filtro();
    this.setear_lote_seleccionado();
    this.cargar_telefonos();
  }

  limpiar_filtro() {
    this.telefonos = [];
    this.contactoService.limpiar();
  }

  esTelefonoFijo(telefono: TelefonoContacto) {
    return (
      telefono.Tipo.toUpperCase() ===
      EnumTipoContacto.TelefonoFijo.toUpperCase()
    );
  }

  cerrar_modal() {
    this.controlService.cerrar_modal();
  }

  doRefresh(event) {
    this.contactoService
      .obtener_telefonos(this.loteSeleccionado)
      .then((resp: TelefonoContacto[]) => {
        if (resp) {
          this.telefonos = resp;
          event.target.complete();
        }
      })
      .catch((error) => {
        event.target.complete();
      });
  }

  reorder(event) {
    const itemMover = this.telefonos.splice(event.detail.from, 1)[0];
    this.telefonos.splice(event.detail.to, 0, itemMover);

    event.detail.complete();
  }

  actualizar_lista_telefonica() {
    this.setear_lote_seleccionado();
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.contactoService
        .actualizar_orden_contactos(this.loteSeleccionado, this.telefonos)
        .then((resp: ResponseApp) => {
          this.controlService.ocultar_loading();
          this.controlService.mostrar_toast(
            MESSAGE.INFO.MENSAJE_CAMBIOS_GUARDADOS,
            TIEMPO_MENSAJE
          );
        })
        .catch((error) => {
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
          this.controlService.ocultar_loading();
        });
    });
  }

  private cargar_telefonos() {
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.setear_lote_seleccionado();
      this.contactoService
        .obtener_telefonos(this.loteSeleccionado)
        .then((resp: TelefonoContacto[]) => {
          if (resp == null) {
            this.controlService.mostrar_toast(
              MESSAGE.ALERT.MENSAJE_SIN_DATOS,
              TIEMPO_MENSAJE
            );
          } else {
            this.telefonos = resp;
          }
        })
        .catch((error) => {
          this.controlService.mostrar_toast_con_error(error, false);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    });
  }

  private configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      lotes: [this.lotes[0].Id, Validators.required],
    });
  }

  private setear_lote_seleccionado() {
    this.loteSeleccionado = this.lotes.filter(
      (lote: Lote) => lote.Id === this.formGroup.value.lotes
    )[0];
  }
}
