import { Component, ViewChild } from "@angular/core";
import { IonInfiniteScroll, IonContent } from "@ionic/angular";

// Servicios
import { NoticiaService } from "src/app/services/business/noticia.service";
import { ControlService } from "src/app/services/common/control.service";

// Clases
import { Noticia } from "src/app/models/entities/entity.index";

// Interfaces
import { IPagination } from "src/app/models/interfaces/common/pagination.interface";

// Constantes
import {
  MESSAGE,
  SCROLL_FAB_HEIGHT,
  ENABLED_SCROLL_EVENT,
  PAGINATOR,
  MENU,
} from "src/app/constants/constants.index";
import { TIEMPO_MENSAJE } from "src/app/constants/message.constant";

// Enums
import { EnumTipoCanal, EnumTipoMenu } from "src/app/models/enums/tipo.enum";
import { SbcService } from "src/app/services/common/sbc.service";
import { IIdentity } from "src/app/models/interfaces/interfaces.index";
import { MenuService } from "src/app/services/common/menu.service";
import { DeviceService } from "src/app/services/common/device.service";

@Component({
  selector: "app-pre-noticia",
  templateUrl: "./pre-noticia.page.html",
  styleUrls: ["./pre-noticia.page.scss"],
})
export class PreNoticiaPage {
  private primerCarga = true;
  private paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  mostrarFabButton = false;
  mostrarFabButtonApartirDe = SCROLL_FAB_HEIGHT;
  habilitarEventosScroll = ENABLED_SCROLL_EVENT;
  mostrarInfiniteScrollEn = PAGINATOR.SIZE;
  urls: IIdentity[] = MENU.MOBILE.PRE_HOLDER;

  noticias: Noticia[] = [];
  mostrarMensajeSinItem = false;

  shownGroup = null;

  constructor(
    private controlService: ControlService,
    private noticiaService: NoticiaService,
    private sbcService: SbcService,
    private menuService: MenuService,
    private deviceService: DeviceService
  ) {}

  ionViewWillEnter() {
    this.sbcService
      .resolver_menu(EnumTipoMenu.PreHolder)
      .subscribe((resp: string | IIdentity[]) => {
        if (typeof resp !== "string") {
          this.urls = this.menuService.reemplazar_menu(this.urls, resp);
        }
      });

    if (this.primerCarga) {
      this.noticiaService.limpiar();
      this.resolver_vista_noticias();
      this.primerCarga = false;
    }
  }

  showSubmenu(group) {
    if (this.isGroupShown(group)) {
      this.shownGroup = null;
    } else {
      this.shownGroup = group;
    }
  }

  isGroupShown(group) {
    return this.shownGroup === group;
  }

  resolver_vista_noticias(event?) {
    if (event) {
      this.paginacion.pagina += 1;
      this.cargar_noticias(event);
    } else {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.cargar_noticias(event);
      });
    }
  }

  informar_scroll(event) {
    if (this.deviceService.isIos) {
      return;
    }

    this.content
      .getScrollElement()
      .then(
        (el) =>
          (this.mostrarFabButton =
            el.scrollTop > this.mostrarFabButtonApartirDe)
      );
  }

  volver_al_inicio() {
    this.content.scrollToTop();
  }

  doRefresh(event) {
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.noticiaService.limpiar();
    this.noticiaService
      .obtener_publicaciones_pre_holder_por_canal(
        EnumTipoCanal.Mobile,
        this.paginacion
      )
      .then((resp: Noticia[]) => {
        this.noticias = resp;
        this.controlaFindePagina();
        event.target.complete();
      })
      .catch((error) => {
        event.target.complete();
      });
  }

  cerrar_modal() {
    this.controlService.cerrar_modal();
  }

  private cargar_noticias(event?) {
    return this.noticiaService
      .obtener_publicaciones_pre_holder_por_canal(
        EnumTipoCanal.Mobile,
        this.paginacion
      )
      .then((resp: Noticia[]) => {
        if (
          resp == null ||
          this.noticiaService.paginacionActual.cantidad === 0
        ) {
          this.controlService.habilitar_infinite_scroll(event, false);
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_SIN_DATOS,
            TIEMPO_MENSAJE
          );
        } else {
          this.noticias = resp;
          this.controlService.completar_infinite_scroll(event);
        }
      })
      .catch((error) => {
        this.controlService.habilitar_infinite_scroll(event, false);
        this.controlService.mostrar_toast_con_error(error, false);
      })
      .finally(() => {
        this.controlaFindePagina();
        this.mostrarMensajeSinItem = this.noticias.length === 0;
        if (event == null) {
          this.controlService.ocultar_loading();
        }
      });
  }
  controlaFindePagina() {
    let maxPages = Math.ceil(
      this.noticiaService.paginacionActual.cantidad / this.paginacion.cantidad
    );
    if (maxPages == this.paginacion.pagina)
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, false);
    else
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    //console.log('total en db:' + this.autorizadoService.paginacionActual.cantidad +', maxPages: ' + maxPages + ' , paginaActual: ' + this.paginacion.pagina)
  }
}
