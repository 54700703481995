import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Clases
import {
  Lote,
  LoteAutorizado,
  ResponseApp,
  AutorizadoDelLote,
  SolicitudAcceso,
} from "src/app/models/entities/entity.index";
import { ItemElement } from "src/app/models/clases/ItemElement";

// Servicios
import { CatalogoService } from "./catalogo.service";
import { RestService } from "../common/rest.service";
import { SecurityService } from "../security/security.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";

// Interfaces
import {
  IPagination,
  IDictionary,
} from "src/app/models/interfaces/interfaces.index";
import { Prerequisito } from "src/app/models/entities/business/autorizado.entity";

@Injectable({
  providedIn: "root",
})
export class AutorizadoService {
  private paginacion: IPagination = { pagina: 1, cantidad: 10 };
  private itemElement = new ItemElement<AutorizadoDelLote>();

  autorizados: AutorizadoDelLote[] = [];
  paginacionActual: IPagination = { pagina: 1, cantidad: 100 };

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private securityService: SecurityService,
    private utilService: UtilService,
    private exceptionService: ExceptionService
  ) { }

  //**agregarAutorizado */
  async crear_autorizacion(autorizacion: LoteAutorizado, lote: Lote) {
    const url = `${this.catalogoService.catalogo}AgregarAutorizado`;
    const body = {
      token: this.securityService.token,
      idLote: lote.Id,
      autorizado: autorizacion,
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              console.log(resp);
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  //**modificarAutorizado */
  async actualizar_autorizacion(autorizacion: AutorizadoDelLote, lote: Lote) {
    // Eliminamos propiedades que no deben viajar en el request
    autorizacion = this.eliminar_propiedades_para_actualizar(autorizacion);

    const url = `${this.catalogoService.catalogo}ModificarAutorizado`;
    const body = {
      token: this.securityService.token,
      idLote: lote.Id,
      autorizado: autorizacion,
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  //**borrarAutorizado */
  async eliminar_autorizacion(autorizacion: AutorizadoDelLote, lote: Lote) {
    const url = `${this.catalogoService.catalogo}BorrarAutorizado`;
    const body = {
      token: this.securityService.token,
      idLote: lote.Id,
      id: autorizacion.Id,
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  //**responderSolicitudAcceso */
  async responder_solicitud_acceso(solicitud: SolicitudAcceso) {
    const url = `${this.catalogoService.catalogo}Autorizar/`;
    const options: IDictionary[] = [
      { key: "{idAutorizacion}", value: solicitud.idAutorizacion },
      { key: "{deviceId}", value: solicitud.deviceId },
      { key: "{autoriza}", value: solicitud.autoriza.toString() },
    ];

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, null, null, null, null, options)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  // obtenerAutorizados
  async obtener_autorizados(
    lote: Lote,
    nombreContiene: string,
    paginacion: IPagination = this.paginacion
  ) {
    const url = `${this.catalogoService.catalogo}ObtenerAutorizados/`;
    const options: IDictionary[] = [
      { key: "{idLote}", value: lote.Id },
      { key: "{codigo_barrio}", value: this.securityService.codigoBarrio },
      { key: "{nombreContiene}", value: nombreContiene },
      { key: "{pagina}", value: paginacion.pagina.toString() },
      { key: "{cantidad}", value: paginacion.cantidad.toString() },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, AutorizadoDelLote[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;

            const autorizados = this.utilService.mapear_propiedades(
              resp.Data,
              new AutorizadoDelLote()
            );
            this.autorizados = this.itemElement.refrescar(
              autorizados,
              this.autorizados
            );

            return this.autorizados;
          })
        )
        .subscribe(
          (data: AutorizadoDelLote[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async PRQObtenerRequisitos(dni: string): Promise<Prerequisito[]> {
    const url = `${this.catalogoService.catalogo}PRQObtenerRequisitos/`;
    const options: IDictionary[] = [
      { key: "{DNI}", value: dni },
    ];

    return new Promise<Prerequisito[]>((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Prerequisito[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);
            const prerequisitos: Prerequisito[] = this.utilService.mapear_propiedades(
              resp.Data,
              new Prerequisito()
            );

            return prerequisitos;
          })
        )
        .subscribe(
          (data: Prerequisito[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async PRQActualizarRequisito(data: any) {
    const url = `${this.catalogoService.catalogo}PRQActualizarRequisito`;
    const body = {
      token: this.securityService.token,
      ...data
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              console.log(resp);
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async obtener_autorizacion_por_documento(lote: Lote, documento: string) {
    const url = `${this.catalogoService.catalogo}ObtenerAutorizadoPorDocumento/`;
    const options: IDictionary[] = [
      { key: "{idLote}", value: lote.Id },
      { key: "{codigo_barrio}", value: this.securityService.codigoBarrio },
      { key: "{documento}", value: documento },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, AutorizadoDelLote>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            let autorizado = null;
            if (resp.Data) {
              autorizado = this.utilService.mapear_propiedades(
                resp.Data,
                new AutorizadoDelLote()
              );
            }

            return autorizado;
          })
        )
        .subscribe(
          (data: AutorizadoDelLote) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  limpiar() {
    this.autorizados = [];
  }

  private eliminar_propiedades_para_actualizar(
    autorizacion: AutorizadoDelLote
  ) {
    // Eliminamos propiedades que no deben viajar en el request
    delete autorizacion.DescripcionTipoAutorizado;
    delete autorizacion.DescripcionTipoRelacion;
    for (const dia of autorizacion.Dias) {
      delete dia.DescripcionDia;
    }

    return autorizacion;
  }
}
