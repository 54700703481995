import { Component, Input, Output, EventEmitter } from "@angular/core";

// Interfaces
import { IIdentity } from "src/app/models/interfaces/interfaces.index";

//Services
import { UtilService } from "src/app/services/common/util.service";

@Component({
  selector: "app-segment-toolbar",
  templateUrl: "./segment-toolbar.component.html",
  styleUrls: ["./segment-toolbar.component.scss"],
})
export class SegmentToolbarComponent {
  @Input() segmentos: IIdentity[] = [];
  @Input() segmentoSeleccionado: string = null;
  @Input() posicionIcono = "icon-top";
  @Input() sizeIcono = "small";
  @Input() scrollable = true;
  @Output() segmentoActual = new EventEmitter();
  segmentosAgrupados: IIdentity[] = [];
  menuIsExpanded: boolean = false;

  constructor(private utilService: UtilService) {}

  ngOnInit() {
    this.segmentosAgrupados = this.utilService.agrupar_array(this.segmentos, 3);
  }

  devolver_segmento_seleccionado(event: any) {
    const segmento = this.segmentos.filter(
      (segment: IIdentity) => segment.id.toUpperCase() === event.toUpperCase()
    )[0];
    this.segmentoSeleccionado = segmento.id;
    this.segmentoActual.emit(segmento);
  }

  toggleMenuHeight() {
    this.menuIsExpanded = !this.menuIsExpanded;
  }
}
