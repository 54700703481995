import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Platform } from "@ionic/angular";
import { Subscription } from "rxjs";
import { SafeUrl } from "@angular/platform-browser";

// Plugins
import { Market } from "@ionic-native/market/ngx";

// Constantes
import { APP } from "src/app/constants/constants.index";

// Servicios
import { DeviceService } from "src/app/services/common/device.service";
import { SbcService } from "src/app/services/common/sbc.service";

@Component({
  selector: "app-bloqueo",
  templateUrl: "./bloqueo.page.html",
  styleUrls: ["./bloqueo.page.scss"],
})
export class BloqueoPage implements OnInit, OnDestroy {
  private backButtonSubscription: Subscription;
  icono: string | SafeUrl = null;
  mensaje: string = "";
  permiteActualizar: boolean = true;

  constructor(
    private platform: Platform,
    private deviceService: DeviceService,
    private market: Market,
    private activadRoute: ActivatedRoute,
    private sbcService: SbcService
  ) {}

  ngOnInit() {
    this.sbcService.resolver_icono().subscribe((icono: string | SafeUrl) => {
      this.icono = icono;
    });

    this.backButtonSubscription = this.platform.backButton.subscribe(() => {
      navigator["app"].exitApp();
    });
  }

  ngOnDestroy() {
    this.backButtonSubscription.unsubscribe();
  }

  ionViewDidEnter() {
    this.mensaje = this.activadRoute.snapshot.queryParams.msg;
    this.permiteActualizar = Boolean(
      JSON.parse(this.activadRoute.snapshot.queryParams.edit)
    );
  }

  actualizar() {
    if (this.deviceService.isAndroid) {
      this.market.open(APP.APP_ID.ANDROID);
    } else if (this.deviceService.isIos) {
      this.market.open(APP.APP_ID.IOS);
    }
  }
}
