import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Interfaces
import {
  IPagination,
  IDictionary,
} from "src/app/models/interfaces/interfaces.index";

// Clases
import { Lote, ResponseApp } from "src/app/models/entities/entity.index";
import { ItemElement } from "src/app/models/clases/ItemElement";

// Servicios
import { CatalogoService } from "./catalogo.service";
import { RestService } from "../common/rest.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";
import { SecurityService } from "../security/security.service";
import { AutorizacionLogistica } from "src/app/models/entities/business/AutorizacionLogistica.entity";

@Injectable({
  providedIn: "root",
})
export class EgresoCamionService {
  private paginacion: IPagination = { pagina: 1, cantidad: 10 };
  private itemElement = new ItemElement<AutorizacionLogistica>();

  autorizaciones: AutorizacionLogistica[] = [];
  paginacionActual: IPagination = { pagina: 1, cantidad: 10 };

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private exceptionService: ExceptionService,
    private securityService: SecurityService
  ) {}

  // obtenerAccesos camiones
  async obtener_AutorizacionesLogistica(
    lote: Lote,
    filtro: string,
    paginacion: IPagination = this.paginacion
  ) {
    const url = `${this.catalogoService.catalogo}ObtenerAutorizacionesLogisticaParaEgreso/`;

    const options: IDictionary[] = [
      { key: "{idLote}", value: lote.Id },
      { key: "{filtro}", value: filtro },
      { key: "{pagina}", value: paginacion.pagina.toString() },
      { key: "{cantidad}", value: paginacion.cantidad.toString() },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, AutorizacionLogistica[]>((resp: any) => {
            console.log("resopp", resp);

            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;
            this.autorizaciones = [];

            let autorizaciones = this.utilService.mapear_propiedades(
              resp.Data,
              new AutorizacionLogistica()
            );
            autorizaciones = this.itemElement.refrescar(
              autorizaciones,
              this.autorizaciones
            );

            autorizaciones.forEach((ticket) => {
              if (
                this.autorizaciones.length <= this.paginacionActual.cantidad
              ) {
                this.autorizaciones.push(ticket);
              }
            });

            console.log("final", this.autorizaciones);
            return this.autorizaciones;
          })
        )
        .subscribe(
          (data: AutorizacionLogistica[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  limpiar() {
    this.autorizaciones = [];
  }

  /**
   * Habilita el egreso de un camión
   * @param id Identificador de la autorizacion
   */
  async HabilitarEgreso(id: string) {
    const url = `${this.catalogoService.catalogo}AutorizacionLogisticaHabilitarEgreso`;

    const body = {
      token: this.securityService.token,
      idAutorizacionLogisticaMobile: id,
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }
}
