import { PATH_URL } from "./url.constant";

export const MENU = {
  // ----------------------------------------------------------------------- MENÚ MOBILE
  MOBILE: {
    HOME: [
      {
        id: "dashboard",
        name: "Home",
        icon: "home",
        forceToView: true,
        path: PATH_URL.MOBILE.TABS.HOME.SEGMENTS.DASHBOARD.DEFAULT,
      },
      {
        id: "noticias",
        name: "Noticias",
        icon: "paper",
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.HOME.SEGMENTS.NOTICIAS.DEFAULT,
      },
      {
        id: "documentos",
        name: "Documentos",
        profiles: ["Habitante"],
        icon: "document",
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.HOME.SEGMENTS.DOCUMENTOS.DEFAULT,
      },
      {
        id: "telefonos",
        name: "Datos útiles",
        icon: "call",
        forceToView: false,
        profiles: ["Habitante"],
        path: PATH_URL.MOBILE.TABS.HOME.SEGMENTS.TELEFONOS.DEFAULT
      },
      {
        id: "reservas",
        name: "Reservas",
        profiles: ["Habitante"],
        icon: "calendar",
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.HOME.SEGMENTS.RESERVAS.DEFAULT,
      },
      {
        id: "comoLlego",
        name: "¿Cómo llego?",
        icon: "locate",
        profiles: ["Habitante"],
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.HOME.SEGMENTS.COMO_LLEGO.DEFAULT,
      },
      {
        id: "camioneta",
        name: "Reserva de transporte",
        icon: "bus",
        profiles: ["Habitante"],
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.HOME.SEGMENTS.CAMIONETA.DEFAULT,
      },
      {
        id: "detalleNoticia",
        name: "Detalle de Noticia",
        icon: "calendar",
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.HOME.SEGMENTS.DETALLE_NOTICIA.DEFAULT,
      },
      {
        id: "onboarding",
        name: "Novedades de la App",
        icon: "book",
        profiles: ["Habitante"],
        forceToView: false,
        path: PATH_URL.MOBILE.ONBOARDING.DEFAULT,
      },
    ],
    MENSAJE: [
      {
        id: "ticket",
        name: "Tickets",
        profiles: ["Habitante"],
        icon: "ticket",
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.MENSAJE.SEGMENTS.TICKET.DEFAULT,
      },
      {
        id: "bandeja-ticket",
        name: "Bandeja de Tickets",
        icon: "ticket",
        profiles: ["Guardia", "Administrativo"],
        forceToView: true,
        owner: false,
        path: PATH_URL.MOBILE.TABS.MENSAJE.SEGMENTS.TICKET.DEFAULT,
      },
      {
        id: "expensa",
        name: "Estado de Cuenta",
        profiles: ["Habitante"],
        icon: "wallet",
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.MENSAJE.SEGMENTS.EXPENSA.DEFAULT,
      },
      {
        id: "pagoExpensas",
        name: "Pago Expensas",
        profiles: ["Habitante"],
        icon: "card",
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.MENSAJE.SEGMENTS.PAGO_EXPENSAS.DEFAULT,
      },
      {
        id: "multa",
        name: "Infracciones",
        profiles: ["Habitante"],
        icon: "cash",
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.MENSAJE.SEGMENTS.MULTA.DEFAULT,
      },
      {
        id: "notificacion",
        name: "Notificaciones",
        profiles: ["Habitante"],
        icon: "notifications",
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.MENSAJE.SEGMENTS.NOTIFICACION.DEFAULT,
      }, {
        id: "correspondencia",
        name: "Correspondencia",
        profiles: ["Habitante"],
        icon: "mail",
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.MENSAJE.SEGMENTS.CORRESPONDENCIA.DEFAULT,
      },
      {
        id: "documentoAF",
        name: "Liquidaciones",
        profiles: ["Habitante"],
        icon: "folder-open",
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.MENSAJE.SEGMENTS.DOCUMENTOSAF.DEFAULT,
      },
    ],
    SEGURIDAD: [
      {
        id: "accesos",
        name: "Accesos",
        profiles: ["Habitante"],
        icon: "key",
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.SEGURIDAD.SEGMENTS.ACCESO.DEFAULT,
      },
      {
        id: "autorizado",
        name: "Autorizados",
        profiles: ["Habitante", "Guardia"],
        icon: "usercheck1",
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.SEGURIDAD.SEGMENTS.AUTORIZADO.DEFAULT,
      },
      {
        id: "invitacion",
        name: "Invitación",
        profiles: ["Habitante"],
        icon: "paper-plane",
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.SEGURIDAD.SEGMENTS.INVITACION.DEFAULT,
      },
      {
        id: "invitacionNautica",
        name: "Invitación Náutica",
        profiles: ["Habitante"],
        icon: "paper-plane",
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.SEGURIDAD.SEGMENTS.INVITACION_NAUTICA.DEFAULT,
      },
      {
        id: "camaras",
        name: "Cámaras",
        profiles: ["Habitante"],
        icon: "videocam",
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.SEGURIDAD.SEGMENTS.CAMARA.DEFAULT,
      },
      {
        id: "test",
        name: "Buscador",
        icon: "search",
        profiles: ["Guardia", "Administrativo"],
        forceToView: false,
        path: PATH_URL.MOBILE.TABS.SEGURIDAD.SEGMENTS.TEST.DEFAULT,
      },
      {
        id: "lote",
        name: "Lote",
        profiles: ["Habitante", "Guardia", "Administrativo"],
        icon: "home",
        forceToView: false,
        logisticItem: true,
        path: PATH_URL.MOBILE.TABS.SEGURIDAD.SEGMENTS.LOTE.DEFAULT,
      },
    ],
    PERFIL: [
      {
        id: "1",
        name: "Perfil",
        profiles: ["Habitante", "Guardia"],
        icon: "person",
        forceToView: true,
      },
      {
        id: "2",
        name: "Cambiar Contraseña",
        profiles: ["Habitante", "Guardia"],
        icon: "eye",
        forceToView: true,
      },
      {
        id: "3",
        name: "Contactos",
        profiles: ["Habitante", "Guardia"],
        icon: "contacts",
        forceToView: true,
      },
      {
        id: "4",
        name: "Mascotas",
        profiles: ["Habitante", "Guardia"],
        icon: "paw",
        forceToView: true,
      },
      {
        id: "5",
        name: "Cerrar Sesión",
        profiles: ["Habitante", "Guardia"],
        icon: "log-out",
        forceToView: true,
      },
    ],
    TABS: [
      {
        id: "home",
        name: "home",
        profiles: ["Habitante"],
        icon: "home",
        forceToView: true,
      },
      {
        id: "mensaje",
        name: "mensaje",
        profiles: ["Habitante"],
        icon: "mail",
        forceToView: true,
      },
      {
        id: "seguridad",
        name: "seguridad",
        profiles: ["Habitante"],
        icon: "lock",
        forceToView: true,
      },
    ],
    LOGIN: [
      {
        id: "pre-holder",
        name: "Ver Noticias",
        icon: "paper",
        forceToView: true,
      },
    ],
    PRE_HOLDER: [{ id: "login", name: "Volver a login" }],
  },

  // ----------------------------------------------------------------------- MENÚ WEB
  WEB: {
    INDEX: [
      {
        id: "home_menu",
        name: "Barrio",
        icon: "home",
        spacer: false,
        opciones: [
          {
            id: "noticia",
            name: "Noticias",
            icon: "sms",
            forceToView: false,
            path: PATH_URL.WEB.HOME.SEGMENTS.NOTICIAS.DEFAULT,
            owner: true,
          },
          {
            id: "documento",
            name: "Documentos",
            icon: "insert_drive_file",
            forceToView: false,
            path: PATH_URL.WEB.HOME.SEGMENTS.DOCUMENTOS.DEFAULT,
            owner: false,
          },
          {
            id: "reserva",
            name: "Reservas",
            icon: "calendar_today",
            forceToView: false,
            path: PATH_URL.WEB.HOME.SEGMENTS.RESERVAS.DEFAULT,
            owner: false,
          },
        ],
        forceToView: true,
      },
      {
        id: "mensaje_menu",
        name: "Consultas",
        icon: "mail",
        spacer: false,
        opciones: [
          {
            id: "ticket",
            name: "Gestiones",
            icon: "loyalty",
            forceToView: false,
            path: PATH_URL.WEB.MENSAJE.SEGMENTS.TICKET.DEFAULT,
            owner: true,
          },
          {
            id: "expensa",
            name: "Expensas",
            icon: "local_atm",
            forceToView: false,
            path: PATH_URL.WEB.MENSAJE.SEGMENTS.EXPENSA.DEFAULT,
            owner: true,
          },
          {
            id: "pagoExpensas",
            name: "Pago Expensas",
            icon: "credit_card",
            forceToView: false,
            path: PATH_URL.WEB.MENSAJE.SEGMENTS.PAGO_EXPENSAS.DEFAULT,
            owner: true,
          },
          {
            id: "multa",
            name: "Infracciones",
            icon: "monetization_on",
            forceToView: false,
            path: PATH_URL.WEB.MENSAJE.SEGMENTS.MULTA.DEFAULT,
            owner: true,
          },
          {
            id: "notificacion",
            name: "Notificaciones",
            icon: "notifications",
            forceToView: false,
            path: PATH_URL.WEB.MENSAJE.SEGMENTS.NOTIFICACION.DEFAULT,
            owner: true,
          },
          {
            id: "documentoAF",
            name: "Expensas",
            icon: "folder_open",
            forceToView: false,
            path: PATH_URL.WEB.MENSAJE.SEGMENTS.DOCUMENTOSAF.DEFAULT,
            owner: true,
          },
          {
            id: "bandejaTickets",
            name: "Bandeja de Tickets",
            icon: "loyalty",
            forceToView: false,
            path: PATH_URL.WEB.MENSAJE.SEGMENTS.BANDEJA_TICKETS.DEFAULT,
            owner: true,
          },
          {
            id: 'inquilinos',
            name: 'Inquilinos',
            icon: 'person',
            forceToView: false,
            path: PATH_URL.WEB.MENSAJE.SEGMENTS.WEB_INQUILINOS.DEFAULT,
            owner: true
          }
        ],
        forceToView: true,
      },
      {
        id: "seguridad_menu",
        name: "Seguridad",
        icon: "lock",
        spacer: false,
        opciones: [
          {
            id: "acceso",
            name: "Accesos",
            icon: "vpn_key",
            forceToView: false,
            path: PATH_URL.WEB.SEGURIDAD.SEGMENTS.ACCESO.DEFAULT,
            owner: true,
          },
          {
            id: "autorizado",
            name: "Autorizados",
            icon: "verified_user",
            forceToView: false,
            path: PATH_URL.WEB.SEGURIDAD.SEGMENTS.AUTORIZADO.DEFAULT,
            owner: true,
          },
          {
            id: "camaras",
            name: "Cámaras",
            icon: "videocam",
            forceToView: false,
            path: PATH_URL.WEB.SEGURIDAD.SEGMENTS.CAMARA.DEFAULT,
            owner: true,
          },
        ],
        forceToView: true,
      },
      {
        id: "spacer",
        name: "",
        icon: "",
        spacer: true,
      },
      {
        id: "menu_perfil",
        name: "",
        icon: "account_circle",
        spacer: false,
        opciones: [
          {
            id: "perfil",
            name: "Perfil",
            icon: "face",
            forceToView: false,
            path: PATH_URL.WEB.PERFIL.SEGMENTS.EDITAR.DEFAULT,
          },
          {
            id: "change_password",
            name: "Cambiar Contraseña",
            icon: "visibility",
            forceToView: false,
            path: PATH_URL.WEB.PERFIL.SEGMENTS.PASSWORD.DEFAULT,
          },
          {
            id: "contacts",
            name: "Contactos",
            icon: "contacts",
            forceToView: false,
            path: PATH_URL.WEB.PERFIL.SEGMENTS.CONTACTS.DEFAULT,
          },
          {
            id: "sesion",
            name: "Salir",
            icon: "power_off",
            forceToView: false,
            path: PATH_URL.WEB.LOGIN.DEFAULT,
          },
        ],
        forceToView: true,
      },
    ],
    LOGIN: [
      {
        id: "spacer",
        name: "",
        icon: "",
        spacer: true,
      },
    ],
    PRE_HOLDER: [
      {
        id: "spacer",
        name: "",
        icon: "",
        spacer: true,
      },
      {
        id: "menu_login",
        name: "Iniciar Sesión",
        icon: "perm_identity",
        spacer: false,
        forceToView: false,
        path: PATH_URL.WEB.LOGIN.DEFAULT,
      },
    ],
  },
};
