import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Interfaces
import {
  IPagination,
  IDictionary,
} from "src/app/models/interfaces/interfaces.index";

// Clases
import {
  Notificacion,
  Lote,
  Item,
  ResponseApp,
  NotificacionConfig,
} from "src/app/models/entities/entity.index";
import { ItemElement } from "src/app/models/clases/ItemElement";

// Servicios
import { CatalogoService } from "./catalogo.service";
import { RestService } from "../common/rest.service";
import { SecurityService } from "../security/security.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";

@Injectable({
  providedIn: "root",
})
export class NotificacionService {
  private paginacion: IPagination = { pagina: 1, cantidad: 10 };
  private itemElement = new ItemElement<Notificacion>();

  notificaciones: Notificacion[] = [];
  paginacionActual: IPagination = { pagina: 1, cantidad: 10 };
  configuraciones: NotificacionConfig[];

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private securityService: SecurityService,
    private utilService: UtilService,
    private exceptionService: ExceptionService
  ) { }

  async marcarTodoLeido() {
    const body = {
      token: this.securityService.token,
      entidad: "Notificacion",
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(`${this.catalogoService.catalogo}MarcarTodoLeidoParaEntidad`, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  // obtenerNotificaciones
  async obtener_notificaciones(
    lote: Lote,
    estado: Item,
    paginacion: IPagination = this.paginacion
  ) {
    const url = `${this.catalogoService.catalogo}ObtenerNotificaciones/`;
    const options: IDictionary[] = [
      { key: "{idLote}", value: lote.Id },
      { key: "{idEstado}", value: estado.Id.toString() },
      { key: "{pagina}", value: paginacion.pagina.toString() },
      { key: "{cantidad}", value: paginacion.cantidad.toString() },
    ];

    console.log(options);

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Notificacion[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;

            const notificaciones = this.utilService.mapear_propiedades(
              resp.Data,
              new Notificacion()
            );
            this.notificaciones = this.itemElement.refrescar(
              notificaciones,
              this.notificaciones
            );

            return this.notificaciones;
          })
        )
        .subscribe(
          (data: Notificacion[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async get_notification(idNotification: string) {
    const url = `${this.catalogoService.catalogo}/ObtenerNotificacion/`;
    const options: IDictionary[] = [
      { key: "{idNotificacion}", value: idNotification },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Notificacion>((resp: any) => {
            this.exceptionService.resolver_error(resp);
            const notification = this.utilService.mapear_propiedades(
              resp.Data,
              new Notificacion()
            );
            return notification;
          })
        )
        .subscribe(
          (data: Notificacion) => {
            resolve(data);
          },
          (error) => {
            console.log("error en get_notification 107", error);
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log("error en get_notification 113", error);
      throw error;
    });
  }

  // obtenerNotificacionesConfig
  async obtener_configuracion_de_notificaciones(lote: Lote) {
    const url = `${this.catalogoService.catalogo}ObtenerNotificationsConfig/`;
    const options: IDictionary[] = [{ key: "{idLote}", value: lote.Id }];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, NotificacionConfig[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);
            this.configuraciones = this.utilService.mapear_propiedades(
              resp.Data,
              new NotificacionConfig()
            );

            return this.configuraciones;
          })
        )
        .subscribe(
          (data: NotificacionConfig[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  //**marcarNotificacionLeida */
  async marcar_notificacion_como_leida(notificacionId: string) {
    const url = `${this.catalogoService.catalogo}MarcarNotificacionLeida/`;
    const options: IDictionary[] = [
      { key: "{notificacion}", value: notificacionId },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  //**guardarNotificaciones */
  async guardar_configuracion(config: NotificacionConfig, lote: Lote) {
    const url = `${this.catalogoService.catalogo}ModificarNotificationsConfig`;
    const body = {
      token: this.securityService.token,
      idLote: lote.Id,
      config,
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  limpiar() {
    this.notificaciones = [];
  }
}
