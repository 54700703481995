import { Injectable } from "@angular/core";

// Plugins
import { Storage } from "@ionic/storage";
import { Platform } from "@ionic/angular";

// Interfaces
import { IDictionary } from "src/app/models/interfaces/common/dictionary.interface";
import { environment } from "src/app/constants/constants.index";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  private isWeb: boolean;
  currentValue: string;

  constructor(private platform: Platform, private storage: Storage) {
    this.isWeb = !this.platform.is("cordova");
  }

  guardar(keyValuePair: IDictionary) {
    if (this.isWeb || environment.APP.MOCK_MOBILE) {
      // web
      localStorage.setItem(keyValuePair.key, keyValuePair.value);
    } else {
      // mobile
      this.storage.set(keyValuePair.key, keyValuePair.value).catch((error) => {
        throw error;
      });
    }
  }

  cargar(key: string) {
    return new Promise<string | boolean>((resolve, reject) => {
      if (this.isWeb || environment.APP.MOCK_MOBILE) {
        // web
        if (localStorage.getItem(key)) {
          this.currentValue = localStorage.getItem(key);
          resolve(this.currentValue);
        } else {
          resolve(null);
        }
      } else {
        // mobile
        this.storage
          .get(key)
          .then((val) => {
            if (val) {
              this.currentValue = val;
              resolve(this.currentValue);
            } else {
              resolve(null);
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  borrar(listKey: string[]) {
    listKey.forEach((key: string) => {
      if (this.isWeb) {
        localStorage.removeItem(key);
      } else {
        this.storage.remove(key);
      }
    });
  }

  limpiar() {
    if (this.isWeb) {
      localStorage.clear();
    } else {
      this.storage.clear().then(() => (this.currentValue = null));
    }
  }
}
