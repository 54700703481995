import { Component, ViewChild, OnInit } from "@angular/core";
import { IonContent, IonInfiniteScroll } from "@ionic/angular";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

// Interfaces
import {
  IAdvancedSearchFilters,
  IPagination,
} from "src/app/models/interfaces/interfaces.index";

// Constantes
import {
  SCROLL_FAB_HEIGHT,
  ENABLED_SCROLL_EVENT,
  PAGINATOR,
  EMPTY_PARAMS,
  DOCUMENT_TYPES,
} from "src/app/constants/config.constant";
import {
  MESSAGE,
  POPOVER_STYLES,
  TIEMPO_MENSAJE,
} from "src/app/constants/constants.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { DocumentoService } from "src/app/services/business/documento.service";
import { FileService } from "src/app/services/common/file.service";
import { DeviceService } from "src/app/services/common/device.service";

// Clases
import { DocumentoAF } from "src/app/models/entities/business/documento.entity";
import { UserService } from "src/app/services/security/user.service";
import { Lote } from "src/app/models/entities/entity.index";
import { AdvancedSearchComponent } from "src/app/components/mobile/component.mobile.index";

@Component({
  selector: "app-documento-af",
  templateUrl: "./documento-af.page.html",
  styleUrls: ["./documento-af.page.scss"],
})
export class DocumentoAFPage implements OnInit {
  private paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  mostrarFabButton = false;
  mostrarFabButtonApartirDe = SCROLL_FAB_HEIGHT;
  habilitarEventosScroll = ENABLED_SCROLL_EVENT;
  mostrarInfiniteScrollEn = PAGINATOR.SIZE;
  formGroup: FormGroup;
  loteSeleccionado: Lote;
  lotes: Lote[];

  documentos: DocumentoAF[] = [];
  mostrarMensajeSinItem = false;
  mostrarFiltro = false;
  mostrarButtonLimpiar = false;

  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private documentoService: DocumentoService,
    private fileService: FileService,
    private userService: UserService,
    private deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.lotes = this.userService.user.Lotes;
    this.configurar_formulario();
    this.filtrar();
  }

  resolver_vista_documento(event?) {
    if (event) {
      this.paginacion.pagina += 1;
      this.cargar_documentos(event);
    } else {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.cargar_documentos(event);
      });
    }
  }

  informar_scroll(event) {
    if (this.deviceService.isIos) {
      return;
    }

    this.content
      .getScrollElement()
      .then(
        (el) =>
          (this.mostrarFabButton =
            el.scrollTop > this.mostrarFabButtonApartirDe)
      );
  }

  volver_al_inicio() {
    this.content.scrollToTop();
  }

  async download(documento: DocumentoAF) {
    try {
      if (!documento.Leido) {
        await this.documentoService.marcarDocumentoLeido(documento.Id);
      }
    } catch (error) {
      console.log("error en marcarDocumentoLeido: ", error);
    }

    this.fileService.download_file(documento.Url);
  }

  doRefresh(event) {
    this.setear_lote_seleccionado();
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.documentoService.limpiar();
    this.documentoService
      .obtener_documentosAF(
        this.loteSeleccionado,
        EMPTY_PARAMS.AF,
        DOCUMENT_TYPES.EXP,
        this.paginacion
      )
      .then((resp: DocumentoAF[]) => {
        this.documentos = resp;
        this.controlaFindePagina();
        event.target.complete();
      })
      .catch((error) => {
        event.target.complete();
      });
  }

  resolver_vista_documentos(event?) {
    if (event) {
      this.paginacion.pagina += 1;
      this.cargar_documentos(event);
    } else {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.cargar_documentos(event);
      });
    }
  }

  filtrar() {
    this.limpiar_filtro();
    this.setear_lote_seleccionado();
    this.resolver_vista_documentos();
    this.mostrarButtonLimpiar = true;
  }

  limpiar_filtro(inicializarForm: boolean = false) {
    this.documentos = [];
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.documentoService.limpiar_documentos_af();
    this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    this.mostrarMensajeSinItem = false;
    this.mostrarButtonLimpiar = false;

    if (inicializarForm) {
      this.configurar_formulario();
    }
  }

  /**------------------------------------------------------------------PRIVATE */

  private cargar_documentos(event?) {
    this.documentoService
      .obtener_documentosAF(
        this.loteSeleccionado,
        EMPTY_PARAMS.AF,
        DOCUMENT_TYPES.EXP,
        this.paginacion
      )
      .then((resp: DocumentoAF[]) => {
        if (
          resp == null ||
          this.documentoService.paginacionActual.cantidad === 0
        ) {
          this.controlService.habilitar_infinite_scroll(event, false);
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_SIN_DATOS,
            TIEMPO_MENSAJE
          );
        } else {
          this.documentos = resp;
          this.controlService.completar_infinite_scroll(event);
        }
      })
      .catch((error) => {
        this.controlService.habilitar_infinite_scroll(event, false);
        this.controlService.mostrar_toast_con_error(error, false);
      })
      .finally(() => {
        this.controlaFindePagina();
        this.mostrarMensajeSinItem = this.documentos.length === 0;
        if (event == null) {
          this.controlService.ocultar_loading();
        }
      });
  }

  private configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      lotes: [this.lotes[0].Id, Validators.required],
    });
  }

  private setear_lote_seleccionado() {
    this.loteSeleccionado = this.lotes.filter(
      (lote: Lote) => lote.Id === this.formGroup.value.lotes
    )[0];
  }

  open_advanced_search() {
    const componentProps: { filters: IAdvancedSearchFilters } = {
      filters: {
        showLoteFilter: true,
      },
    };
    this.controlService
      .mostrarPopOver({
        component: AdvancedSearchComponent,
        componentProps,
        cssClass: POPOVER_STYLES.ADVANZED_SEARCH_MODAL,
        backdropDismiss: false,
      })
      .then((data) => {
        if (data) {
          this.formGroup.controls["lotes"].setValue(data.lotes);
          this.filtrar();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  //controlamos si llegó al final de la paginación para deshabilitar el Infinite Scroll
  controlaFindePagina() {
    let maxPages = Math.ceil(
      this.documentoService.paginacionActual.cantidad / this.paginacion.cantidad
    );
    if (maxPages == this.paginacion.pagina)
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, false);
    else
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    //console.log('total en db:' + this.documentoService.paginacionActual.cantidad +', maxPages: ' + maxPages + ' , paginaActual: ' + this.paginacion.pagina)
  }

  markAllAsRead(): void{
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.documentoService
        .marcarTodoLeido()
        .then(() => {
          this.setear_lote_seleccionado();
          this.paginacion.pagina = PAGINATOR.PAGE_INIT;
          this.documentoService.limpiar();
          this.documentoService
            .obtener_documentosAF(
              this.loteSeleccionado,
              EMPTY_PARAMS.AF,
              DOCUMENT_TYPES.EXP,
              this.paginacion
            )
            .then((resp: DocumentoAF[]) => {
              this.documentos = resp;
              this.controlaFindePagina();
            });
        })
        .catch((error) => {
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    });
  }
}
