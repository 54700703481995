import { Component, ViewChild } from "@angular/core";
import { IonContent, IonInfiniteScroll } from "@ionic/angular";

// Interfaces
import {
  IPagination,
  IModal,
} from "src/app/models/interfaces/interfaces.index";

// Constantes
import {
  PAGINATOR,
  SCROLL_FAB_HEIGHT,
  ENABLED_SCROLL_EVENT,
} from "src/app/constants/config.constant";
import { MESSAGE, TIEMPO_MENSAJE } from "src/app/constants/constants.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { CamaraService } from "src/app/services/business/camara.service";
import { DeviceService } from "src/app/services/common/device.service";

// Clases
import { Camara } from "src/app/models/entities/entity.index";

// Páginas
import { CamaraModalPage } from "./camara-modal/camara-modal.page";

@Component({
  selector: "app-camara",
  templateUrl: "./camara.page.html",
  styleUrls: ["./camara.page.scss"],
})
export class CamaraPage {
  private primerCarga = true;
  private paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  mostrarFabButton = false;
  mostrarFabButtonApartirDe = SCROLL_FAB_HEIGHT;
  habilitarEventosScroll = ENABLED_SCROLL_EVENT;
  mostrarInfiniteScrollEn = PAGINATOR.SIZE;

  camaras: Camara[] = [];
  mostrarMensajeSinItem = false;

  constructor(
    private controlService: ControlService,
    private camaraService: CamaraService,
    private deviceService: DeviceService
  ) {}

  ionViewWillEnter() {
    if (this.primerCarga) {
      this.resolver_vista_camara();
      this.primerCarga = false;
    }
  }

  resolver_vista_camara(event?) {
    if (event) {
      this.paginacion.pagina += 1;
      this.cargar_camaras(event);
    } else {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.cargar_camaras(event);
      });
    }
  }

  async mostrar_camara(camara: Camara) {
    const modal: IModal = {
      component: CamaraModalPage,
      componentProps: { camara: camara },
      returnData: true,
    };

    this.controlService.mostrar_modal(modal).then((info: any) => {
      this.ionViewWillEnter();
    });
  }

  informar_scroll(event) {
    if (this.deviceService.isIos) {
      return;
    }

    this.content
      .getScrollElement()
      .then(
        (el) =>
          (this.mostrarFabButton =
            el.scrollTop > this.mostrarFabButtonApartirDe)
      );
  }

  volver_al_inicio() {
    this.content.scrollToTop();
  }

  doRefresh(event) {
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.camaraService.limpiar();
    this.camaraService
      .obtener_camaras_de_seguridad(this.paginacion)
      .then((resp: Camara[]) => {
        this.camaras = resp;
        this.controlaFindePagina();
        event.target.complete();
      })
      .catch((error) => {
        event.target.complete();
      });
  }

  /**------------------------------------------------------------------PRIVATE */

  private cargar_camaras(event?) {
    this.camaraService
      .obtener_camaras_de_seguridad(this.paginacion)
      .then((resp: Camara[]) => {
        // console.log( this.documentoService.paginacionActual );
        if (
          resp == null ||
          this.camaraService.paginacionActual.cantidad === 0
        ) {
          this.controlService.habilitar_infinite_scroll(event, false);
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_SIN_DATOS,
            TIEMPO_MENSAJE
          );
        } else {
          this.camaras = resp;
          this.controlService.completar_infinite_scroll(event);
        }
      })
      .catch((error) => {
        this.controlService.habilitar_infinite_scroll(event, false);
        this.controlService.mostrar_toast_con_error(error, false);
      })
      .finally(() => {
        this.controlaFindePagina();
        this.mostrarMensajeSinItem = this.camaras.length === 0;
        if (event == null) {
          this.controlService.ocultar_loading();
        }
      });
  }
  controlaFindePagina() {
    let maxPages = Math.ceil(
      this.camaraService.paginacionActual.cantidad / this.paginacion.cantidad
    );
    if (maxPages == this.paginacion.pagina)
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, false);
    else
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    //console.log('total en db:' + this.camaraService.paginacionActual.cantidad +', maxPages: ' + maxPages + ' , paginaActual: ' + this.paginacion.pagina)
  }
}
