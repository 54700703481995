import { Component, Input } from "@angular/core";

// Clases
import { Encuesta } from "src/app/models/entities/entity.index";

// Servicios
import { ControlService } from "../../../../../services/common/control.service";

@Component({
  selector: "app-encuesta-cerrada-modal",
  templateUrl: "./encuesta-cerrada-modal.page.html",
  styleUrls: ["./encuesta-cerrada-modal.page.scss"],
})
export class EncuestaCerradaModalPage {
  @Input() encuesta: Encuesta;
  slideOpts = {
    pagination: {
      el: ".swiper-pagination",
      type: "progressbar",
    },
  };

  constructor(private controlService: ControlService) {}

  cerrar_modal() {
    this.controlService.cerrar_modal(this.encuesta);
  }
}
