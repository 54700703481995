import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Servicios
import { CatalogoService } from "./catalogo.service";
import { RestService } from "../common/rest.service";
import { DeviceService } from "../common/device.service";
import { ExceptionService } from "../common/exception.service";

// Constantes
import { ResponseApp } from "src/app/models/entities/entity.index";
import { environment } from "src/app/constants/constants.index";

@Injectable({
  providedIn: "root",
})
export class DispositivoService {
  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private deviceService: DeviceService,
    private exceptionService: ExceptionService
  ) {}

  // devincularDevice
  async desvincular_dispositivo(deviceId: string) {
    const url = `${this.catalogoService.catalogo}DesvincularDevice`;
    const body = {
      deviceId,
    };

    return new Promise<ResponseApp>((resolve, reject) => {
      if (this.deviceService.isWeb || environment.APP.MOCK_MOBILE) {
        const resp = new ResponseApp();
        resp.Status = true;

        resolve(resp);
        return;
      }

      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resp.Status = true;
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }
}
