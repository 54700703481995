import { Component, ViewChild, OnInit, ChangeDetectorRef } from "@angular/core";
import { IonContent, IonInfiniteScroll } from "@ionic/angular";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

// Constantes
import {
  SCROLL_FAB_HEIGHT,
  ENABLED_SCROLL_EVENT,
  MODULOS,
  PAGINATOR,
} from "src/app/constants/config.constant";
import {
  MESSAGE,
  POPOVER_STYLES,
  TIEMPO_MENSAJE,
} from "src/app/constants/constants.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { DeviceService } from "src/app/services/common/device.service";

// Clases
import { Lote } from "src/app/models/entities/entity.index";

// Interfaces
import {
  IPagination,
  IModal,
  IAdvancedSearchFilters,
} from "src/app/models/interfaces/interfaces.index";

// Components
import { AdvancedSearchComponent } from "src/app/components/mobile/component.mobile.index";
import { EgresoCamionService } from "src/app/services/business/egreso-camion.service";
import { AutorizacionLogistica } from "../../../../models/entities/business/AutorizacionLogistica.entity";

@Component({
  selector: "app-egreso-camion",
  templateUrl: "./egreso-camion.page.html",
  styleUrls: ["./egreso-camion.page.scss"],
})
export class EgresoCamionPage implements OnInit {
  private paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  mostrarFabButton = false;
  mostrarFabButtonApartirDe = SCROLL_FAB_HEIGHT;
  habilitarEventosScroll = ENABLED_SCROLL_EVENT;
  mostrarInfiniteScrollEn = PAGINATOR.SIZE;

  mostrarFiltro = false;
  formGroup: FormGroup;
  mostrarMensajeSinItem = false;
  autorizaciones: AutorizacionLogistica[] = [];

  loteSeleccionado: Lote;
  lotes: Lote[];
  filtro: string = null;

  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private userService: UserService,
    private deviceService: DeviceService,
    private egresoCamionesService: EgresoCamionService,
    private changeDedectionRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.lotes = this.userService.user.Lotes;
    this.configurar_formulario();
    this.filtrar();
  }

  filtrar() {
    this.limpiar_filtro();
    this.setear_lote_seleccionado();
    this.resolver_vista_autorizaciones();
  }

  resolver_vista_autorizaciones(event?) {
    if (event) {
      this.paginacion.pagina += 1;
      this.cargar_autorizaciones(event);
    } else {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.cargar_autorizaciones(event);
      });
    }
  }

  limpiar_filtro() {
    this.autorizaciones = [];
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.egresoCamionesService.limpiar();
    this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    this.mostrarMensajeSinItem = false;
  }

  informar_scroll(event) {
    if (this.deviceService.isIos) {
      return;
    }

    this.content
      .getScrollElement()
      .then(
        (el) =>
          (this.mostrarFabButton =
            el.scrollTop > this.mostrarFabButtonApartirDe)
      );
  }

  volver_al_inicio() {
    this.content.scrollToTop();
  }

  doRefresh(event) {
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.egresoCamionesService.limpiar();
    this.cargar_autorizaciones(event);
  }

  private cargar_autorizaciones(event?) {
    this.autorizaciones = [];
    this.egresoCamionesService
      .obtener_AutorizacionesLogistica(
        this.loteSeleccionado,
        this.filtro,
        this.paginacion
      )
      .then((resp: AutorizacionLogistica[]) => {
        if (
          resp == null ||
          this.egresoCamionesService.paginacionActual.cantidad === 0
        ) {
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_SIN_DATOS,
            TIEMPO_MENSAJE
          );
        } else {
          this.autorizaciones = this.autorizaciones.concat(resp);
          this.controlService.completar_infinite_scroll(event);
        }
      })
      .catch((error) => {
        this.controlService.habilitar_infinite_scroll(event, false);
        this.controlService.mostrar_toast_con_error(error, false);
      })
      .finally(() => {
        this.controlaFindePagina();
        this.mostrarMensajeSinItem = this.autorizaciones.length === 0;
        this.changeDedectionRef.detectChanges();
        if (event == null) {
          this.controlService.ocultar_loading();
        } else {
          event.target.complete();
        }
      });
  }

  private configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      lotes: [this.lotes[0].Id, Validators.required],
      filtro: [this.filtro, Validators.nullValidator],
    });
  }

  private setear_lote_seleccionado() {
    this.loteSeleccionado = this.lotes.filter(
      (lote: Lote) => lote.Id === this.formGroup.value.lotes
    )[0];
  }

  open_advanced_search() {
    const componentProps: { filters: IAdvancedSearchFilters } = {
      filters: {
        showLoteFilter: true,
        showGeneralFilter: true,
      },
    };
    this.controlService
      .mostrarPopOver({
        component: AdvancedSearchComponent,
        componentProps,
        cssClass: POPOVER_STYLES.ADVANZED_SEARCH_MODAL,
        backdropDismiss: false,
      })
      .then((data) => {
        if (data) {
          this.formGroup.controls["lotes"].setValue(data.lotes);
          this.filtro = data.filtro ? data.filtro : null;
          this.filtrar();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //controlamos si llegó al final de la paginación para deshabilitar el Infinite Scroll
  controlaFindePagina() {
    let maxPages = Math.ceil(
      this.egresoCamionesService.paginacionActual.cantidad /
        this.paginacion.cantidad
    );
    if (maxPages == this.paginacion.pagina)
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, false);
    else
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
  }

  egresoCamion(id) {
    this.controlService.mostrar_alert({
      header: MESSAGE.ALERT.MENSAJE_LOGISTICA.HABILITAR_EGRESO.TITULO,
      message: MESSAGE.ALERT.MENSAJE_LOGISTICA.HABILITAR_EGRESO.PREGUNTA,
      backdropDismiss: false,
      buttons: [
        {
          text: MESSAGE.ALERT.MENSAJE_LOGISTICA.HABILITAR_EGRESO.BUTTONS
            .ACEPTAR,
          handler: () => {
            this.controlService
              .mostrarLoading(MESSAGE.LOADING.DEFAULT)
              .then(() => {
                this.egresoCamionesService
                  .HabilitarEgreso(id)
                  .then(() => {
                    this.controlService.ocultar_loading();
                    this.paginacion.pagina = 1;
                    this.egresoCamionesService.limpiar();
                    this.filtrar();
                  })
                  .catch((error) => {
                    this.controlService.ocultar_loading();
                    this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
                  });
              });
          },
        },
        {
          text: MESSAGE.ALERT.MENSAJE_NOTICIA.ELIMINAR_NOTICIA.BUTTONS.CANCELAR,
        },
      ],
    });
  }
}
