export { TabsPage } from "./tabs/tabs.page";
export { HomePage } from "./home/home.page";
export { MensajePage } from "./mensaje/mensaje.page";
export { SeguridadPage } from "./seguridad/seguridad.page";

export { DashboardPage } from "./home/dashboard/dashboard.page";
export { DetalleNoticiaPage } from "./home/detalleNoticia/detalleNoticia.page";
export { DetalleNoticiaPageNotification } from "./home/detalle-noticia/detalle-noticia.page";

export { NoticiaPage } from "../mobile/home/noticia/noticia.page";
export { AgregarNoticiaPage } from "../mobile/home/noticia/agregar/agregar.page";

export { EncuestaPage } from "../mobile/home/encuesta/encuesta.page";
export { EncuestaAbiertaModalPage } from "../mobile/home/encuesta/encuesta-abierta-modal/encuesta-abierta-modal.page";
export { EncuestaCerradaModalPage } from "../mobile/home/encuesta/encuesta-cerrada-modal/encuesta-cerrada-modal.page";

export { DocumentoPage } from "../mobile/home/documento/documento.page";
export { DocumentoAFPage } from "../mobile/mensaje/documento-af/documento-af.page";

export { ReservaPage } from "./home/reserva/reserva.page";

export { TicketPage } from "../mobile/mensaje/ticket/ticket.page";
export { AgregarTicketPage } from "../mobile/mensaje/ticket/agregar/agregar.page";
export { DetalleTicketPage } from "../mobile/mensaje/ticket/detalle/detalle.page";

export { ExpensaPage } from "../mobile/mensaje/expensa/expensa.page";

export { PagoExpensasPage } from "./mensaje/pago-expensas/pago-expensas.page";

export { MultaPage } from "../mobile/mensaje/multa/multa.page";
export { DetalleMultaPage } from "../mobile/mensaje/multa/detalle/detalle.page";
export { ImagenModalMultaPage } from "../mobile/mensaje/multa/imagen-modal/imagen-modal.page";
export { NotificacionPage } from "../mobile/mensaje/notificacion/notificacion.page";
export { CorrespondenciaPage } from "../mobile/mensaje/correspondencia/correspondencia.page";

export { AccesoPage } from "../mobile/seguridad/acceso/acceso.page";
export { AutorizadoPage } from "../mobile/seguridad/autorizado/autorizado.page";
export { AgregarAutorizadoPage } from "../mobile/seguridad/autorizado/agregar/agregar-autorizado.page";
export { DetalleAutorizadoPage } from "../mobile/seguridad/autorizado/detalle/detalle-autorizado.page";
export { ObservacionAutorizadoPage } from "../mobile/seguridad/autorizado/observacion/observacion-autorizado.page";
export { CamaraPage } from "../mobile/seguridad/camara/camara.page";
export { InvitacionPage } from "../mobile/seguridad/invitacion/invitacion.page";
export { InvitacionNauticaPage } from "../mobile/seguridad/invitacion-nautica/invitacion-nautica.page";
export { CamaraModalPage } from "../mobile/seguridad/camara/camara-modal/camara-modal.page";
export { EgresoCamionPage } from "../mobile/seguridad/egreso-camion/egreso-camion.page";

export { PerfilPage } from "../mobile/perfil/perfil.page";
export { ChangePasswordPage } from "../mobile/perfil/change-password/change-password.page";
export { ContactsPage } from "../mobile/perfil/contacts/contacts.page";
export { MascotasPage } from "../mobile/perfil/mascotas/mascotas.page";
export { BloqueoPage } from "../mobile/bloqueo/bloqueo.page";
export { PushNotificationPage } from "../mobile/push-notification/push-notification.page";
export { NotificationDetailPage } from "../mobile/notification-detail/notification-detail.page";
export { LoginPage } from "../mobile/login/login.page";
export { BarrioPage } from "../mobile/login/barrio/barrio.page";
export { OnboardingPage } from "../mobile/onboarding/onboarding.page";
export { ForgotMyPasswordPage } from "../mobile/login/forgot-my-password/forgot-my-password.page";
export { EmergencyPage } from "../mobile/emergency/emergency.page";
export { SplashPage } from "../mobile/splash/splash.page";
export { PreNoticiaPage } from "../mobile/pre-holder/pre-noticia/pre-noticia.page";

export { LotePage } from "./seguridad/lote/lote.page";
