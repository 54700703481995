import { Component, ViewChild, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IonContent, IonInfiniteScroll } from "@ionic/angular";

//Componentes
import { AdvancedSearchComponent } from "src/app/components/mobile/component.mobile.index";

// Interfaces
import {
  IPagination,
  IPopover,
  IAdvancedSearchFilters,
} from "src/app/models/interfaces/interfaces.index";

// Constantes
import {
  SCROLL_FAB_HEIGHT,
  ENABLED_SCROLL_EVENT,
  PAGINATOR,
} from "src/app/constants/config.constant";

// Clases
import { Expensa, Lote } from "src/app/models/entities/entity.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { ExpensaService } from "src/app/services/business/expensa.service";
import { DeviceService } from "src/app/services/common/device.service";

// Constantes
import {
  MESSAGE,
  POPOVER_STYLES,
  TIEMPO_MENSAJE,
} from "src/app/constants/constants.index";

@Component({
  selector: "app-expensa",
  templateUrl: "./expensa.page.html",
  styleUrls: ["./expensa.page.scss"],
})
export class ExpensaPage implements OnInit {
  private paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  mostrarFabButton = false;
  mostrarFabButtonApartirDe = SCROLL_FAB_HEIGHT;
  habilitarEventosScroll = ENABLED_SCROLL_EVENT;
  mostrarInfiniteScrollEn = PAGINATOR.SIZE;

  mostrarFiltro = false;
  mostrarButtonLimpiar = false;
  formGroup: FormGroup;
  mostrarMensajeSinItem = false;
  expensas: Expensa[] = [];

  loteSeleccionado: Lote;
  lotes: Lote[];

  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private userService: UserService,
    private expensaService: ExpensaService,
    private deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.lotes = this.userService.user.Lotes;
    this.configurar_formulario();
    this.filtrar();
  }

  filtrar() {
    this.limpiar_filtro();
    this.setear_lote_seleccionado();
    this.resolver_vista_expensa();
    this.mostrarButtonLimpiar = true;
  }

  resolver_vista_expensa(event?) {
    if (event) {
      this.paginacion.pagina += 1;
      this.cargar_expensas(event);
    } else {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.cargar_expensas(event);
      });
    }
  }

  limpiar_filtro() {
    this.expensas = [];
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.expensaService.limpiar();
    this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    this.mostrarButtonLimpiar = false;
    this.mostrarMensajeSinItem = false;
  }

  informar_scroll(event) {
    if (this.deviceService.isIos) {
      return;
    }

    this.content
      .getScrollElement()
      .then(
        (el) =>
          (this.mostrarFabButton =
            el.scrollTop > this.mostrarFabButtonApartirDe)
      );
  }

  volver_al_inicio() {
    this.content.scrollToTop();
  }

  doRefresh(event) {
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.expensaService.limpiar();
    this.expensaService
      .obtener_expensas(this.loteSeleccionado, this.paginacion)
      .then((resp: Expensa[]) => {
        this.expensas = resp;
        this.controlaFindePagina();
        event.target.complete();
      })
      .catch((error) => {
        event.target.complete();
      });
  }
  private cargar_expensas(event?) {
    this.expensaService
      .obtener_expensas(this.loteSeleccionado, this.paginacion)
      .then((resp: Expensa[]) => {
        if (
          resp == null ||
          this.expensaService.paginacionActual.cantidad === 0
        ) {
          this.controlService.habilitar_infinite_scroll(event, false);
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_SIN_DATOS,
            TIEMPO_MENSAJE
          );
        } else {
          this.expensas = resp;
          this.controlService.completar_infinite_scroll(event);
        }
      })
      .catch((error) => {
        this.controlService.habilitar_infinite_scroll(event, false);
        this.controlService.mostrar_toast_con_error(error, false);
      })
      .finally(() => {
        this.controlaFindePagina();
        this.mostrarMensajeSinItem = this.expensas.length === 0;
        if (event == null) {
          this.controlService.ocultar_loading();
        }
      });
  }

  private configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      lotes: [this.lotes[0].Id, Validators.required],
    });
  }

  private setear_lote_seleccionado() {
    this.loteSeleccionado = this.lotes.filter(
      (lote: Lote) => lote.Id === this.formGroup.value.lotes
    )[0];
  }

  open_advanced_search() {
    const componentProps: { filters: IAdvancedSearchFilters } = {
      filters: {
        showLoteFilter: true,
      },
    };
    this.controlService
      .mostrarPopOver({
        component: AdvancedSearchComponent,
        componentProps,
        cssClass: POPOVER_STYLES.ADVANZED_SEARCH_MODAL,
        backdropDismiss: false,
      })
      .then((data) => {
        if (data) {
          this.formGroup.controls["lotes"].setValue(data.lotes);
          this.filtrar();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //controlamos si llegó al final de la paginación para deshabilitar el Infinite Scroll
  controlaFindePagina() {
    let maxPages = Math.ceil(
      this.expensaService.paginacionActual.cantidad / this.paginacion.cantidad
    );
    if (maxPages == this.paginacion.pagina)
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, false);
    else
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    //console.log('total en db:' + this.expensaService.paginacionActual.cantidad +', maxPages: ' + maxPages + ' , paginaActual: ' + this.paginacion.pagina)
  }
}
