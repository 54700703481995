export { position } from "./position.enum";
export { spinner } from "./spinner.enum";

export {
  EnumEstadoEncuesta,
  EnumEstadoTicket,
  EnumEstadoLeido,
  EnumEstadoNotificacionLeido,
} from "./estado.enum";

export {
  EnumTipoAcceso,
  EnumTipoNotificacion,
  EnumTipoApp,
  EnumTipoConfig,
  EnumTipoImg,
  EnumContentType,
  EnumTipoCanal,
  EnumTipoMenu,
  EnumTipoSegmento,
  EnumEmergencyTypes,
  EnumNotificationTypes,
} from "./tipo.enum";
