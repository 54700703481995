import { Component, Input } from "@angular/core";

// Clases
import { Camara } from "src/app/models/entities/entity.index";

// Constantes
import { INTERVAL } from "src/app/constants/config.constant";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UtilService } from "src/app/services/common/util.service";

@Component({
  selector: "app-camara-modal",
  templateUrl: "./camara-modal.page.html",
  styleUrls: ["./camara-modal.page.scss"],
})
export class CamaraModalPage {
  private intervalRefreshImg: any;
  @Input() camara: Camara;

  constructor(
    private controlService: ControlService,
    private utilService: UtilService
  ) {}

  ionViewDidEnter() {
    this.camara.Url = this.utilService.refresh_imagen(this.camara.Url);
    this.intervalRefreshImg = setInterval(() => {
      this.camara.Url = this.utilService.refresh_imagen(this.camara.Url);
    }, INTERVAL.REFRESH);
  }

  ionViewWillLeave() {
    clearInterval(this.intervalRefreshImg);
  }

  cerrar_modal() {
    this.controlService.cerrar_modal();
  }
}
