import { Component, NgModule, OnInit, Input } from "@angular/core";
import { TicksToDatePipe } from "src/app/pipes/date/ticks-to-date.pipe";
import { Noticia } from "src/app/models/entities/entity.index";
import { ControlService } from "src/app/services/common/control.service";
import { SbcService } from "src/app/services/common/sbc.service";

@NgModule({
  providers: [TicksToDatePipe],
})
@Component({
  selector: "app-full-new",
  templateUrl: "./full-new.component.html",
  styleUrls: ["./full-new.component.scss"],
})
export class FullNewComponent implements OnInit {
  @Input("noticia") noticia: Noticia;
  icono: string;

  constructor(
    private controlService: ControlService,
    private ticksToDatePipe: TicksToDatePipe,
    private sbcService: SbcService
  ) {}

  ngOnInit() {
    this.resolver_icono();
  }

  resolver_icono() {
    this.sbcService.resolver_icono().subscribe(
      (icono: string) => {
        this.icono = icono;
      },
      (error) => {
        this.controlService.mostrar_toast_con_error(error, false);
      }
    );
  }

  closePopover() {
    this.controlService.cerrar_modal();
  }

  ticksToDate(ticks: number | Date) {
    return this.ticksToDatePipe.transform(ticks);
  }

  public set_Html_Iframe(dataHTML: String) {
    var iframe = document.getElementById("iframeID") as any;
    iframe =
      iframe.contentWindow ||
      iframe.contentDocument.document ||
      iframe.contentDocument;

    iframe.document.open();
    iframe.document.write(dataHTML);
    iframe.document.close();
  }
}
