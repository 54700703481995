import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";
import * as moment from "moment";

// Clases
import {
  AutorizadoDelLote,
  ObservacionModel,
  Observacion,
  Lote,
  ResponseApp,
  Persona,
} from "src/app/models/entities/entity.index";

// Interfaces
import { IPagination } from "src/app/models/interfaces/common/pagination.interface";

// Constantes
import { MESSAGE, FECHA, PAGINATOR } from "src/app/constants/constants.index";
import { TIEMPO_MENSAJE } from "src/app/constants/message.constant";

// Servicios
import { ObservacionService } from "src/app/services/business/observacion.service";
import { ControlService } from "src/app/services/common/control.service";
import { UtilService } from "src/app/services/common/util.service";
import { ValidationService } from "src/app/services/common/validation.service";
import { UserService } from "src/app/services/security/user.service";
import { RouteService } from "src/app/services/common/route.service";
import { AutorizadoService } from "src/app/services/business/autorizado.service";

@Component({
  selector: "app-observacion-autorizado",
  templateUrl: "./observacion-autorizado.page.html",
  styleUrls: ["./observacion-autorizado.page.scss"],
})
export class ObservacionAutorizadoPage implements OnInit {
  @Input() lote: Lote;
  @Input() autorizado: AutorizadoDelLote;
  private paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };

  mostrarObservaciones = true;
  buttonIcon = true;
  esEdicion = false;

  formGroup: FormGroup;
  modelo: ObservacionModel;
  observaciones: Observacion[] = [];

  formatoFechaControl: string = FECHA.FORMATO_VISUAL;

  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private utilService: UtilService,
    private observacionService: ObservacionService
  ) { }

  ngOnInit() {
    this.limpiar_grilla();
    this.obtener_observaciones();
    this.configurar_formulario();
  }

  private configurar_formulario(observacion?: Observacion) {
    this.inicializar_controles_formulario(observacion);

    this.formGroup = this.formBuilder.group({
      observacion: [this.modelo.Observacion, Validators.required],
      VigenciaDesde: [this.modelo.VigenciaDesde, Validators.required],
      VigenciaHasta: [this.modelo.VigenciaHasta, Validators.required],
    });
  }

  private inicializar_controles_formulario(observacion?: Observacion) {
    const fechaPublicacion = this.utilService.obtener_fecha_diferencial(
      new Date()
    );
    const fechaCierre = this.utilService.obtener_fecha_diferencial(new Date());

    this.modelo = new ObservacionModel();

    this.modelo.IdLote = this.lote.Id;

    this.modelo.Persona = this.utilService.mapear_propiedades(
      this.autorizado,
      new Persona(),
      false
    );
    this.modelo.Persona.Documento = this.autorizado.NumeroDocumento;
    this.modelo.Persona.Id = this.autorizado.IdAutorizado;

    if (!observacion) {
      this.modelo.Id = null;
      this.modelo.Observacion = "";
      this.modelo.VigenciaDesde = "";
      this.modelo.VigenciaHasta = "";
    } else {
      this.modelo.Id = observacion.Id;
      this.modelo.Observacion = observacion.Observacion;
      this.modelo.VigenciaDesde = observacion.VigenciaDesde
        ? this.utilService
          .convert_ticks_to_date(Number(observacion.VigenciaDesde))
          .toISOString()
        : null;
      this.modelo.VigenciaHasta = observacion.VigenciaHasta
        ? this.utilService
          .convert_ticks_to_date(Number(observacion.VigenciaHasta))
          .toISOString()
        : null;
    }
  }

  private obtener_observaciones(event?) {
    this.observacionService
      .obtener_observaciones(this.lote, this.autorizado, this.paginacion)
      .then((resp: Observacion[]) => {
        if (
          resp == null ||
          this.observacionService.paginacionActual.cantidad === 0
        ) {
          this.controlService.habilitar_infinite_scroll(event, false);
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_SIN_DATOS,
            TIEMPO_MENSAJE
          );
        } else {
          this.observaciones = resp;
        }
      })
      .catch((error) => {
        this.controlService.habilitar_infinite_scroll(event, false);
        this.controlService.mostrar_toast_con_error(error, false);
      })
      .finally(() => {
        //  this.mostrarMensajeSinItem = this.observaciones.length === 0;
        if (event == null) {
          this.controlService.ocultar_loading();
        }
      });
  }

  private eliminar_observaciones(observacion: Observacion) {
    this.observacionService
      .eliminar_observacion(observacion)
      .then((resp: ResponseApp) => {
        this.controlService.mostrar_toast(
          MESSAGE.INFO.MENSAJE_CAMBIOS_GUARDADOS,
          TIEMPO_MENSAJE
        );
        this.limpiar_grilla();
        this.limpiar_filtro();
      })
      .catch((error) => {
        this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
      });
  }

  eliminar_observacion(observacion: Observacion) {
    this.controlService.mostrar_alert({
      header: MESSAGE.ALERT.MENSAJE_OBSERVACION.ELIMINAR_OBSERVACION.TITULO,
      message: MESSAGE.ALERT.MENSAJE_OBSERVACION.ELIMINAR_OBSERVACION.PREGUNTA,
      backdropDismiss: false,
      buttons: [
        {
          text: MESSAGE.ALERT.MENSAJE_OBSERVACION.ELIMINAR_OBSERVACION.BUTTONS
            .CANCELAR,
          role: "cancel",
        },
        {
          text: MESSAGE.ALERT.MENSAJE_OBSERVACION.ELIMINAR_OBSERVACION.BUTTONS
            .ACEPTAR,
          handler: () => {
            this.eliminar_observaciones(observacion);
          },
        },
      ],
    });
  }

  actualizar_observacion(observacion: Observacion) {
    this.configurar_formulario(observacion);

    this.mostrarObservaciones = false;
  }

  async guardar() {
    const modelo = await this.obtener_modelo_actualizado();
    const observacion: Observacion = this.utilService.mapear_propiedades(
      modelo,
      new Observacion(),
      false
    );

    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.observacionService
        .crear_observacion(observacion)
        .then((resp: ResponseApp) => {
          this.controlService.mostrar_toast(
            MESSAGE.INFO.MENSAJE_CAMBIOS_GUARDADOS,
            TIEMPO_MENSAJE
          );

          this.formGroup.reset();
          this.limpiar_grilla();
          this.limpiar_filtro();
          this.mostrar_agregar_observacion();
        })
        .catch((error) => {
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    });
  }

  private async obtener_modelo_actualizado(): Promise<ObservacionModel> {
    //this.modelo.Observacion = this.esEdicion ? this.noticia.Numero : '';
    this.modelo.Observacion = this.formGroup.value.observacion;

    this.modelo.VigenciaDesde = this.formGroup.value.VigenciaDesde;
    if (this.modelo.VigenciaDesde != null) {
      this.modelo.VigenciaDesde = this.utilService.convertir_date_a_ticks(
        this.modelo.VigenciaDesde
      );
    }

    this.modelo.VigenciaHasta = this.formGroup.value.VigenciaHasta;
    if (this.modelo.VigenciaHasta != null) {
      this.modelo.VigenciaHasta = this.utilService.convertir_date_a_ticks(
        this.modelo.VigenciaHasta
      );
    }

    return this.modelo;
  }

  limpiar_fecha_desde() {
    (<FormControl>this.formGroup.controls["VigenciaDesde"]).setValue("");
  }

  limpiar_fecha_hasta() {
    (<FormControl>this.formGroup.controls["VigenciaHasta"]).setValue("");
  }

  limpiar_filtro() {
    this.observaciones = [];
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.obtener_observaciones();
  }

  private limpiar_grilla() {
    this.observacionService.limpiar();
  }

  mostrar_agregar_observacion() {
    this.formGroup.reset();
    this.mostrarObservaciones = !this.mostrarObservaciones;
  }

  cerrar_modal() {
    this.controlService.cerrar_modal();
  }
}
