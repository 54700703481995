import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Servicios
import { RestService } from "../common/rest.service";
import { CatalogoService } from "./catalogo.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";

// Constantes
import { PAGINATOR } from "src/app/constants/config.constant";

// Interfaces
import { IPagination } from "src/app/models/interfaces/interfaces.index";
import { IDictionary } from "src/app/models/interfaces/interfaces.index";

// Clases
import {
  Documento,
  Lote,
  ResponseApp,
} from "src/app/models/entities/entity.index";
import { ItemElement } from "src/app/models/clases/ItemElement";
import { DocumentoAF } from "src/app/models/entities/business/documento.entity";
import { SecurityService } from "../security/security.service";

@Injectable({
  providedIn: "root",
})
export class DocumentoService {
  private paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };
  private itemElement = new ItemElement<Documento>();
  private itemElementAF = new ItemElement<DocumentoAF>();

  documentos: Documento[] = [];
  documentosAF: DocumentoAF[] = [];
  paginacionActual: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private exceptionService: ExceptionService,
    private securityService: SecurityService
  ) {}

  async marcarTodoLeido(){
    const token = this.securityService.token;
    const entidad = "LoteDocumento";
    const body = {
      token,
      entidad
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(`${this.catalogoService.catalogo}MarcarTodoLeidoParaEntidad`, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  //* Obtener documentos */

  async obtener_documentos_v3(documento?, searchQuery?: string) {
    if (searchQuery) {
      return this.obtener_documentos_by_filter(searchQuery);
    } else {
      return this.obtener_documentos_v2(documento);
    }
  }

  async obtener_documentos_by_filter(searchQuery?: string) {
    const url = `${this.catalogoService.catalogo}ObtenerDocumentosCarpetasByFilter/`;
    const options: IDictionary[] = [
      { key: "{descripcion}", value: searchQuery },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Documento[] | string>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;

            this.documentos = resp.Data;
            return this.documentos;
          })
        )
        .subscribe(
          (data: Documento[] | string) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async obtener_documentos_v2(documento?) {
    const url = `${this.catalogoService.catalogo}ObtenerDocumentosByCarpeta/`;
    const options: IDictionary[] = [
      { key: "{soloVisiblesEnHome}", value: "1" },
      {
        key: "{idCarpeta}",
        value: documento && documento.Id != "" ? documento.Id : null,
      },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Documento[] | string>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;

            //const documentos = this.utilService.mapear_propiedades( resp.Data , new Documento() );
            //this.documentos = this.itemElement.refrescar( documentos, this.documentos );

            this.documentos = resp.Data;
            return this.documentos;
          })
        )
        .subscribe(
          (data: Documento[] | string) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  //*obtenerDocumentosHome */
  async obtener_documentos(paginacion: IPagination = this.paginacion) {
    const url = `${this.catalogoService.catalogo}ObtenerDocumentosHome/`;
    const options: IDictionary[] = [
      { key: "{pagina}", value: paginacion.pagina.toString() },
      { key: "{cantidad}", value: paginacion.cantidad.toString() },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Documento[] | string>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;

            const documentos = this.utilService.mapear_propiedades(
              resp.Data,
              new Documento()
            );
            this.documentos = this.itemElement.refrescar(
              documentos,
              this.documentos
            );

            return this.documentos;
          })
        )
        .subscribe(
          (data: Documento[] | string) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async obtener_documentosAF(
    lote: Lote,
    descripcion: string,
    tipo: string,
    paginacion: IPagination = this.paginacion
  ) {
    const url = `${this.catalogoService.catalogo}ObtenerDocumentosLote/`;
    const options: IDictionary[] = [
      { key: "{idLote}", value: lote.Id },
      { key: "{descripcion}", value: descripcion },
      { key: "{tipo}", value: tipo },
      { key: "{pagina}", value: paginacion.pagina.toString() },
      { key: "{cantidad}", value: paginacion.cantidad.toString() },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, DocumentoAF[] | string>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;

            const documentos = this.utilService.mapear_propiedades(
              resp.Data,
              new DocumentoAF()
            );
            this.documentosAF = this.itemElementAF.refrescar(
              documentos,
              this.documentosAF
            );

            return this.documentosAF;
          })
        )
        .subscribe(
          (data: DocumentoAF[] | string) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async marcarDocumentoLeido(idDocumento: string) {
    const url = `${this.catalogoService.catalogo}MarcarDocumentoLeido/`;
    const options: IDictionary[] = [
      { key: "{token}", value: this.securityService.token },
      { key: "{idDocumento}", value: idDocumento },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, false, false, options)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async get_documentoLote(
    catalogo: string,
    token: string,
    idLoteDocumento: string
  ) {
    const url = `${catalogo}ObtenerDocumentoLote/${token}/`;
    const options: IDictionary[] = [
      { key: "{idLoteDocumento}", value: idLoteDocumento },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, false, false, options)
        .pipe(
          map<any, Documento>((resp: any) => {
            this.exceptionService.resolver_error(resp);
            const documentoLote = this.utilService.mapear_propiedades(
              resp.Data,
              new Documento()
            );
            return documentoLote;
          })
        )
        .subscribe(
          (data: Documento) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  limpiar() {
    this.documentos = [];
    this.documentosAF = [];
  }

  limpiar_documentos() {
    this.documentos = [];
  }

  limpiar_documentos_af() {
    this.documentosAF = [];
  }
}
