import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Constantes
import { environment, STORAGE } from "src/app/constants/constants.index";

// Servicios
import { RestService } from "src/app/services/common/rest.service";
import { StorageService } from "../common/storage.service";

// Clases
import { ResponseApp } from "src/app/models/entities/entity.index";
import { SecurityService } from "../security/security.service";
import { ExceptionService } from "../common/exception.service";
import { DeviceService } from "../common/device.service";

@Injectable({
  providedIn: "root",
})
export class CatalogoService {
  catalogo: string;

  constructor(
    private restService: RestService,
    private storageService: StorageService,
    private securityService: SecurityService,
    private exceptionService: ExceptionService,
    private deviceService: DeviceService
  ) { }

  async cargar_catalogo_barrio(codigoBarrio?: string): Promise<ResponseApp> {
    return new Promise<ResponseApp>((resolve, reject) => {
      const validation = this.deviceService.isWeb ? codigoBarrio : (codigoBarrio && !this.catalogo);
      if (validation) {
        this.securityService.cargar_codigo_barrio(codigoBarrio).then(() => {
          this.resolver_catalogo_barrio(resolve, reject);
        });
      } else {
        // Si está el código de barrio en la caché, también lo estará el catálogo
        const resp = new ResponseApp();
        this.storageService
          .cargar(STORAGE.KEY.CODIGO_BARRIO)
          .then((codigo: string) => {
            if (codigo) {
              this.securityService.codigoBarrio = codigo;
              this.storageService
                .cargar(STORAGE.KEY.CATALOGO)
                .then((catalogo: string) => {
                  this.catalogo = catalogo;
                  resp.Status = true;
                  resolve(resp);
                });
            } else {
              resp.Status = false;
              resolve(resp);
            }
          });
      }
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  private async resolver_catalogo_barrio(resolve, reject) {
    const url = environment.PATH_REST.REST.HOST;
    return this.restService
      .get(url, false, true)
      .pipe(
        map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
          this.exceptionService.resolver_error(resp);
          return resp;
        })
      )
      .subscribe(
        (resp: ResponseApp) => {
          this.catalogo = environment.PATH_REST.REST.CUSTOM_URL
            ? environment.PATH_REST.REST.CATALOGO
            : resp.Data;
          resp.Status = true;
          this.storageService.guardar({
            key: STORAGE.KEY.CATALOGO,
            value: this.catalogo,
          });
          resolve(resp);
        },
        (error) => {
          reject(this.exceptionService.resolver_mensaje_excepcion(error));
          return;
        }
      );
  }
}
