import { Component, ViewChild, OnInit } from "@angular/core";
import { IonContent, IonInfiniteScroll } from "@ionic/angular";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

// Interfaces
import {
  IAdvancedSearchFilters,
  IModal,
  IPagination,
} from "src/app/models/interfaces/interfaces.index";

// Constantes
import {
  SCROLL_FAB_HEIGHT,
  ENABLED_SCROLL_EVENT,
  MODULOS,
  PAGINATOR,
} from "src/app/constants/config.constant";

// Clases
import {
  Notificacion,
  Lote,
  Item,
  Estado,
  ResponseApp,
} from "src/app/models/entities/entity.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { SbcService } from "src/app/services/common/sbc.service";
import { DeviceService } from "src/app/services/common/device.service";

// Constantes
import {
  MESSAGE,
  POPOVER_STYLES,
  TIEMPO_MENSAJE,
} from "src/app/constants/constants.index";

// Enums
import { EnumEstadoNotificacionLeido } from "src/app/models/enums/estado.enum";
import { AdvancedSearchComponent } from "src/app/components/mobile/component.mobile.index";
import { UtilService } from "src/app/services/common/util.service";
import { CorrespondenciaService } from "src/app/services/business/correspondencia.service";
import { Correspondencia } from "src/app/models/entities/business/correspondencia.entity";

@Component({
  selector: "app-correspondencia",
  templateUrl: "./correspondencia.page.html",
  styleUrls: ["./correspondencia.page.scss"],
})
export class CorrespondenciaPage implements OnInit {
  private paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  mostrarFabButton = false;
  mostrarFabButtonApartirDe = SCROLL_FAB_HEIGHT;
  habilitarEventosScroll = ENABLED_SCROLL_EVENT;
  mostrarInfiniteScrollEn = PAGINATOR.SIZE;

  mostrarFiltro = false;
  mostrarButtonLimpiar = false;
  formGroup: FormGroup;
  correspondencias: Correspondencia[] = [];
  expandidos: any[] = [];
  selectedCorrespondencias: string[] = [];

  loteSeleccionado: Lote;
  lotes: Lote[];

  estadoSeleccionado: Item;
  estados: Item[];

  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private userService: UserService,
    private correspondenciaService: CorrespondenciaService,
    private deviceService: DeviceService,
    private utilService: UtilService
  ) { }

  ngOnInit() {
    this.lotes = this.userService.user.Lotes;
    this.configurar_formulario();
    this.filtrar();
  }

  filtrar() {
    this.limpiar_filtro();
    this.setear_lote_seleccionado();
    this.resolver_vista_notificacion();
    this.mostrarButtonLimpiar = true;
  }

  resolver_vista_notificacion(event?) {
    if (event) {
      this.paginacion.pagina += 1;
      this.cargar_notificaciones(event);
    } else {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.cargar_notificaciones(event);
      });
    }
  }

  openInNewTab(url: string): void {
    this.utilService.openNewTab(url);
  }

  limpiar_filtro() {
    this.correspondencias = [];
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.correspondenciaService.limpiar();
    this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    this.mostrarButtonLimpiar = false;
  }

  informar_scroll(event) {
    if (this.deviceService.isIos) {
      return;
    }

    this.content
      .getScrollElement()
      .then(
        (el) =>
        (this.mostrarFabButton =
          el.scrollTop > this.mostrarFabButtonApartirDe)
      );
  }

  volver_al_inicio() {
    this.content.scrollToTop();
  }

  notificacion_leida(notificacion: Notificacion) {
    return notificacion.Estado === EnumEstadoNotificacionLeido.Leida;
  }

  mostrar_detalle(notificacion: Notificacion) {
    if (notificacion.Estado === EnumEstadoNotificacionLeido.NoLeida) {
      this.actualizar_notificacion_como_leida(notificacion);
    }
  }

  actualizar_notificacion_como_leida(notificacion: Notificacion) {
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.correspondenciaService
        .marcar_notificacion_como_leida(notificacion.Id)
        .then((resp: ResponseApp) => {
          notificacion.Estado = EnumEstadoNotificacionLeido.Leida;
        })
        .catch((error) => {
          this.controlService.mostrar_toast_con_error(error);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    });
  }

  toggle(exceptuando: number) {
    this.expandidos.forEach((item, index: number) => {
      if (index === exceptuando) {
        this.expandidos[index].expandido = !this.expandidos[index].expandido;
      } else {
        this.expandidos[index].expandido = false;
      }
    });
  }

  doRefresh(event) {
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.correspondenciaService.limpiar();
    this.correspondenciaService
      .obtener_correspondencia_pendientes(
        this.loteSeleccionado,
        this.paginacion
      )
      .then((resp: Correspondencia[]) => {
        this.correspondencias = resp;
        this.controlaFindePagina();
        event.target.complete();
      })
      .catch((error) => {
        event.target.complete();
      });
  }

  private cargar_notificaciones(event?) {
    this.correspondenciaService
      .obtener_correspondencia_pendientes(
        this.loteSeleccionado,
        this.paginacion
      )
      .then((resp: Correspondencia[]) => {
        if (
          resp == null ||
          this.correspondenciaService.paginacionActual.cantidad === 0
        ) {
          this.controlService.habilitar_infinite_scroll(event, false);
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_SIN_DATOS,
            TIEMPO_MENSAJE
          );
        } else {
          this.correspondencias = resp;
          this.controlService.completar_infinite_scroll(event);
          this.sincronizar_notificaciones_expandidas();
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.controlService.habilitar_infinite_scroll(event, false);
        this.controlService.mostrar_toast_con_error(error, false);
      })
      .finally(() => {
        this.controlaFindePagina();
        if (event == null) {
          this.controlService.ocultar_loading();
        }
      });
  }

  isChecked(correspondenciaId: string): boolean {
    return this.selectedCorrespondencias.includes(correspondenciaId);
  }

  public set_Html_Iframe(dataHTML: String) {
    var iframe = document.getElementById("iframeID") as any;
    iframe =
      iframe.contentWindow ||
      iframe.contentDocument.document ||
      iframe.contentDocument;

    iframe.document.open();
    iframe.document.write(dataHTML);
    iframe.document.close();
  }

  private configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      lotes: [this.lotes[0].Id, Validators.required],
    });
  }

  private setear_lote_seleccionado() {
    this.loteSeleccionado = this.lotes.filter(
      (lote: Lote) => lote.Id === this.formGroup.value.lotes
    )[0];
  }

  private sincronizar_notificaciones_expandidas() {
    this.correspondencias.forEach((notificacion: Correspondencia) => {
      this.expandidos.push({ expandido: false });
    });
  }

  open_advanced_search() {
    const componentProps: { filters: IAdvancedSearchFilters } = {
      filters: {
        showLoteFilter: true,
        showEstadoFilter: true,
        moduloEstado: MODULOS.NOTIFICACION,
      },
    };
    this.controlService
      .mostrarPopOver({
        component: AdvancedSearchComponent,
        componentProps,
        cssClass: POPOVER_STYLES.ADVANZED_SEARCH_MODAL,
        backdropDismiss: false,
      })
      .then((data) => {
        if (data) {
          this.formGroup.controls["lotes"].setValue(data.lotes);
          this.formGroup.controls["estados"].setValue(data.estados);
          this.filtrar();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //controlamos si llegó al final de la paginación para deshabilitar el Infinite Scroll
  controlaFindePagina() {
    let maxPages = Math.ceil(
      this.correspondenciaService.paginacionActual.cantidad /
      this.paginacion.cantidad
    );
    if (maxPages == this.paginacion.pagina)
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, false);
    else
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
  }

  marcarCorrespondencia(idCorrespondencia: string, value: any) {
    if (value.detail.checked === true) {
      this.selectedCorrespondencias.push(idCorrespondencia);
    } else {
      this.selectedCorrespondencias = this.selectedCorrespondencias.filter((item) => item !== idCorrespondencia);
    }
  }

  deseleccionarCorrespondencias() {
    this.selectedCorrespondencias = [];
  }

  marcarRetirados() {
    this.controlService.mostrarLoading().then(async () => {
      await this.correspondenciaService.marcarTodoLeido(this.selectedCorrespondencias);
      this.correspondencias = this.correspondencias.filter(({ Id }) => !this.selectedCorrespondencias.includes(Id));
      this.deseleccionarCorrespondencias();
      this.controlService.mostrar_toast(
        MESSAGE.INFO.MENSAJE_CORRESPONDENCIA_RETIRADA,
        TIEMPO_MENSAJE
      );
    }).finally(() => {
      this.controlService.ocultar_loading();
    });
  }
}
