import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

// Plugins
import { SplashScreen } from "@ionic-native/splash-screen/ngx";

// Constantes
import { TIEMPO_MENSAJE, PATH_URL } from "src/app/constants/constants.index";

// Clases
import { ResponseApp } from "src/app/models/entities/entity.index";

// Guards
import { AuthGuard } from "src/app/guards/auth.guard";

// Servicios
import { ConfigService } from "./config.service";
import { SecurityService } from "../security/security.service";
import { CatalogoService } from "../business/catalogo.service";
import { SbcService } from "./sbc.service";
import { ValidationService } from "./validation.service";
import { ExceptionService } from "./exception.service";
import { ControlService } from "./control.service";
import { AuthService } from "../security/auth.service";
import { UserService } from "../security/user.service";
import { StyleService } from "./style.service";

@Injectable({
  providedIn: "root",
})
export class WebService {
  constructor(
    private splashScreen: SplashScreen,
    private router: Router,
    private configService: ConfigService,
    private securityService: SecurityService,
    private catalogoService: CatalogoService,
    private sbcService: SbcService,
    private validationService: ValidationService,
    private exceptionService: ExceptionService,
    private controlService: ControlService,
    private authService: AuthService,
    private userService: UserService,
    private styleService: StyleService
  ) { }

  ready() {
    this.iniciar_app();
    this.splashScreen.hide();
  }

  private iniciar_app() {
    this.cargar_datos_barrio().then((resp: ResponseApp) => {
      if (resp.Status) {
        console.log("cargar datos app", resp);
        this.cargar_datos_app();
      } else {
        // Redirect a login
        this.redireccionar_pagina_inicial();
      }
    });
  }

  private async cargar_datos_barrio() {
    return new Promise<ResponseApp>((resolve, reject) => {
      this.configService.cargar_configuracion_app().then((config) => {
        const codigoBarrio = config.CODIGO_BARRIO;
        this.securityService.codigoBarrio = codigoBarrio;
        this.catalogoService
          .cargar_catalogo_barrio(this.securityService.codigoBarrio)
          .then((resp: ResponseApp) => {
            resolve(resp);
          })
          .catch((error) => {
            console.log(error);
            this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
          });
      });
    });
  }

  private cargar_datos_app() {
    this.sbcService
      .cargar_datos_preliminares_aplicacion()
      .then(() => {
        this.validationService
          .validar_condiciones_app()
          .then((resp: ResponseApp) => {
            // Si no tengo redirección
            if (resp.Data == null) {
              this.styleService.resolver_vista();
              this.inicializar_info();
            } else {
              this.router.navigateByUrl(resp.Data);
            }
          });
      })
      .catch((error: ResponseApp | string) => {
        const mensaje = this.exceptionService.obtener_mensaje(error);
        this.controlService.mostrar_toast(mensaje, TIEMPO_MENSAJE);
      });
  }

  private redireccionar_pagina_inicial() {
    if (
      this.securityService.contiene_codigo_barrio() &&
      this.securityService.contiene_token()
    ) {
      this.router.navigate([PATH_URL.WEB.LOGIN.DEFAULT]);
    }
  }

  private inicializar_info() {
    this.authService.estaAutenticado().then((autenticado: boolean) => {
      if (autenticado) {
        this.userService.cargar_datos_perfil();
        this.sbcService.cargar_datos_aplicacion();
        this.redireccionar_app();
      } else {
        this.redireccionar_pagina_inicial();
      }
    });
  }

  private redireccionar_app() {
    const url = AuthGuard.lastUrl ? AuthGuard.lastUrl : PATH_URL.WEB.DEFAULT;
    this.router.navigate([url]);
  }
}
