import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Routes, RouterModule } from "@angular/router";

import { IonicModule } from "@ionic/angular";

import { ObservacionAutorizadoPage } from "./observacion-autorizado.page";

// Módulos
import { ComponentsMobileModule } from "src/app/components/components-mobile.module";
import { PipesModule } from "src/app/pipes/pipes.module";

const routes: Routes = [
  {
    path: "",
    component: ObservacionAutorizadoPage,
  },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ComponentsMobileModule,
    PipesModule,
    RouterModule.forChild(routes),
  ],
  declarations: [ObservacionAutorizadoPage],
})
export class ObservacionAutorizadoPageModule {}
