import { Component } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Router } from "@angular/router";

// Plugins
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { Environment } from "@ionic-native/google-maps";

// Servicios
import { DeviceService } from "./services/common/device.service";
import { WebService } from "./services/common/web.service";
import { MobileService } from "./services/common/mobile.service";
import { SegmentService } from "./services/common/segment.service";
import { IIdentity, IModal } from "./models/interfaces/interfaces.index";
import { EnumTipoSegmento } from "./models/enums/tipo.enum";
import { ControlService } from "./services/common/control.service";
import { OnboardingPage } from "./pages/mobile/page.mobile.index";
import { UserService } from "./services/security/user.service";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  segmentosHome: IIdentity[];
  segmentosMensaje: IIdentity[];
  segmentosSeguridad: IIdentity[];
  tipoSegmento = EnumTipoSegmento;
  isWeb: boolean;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private deviceService: DeviceService,
    private webService: WebService,
    private mobileService: MobileService,
    private router: Router,
    private segmentService: SegmentService,
    private controlService: ControlService,
    private userService: UserService,
  ) {
    this.segmentosHome = this.segmentService.segmentosHome;
    this.segmentosMensaje = this.segmentService.segmentosMensaje;
    this.segmentosSeguridad = this.segmentService.segmentosSeguridad;
    this.segmentService.segmentsWereUpdated.subscribe((data) => {
      switch (data.segmentType) {
        case EnumTipoSegmento.Home:
          this.segmentosHome = data.segments;
          break;
        case EnumTipoSegmento.Mensaje:
          if (
            data.segments.some(({ id }) => id === 'ticket') &&
            (this.userService.user.Profiles.includes('Guardia') || this.userService.user.Profiles.includes('Administrativo'))) {
            data.segments.find(({ id }) => id === 'ticket').name = "Bandeja de tickets";
          }
          //console.log("menuMensajes", data.segments);
          this.segmentosMensaje = data.segments;
          break;
        case EnumTipoSegmento.Seguridad:
          this.segmentosSeguridad = data.segments;
          break;
        default:
          break;
      }
    });
    this.platform.backButton.subscribeWithPriority(9999, () => {
      // do nothing
      console.log("entró al backButton.subscribeWithPriority");
    });
    this.initializeApp();
  }

  navigateTo(segmentType: EnumTipoSegmento, option: IIdentity): void {
    if (option.id === "onboarding") {
      const modal: IModal = { component: OnboardingPage, returnData: false };
      this.controlService.mostrar_modal(modal);
    } else {
      this.router.navigate([option.path]);
      setTimeout(() => {
        this.segmentService.actualSegment.next({
          type: segmentType,
          segment: option,
        });
      }, 10);
    }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.isWeb = this.deviceService.isWeb;
      if (this.deviceService.isWeb) {
        this.webService.ready();
      } else {
        this.mobileService.ready();
        Environment.setEnv({
          // api key for server
          API_KEY_FOR_BROWSER_RELEASE: "AIzaSyDMViLroRzqrVMeaG7ecoqD7vdgRam8x3M",

          // api key for local development
          API_KEY_FOR_BROWSER_DEBUG: "AIzaSyDMViLroRzqrVMeaG7ecoqD7vdgRam8x3M",
        });
        //}
        /*this.platform.resume.subscribe(() => {
          this.mobileService.reanudar_app();
        });*/

      }

      this.statusBar.styleBlackTranslucent();
    });
  }
}
