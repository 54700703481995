import { Component, Output, EventEmitter, Input } from "@angular/core";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";

// Plugins
import { Camera, CameraOptions } from "@ionic-native/camera/ngx";
import {
  ImagePicker,
} from "@ionic-native/image-picker/ngx";
import { ControlService } from "src/app/services/common/control.service";

@Component({
  selector: "app-photoupload",
  templateUrl: "./photoupload.component.html",
  styleUrls: ["./photoupload.component.scss"],
})
export class PhotoUploadComponent {
  @Output() getFile: EventEmitter<{ file: File, base64: string }> = new EventEmitter();
  @Input() title = "sin nombre";
  imagen: string = null;

  constructor(
    private camera: Camera,
    private imagePicker: ImagePicker,
    private androidPermissions: AndroidPermissions,
    private controlService: ControlService) { }

  mostrar_camara() {
    const options: CameraOptions = {
      quality: 50,
      sourceType: this.camera.PictureSourceType.CAMERA,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true
    };

    this.camera.getPicture(options).then(
      (imageData) => {
        this.convert_base64_to_File(imageData);
      },
      (err) => {
        console.log("ERROR EN CAMARA", JSON.stringify(err));
      }
    );
  }

  seleccionar_foto() {

    const options: CameraOptions = {
      quality: 50,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      allowEdit: false,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true
    };

    this.camera.getPicture(options).then(
      (results) => {
        this.convert_base64_to_File(results);
      },
      (err) => {
        console.log("ERROR en selector", JSON.stringify(err));
      }
    );
  }

  /**
   * Convierte el recurso en base64 a objeto File.
   * Una vez que lo hace, envía el archivo
   * @param imageData Información de la imagen en base 64
   */
  private async convert_base64_to_File(imageData: string) {
    const urlBase64 = "data:image/jpeg;base64," + imageData;
    this.imagen = urlBase64;

    const archivo = await this.create_object_file_of_url(urlBase64, this.title);
    this.getFile.emit({ file: archivo, base64: imageData });
  }

  /**
   * Crea y obtiene un File Object desde la Url
   * @param url Url de donde se extraerá el recurso
   * @param fileName Nombre del archivo resultante
   */
  private async create_object_file_of_url(url: string, fileName: string): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      let blob = null;
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob"; // force the HTTP response, response-type header to be blob
      xhr.onload = () => {
        blob = xhr.response; // xhr.response is now a blob object
        const archivo: File = this.convert_blob_To_File(blob, fileName);

        resolve(archivo);
      };

      xhr.onerror = (err) => {
        reject(err);
      };

      xhr.send();
    });
  }

  /**
   * Convierte un blob en un File
   * @param blob Blob
   * @param fileName Nombre del archivo resultante
   */
  private convert_blob_To_File(blob: Blob, fileName: string): File {
    const b: any = blob;
    b.lastModifiedDate = new Date();
    b.name = fileName;

    return b as File;
  }

  public eliminarImagen() {
    this.imagen = null;
    this.getFile.emit(null);
  }
}
