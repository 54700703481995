import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Servicios
import { RestService } from "../common/rest.service";
import { CatalogoService } from "./catalogo.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";

// Clases
import { Lote } from "src/app/models/entities/entity.index";
import { IDictionary } from "src/app/models/interfaces/interfaces.index";

@Injectable({
  providedIn: "root",
})
export class LoteService {
  lotes: Lote[] = [];
  lote: Lote = null;
  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private exceptionService: ExceptionService
  ) {}

  // obtenerLotes
  async obtener_lotes(): Promise<Lote[]> {
    const url = `${this.catalogoService.catalogo}ObtenerLotes/`;
    return new Promise<Lote[]>((resolve, reject) => {
      this.restService
        .get(url, true)
        .pipe(
          map<any, Lote[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.lotes = this.utilService.mapear_propiedades(
              resp.Data,
              new Lote()
            );
            return this.lotes;
          })
        )
        .subscribe(
          (data: Lote[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  //LoteMobileByNumeroGet(string token, string numeroLote)
  public async LoteMobileByNumeroGet(numeroLote: string = null) {
    const url = `${this.catalogoService.catalogo}LoteMobileByNumeroGet/`;

    const options: IDictionary[] = [{ key: "{numeroLote}", value: numeroLote }];

    return new Promise<Lote[]>((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Lote[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.lotes = this.utilService.mapear_propiedades(
              resp.Data,
              new Lote()
            );
            return this.lotes;
          })
        )
        .subscribe(
          (data: Lote[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  limpiar() {
    this.lotes = [];
  }
}
