import {
  Directive,
  EventEmitter,
  ElementRef,
  HostListener,
  Input,
  Output,
} from "@angular/core";

// Clases
import { FileItem } from "src/app/models/entities/entity.index";

// Interfaces
import { IFileItem } from "src/app/models/interfaces/interfaces.index";

@Directive({
  selector: "[appDropFiles]",
})
export class DropFilesDirective {
  @Input() archivos: FileItem[] = [];
  @Input() maxArchivos = 10;
  @Output() refresh: EventEmitter<IFileItem> = new EventEmitter();

  constructor() {}

  @HostListener("dragover", ["$event"])
  public onDragEnter(event: any) {
    this.refresh.emit({ mouseSobre: true, archivos: this.archivos });
    this._prevenirDetener(event);
  }

  @HostListener("dragleave", ["$event"])
  public onDragLeave(event: any) {
    this.refresh.emit({ mouseSobre: false, archivos: this.archivos });
    this._prevenirDetener(event);
  }

  @HostListener("drop", ["$event"])
  public onDrop(event: any) {
    const transferencia = this._getTransferencia(event);
    if (!transferencia) {
      return;
    }

    this._extraerArchivos(transferencia.files);
    this._prevenirDetener(event);
    this.refresh.emit({ mouseSobre: false, archivos: this.archivos });
  }

  /**
   * Extiende la compatibilidad con otros navegadores.
   * Obtiene la información de los arcivos.
   * @param event Evento de quién lo emite
   */
  private _getTransferencia(event: any) {
    return event.dataTransfer
      ? event.dataTransfer
      : event.originalEvent.dataTransfer;
  }

  private _extraerArchivos(archivosLista: FileList) {
    // tslint:disable-next-line: forin
    for (const propiedad in Object.getOwnPropertyNames(archivosLista)) {
      const archivoTemporal = archivosLista[propiedad];
      if (this._archivoPuedeSerCargado(archivoTemporal)) {
        const archivoNuevo = new FileItem(archivoTemporal);
        this.archivos.push(archivoNuevo);
      }
    }

    console.log(this.archivos);
  }

  // Validaciones
  private _archivoPuedeSerCargado(archivo: File): boolean {
    if (
      !this._archivoYaFueDroppeado(archivo.name) &&
      this._esImagen(archivo.type) &&
      !this._superaLimiteMaximoCarga()
    ) {
      return true;
    } else {
      return false;
    }
  }

  private _prevenirDetener(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  private _archivoYaFueDroppeado(nombreArchivo: string): boolean {
    for (const archivo of this.archivos) {
      if (archivo.nombreArchivo.toUpperCase() === nombreArchivo.toUpperCase()) {
        console.log("El archivo " + nombreArchivo + " ya está agregado");
        return true;
      }
    }

    return false;
  }

  private _esImagen(tipoArchivo: string): boolean {
    return tipoArchivo === "" || tipoArchivo === undefined
      ? false
      : tipoArchivo.startsWith("image");
  }

  private _superaLimiteMaximoCarga() {
    return this.archivos.length >= this.maxArchivos;
  }
}
