import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";

// Servicios
import { UtilService } from "src/app/services/common/util.service";
import { SecurityService } from "src/app/services/security/security.service";

// Constantes
import { TEMPLATE } from "src/app/constants/constants.index";

// Interfaces
import { IDictionary } from "src/app/models/interfaces/common/dictionary.interface";

@Injectable({
  providedIn: "root",
})
export class RestService {
  constructor(
    private securityService: SecurityService,
    private utilService: UtilService,
    private http: HttpClient
  ) {}

  get(
    uri: string,
    withToken?: boolean,
    withBarrio?: boolean,
    options?: IDictionary[],
    headers?: IDictionary[]
  ) {
    options = this.resolver_barrio(withBarrio, options, true);
    options = this.resolver_token(withToken, options, true);

    const templates = this.convert_to_templates(options);
    uri = this.utilService.agregar_template(uri, templates);

    const headersList: HttpHeaders = this.resolver_header(headers);

    uri = this.utilService.interpolar(uri, options);

    return this.http.get(uri, { headers: headersList });
  }

  post(
    uri: string,
    body?: any,
    params?: IDictionary[],
    withToken?: boolean,
    withBarrio?: boolean,
    options?: IDictionary[],
    headers?: IDictionary[]
  ) {
    options = this.resolver_barrio(withBarrio, options, true);
    options = this.resolver_token(withToken, options, true);

    const templates = this.convert_to_templates(options);
    uri = this.utilService.agregar_template(uri, templates);

    const headersList: HttpHeaders = this.resolver_header(headers);
    const paramsList: HttpParams = this.resolver_params(params);

    uri = this.utilService.interpolar(uri, options);

    return this.http.post(uri, body, {
      headers: headersList,
      params: paramsList,
    });
  }

  put(
    uri: string,
    body?: any,
    params?: IDictionary[],
    withToken?: boolean,
    withBarrio?: boolean,
    options?: IDictionary[],
    headers?: IDictionary[]
  ) {
    options = this.resolver_barrio(withBarrio, options, true);
    options = this.resolver_token(withToken, options, true);

    const templates = this.convert_to_templates(options);
    uri = this.utilService.agregar_template(uri, templates);

    const headersList: HttpHeaders = this.resolver_header(headers);
    const paramsList: HttpParams = this.resolver_params(params);

    uri = this.utilService.interpolar(uri, options);

    return this.http.put(uri, body, {
      headers: headersList,
      params: paramsList,
    });
  }

  delete(
    uri: string,
    withToken?: boolean,
    withBarrio?: boolean,
    options?: IDictionary[],
    headers?: IDictionary[]
  ) {
    options = this.resolver_barrio(withBarrio, options, true);
    options = this.resolver_token(withToken, options, true);

    const templates = this.convert_to_templates(options);
    uri = this.utilService.agregar_template(uri, templates);

    const headersList: HttpHeaders = this.resolver_header(headers);

    uri = this.utilService.interpolar(uri, options);

    return this.http.delete(uri, { headers: headersList });
  }

  //**---------------------------------------------------> MÉTODOS PRIVADOS  */

  private resolver_header(headersItem: IDictionary[]): HttpHeaders {
    if (headersItem == null) {
      return new HttpHeaders();
    }

    let headers = new HttpHeaders();
    headersItem.forEach(
      (header: IDictionary) =>
        (headers = headers.append(header.key, header.value))
    );

    return headers;
  }

  private resolver_params(paramsItem: IDictionary[]): HttpParams {
    if (paramsItem == null) {
      return new HttpParams();
    }

    const params = new HttpParams();
    paramsItem.forEach((param: IDictionary) =>
      params.set(param.key, param.value)
    );

    return params;
  }

  private resolver_token(
    withToken?: boolean,
    options?: IDictionary[],
    insertarPrimero: boolean = false
  ): IDictionary[] {
    if (withToken && withToken.valueOf()) {
      if (options == null) {
        options = [];
      }

      if (insertarPrimero) {
        options.unshift({
          key: TEMPLATE.TOKEN,
          value: this.securityService.token,
        });
      } else {
        options.push({
          key: TEMPLATE.TOKEN,
          value: this.securityService.token,
        });
      }
    }

    return options;
  }

  private resolver_barrio(
    withBarrio?: boolean,
    options?: IDictionary[],
    insertarPrimero: boolean = false
  ): IDictionary[] {
    if (withBarrio && withBarrio.valueOf()) {
      if (options == null) {
        options = [];
      }

      if (insertarPrimero) {
        options.unshift({
          key: TEMPLATE.CODIGO_BARRIO,
          value: this.securityService.codigoBarrio,
        });
      } else {
        options.push({
          key: TEMPLATE.CODIGO_BARRIO,
          value: this.securityService.codigoBarrio,
        });
      }
    }

    return options;
  }

  private convert_to_templates(options: IDictionary[]): string[] {
    if (options && options.length > 0) {
      return options.map((option: IDictionary) => option.key);
    }

    return null;
  }
}
