import { TIViajeEstado } from "../../enums/estado.enum";

export class ReservaDestino {
  Id: string = null;
  Descripcion: string = null;
  Instalaciones: ReservaDestinoInstalacion[] = [];
}

export class ReservaDestinoInstalacion {
  Id: string = null;
  Descripcion: string = null;
}

class Luminaria {
  Descripcion: string;
  Id: string;
  PermiteApagar: boolean;
  PermiteEncender: boolean;
}

export class ReservaTurno {
  Id: string = null;
  Descripcion: string = null;
  Fecha: string = null;
  Desde: string = null;
  Hasta: string = null;
  DesdeDouble: number = null;
  HastaDouble: number = null;
  Disponible: number = 1;
  IdReservaDestinoMobile: string = null;
  IdReservaDestinoInstalacionMobile: string = null;
  InstalacionDescripcion: string = null;
  CupoTotal: number = 1;
  CupoDisponible: number = 1;
  Cupo: number = 1;
  MensajeInformativo: string = null;
  Luminaria: Luminaria = null;
}

export class ReservaCamioneta {
  DiaNombre: string = null;
  Fecha: string = null;
  HoraDesde: string = null;
  HoraHasta: string = null;
  Numero: string = null;
  Parada: Parada = null;
  ViajeEstado: TIViajeEstado = null;
  Personas: Persona[] = [];
  Pasajeros: Persona[] = [];
  Recorrido: string = null;
  LlegaEn?: string = null;
}

export class Persona {
  Id: string;
  Apellido: string;
  Documento: string;
  Estado: number;
  Lote: string;
  Nombre: string;
  Selected?: boolean;
}


export class Parada {
  Id: string;
  Descripcion: string;
  Geoposicion: string;
}

export class TransporteViajeIniciado extends Parada {
  GeoposicionActual: string;
  Dominio: string;
  UltimaParadaRecorrida: {
    Descripcion: string,
    Fecha: string,
    Hora: string
  }
}


export class Horario {
  Disponible: boolean;
  HoraDesde: string;
  HoraHasta: string;
  IdRecorridoParada: string;
  Parada: string;
  PlazasDisponibles: number;
}