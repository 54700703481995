import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ResponseApp } from 'src/app/models/entities/entity.index';
import { IDictionary } from 'src/app/models/interfaces/interfaces.index';
import { CatalogoService } from 'src/app/services/business/catalogo.service';
import { ExceptionService } from 'src/app/services/common/exception.service';
import { RestService } from 'src/app/services/common/rest.service';
import { SecurityService } from 'src/app/services/security/security.service';

@Injectable({
  providedIn: 'root'
})
export class ComoLlegoService {

  constructor(
    private restService: RestService,
    private catalogoService: CatalogoService,
    private exceptionService: ExceptionService,
    private securityService: SecurityService
  ) { }


  async comollego(
    data: {
      latitud: number,
      longitud: number,
      lote: string
    }
  ): Promise<string>{
    const url = `${this.catalogoService.catalogo}ObtenerMobileNavigationLinkLote/`;
    const options: IDictionary[] = [
      { key: "{token}", value: this.securityService.token },
      { key: "{origenLatitud}", value: data.latitud.toString() },
      {key: "{origenLongitud}", value: data.longitud.toString()},
      {key: "{destinoLote}", value: data.lote}
    ];

    return new Promise<string>((resolve, reject) => {
      this.restService
        .get(url, false, false, options)
        .pipe(
          map<string, string>((resp: any) => {
            this.exceptionService.resolver_error(resp);
            return resp.Data;
          })
        )
        .subscribe(
          (resp: string) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }
}
