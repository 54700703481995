import { Component, Input, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { FECHA, RANGO_FECHA } from "src/app/constants/config.constant";
import {
  InvitacionDetalleModel,
  InvitacionModel,
} from "src/app/models/entities/common/form.entity";
import { Lote, ResponseApp } from "src/app/models/entities/entity.index";
import { ControlService } from "src/app/services/common/control.service";
import { UtilService } from "src/app/services/common/util.service";
import { ValidationService } from "src/app/services/common/validation.service";
import { UserService } from "src/app/services/security/user.service";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
import { MESSAGE, TIEMPO_MENSAJE } from "src/app/constants/message.constant";
import { InvitacionService } from "../../../../../services/business/invitacion.service";
import { Invitacion } from "src/app/models/entities/business/invitacion.entity";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-agregar-invitacion",
  templateUrl: "./agregar-invitacion.page.html",
  styleUrls: ["./agregar-invitacion.page.scss"],
})
export class AgregarInvitacionPage implements OnInit {
  @Input("isForNautica") isForNautica = false;
  anioMin: number = RANGO_FECHA.ACCESO.NUEVO.ANIO_MINIMO;
  anioMax: number = RANGO_FECHA.ACCESO.NUEVO.ANIO_MAXIMO;
  formatoFechaControl: string = FECHA.FORMATO_VISUAL;
  formGroup: FormGroup;
  lotes: Lote[];
  lote: Lote;
  modelo: InvitacionDetalleModel;

  constructor(
    private controlService: ControlService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private utilService: UtilService,
    private validationService: ValidationService,
    private socialSharing: SocialSharing,
    private invitacionService: InvitacionService,
    private datepipe: DatePipe
  ) { }

  ngOnInit() {
    this.lotes = this.userService.user.Lotes;
    this.lote = this.lotes[0];
    this.configurar_formulario();
  }

  cerrar_modal() {
    this.controlService.cerrar_modal();
  }

  enviar_Invitacion() {
    this.cargar_valores_del_formulario_al_modelo();
    this.validar()
      .then((result: boolean) => {
        if (result) {
          this.socialShare();
        } else {
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_VALIDACIONES_INCORRECTAS,
            TIEMPO_MENSAJE
          );
        }
      })
      .catch((error) => {
        this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
      });
  }
  private cargar_valores_del_formulario_al_modelo() {
    this.modelo.Lote = this.formGroup.value.lote;
    this.modelo.FechaDesde = this.datepipe.transform(
      this.formGroup.value.desde,
      "yyyyMMdd"
    ); //this.utilService.convertir_date_a_ticks( this.formGroup.value.desde );
    this.modelo.FechaHasta = this.datepipe.transform(
      this.formGroup.value.hasta,
      "yyyyMMdd"
    );
    this.modelo.Comentario = this.formGroup.value.comentario;
  }
  private configurar_formulario(resetValues = true) {
    if (resetValues) {
      this.inicializar_controles_formulario();
    }

    this.formGroup = this.formBuilder.group({
      lote: [this.modelo.Lote, Validators.required],
      desde: [this.modelo.FechaDesde],
      hasta: [this.modelo.FechaHasta],
      comentario: [this.modelo.Comentario],
    });

    this.formGroup.controls["desde"].setValidators([Validators.required]);
    this.formGroup.controls["hasta"].setValidators([
      Validators.required,
      this.validar_rango_fecha.bind(this.formGroup, this.validationService),
    ]);
  }

  private inicializar_controles_formulario() {
    const fechaDesde = this.utilService.obtener_fecha_diferencial(
      new Date(),
      RANGO_FECHA.ACCESO.NUEVO.DESCUENTO_HORAS_DESDE
    );
    const fechaHasta = this.utilService.obtener_fecha_diferencial(
      new Date(),
      RANGO_FECHA.ACCESO.NUEVO.DESCUENTO_HORAS_HASTA
    );
    this.modelo = new InvitacionModel();
    this.modelo.Lote = this.lote.Id;
    this.modelo.FechaDesde = fechaDesde;
    this.modelo.FechaHasta = fechaHasta;
  }

  private validar_rango_fecha(
    validationService: ValidationService,
    control: FormControl
  ): { [s: string]: boolean } {
    const form: any = this;
    const fechaDesde = form.controls["desde"];
    const fechaHasta = form.controls["hasta"];

    if (
      !validationService.rango_fecha_correcto(
        fechaDesde.value,
        fechaHasta.value
      )
    ) {
      return { range: true };
    }

    return null;
  }

  deshabilitarEnviar() {
    const result = this.formGroup.invalid;
    return result;
  }
  limpiar_fecha_desde() {
    (this.formGroup.controls["desde"] as FormControl).setValue("");
  }

  limpiar_fecha_hasta() {
    (this.formGroup.controls["hasta"] as FormControl).setValue("");
  }
  limpiar_datos_principales(evento) {
    this.modelo.Lote = this.setear_lote_seleccionado().Id;
    this.configurar_formulario(false);
  }
  setear_lote_seleccionado(): Lote {
    this.lote = this.lotes.filter(
      (lote: Lote) => lote.Id === this.formGroup.value.lote
    )[0];

    return this.lote;
  }

  async socialShare() {
    const invitacion: Invitacion = this.mapear_invitacion();
    const lote = new Lote();
    let creaNuevaInvitacion: boolean = true;
    lote.Id = this.modelo.Lote;

    while (creaNuevaInvitacion) {
      try {
        await this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT);
        const result = (await this.invitacionService.CrearInvitacion(
          invitacion,
          lote,
          this.isForNautica
        )) as ResponseApp;
        this.controlService.ocultar_loading();

        const shared = await this.socialSharing.share(
          this.armarTextoMensage(invitacion, result.Data)
        );
        if (shared) {
          creaNuevaInvitacion = await this.preguntaNuevaInvitacion();
        } else {
          creaNuevaInvitacion = false;
        }
      } catch (error) {
        console.log(error);
        this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
        creaNuevaInvitacion = false;
      } finally {
        this.controlService.ocultar_loading();
      }
    }
  }
  async preguntaNuevaInvitacion() {
    let result: boolean = false;
    await this.controlService.mostrar_alert({
      header: MESSAGE.ALERT.MENSAJE_INVITACION.NUEVO_ENVIO.TITULO,
      message: MESSAGE.ALERT.MENSAJE_INVITACION.NUEVO_ENVIO.PREGUNTA,
      backdropDismiss: false,
      returnData: true,
      buttons: [
        {
          text: MESSAGE.ALERT.MENSAJE_INVITACION.NUEVO_ENVIO.BUTTONS.CANCELAR,
          role: "cancel",
          handler: () => {
            result = false;
          },
        },
        {
          text: MESSAGE.ALERT.MENSAJE_INVITACION.NUEVO_ENVIO.BUTTONS.ACEPTAR,
          handler: () => {
            result = true;
          },
        },
      ],
    });
    return result;
  }
  private armarTextoMensage(invitacion: Invitacion, url: string): string {
    const comentario =
      this.formGroup.value.comentario &&
        this.formGroup.value.comentario.trim() != ""
        ? `
Comentario: ${this.formGroup.value.comentario}.`
        : "";
    const message = `${this.userService.user.Apellido} ${this.userService.user.Nombre
      }, le ha enviado una invitación para el día ${this.datepipe.transform(
        this.formGroup.value.desde,
        "dd/MM/yyyy"
      )}.${comentario}

Por favor complete sus datos ingresando a ${url}. Luego el sistema le enviará via email un QR para presentar en la guardia.`;
    return message;
  }
  private mapear_invitacion(): Invitacion {
    const invitacion = new Invitacion();

    invitacion.FechaDesde = this.modelo.FechaDesde;
    invitacion.FechaHasta = this.modelo.FechaHasta;
    invitacion.Comentario = this.modelo.Comentario;

    return invitacion;
  }
  private validar() {
    const promesa = new Promise((resolve, reject) => {
      const fechaDesde = this.formGroup.value.desde;
      const fechaHasta = this.formGroup.value.hasta;

      // Valido que los campos fechas tengas algún valor
      if (fechaDesde !== "" && fechaHasta !== "") {
        // Valido rango de fechas
        if (
          this.validationService.rango_fecha_correcto(fechaDesde, fechaHasta)
        ) {
          resolve(true);
          return;
        }
      }

      this.controlService.mostrar_toast(
        MESSAGE.ERROR.MENSAJE_ERROR_RANGO_FECHAS,
        TIEMPO_MENSAJE
      );
      resolve(false);
    });

    return promesa;
  }
}
