export class ItemElement<T> {
  //** Mantiene actualizado con los últimos cambios del array ItemsCargados al itemsNuevos */
  refrescar(
    itemsNuevos: T[],
    itemsCargados: T[],
    identificador: string = "Id"
  ): T[] {
    if (itemsNuevos == null || itemsNuevos.length === 0) {
      return itemsCargados;
    }

    if (itemsCargados.length === 0) {
      itemsCargados = itemsNuevos;
      return itemsCargados;
    }

    const itemsAux = [...itemsCargados];
    itemsNuevos.forEach((itemNuevo: any) => {
      let agregar = true;
      itemsAux.filter((item: any, index: number) => {
        if (itemNuevo[identificador] === item[identificador]) {
          itemsCargados[index] = itemNuevo;
          agregar = false;
          return false;
        }
      });

      if (agregar) {
        itemsCargados.push(itemNuevo);
      }
    });

    return itemsCargados;
  }
}
