import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

// Clases
import {
  Ticket,
  ResponseApp,
  Bandeja,
  FileItem,
  Usuario,
} from "src/app/models/entities/entity.index";

// Servicios
import { TicketService } from "src/app/services/business/ticket.service";
import { ControlService } from "src/app/services/common/control.service";

// Enums
import {
  EnumEstadoTicket,
  EnumEstadoLeido,
} from "src/app/models/enums/estado.enum";

// Constantes
import {
  MESSAGE,
  TIEMPO_MENSAJE,
  ALERT,
} from "src/app/constants/constants.index";
import { NavParams } from "@ionic/angular";
import { FileService } from "src/app/services/common/file.service";
import { UserService } from "src/app/services/security/user.service";

@Component({
  selector: "app-detalle-ticket",
  templateUrl: "./detalle.page.html",
  styleUrls: ["./detalle.page.scss"],
})
export class DetalleTicketPage implements OnInit {
  @Input() ticketInput: Ticket;
  @Input() bandejas: Bandeja[];
  @Input() bandejaTicketService: any;
  @Input() user: Usuario;
  puedeEditar = true;
  formGroup: FormGroup;
  ticket: Ticket;
  esGuardia = false;
  esAdministrativo = false;
  enumEstadoTicket = EnumEstadoTicket;
  estados = [
    { Id: 0, Description: "En Tratamiento" },
    { Id: 1, Description: "Cerrado" },
    { Id: 2, Description: "Cancelado" }
  ];
  imagen = "";

  constructor(
    private formBuilder: FormBuilder,
    private ticketService: TicketService,
    private controlService: ControlService,
    private navParams: NavParams,
    private fileService: FileService
  ) {
  }

  ngOnInit(): void {
    this.ticket = this.navParams.get("ticketInput");
    console.log("ticket recibido", this.ticket);
    this.puedeEditar = this.ticket.Estado !== EnumEstadoTicket.Cerrado;
    // TODO: Se debe consulta por el estado Leído
    if (this.ticket.Leido === EnumEstadoLeido.NoLeida) {
      this.actualizar_ticket_como_leido();
    }
    this.configurar_formulario();
  }

  ionViewWillEnter() {
    if (this.user) {
      this.esGuardia = this.user.Profiles.includes('Guardia');
      this.esAdministrativo = this.user.Profiles.includes('Administrativo');
    }
  }

  async guardar() {
    let comentario = this.formGroup.value.comentario;
    let estado = this.formGroup.value.estadoSeleccionado;
    let bandeja = this.formGroup.value.bandejaSeleccionada;

    if (comentario !== "") {
      if (this.user && this.user.Profiles.includes('Habitante')) {
        this.formGroup.controls["comentarioPrivado"].setValue(false);
      }
      await this.agregar_comentario();
    }

    if (
      estado != this.ticket.Estado ||
      (bandeja && bandeja != this.ticket.IdBandeja)
    ) {
      await this.actualizar_ticket();
    }

    this.controlService.mostrar_alert({
      header: "Ticket actualizado",
      message: MESSAGE.INFO.MENSAJE_TICKET.COMENTARIO_GUARDADO.MENSAJE,
      backdropDismiss: false,
      buttons: [
        {
          text: ALERT.BUTTONS.ACEPTAR,
          handler: () => {
            this.controlService.cerrar_modal();
          },
        },
      ],
    });
  }

  mostrar_alert(titulo: string, mensaje: string) {
    this.controlService.mostrar_alert({
      header: titulo,
      message: mensaje,
      backdropDismiss: false,
      buttons: [
        {
          text: ALERT.BUTTONS.ACEPTAR,
        },
      ],
    });
  }

  agregar_comentario() {
    if (this.formGroup.valid) {
      const comentarioPrivado = this.formGroup.value.comentarioPrivado === "true";
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.ticketService
          .agregar_comentario(
            this.ticket,
            this.formGroup.value.comentario,
            comentarioPrivado,
            this.imagen
          )
          .then((resp: ResponseApp) => {
            this.controlService.mostrar_alert({
              header: MESSAGE.INFO.MENSAJE_TICKET.COMENTARIO_GUARDADO.TITULO,
              message: MESSAGE.INFO.MENSAJE_TICKET.COMENTARIO_GUARDADO.MENSAJE,
              backdropDismiss: false,
              buttons: [
                {
                  text: ALERT.BUTTONS.ACEPTAR,
                  handler: () => {
                    this.controlService.cerrar_modal();
                  },
                },
              ],
            });
          })
          .catch((error) => {
            this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
          })
          .finally(() => {
            this.controlService.ocultar_loading();
          });
      });
    }
  }

  async actualizar_ticket() {
    let estado = this.formGroup.value.estadoSeleccionado;
    let bandeja = this.formGroup.value.bandejaSeleccionada;

    await this.bandejaTicketService.TicketMobileUpdate(this.ticket, bandeja, estado);
  }

  cerrar_modal() {
    this.controlService.cerrar_modal(this.ticket);
  }

  actualizar_ticket_como_leido() {
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.ticketService
        .marcar_ticket_como_leido(this.ticket.Id)
        .then((resp: ResponseApp) => {
          this.ticket.Leido = EnumEstadoLeido.Leida;
        })
        .catch((error) => {
          this.controlService.mostrar_toast_con_error(error);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    });
  }

  private configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      comentario: [""],
      estadoSeleccionado: [this.ticket ? this.ticket.Estado : ""],
      bandejaSeleccionada: [this.ticket && this.ticket.IdBandeja ? this.ticket.IdBandeja : ""],
      comentarioPrivado: ["false"],
    });
  }

  async sincronizar_imagen(file: { file: File, base64: string } | null) {
    this.imagen = file !== null ? file.base64 : null;
  }
}
