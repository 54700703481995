export const MESSAGE = {
  INFO: {
    MENSAJE_CAMBIOS_GUARDADOS: "Se guardaron los cambios.",
    MENSAJE_MASCOTA: {
      MASCOTA_GUARDADA: {
        TITULO: "Datos de la mascota",
        MENSAJE: "Se guardaron correctamente"
      },
      MASCOTA_NO_GUARDADA: {
        TITULO: '¡Atención!',
      }
    },
    MENSAJE_TICKET: {
      COMENTARIO_GUARDADO: {
        TITULO: "Comentario enviado",
        MENSAJE: "Se guardaron los cambios.",
      },
      TICKET_ACTUALIZADO: {
        TITULO: "Ticket actualizado",
        MENSAJE: "El ticket se actualizó correctamente.",
      },
      TICKET_GUARDADO: {
        TITULO: "Ticket enviado",
      },
      TICKET_NO_GUARDADO: {
        TITULO: "Error en Ticket",
      },
      OK: "OK",
    },
    MENSAJE_MULTA: {
      COMENTARIO_GUARDADO: {
        TITULO: "Descargo recibido",
        MENSAJE: "Gracias por haber hecho su descargo",
      },
    },
    MENSAJE_APP_BLOQUEADA: {
      MENSAJE:
        "Por el momento no se puede operar con la app. Por favor, inténtelo más tarde.",
    },
    MENSAJE_USUARIO_SIN_LOTE:
      "El usuario no puede ingresar ya que no contiene lotes asociados a él.",
    MENSAJE_CORRESPONDENCIA_RETIRADA: "Se marcaron las correspondencias como retiradas.",
  },
  ALERT: {
    MENSAJE_LOGIN: "Su sesión expiró. Ingrese nuevamente.",
    MENSAJE_PERFIL: "No tiene permisos suficientes para ingresar a la sección.",
    MENSAJE_AUTORIZADO_DUPLICADO:
      "Ya existe un autorizado con ese mismo número de documento.",
    MENSAJE_SIN_DATOS: "No se encontraron datos.",
    MENSAJE_TIEMPO_EXPIRADO:
      "Expiró el tiempo permitido para responder la solicitud.",
    MENSAJE_APP_DESACTUALIZADA:
      "Para continuar usando la aplicación debe actualizarla.",
    MENSAJE_RESPUESTAS_MAXIMAS: `Seleccione menos respuestas para: '{0}', el máximo permitido es: {1}`,
    MENSAJE_VALIDACIONES_INCORRECTAS:
      "Debe completar correctamente el formulario",
    MENSAJE_NOTICIA: {
      ELIMINAR_NOTICIA: {
        TITULO: "Confirmación",
        PREGUNTA: "¿Está seguro que desea eliminar esta publicación?",
        BUTTONS: {
          ACEPTAR: "SÍ",
          CANCELAR: "NO",
        },
      },
    },
    MENSAJE_RESERVA_CAMIONETA: {
      ELIMINAR_RESERVA: {
        TITULO: "Confirmación",
        PREGUNTA: "¿Está seguro que desea eliminar esta reserva?",
        BUTTONS: {
          ACEPTAR: "SÍ",
          CANCELAR: "NO",
        },
      },
    },
    MENSAJE_LOGISTICA: {
      HABILITAR_EGRESO: {
        TITULO: "Confirmación",
        PREGUNTA: "¿Está seguro que desea confirmar el egreso?",
        BUTTONS: {
          ACEPTAR: "SÍ",
          CANCELAR: "NO",
        },
      },
    },
    MENSAJE_AUTORIZADO: {
      ELIMINAR_AUTORIZACION: {
        TITULO: "Confirmación",
        PREGUNTA: "¿Está seguro que desea eliminar esta autorización?",
        BUTTONS: {
          ACEPTAR: "Eliminar",
          CANCELAR: "Cancelar",
        },
      },
      AUTORIZAR_SOLICITUD: {
        MENSAJE: "Solicitud aceptada",
      },
      RECHAZAR_SOLICITUD: {
        MENSAJE: "Solicitud rechazada",
      },
    },
    MENSAJE_OBSERVACION: {
      ELIMINAR_OBSERVACION: {
        TITULO: "Confirmación",
        PREGUNTA: "¿Está seguro que desea eliminar esta observación?",
        BUTTONS: {
          ACEPTAR: "Eliminar",
          CANCELAR: "Cancelar",
        },
      },
    },
    MENSAJE_PREREQUISITOS: {
      DATOS_ACTUALIZADOS: {
        TITULO: "Prerequisitos",
        MENSAJE: "Datos actualizados correctamente",
      },
    },
    MENSAJE_RESERVA: {
      CANCELAR_RESERVA: {
        TITULO: "Confirmación",
        PREGUNTA: "¿Está seguro que desea cancelar esta reserva?",
        BUTTONS: {
          ACEPTAR: "OK",
          CANCELAR: "Cancelar",
        },
      },
      INFORMATIVO: {
        TITULO: "Atención",
        BUTTONS: {
          ACEPTAR: "OK",
        },
      },
    },
    MENSAJE_AUTORIZACION: {
      INFORMATIVO: {
        TITULO: "Recordatorio:",
        MENSAJE: "Esta por modificar los datos de un autorizado vigente.",
        BUTTONS: {
          ACEPTAR: "OK",
        },
      },
      CONFIRMA_MODIFICACION: {
        TITULO: "Confirmación",
        PREGUNTA: "¿Está seguro que desea modificar la autorización?",
        BUTTONS: {
          ACEPTAR: "OK",
          CANCELAR: "Cancelar",
        },
      },
    },
    PERMISOS: {
      TITULO: "Solicitud de permisos",
      MENSAJE:
        "Para poder usar SBC es necesario que acepte los permisos solicitados. Si quiere saber más acerca de nuestras políticas de privacidad puede consultarlas haciendo click en el siguiente botón.",
    },
    MENSAJE_INVITACION: {
      NUEVO_ENVIO: {
        TITULO: "Invitación enviada con éxito",
        PREGUNTA: "¿Desea enviar la invitación a un nuevo contacto?",
        BUTTONS: {
          ACEPTAR: "Si",
          CANCELAR: "No",
        },
      },
      CANCELAR_INVITACION: {
        TITULO: "Cancelación",
        PREGUNTA: "¿Está seguro que desea cancelar esta invitación?",
        BUTTONS: {
          ACEPTAR: "OK",
          CANCELAR: "Cancelar",
        },
      },
      APROBAR_INVITACION: {
        TITULO: "Aprobación",
        PREGUNTA: "¿Está seguro que desea aprobar esta invitación?",
        BUTTONS: {
          ACEPTAR: "OK",
          CANCELAR: "Cancelar",
        },
      },
    },
    PERFIL: {
      DATOS_INCOMPLETOS: {
        TITULO: "Datos incompletos",
        MENSAJE: "Faltan completar datos obligatorios.",
      },
      PERMISOS: {
        TITULO: "Solicitud de permisos",
        MENSAJE:
          "Para poder usar SBC es necesario que acepte los permisos solicitados. Si quiere saber más acerca de nuestras políticas de privacidad puede consultarlas haciendo click en el siguiente botón.",
      },
      FOTO_NO_HABILITADA: {
        TITULO: "Foto Perfil",
        MENSAJE:
          "No es posible modificar la foto de perfil. Póngase en contacto con la administración para su habilitación.",
      },
      MENSAJE_PERFIL_UFACE_OK: {
        TITULO: "Foto Perfil",
        MENSAJE:
          "Su foto es válida para utilizar el ingreso mediante Reconocimiento Facial.",
      },
      MENSAJE_PERFIL_UFACE_ERROR: {
        TITULO: "Foto Perfil",
      },
      MENSAJE_PERFIL_SIN_UFACE: {
        TITULO: "Foto Perfil",
        MENSAJE: `Recuerde que esta foto no será utilizada para Reconocimiento Facial.
                En caso que desee habilitarla, por favor comuníquese con la administración.`,
      },
      OK: "Ok",
    },
  },
  ERROR: {
    MENSAJE_ERROR_SERVIDOR: "Se produjo un error accediendo a sus datos.",
    MENSAJE_ERROR_RANGO_FECHAS: "Verifique el rango de fechas.",
    MENSAJE_ERROR_CATALOGO: "Código de barrio incorrecto.",
    MENSAJE_ERROR_DISENIO: "Error al obtenter el diseño.",
    MENSAJE_ERROR_MENU: "Error al obtenter el menú.",
    MENSAJE_ERROR_LLAMADA: "Error al intentar llamar",
  },
  LOADING: {
    DEFAULT: "Cargando...",
    VALIDANDO_IMAGE: "Validando imagen...",
    SOLICITANDO_PERMISOS: "Solicitando permisos...",
  },
  RECOVERY_PASSWORD: {
    ENVIANDO: "Enviando...",
    OK: "La solicitud de cambio de contraseña ha sido enviada exitosamente",
    ERROR: "Los datos indicados no son correctos para recuperar su contraseña",
  },
};

export const TIEMPO_MENSAJE = 3000;
