import { Component, Input } from "@angular/core";
import { DatePipe } from "@angular/common";

// Services
import { ControlService } from "src/app/services/common/control.service";

// Constantes
import { MESSAGE, TIEMPO_MENSAJE } from "src/app/constants/constants.index";
import { IIdentity } from "src/app/models/interfaces/interfaces.index";
import { SegmentService } from "src/app/services/common/segment.service";
import { Noticia, ResponseApp } from "src/app/models/entities/entity.index";
import { ActivatedRoute } from "@angular/router";
import { TicksToDatePipe } from "src/app/pipes/pipes.index";
import { UtilService } from "src/app/services/common/util.service";
import { NoticiaService } from "src/app/services/business/noticia.service";
import { EnumTipoSegmento } from "src/app/models/enums/tipo.enum";
import { EnumEstadoLeido } from "src/app/models/enums/estado.enum";

@Component({
  selector: "app-detalleNoticia",
  templateUrl: "./detalleNoticia.page.html",
  styleUrls: ["./detalleNoticia.page.scss"],
  providers: [DatePipe],
})
export class DetalleNoticiaPage {
  @Input("noticia") noticia: Noticia;
  @Input("isNotification") isNotification: boolean = false;
  icono: string;
  showHTMLContentNotification = false;
  segmentos: IIdentity[] = [];

  constructor(
    private controlService: ControlService,
    private segmentService: SegmentService,
    private route: ActivatedRoute,
    private ticksToDatePipe: TicksToDatePipe,
    private utilService: UtilService,
    private noticiaService: NoticiaService
  ) {}

  ionViewWillEnter() {
    if (!this.isNotification) {
      this.route.queryParams.subscribe((params) => {
        if (params["noticia"]) {
          this.noticia = JSON.parse(params["noticia"]);
          if (!this.noticia.Leida) {
            this.actualizar_publicacion_como_leido();
          }
        }

        if (params["icono"]) {
          this.icono = JSON.parse(params["icono"]);
        }
      });
    }
  }

  actualizar_publicacion_como_leido() {
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.noticiaService
        .marcarPublicacionLeida(this.noticia.Id)
        .then((resp: ResponseApp) => {
          this.noticia.Leida = EnumEstadoLeido.Leida;
        })
        .catch((error) => {
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    });
  }

  backToNewsList() {
    if (this.isNotification) {
      this.controlService.cerrar_modal();
    } else {
      const segment = this.segmentService.segmentosHome.find(
        ({ id }) => id === "noticias"
      );
      this.segmentService.actualSegment.next({
        type: EnumTipoSegmento.Home,
        segment,
      });
    }
  }

  ticksToDate(ticks: number | Date) {
    return this.ticksToDatePipe.transform(ticks);
  }

  public set_Html_Iframe(dataHTML: String) {
    var iframe = document.getElementById("iframeID") as any;
    iframe =
      iframe.contentWindow ||
      iframe.contentDocument.document ||
      iframe.contentDocument;

    iframe.document.open();
    iframe.document.write(dataHTML);
    iframe.document.close();
  }

  openDocument(url: string) {
    this.utilService.openNewTab(url);
  }

  async showMoreInformation() {
    this.noticiaService
      .get_noticia(this.noticia.Id)
      .then((noticia: Noticia) => {
        this.noticia.Contenido = noticia.Contenido;
        this.showHTMLContentNotification = true;
      });
  }
}
