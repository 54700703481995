import { Injectable } from "@angular/core";

// Interfaces
import { IIdentity } from "src/app/models/interfaces/interfaces.index";

// Servicios
import { UserService } from "../security/user.service";
import { SecurityService } from "../security/security.service";
import { DeviceService } from "./device.service";
import { RestService } from "./rest.service";

// Enums
import { EnumTipoMenu, EnumTipoSegmento } from "src/app/models/enums/tipo.enum";

// Constantes
import { MENU } from "src/app/constants/menu.constant";

// Clases
import { SettingApp } from "src/app/models/entities/common/setting.entity";

@Injectable({
  providedIn: "root",
})
export class MenuService {
  private menu: IIdentity[] = [];
  private menuRemoto: any = null;

  constructor(
    private userService: UserService,
    private securityService: SecurityService,
    private deviceService: DeviceService,
    private restService: RestService
  ) { }

  /**
   * Actualiza los permisos de las opciones del menú
   * @param menuLocal Menú local
   * @param menuServer Menú server
   */
  reemplazar_menu(menuLocal: IIdentity[], menuServer: IIdentity[]) {
    this.menu = menuLocal;
    console.log("menuLocal", menuLocal);
    console.log("menuServer", menuServer);

    this.menu.forEach((item: IIdentity, indexItem: number) => {
      item = this.sincronizar_acceso_por_items(item, indexItem, menuServer);

      // Si contiene subitems
      if (item.opciones) {
        this.reemplazar_submenu(
          item.opciones,
          menuServer[indexItem].opciones,
          item.hide
        );
      }
    });

    menuLocal = this.menu;

    return menuLocal;
  }

  /**
   * Actualiza los permisos de las opciones del submenú
   * @param submenues Submenú local
   * @param submenuesConfig  Submenú server
   * @param menuHide Indica si el menú padre está con la propiedad hide = true.
   * En este caso, todos sus subItems quedan con la propiedad hide = true.
   */
  reemplazar_submenu(
    submenues: IIdentity[],
    submenuesConfig: IIdentity[],
    menuHide: boolean = false
  ) {
    submenues.forEach((subItem: IIdentity, indexSubItem: number) => {
      if (menuHide) {
        subItem.hide = true;
      } else {
        subItem = this.sincronizar_acceso_por_items(
          subItem,
          indexSubItem,
          submenuesConfig
        );
      }
    });
  }

  /**
   * Setea la propiedad hide=true en el itemLocal en caso que el usuario no tenga acceso
   * @param itemLocal Opción de la que se quiere saber si tiene acceso
   * @param itemConfig Opción del server contra la cual se compara la opción local
   */
  habilitar_acceso_por_item(itemLocal: IIdentity, itemConfig: IIdentity) {
    const usuario = this.userService.user;
    if (
      itemConfig &&
      itemConfig.id === itemLocal.id &&
      !this.securityService.permitir_ingresar(usuario, itemConfig)
    ) {
      itemLocal.hide = true;
    } else {
      itemLocal.hide = false;
    }

    if (this.deviceService.isWeb) {
      itemLocal.hide = false;
    }

    return itemLocal;
  }

  /**
   * Obtiene la configuración del menú que se encuentra en la constante MENU de la app
   * @param config Configuración del menú
   * @param tipo Tipo de menú a obtener de la app
   * @param property (Opcional) propiedad de la que se quiere obtener el menú (sirve para los casos de menues como Mobile anidados)
   */
  obtener_menu_por_tipo(
    config: any,
    tipo: EnumTipoMenu,
    property?: EnumTipoSegmento
  ): IIdentity[] | any {
    let result: IIdentity[] | any = [];
    if (tipo === EnumTipoMenu.Login) {
      result = (
        this.deviceService.isWeb
          ? config.MENU.WEB.LOGIN
          : config.MENU.MOBILE.LOGIN
      ) as IIdentity[];
    } else if (tipo === EnumTipoMenu.PreHolder) {
      result = (
        this.deviceService.isWeb
          ? config.MENU.WEB.PRE_HOLDER
          : config.MENU.MOBILE.PRE_HOLDER
      ) as IIdentity[];
    } else if (tipo === EnumTipoMenu.Home) {
      if (this.deviceService.isWeb) {
        result = config.MENU.WEB.INDEX;
      } else {
        result = property
          ? config.MENU.MOBILE[property]
          : config.MENU
            ? config.MENU.MOBILE
            : config;
      }
    }

    return result;
  }

  /**
   * Busca el objeto del array de menú que coincida con el valor de la propiedad
   * @param value Valor de la propiedad con que se filtrará
   * @param property Propiedad con que se filtrará
   * @param config Configuración del menú que respete la interfaz IIdentity. Si no se pasa, se tomará la configuración local
   */
  obtener_opcion_menu_por_propiedad(
    value: string,
    property: string,
    config: IIdentity[] = null
  ): IIdentity {
    let result: IIdentity = null;
    if (this.deviceService.isWeb) {
      result = this.buscar_item_por_propiedad_en_web(value, property, config);
    } else {
      result = this.buscar_item_por_propiedad_en_mobile(
        value,
        property,
        config
      );
    }

    return result;
  }

  /**
   * Devuelve el menú sin los items a ocultar
   * @param menu Listado de menú a purificar
   */
  obtener_menu_sin_opciones_ocultas(menu: IIdentity[]) {
    const menuNuevo: IIdentity[] = [];
    menu.forEach((item: IIdentity) => {
      // Si el menú principal es accesible
      if (!item.hide) {
        // Si tiene subItems
        if (item.opciones && item.opciones.length > 0) {
          // Oculto aquellos items que tengan la propiedad hide
          const subMenues = [];
          item.opciones.forEach((subItem: IIdentity) => {
            if (!subItem.hide) {
              subMenues.push(subItem);
            }
          });

          item.opciones = subMenues;
        }

        menuNuevo.push(item);
      }
    });

    return menuNuevo;
  }

  obtener_primer_menu_disponible(menuConfig: IIdentity[] | any) {
    if (this.deviceService.isWeb || Array.isArray(menuConfig)) {
      return this.obtener_menu_sin_opciones_ocultas(menuConfig)[0];
    }

    const arrayMenu = this.convert_menu_to_array(menuConfig);
    const arrayMenuLocal = this.convert_menu_to_array(MENU.MOBILE);
    menuConfig = this.reemplazar_menu(arrayMenuLocal, arrayMenu);
    menuConfig = this.obtener_menu_sin_opciones_ocultas(menuConfig);

    return menuConfig ? menuConfig[0] : [];
  }

  /**
   * (Sólo para la app) Convierte las opciones de menú de la app a un array
   * @param menuConfig Menú a convertir en array de IIdentity
   * @param tipoSegmento Tipo segmento que se quiere recuperar (HOME, MENSAJE, SEGURIDAD, TABS, ETC.).
   * En caso que no se pase, se obtendrá el array completo menuConfig parseado
   */
  convert_menu_to_array(
    menuConfig: any,
    tipoSegmento?: EnumTipoSegmento
  ): IIdentity[] {
    const result: IIdentity[] = [];
    if (tipoSegmento) {
      menuConfig[tipoSegmento.toUpperCase()].forEach((item: IIdentity) => {
        result.push(item);
      });
    } else {
      Object.keys(EnumTipoSegmento).forEach((key: string) => {
        menuConfig[key.toUpperCase()].forEach((item: IIdentity) => {
          result.push(item);
        });
      });
    }

    return result;
  }

  /**
   * Obtiene la configuración de la opción de menú a través de la descripción de la propiedad para la web
   * @param value Valor de la propiedad con que se filtrará
   * @param property Propiedad con que se filtrará
   * @param config Configuración del menú que respete la interfaz IIdentity. Si no se pasa, se tomará la configuración local
   */
  buscar_item_por_propiedad_en_web(
    value: string,
    property: string,
    config?: IIdentity[]
  ): IIdentity {
    return this.buscar_item_por_propiedad(
      config ? config : MENU.WEB.INDEX,
      value,
      property
    );
  }

  /**
   * Obtiene la configuración de la opción de menú a través de la descripción de la propiedad para mobile
   * @param value Valor de la propiedad con que se filtrará
   * @param property Propiedad con que se filtrará
   * @param config Configuración del menú que respete la interfaz IIdentity. Si no se pasa, se tomará la configuración local
   */
  buscar_item_por_propiedad_en_mobile(
    value: string,
    property: string,
    config?: any
  ): IIdentity {
    let result: IIdentity = null;
    Object.keys(EnumTipoSegmento).every((key: string) => {
      result = this.buscar_item_por_propiedad(
        config ? config[key.toUpperCase()] : MENU.MOBILE[key.toUpperCase()],
        value,
        property
      );
      return result === null;
    });

    return result;
  }

  /**
   * Busca en el array de menú a través de la propiedad y devuelve el item correspondiente con su configuración
   * @param menu Opciones de menú
   * @param value Valor de la propiedad con que se filtrará
   * @param property Propiedad con que se filtrará
   */
  buscar_item_por_propiedad(
    menu: any[],
    value: string,
    property: string
  ): IIdentity {
    // Busco en las opciones principales
    let result: IIdentity = null;
    menu.every((item: IIdentity) => {
      if (item[property] === value) {
        result = item;
        return false;
      }

      return true;
    });

    // Si no se encuentra entre las opciones principales, busco en los submenues
    if (result == null) {
      menu.every((item: IIdentity) => {
        if (item.opciones && item.opciones.length > 0) {
          item.opciones.every((subItem: IIdentity) => {
            if (subItem[property] === value) {
              result = subItem;
              return false;
            }

            return true;
          });

          if (result != null) {
            return false;
          }

          return true;
        } else {
          return true;
        }
      });
    }

    return result;
  }

  item_pertenece_a_segmento(
    menu: IIdentity,
    menues: any,
    segmento: EnumTipoSegmento
  ) {
    return this.deviceService.isWeb
      ? this.item_pertenece_a_segmento_web(menu, menues, segmento)
      : this.item_pertenece_a_segmento_mobile(menu, menues, segmento);
  }

  /**
   * Extrae del objeto config el menú setting en formato entendible para la app y web
   * @param config Objeto devuelto por la api ShellSetting
   * Devuelve el objeto menú extraído
   */
  extraer_menu_setting(config: SettingApp): Promise<any> {
    const menuLocal = {
      MENU: this.deviceService.isWeb
        ? { WEB: MENU.WEB }
        : { MOBILE: MENU.MOBILE },
    };

    // Si no tengo el menu o está deshabilitada la opción remota, devuelvo el local
    if (config.Menu == null || !config.Menu.Enabled) {
      return new Promise<any>((resolve) => resolve(menuLocal));
    }
    // Si ya tengo el menú resuelto, no lo vuelvo a pedir
    /*if (this.menuRemoto) {
      console.log(this.menuRemoto);
      return new Promise<any>((resolve) => resolve(this.menuRemoto));
    }*/

    console.log("url config", config.Menu.UrlJson);
    return new Promise<any>((resolve, reject) => {
      this.restService.get(config.Menu.UrlJson).subscribe(
        (data: any) => {
          console.log("menú resultante", data);
          // this.menuRemoto = { MENU: MENU.MOBILE };
          this.menuRemoto = { MENU: data };
          resolve(this.menuRemoto);
        },
        (err) => {
          console.log("entró en el error de resultante");
          console.log(err.error.text);
          this.menuRemoto = menuLocal;
          resolve(menuLocal);
        }
      );
    });
  }

  private item_pertenece_a_segmento_mobile(
    menu: IIdentity,
    menues: any,
    segmento: EnumTipoSegmento
  ) {
    switch (segmento) {
      case EnumTipoSegmento.Home:
        return menues.HOME
          ? menues.HOME.filter((item: IIdentity) => item.id === menu.id)
            .length > 0
          : menues.filter((item: IIdentity) => item.id === menu.id).length > 0;

      case EnumTipoSegmento.Mensaje:
        return menues.MENSAJE
          ? menues.MENSAJE.filter((item: IIdentity) => item.id === menu.id)
            .length > 0
          : menues.filter((item: IIdentity) => item.id === menu.id).length > 0;

      case EnumTipoSegmento.Seguridad:
        return menues.SEGURIDAD
          ? menues.SEGURIDAD.filter((item: IIdentity) => item.id === menu.id)
            .length > 0
          : menues.filter((item: IIdentity) => item.id === menu.id).length > 0;

      case EnumTipoSegmento.Perfil:
        return menues.PERFIL
          ? menues.PERFIL.filter((item: IIdentity) => item.id === menu.id)
            .length > 0
          : menues.filter((item: IIdentity) => item.id === menu.id).length > 0;

      case EnumTipoSegmento.Tabs:
        return menues.TABS
          ? menues.TABS.filter((item: IIdentity) => item.id === menu.id)
            .length > 0
          : menues.filter((item: IIdentity) => item.id === menu.id).length > 0;

      default:
        return false;
    }
  }

  private item_pertenece_a_segmento_web(
    menu: IIdentity,
    menues: any,
    segmento: EnumTipoSegmento
  ) {
    switch (segmento) {
      case EnumTipoSegmento.Home:
        return (
          menues.filter(
            (item: IIdentity) =>
              !menu.spacer &&
              !item.spacer &&
              item.name.toUpperCase().indexOf(menu.name.toUpperCase()) >= 0
          ).length > 0
        );

      case EnumTipoSegmento.Mensaje:
        return (
          menues.filter(
            (item: IIdentity) =>
              !menu.spacer &&
              !item.spacer &&
              item.name.toUpperCase().indexOf(menu.name.toUpperCase()) >= 0
          ).length > 0
        );

      case EnumTipoSegmento.Seguridad:
        return (
          menues.filter(
            (item: IIdentity) =>
              !menu.spacer &&
              !item.spacer &&
              item.name.toUpperCase().indexOf(menu.name.toUpperCase()) >= 0
          ).length > 0
        );

      case EnumTipoSegmento.Perfil:
        return (
          menues.filter(
            (item: IIdentity) =>
              !menu.spacer &&
              !item.spacer &&
              item.name.toUpperCase().indexOf(menu.name.toUpperCase()) >= 0
          ).length > 0
        );

      default:
        return false;
    }
  }

  /**
   * Setea la propiedad hide=true, en el item en caso que el usuario no tenga acceso.
   * Además setea las propiedades name y el icon tomándola de la configuración
   * @param item Opción de la que se quiere saber si tiene acceso
   * @param indexItem Índice en el array de la opción de la que se quiere tener acceso
   * @param menu Array de Opciones para obtener la información de acceso
   */
  private sincronizar_acceso_por_items(
    item: IIdentity,
    indexItem: number,
    menu: IIdentity[]
  ) {

    const usuario = this.userService.user;
    //buscamos el item por el id
    indexItem = menu.findIndex((x) => x.id === item.id);
    if (indexItem < 0) {
      //no esta en el menú conf, agregamos el local
      indexItem = menu.push(item) - 1;
    }

    // Reemplazamos name y icon y profiles
    if (menu[indexItem].id === item.id) {
      item.name = menu[indexItem].name;
      item.icon = menu[indexItem].icon;

      item.profiles = menu[indexItem].profiles;
    }
    // Verificamos permisos
    if (
      menu[indexItem].id === item.id &&
      !this.securityService.permitir_ingresar(usuario, menu[indexItem])
    ) {
      item.hide = true;
    } else {
      item.hide = false;
    }
    return item;
  }

  quitarBandejaTickets(urls) {
    const usuario = this.userService.user;

    urls.forEach((url) => {
      if (url.opciones && !usuario.Profiles.includes("Administrativo")) {
        url.opciones = url.opciones.filter(
          (opcion) => opcion.id != "bandejaTickets"
        );
      }
    });

    return urls;
  }
}
