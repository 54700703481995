import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

// Clases
import {
  FileItem,
  Lote,
  ResponseApp,
} from "src/app/models/entities/entity.index";
import { Ticket } from "src/app/models/entities/business/ticket.entity";

// Servicios
import { TicketService } from "src/app/services/business/ticket.service";
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { FileService } from "src/app/services/common/file.service";

// Constantes
import { MESSAGE, TIEMPO_MENSAJE } from "src/app/constants/constants.index";
import { BandejaTicketsService } from "src/app/services/business/bandeja-tickets.service";
import { LoteService } from "src/app/services/business/lote.service";

@Component({
  selector: "app-agregar-ticket",
  templateUrl: "./agregar.page.html",
  styleUrls: ["./agregar.page.scss"],
})
export class AgregarTicketPage {
  lotes: Lote[];
  titulo = "Nuevo ticket";
  formGroup: FormGroup;
  imagen: string = null;
  archivos: FileItem[] = [];
  user: any;
  bandejas: any = [];
  esGuardia: boolean;
  esAdministativo = false;
  ubicacion = { latitud: 0, longitud: 0 };
  guardiaValidator = [];
  ticketPrivado: boolean = false;
  checks = [
    { Id: "publico", Description: "Público", isChecked: true },
    { Id: "privado", Description: "Privado", isChecked: false },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private ticketService: TicketService,
    private controlService: ControlService,
    private userService: UserService,
    private fileService: FileService,
    private bandejaTicketService: BandejaTicketsService,
    private loteService: LoteService
  ) {
    this.lotes = this.userService.user.Lotes;
    this.user = this.userService.user;
    this.esGuardia = this.userService.user.Profiles.includes("Guardia");
    this.esAdministativo = this.userService.user.Profiles.includes("Administrativo");
    this.configurar_formulario();
  }

  async ionViewWillEnter(): Promise<void> {
    if (this.esGuardia || this.esAdministativo) {
      this.formGroup.controls["lote"].setValue("");
      this.obtenerBandejas();
      this.titulo = "Nuevo Ticket";
      this.guardiaValidator = [Validators.required];
    }
    this.configurar_formulario();
  }

  private obtenerBandejas() {
    this.bandejaTicketService.ObtenerBandejas().then((resp: []) => {
      resp.forEach((element) => {
        this.bandejas.push(element);
      });
    });
  }

  cerrar_modal() {
    this.controlService.cerrar_modal();
  }

  async obtener_info_lote(loteNumero: string) {
    return this.loteService
      .LoteMobileByNumeroGet(loteNumero)
      .then((resp: Lote[]) => {
        return resp;
      });
  }

  async agregar_ticket() {
    const ticket = new Ticket();
    ticket.Titulo = this.formGroup.value.titulo;
    ticket.Contenido = this.formGroup.value.contenido;
    ticket.Imagen = this.imagen;
    let loteSeleccionado;
    if (this.esGuardia || this.esAdministativo) {
      this.ubicacion.latitud = -34.6069832;
      this.ubicacion.longitud = -58.4555166;
      ticket.latitud = this.ubicacion.latitud;
      ticket.longitud = this.ubicacion.longitud;
      ticket.IdBandeja = this.formGroup.value.bandejas;
      loteSeleccionado = await this.obtener_info_lote(
        this.formGroup.value.lote
      );
    } else {
      loteSeleccionado = this.lotes.filter(
        (lote: Lote) => lote.Id === this.formGroup.value.lote
      )[0];
      this.ticketPrivado = false;
    }
    let lotes = [];
    lotes.push(loteSeleccionado);
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.ticketService
        .guardar_ticket(ticket, lotes, this.ticketPrivado)
        .then((resp: ResponseApp) => {
          this.controlService.mostrar_alert({
            header: MESSAGE.INFO.MENSAJE_TICKET.TICKET_GUARDADO.TITULO,
            message: `El ticket ${resp.Data} ha sido guardado`,
            backdropDismiss: true,
            buttons: [
              {
                text: MESSAGE.INFO.MENSAJE_TICKET.OK,
                role: "cancel",
              },
            ],
          });
        })
        .catch((error) => {
          this.controlService.mostrar_alert({
            header: MESSAGE.INFO.MENSAJE_TICKET.TICKET_NO_GUARDADO.TITULO,
            message: `El ticket no ha sido guardado. Revise los datos.`,
            backdropDismiss: true,
            buttons: [
              {
                text: MESSAGE.INFO.MENSAJE_TICKET.OK,
                role: "cancel",
              },
            ],
          });
          //this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
          this.cerrar_modal();
        });
    });
  }

  private configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      lote: [
        (this.esAdministativo || this.esGuardia)
          ? ""
          : this.lotes[0].Id,
        Validators.required,
      ],
      titulo: ["", Validators.required],
      bandejas: ["", this.guardiaValidator],
      contenido: ["", Validators.required],
    });
  }

  async obtener_imagen_seleccionada() {
    if (this.archivos.length > 0) {
      const url = await this.fileService.convert_to_base64(
        this.archivos[0],
        true
      );
      this.imagen = url;
      return url;
    }

    return null;
  }

  sincronizar_imagen(file: { file: File, base64: string } | null) {
    this.imagen = file !== null ? file.base64 : null;
  }

  togglePrivacy(selectedCheck) {
    this.ticketPrivado = selectedCheck.Id == "privado" ? true : false;
  }
}
