import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { GoogleMap, GoogleMaps, GoogleMapsEvent, LatLng, Marker } from '@ionic-native/google-maps';

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { CamionetaService } from "src/app/services/business/camioneta.service";
import { ReservaCamioneta, TransporteViajeIniciado } from "src/app/models/entities/business/reserva.entity";
import { GoogleMapsService } from "src/app/services/common/google-maps.service";

declare var google: any;

@Component({
  selector: "app-details",
  templateUrl: "./details.page.html",
  styleUrls: ["./details.page.scss"],
})
export class DetailsReservaCamionetaPage implements OnInit {
  @ViewChild('map') mapElement;
  @Input() reserva: ReservaCamioneta;
  @Input() titulo = "";

  private map: GoogleMap;
  private directionsService;
  private directionsDisplay;
  transporte: TransporteViajeIniciado;

  constructor(
    private controlService: ControlService,
    private camionetaService: CamionetaService,
    private googleMapsService: GoogleMapsService,
  ) {
    this.directionsService = new google.maps.DirectionsService;
    this.directionsDisplay = new google.maps.DirectionsRenderer;
  }


  cerrar_modal() {
    this.controlService.cerrar_modal();
  }

  async ngOnInit() {
    this.transporte = await this.camionetaService.obtenerReservaTransporteAsignado(this.reserva.Numero);


    const paradaPosition = this.reserva.Parada.Geoposicion.split(', ');
    const paradaCoords = { latitude: paradaPosition[0], longitude: paradaPosition[1] };

    const transportePosition = this.transporte.GeoposicionActual.split(', ');
    const choferCoords = { latitude: transportePosition[0], longitude: transportePosition[1] };
    const result = await this.googleMapsService.calculateDistance(paradaCoords, choferCoords);
    this.reserva.LlegaEn = result;
    this.loadMap(paradaCoords, choferCoords);
  }

  loadMap(paradaCoords, choferCoords) {
    this.map = new google.maps.Map(document.getElementById('map'), {
      zoom: 20,
      disableDefaultUI: true
    });

    this.directionsDisplay.setMap(this.map);
    this.directionsDisplay.setOptions();

    this.directionsService.route({
      origin: { lat: parseFloat(paradaCoords.latitude), lng: parseFloat(paradaCoords.longitude) },
      destination: { lat: parseFloat(choferCoords.latitude), lng: parseFloat(choferCoords.longitude) },
      travelMode: 'DRIVING',
      provideRouteAlternatives: true
    }, (response, status) => {
      if (status === 'OK') {
        this.directionsDisplay.setDirections(response)
      } else {
        console.log('Directions request failed due to ' + status)
      }
    })
  }
}
