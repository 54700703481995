import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Geolocation, Geoposition } from "@ionic-native/geolocation/ngx";
// import {
//   BarcodeScanner,
//   BarcodeScannerOptions,
// } from "@ionic-native/barcode-scanner/ngx";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { SegmentService } from "src/app/services/common/segment.service";
import { EnumTipoSegmento } from "src/app/models/enums/tipo.enum";
import { ControlService } from "src/app/services/common/control.service";
import { STORAGE, TEMPLATE } from "src/app/constants/constants.index";
import { StorageService } from "src/app/services/common/storage.service";
import { RestService } from "src/app/services/common/rest.service";
import { SecurityService } from "src/app/services/security/security.service";
import { UtilService } from "src/app/services/common/util.service";

@Component({
  selector: "app-buscador",
  templateUrl: "./buscador.page.html",
  styleUrls: ["./buscador.page.scss"],
})
export class BuscadorPage implements OnInit {
  catalogo: string = null;
  codigoSeguridad: string = null;
  formGroup: FormGroup;
  segmentTypes = EnumTipoSegmento;
  esVehiculo = false;
  formEnviado = false;
  verUsuarioDetallado = false;
  usuarioDetallado: any;
  usuarios: any;
  vehiculo: any;

  // options: BarcodeScannerOptions = {
  //   preferFrontCamera: false,
  //   showFlipCameraButton: true,
  //   showTorchButton: true,
  //   torchOn: false,
  //   prompt: "Place a barcode inside the scan area",
  //   resultDisplayDuration: 500,
  //   formats: "EAN_13,EAN_8,QR_CODE,PDF_417 ",
  //   orientation: "portrait",
  // };

  ubicacion = { latitud: 0, longitud: 0 };

  constructor(
    private route: ActivatedRoute,
    private controlService: ControlService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private geolocation: Geolocation,
    private http: HttpClient,
    private router: Router,
    private segmentService: SegmentService,
    private securityService: SecurityService,
    // private barcodeScanner: BarcodeScanner,
    private util: UtilService
  ) { }

  ngOnInit() {
    this.geolocation
      .getCurrentPosition()
      .then((resp) => {
        this.ubicacion.latitud = resp.coords.latitude;
        this.ubicacion.longitud = resp.coords.longitude;
      })
      .catch((error) => {
        console.log("Error getting location", error);
      });

    this.configurar_formulario();

    this.route.queryParams.subscribe((params) => {
      if (params && params.catalogo) {
        this.catalogo = JSON.parse(params.catalogo);
      }
    });
  }

  configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      patente: [""],
      dni: [""],
    });
  }

  buscar() {
    if (this.formGroup.value.patente && this.formGroup.value.dni) {
      this.presentToast(
        "Tiene que buscar por DNI o por matrícula, no ambos.",
        1
      );
    } else {
      this.storageService
        .cargar(STORAGE.KEY.CATALOGO)
        .then((response: string) => {
          this.catalogo = response;
          this.geolocation.getCurrentPosition().then((position) => {
            this.ubicacion.latitud = position.coords.latitude;
            this.ubicacion.longitud = position.coords.longitude;
            if (this.formGroup.value.dni) {
              this.obtenerUsuario();
              this.formEnviado = true;
            }
            if (this.formGroup.value.patente) {
              const url = `${this.catalogo}ObtenerUltimoMovimientoVehiculo/${this.securityService.token}/${this.ubicacion.latitud}/${this.ubicacion.longitud}/${this.formGroup.value.patente}`;
              this.http.get(url).subscribe((resp) => {
                if (resp["Status"] != "ERROR") {
                  console.log(resp);
                  this.vehiculo = resp["Data"];
                  this.usuarios = resp["Data"]["Ocupantes"];
                  this.vehiculo.FechaHora = this.util.convert_ticks_to_date(
                    this.vehiculo.FechaHora
                  );
                  this.formEnviado = true;
                  this.esVehiculo = true;
                  this.verUsuarioDetallado = false;
                } else {
                  this.presentToast(resp["StatusDescription"], 2);
                  this.resetForm();
                }
              });
            }
          });
        });
    }
  }

  obtenerUsuario(usuario?: any) {
    const dni = usuario ? usuario.Documento : this.formGroup.value.dni;
    const url = `${this.catalogo}ObtenerUltimoMovimientoPersona/${this.securityService.token}/${this.securityService.codigoBarrio}/${this.ubicacion.latitud}/${this.ubicacion.longitud}/${dni}`;
    this.http.get(url).subscribe((resp) => {
      if (resp["Status"] != "ERROR") {
        this.usuarioDetallado = resp["Data"];
        if (this.usuarioDetallado) {
          this.usuarioDetallado.FechaHora = this.util.convert_ticks_to_date(
            this.usuarioDetallado.FechaHora
          );
          this.usuarioDetallado.FechaHoraEgresoEsperado =
            this.util.convert_ticks_to_date(
              this.usuarioDetallado.FechaHoraEgresoEsperado
            );
          this.usuarioDetallado.UrlImagen = this.util.resolver_url_imagen(
            this.usuarioDetallado.UrlImagen
          );
        }
        this.verUsuarioDetallado = true;
      } else {
        this.presentToast(resp["StatusDescription"], 2);
        this.resetForm();
      }
    });
  }

  verUsuariosVehiculos() {
    this.verUsuarioDetallado = false;
  }

  resetForm() {
    this.formEnviado = false;
    this.esVehiculo = false;
    this.usuarioDetallado = null;
    this.formGroup.reset();
  }

  async presentToast(message: any, type: any) {
    this.controlService.mostrar_alert({
      header: "Buscador",
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          text: "OK",
          handler: () => {
            if (type != 2) {
              this.backToHome();
            }
          },
        },
      ],
    });
  }

  backToHome() {
    let option = this.segmentService.segmentosHome[0];
    this.router.navigate([option.path]);
    setTimeout(() => {
      this.segmentService.actualSegment.next({
        type: this.segmentTypes.Home,
        segment: option,
      });
    }, 1000);
  }

  async scanBarcode() {
    // try {
    //   const escaneo = await this.barcodeScanner.scan(this.options);
    //   console.log("Barcode data", escaneo);
    // } catch (error) {
    //   console.log(error);
    //   return false;
    // }
    /*this.barcodeScanner.scan(this.options).then(barcodeData => {
      let toArray =  barcodeData.text.split("@");
      console.log('Barcode data', toArray);
      let option =  this.segmentService.segmentosSeguridad[5];
      console.log(this.segmentService.actualSegment);
    }).catch(err => {
      console.log('Error', err);
    });*/
  }
}
