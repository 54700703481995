import { Component, ViewChild } from "@angular/core";
import { IonInfiniteScroll, IonContent } from "@ionic/angular";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";

// Servicios
import { NoticiaService } from "src/app/services/business/noticia.service";
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { DeviceService } from "src/app/services/common/device.service";
import { SbcService } from "src/app/services/common/sbc.service";
import { CategoriaService } from "src/app/services/business/categoria.service";

// Clases
import { Noticia, Categoria } from "src/app/models/entities/entity.index";

// Interfaces
import { IPagination } from "src/app/models/interfaces/common/pagination.interface";
import { IModal } from "src/app/models/interfaces/interfaces.index";

// Constantes
import {
  MESSAGE,
  SCROLL_FAB_HEIGHT,
  ENABLED_SCROLL_EVENT,
  PAGINATOR,
} from "src/app/constants/constants.index";
import { TIEMPO_MENSAJE } from "src/app/constants/message.constant";

// Enums
import {
  EnumTipoCanal,
  EnumTipoSegmento,
} from "src/app/models/enums/tipo.enum";

// Páginas
import { AgregarNoticiaPage } from "./agregar/agregar.page";
import { SegmentService } from "src/app/services/common/segment.service";

@Component({
  selector: "app-noticia",
  templateUrl: "./noticia.page.html",
  styleUrls: ["./noticia.page.scss"],
})
export class NoticiaPage {
  private categoriaSeleccionada: string;
  private searchInput: string = null;
  private primerCarga = true;
  private paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: 10,
  };

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  mostrarFabButton = false;
  mostrarFabButtonApartirDe = SCROLL_FAB_HEIGHT;
  habilitarEventosScroll = ENABLED_SCROLL_EVENT;
  mostrarInfiniteScrollEn = PAGINATOR.SIZE;

  noticias: Noticia[] = [];
  categorias: any;
  mostrarMensajeSinItem = false;
  categoriaActual: any;
  formGroup: FormGroup;
  icono: string = null;

  constructor(
    private controlService: ControlService,
    private noticiaService: NoticiaService,
    public userService: UserService,
    private deviceService: DeviceService,
    private sbcService: SbcService,
    private categoriaService: CategoriaService,
    private formBuilder: FormBuilder,
    public segmentService: SegmentService
  ) {
    this.configurar_formulario();
  }

  ionViewWillEnter() {

    this.segmentService.actualSegment.subscribe((selector: any) => {
      if (selector.segment.id === "noticias") {
        this.onClear();
      }
    });

    this.resolver_icono();
    if (this.primerCarga) {
      this.obtener_categorias();
      this.primerCarga = false;
    }
  }

  resolver_icono() {
    this.sbcService.resolver_icono().subscribe(
      (icono: string) => {
        this.icono = icono;
      },
      (error) => {
        this.controlService.mostrar_toast_con_error(error, false);
      }
    );
  }

  obtener_categorias() {
    this.noticiaService.limpiar();
    this.noticias = [];
    this.categoriaService.limpiar();
    this.categorias = [];
    this.paginacion.pagina = 1;

    this.categoriaService
      .obtener_catagorias()
      .then((resp) => {
        this.categorias = resp;
      })
      .finally(() => {
        this.categorias.unshift({ Id: "todos", Descripcion: "Todos" });
        if (
          this.formGroup.value.categoria == "" ||
          typeof this.formGroup.value.categoria != "string"
        ) {
          this.configurar_formulario(this.categorias[0]);
        }
        this.resolver_vista_noticias();
      });
  }

  configurar_formulario(categoria?: Categoria) {
    this.formGroup = this.formBuilder.group({
      categoria: [categoria ? categoria.Id : "", Validators.required],
    });
  }

  resolver_vista_noticias(event?) {
    if (event) {
      this.paginacion.pagina += 1;
      this.cargar_noticias(event);
    } else {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.cargar_noticias(event);
      });
    }
  }

  openNews(noticia: Noticia) {
    try {
      const segment = this.segmentService.segmentosHome.find(
        ({ id }) => id === "detalleNoticia"
      );
      const data = {
        noticia: JSON.stringify(noticia),
        icono: JSON.stringify(this.icono),
      };
      this.segmentService.actualSegment.next({
        type: EnumTipoSegmento.Home,
        segment,
        data,
      });
    } catch (error) {
      console.log("error: " + error);
    }
  }

  informar_scroll(event) {
    if (this.deviceService.isIos) {
      return;
    }

    this.content
      .getScrollElement()
      .then(
        (el) =>
        (this.mostrarFabButton =
          el.scrollTop > this.mostrarFabButtonApartirDe)
      );
  }

  volver_al_inicio() {
    this.content.scrollToTop();
  }

  doRefresh(event) {
    const data = {
      categoriaId: this.categoriaSeleccionada,
      texto: this.searchInput
    }
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.noticiaService.limpiar();
    this.noticiaService
      .obtener_publicaciones_por_canal(data, EnumTipoCanal.Mobile, this.paginacion)
      .then((resp: Noticia[]) => {
        this.noticias = resp;
        this.controlaFindePagina();
        event.target.complete();
      })
      .catch((error) => {
        event.target.complete();
      });
  }

  mostrar_agregar_noticia(noticia: Noticia) {
    const titulo = noticia
      ? "Editar publicación " + noticia.Numero
      : "Nueva publicación";
    const modal: IModal = {
      component: AgregarNoticiaPage,
      componentProps: { noticia: noticia, titulo: titulo },
      returnData: true,
    };

    this.controlService.mostrar_modal(modal).then(() => {
      this.primerCarga = true;
      this.paginacion.pagina = 1;
      this.noticiaService.limpiar();
      this.resolver_vista_noticias();
    });
  }

  eliminarNoticia(noticia: Noticia) {
    this.controlService.mostrar_alert({
      header: MESSAGE.ALERT.MENSAJE_NOTICIA.ELIMINAR_NOTICIA.TITULO,
      message: MESSAGE.ALERT.MENSAJE_NOTICIA.ELIMINAR_NOTICIA.PREGUNTA,
      backdropDismiss: false,
      buttons: [
        {
          text: MESSAGE.ALERT.MENSAJE_NOTICIA.ELIMINAR_NOTICIA.BUTTONS.ACEPTAR,
          handler: () => {
            this.controlService
              .mostrarLoading(MESSAGE.LOADING.DEFAULT)
              .then(() => {
                this.noticiaService.eliminar_noticia(noticia.Id).then(() => {
                  this.controlService.ocultar_loading();
                  this.primerCarga = true;
                  this.paginacion.pagina = 1;
                  this.noticiaService.limpiar();
                  this.resolver_vista_noticias();
                });
              });
          },
        },
        {
          text: MESSAGE.ALERT.MENSAJE_NOTICIA.ELIMINAR_NOTICIA.BUTTONS.CANCELAR,
        },
      ],
    });
  }

  private cargar_noticias(event?) {
    const data = {
      categoriaId: this.categoriaSeleccionada,
      texto: this.searchInput
    }
    this.noticiaService
      .obtener_publicaciones_por_canal(data, EnumTipoCanal.Mobile, this.paginacion)
      .then((resp: Noticia[]) => {
        if (
          resp == null ||
          this.noticiaService.paginacionActual.cantidad === 0
        ) {
          this.controlService.habilitar_infinite_scroll(event, false);
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_SIN_DATOS,
            TIEMPO_MENSAJE
          );
        } else {
          this.categoriaActual = this.formGroup.value.categoria;
          this.noticias = resp;

          this.controlService.completar_infinite_scroll(event);
        }
      })
      .catch((error) => {
        this.controlService.habilitar_infinite_scroll(event, false);
        this.controlService.mostrar_toast_con_error(error, false);
      })
      .finally(() => {
        this.controlaFindePagina();
        this.mostrarMensajeSinItem = this.noticias.length === 0;
        if (event == null) {
          this.controlService.ocultar_loading();
        }
      });
  }

  recortarCadena(cadena: string, limite: number) {
    //ocultamos momentáneamente los <br>
    cadena = cadena.split("<br>").join(" ");
    if (cadena.length >= limite) {
      cadena = cadena.slice(0, limite);
      cadena += "...";
    }

    return cadena;
  }

  cambiar_categoria(event: any) {
    this.searchInput = null;
    this.categoriaSeleccionada = event.target.value;
    this.paginacion.pagina = 1;
    this.noticiaService.limpiar();
    this.noticias = [];
    this.resolver_vista_noticias();
  }

  controlaFindePagina() {
    let maxPages = Math.ceil(
      this.noticiaService.paginacionActual.cantidad / this.paginacion.cantidad
    );
    if (maxPages == this.paginacion.pagina)
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, false);
    else
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
  }

  onClear(): void {
    this.searchInput = null;
    this.paginacion.pagina = 1;
    this.noticiaService.limpiar();
    this.noticias = [];
    this.resolver_vista_noticias();
  }

  search(event) {
    if (event && event.key === "Enter") {
      const query = event.target.value.toLowerCase();
      this.searchInput = query;
      this.paginacion.pagina = 1;
      this.noticiaService.limpiar();
      this.noticias = [];
      this.resolver_vista_noticias();
    }
  }

  markAllAsRead(): void {
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.noticiaService
        .marcarTodoLeido()
        .then(() => {
          const data = {
            categoriaId: this.categoriaSeleccionada,
            texto: this.searchInput
          }
          this.paginacion.pagina = PAGINATOR.PAGE_INIT;
          this.noticiaService.limpiar();
          this.noticiaService
            .obtener_publicaciones_por_canal(data, EnumTipoCanal.Mobile, this.paginacion)
            .then((resp: Noticia[]) => {
              this.noticias = resp;
              this.controlaFindePagina();
            });
        })
        .catch((error) => {
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    });
  }
}

