export enum EnumEstadoEncuesta {
  Abierta = 0,
  Cerrada = 1,
  Todas = 2,
}

export enum EnumEstadoTicket {
  EnTratamiento = 0,
  Cerrado = 1,
  Cancelado = 2,
  EnTratamientoConNovedad = 3
}

export enum EnumEstadoNotificacionLeido {
  Leida = 0,
  NoLeida = 1,
  Todas = 2,
}

export enum EnumEstadoLeido {
  NoLeida = 0,
  Leida = 1,
}

export enum TIViajeEstado {
  NoIniciado = 0,
  Iniciado = 1,
  Finalizado = 2
}