import { EnumTipoCanal } from "../../enums/tipo.enum";
import { Categoria } from "../entity.index";
import { Documento } from "./documento.entity";

export class Noticia {
  Id: string = null;
  Numero: string = null;
  Titulo: string = null;
  UrlImagen: string = null;
  Contenido: string = null;
  FechaCierre: Date | number = null;
  FechaPublicacion: Date | number = null;
  Imagen: string = null;
  Canal: EnumTipoCanal = null;
  Categoria: Categoria = null;
  VisibleInHome: boolean = false;
  ContenidoIsHTML: number = 0;
  Documentos: Documento[] = [];
  FechaHoraModificacion: Date | number = null;
  Leida: number = 0;
}
