export class Multa {
  Id: string = null;
  Titulo: string = null;
  FechaActa?: Date | any = null;
  FechaCreacion?: Date | any = null;
  Tipo: string = null;
  Estado: number = 0;
  EstadoDescripcion: string = null;
  Numero: string = null;
  Contenido: string = null;
  Leida: number | boolean = 0;
  PermiteNuevoComentario: number = 0;
  Adjuntos: Adjunto[] = [];
  Comentarios: Feedback[] = [];
}

export class Adjunto {
  UrlImagen: string = null;
}

export class Feedback {
  Id: string = null;
  Fecha?: Date | any = null;
  Usuario: string = null;
  Bandeja: string = null;
  Comentario: string = null;
}
