import { Component } from "@angular/core";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { SbcService } from "src/app/services/common/sbc.service";
import { MenuService } from "src/app/services/common/menu.service";

// Interfaces
import { IIdentity } from "src/app/models/interfaces/common/identity.interface";

// Constantes
import { MENU } from "src/app/constants/menu.constant";

// Enums
import { EnumTipoMenu, EnumTipoSegmento } from "src/app/models/enums/tipo.enum";

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
})
export class AccountComponent {
  opciones: IIdentity[] = MENU.MOBILE.PERFIL;

  constructor(
    private controlService: ControlService,
    private sbcService: SbcService,
    private menuService: MenuService
  ) {
    this.actualizar_configuración_perfil();
  }

  ejecutarOpcion(opcion: string) {
    this.controlService.cerrarPopOver({ opcion });
  }

  /**
   * Actualiza en base a la configuración del menú la opción perfil
   */
  private actualizar_configuración_perfil() {
    this.sbcService
      .resolver_menu(EnumTipoMenu.Home, EnumTipoSegmento.Perfil)
      .subscribe((resp: string | IIdentity[]) => {
        if (typeof resp !== "string") {

          console.log("aquí actualizar_configuración_perfil");
          this.opciones = this.menuService.reemplazar_menu(this.opciones, resp);
        }
      });
  }
}
