import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

// Componentes
import { Router } from "@angular/router";

// Interfaces
import { IIdentity } from "src/app/models/interfaces/interfaces.index";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
  @Input() icono: string = null;
  @Input() title: string = null;
  @Input() urls: IIdentity[] = [];
  @Output() action = new EventEmitter();

  constructor(private router: Router) { }

  ngOnInit() {
    console.log("navbar.component", this.urls);
  }

  redireccionar(opcion: IIdentity) {
    this.action.emit(opcion);

    if (opcion.path) {
      this.router.navigate([opcion.path]);
    }
  }

  ocultar(url: IIdentity) {
    if (!url.opciones || url.opciones.length === 0) {
      return true;
    }

    return url.opciones.filter((item: IIdentity) => !item.hide).length === 0;
  }
}
