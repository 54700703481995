import { Component, Input } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import * as moment from "moment";
import { Ionic4DatepickerModalComponent } from '@logisticinfotech/ionic4-datepicker';

// Clases
import {
  FileItem,
  Lote,
  ResponseApp,
} from "src/app/models/entities/entity.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { FileService } from "src/app/services/common/file.service";

// Constantes
import { FECHA, MESSAGE } from "src/app/constants/constants.index";
import { LoteService } from "src/app/services/business/lote.service";
import { MascotaService } from "src/app/services/business/mascota.service";
import Mascota, { MascotaColor, MascotaEspecie, MascotaTamano } from "src/app/models/entities/business/mascota.entity";
import { ModalController } from "@ionic/angular";


@Component({
  selector: "app-agregar-mascota",
  templateUrl: "./agregar.page.html",
  styleUrls: ["./agregar.page.scss"],
})
export class AgregarMascotaPage {
  @Input() mascota: Mascota = null;
  lotes: Lote[];
  titulo = "Nueva mascota";
  formGroup: FormGroup;
  imagen: string = null;
  user: any;
  tamanos: MascotaTamano[] = [];
  colores: MascotaColor[] = [];
  especies: MascotaEspecie[] = [];
  formatoFechaControl: string = FECHA.FORMATO_VISUAL;
  mascotaImage: string = null;
  vacunaCertificadoImage: string = null;
  declaration = false;
  selectedDate = null;
  datepickerValue = null;
  datePickerObj: any = {
    inputDate: new Date(), // default new Date()
    fromDate: null, // default null
    toDate: '2030-12-31', // default null
    showTodayButton: true, // default true
    closeOnSelect: true, // default false
    disableWeekDays: [], // default []
    mondayFirst: true, // default false
    setLabel: 'Confirmar',  // default 'Set'
    todayLabel: 'Hoy', // default 'Today'
    closeLabel: 'Cerrar', // default 'Close'
    disabledDates: [], // default []
    titleLabel: '¿En qué fecha?', // default null
    monthsList: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    weeksList: ["D", "L", "M", "X", "J", "V", "S"],
    dateFormat: 'YYYY-MM-DD', // default DD MMM YYYY
    clearButton: false, // default true
    momentLocale: 'pt-US', // Default 'en-US'
    yearInAscending: false, // Default false
    btnCloseSetInReverse: false, // Default false
    btnProperties: {
      expand: 'block', // Default 'block'
      fill: '', // Default 'solid'
      size: '', // Default 'default'
      disabled: '', // Default false
      strong: '', // Default false
      color: '' // Default ''
    },
    // This object supports only SVG files.
    highlightedDates: [],// Default [],
    isSundayHighlighted: {} // Default {}
  };
  datePickerIsOpen = false;

  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private userService: UserService,
    private fileService: FileService,
    private loteService: LoteService,
    private mascotaService: MascotaService,
    private modalCtrl: ModalController,
  ) {
    this.lotes = this.userService.user.Lotes;
    this.user = this.userService.user;
    this.configurar_formulario();
  }

  async ionViewWillEnter(): Promise<void> {
    await this.getFormData();
    this.configurar_formulario();
    this.applyKeyboardStayOpenFix();
  }

  applyKeyboardStayOpenFix() {
    let el = document.getElementById('btnMascotaAgregar');
    if (!el) { return; }

    el.addEventListener('click', (e) => { this.stopBubble("click", e); });
    el.addEventListener('mousedown', (e) => { this.stopBubble("mousedown", e); });
    el.addEventListener('touchdown', (e) => { this.stopBubble("touchdown", e); });
    el.addEventListener('touchmove', (e) => { this.stopBubble("touchmove", e); });
    el.addEventListener('touchstart', (e) => { this.stopBubble("touchstart", e); });

    //Triggered by a phone
    el.addEventListener('touchend', (e) => { this.stopBubble("touchend", e); this.guardarMascota(); });
    //Triggered by the browser
    // el.addEventListener('mouseup', (event) => { this.guardarMascota(); });
  }

  stopBubble(from, event) {
    console.log("from: " + from);
    //this.guardarMascota();
    event.preventDefault();
    event.stopPropagation(); //Stops event bubbling
  }

  async getFormData() {
    await this.controlService.mostrarLoading();
    this.colores = await this.mascotaService.obtenerColores();
    this.tamanos = await this.mascotaService.obtenerMascotaTamanos();
    this.especies = await this.mascotaService.obtenerEspecies();
    await this.controlService.ocultar_loading();
  }

  cerrar_modal() {
    this.controlService.cerrar_modal();
  }

  async obtener_info_lote(loteNumero: string) {
    return this.loteService
      .LoteMobileByNumeroGet(loteNumero)
      .then((resp: Lote[]) => {
        return resp;
      });
  }

  private configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      lote: [this.mascota !== null ? this.mascota.IdLote : this.lotes[0].Id, Validators.required],
      nombre: [this.mascota !== null ? this.mascota.Nombre : '', Validators.required],
      identificacion: [this.mascota !== null ? this.mascota.Identificacion : '', Validators.required],
      idEspecie: [this.mascota !== null ? this.mascota.IdEspecie : '', Validators.required],
      idRaza: [this.mascota !== null ? this.mascota.IdRaza : '', Validators.required],
      idColor: [this.mascota !== null ? this.mascota.IdColor : '', Validators.required],
      tamano: [this.mascota !== null ? this.mascota.Tamano : '', Validators.required],
      fechaVencimientoVacuna: [''],
    });
  }

  getRazas() {
    if (this.formGroup.value.idEspecie !== '') {
      const idEspecie = this.formGroup.value.idEspecie;
      return this.especies.find(item => item.Id === idEspecie).Razas;
    } else {
      return [];
    }
  }

  async obtener_imagen_seleccionada(type: string) {
    console.log("obtener_imagen_seleccionada type: " + JSON.stringify(type));
    const image = type === "mascota-image" ? this.mascotaImage : this.vacunaCertificadoImage;
    console.log("image 183: " + JSON.stringify(image));
    //const url = await this.fileService.convert_to_base64(image, true);
    console.log("image 185: " + JSON.stringify(image));
    return image;
  }

  async sincronizar_mascota_imagen(fileMascota: { file: File, base64: string } | null) {
    console.log("file Item: " + fileMascota);
    this.mascotaImage = fileMascota !== null ? fileMascota.base64 : null;
  }

  sincronizar_certificado_vacuna_imagen(fileVacuna: { file: File, base64: string } | null) {
    this.vacunaCertificadoImage = fileVacuna !== null ? fileVacuna.base64 : null;
  }

  async guardarMascota() {
    console.log("entró a guardarMascota()");
    let body = null;
    console.log("body" + JSON.stringify(body));
    if (this.mascota) {
      console.log("this.mascota 199" + JSON.stringify(this.mascota));
      body = {
        idLote: this.formGroup.value.lote,
        idMascota: this.mascota.Id,
        vencimiento: this.formGroup.value.fechaVencimientoVacuna ? moment(this.formGroup.value.fechaVencimientoVacuna).format('YYYYMMDD') : null,
        vacunaFotoBase64: this.vacunaCertificadoImage ? this.vacunaCertificadoImage : null
      };
      console.log("body 206" + JSON.stringify(body));
    } else {
      console.log("this.mascota 207" + JSON.stringify(this.mascota));
      body = {
        mascotaMobile: {
          Id: "00000000-0000-0000-0000-000000000000",
          IdLote: this.formGroup.value.lote,
          Nombre: this.formGroup.value.nombre,
          Tamano: this.formGroup.value.tamano,
          Identificacion: this.formGroup.value.identificacion,
          VacunaVencimiento: this.formGroup.value.fechaVencimientoVacuna ? moment(this.formGroup.value.fechaVencimientoVacuna).format('YYYYMMDD') : null,
          IdColor: this.formGroup.value.idColor,
          IdEspecie: this.formGroup.value.idEspecie,
          IdRaza: this.formGroup.value.idRaza,
          FotografiaBase64: this.mascotaImage ? this.mascotaImage : null,
          VacunaFotoBase64: this.vacunaCertificadoImage ? this.vacunaCertificadoImage : null
        }
      };
      console.log("body 224" + JSON.stringify(body));
    }

    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      console.log("guardar_mascota 230" + JSON.stringify(body));
      this.mascotaService
        .guardar_mascota(body, this.mascota !== null)
        .then((resp: ResponseApp) => {
          console.log("guardar_mascota then 234" + JSON.stringify(resp));
          this.controlService.mostrar_alert({
            header: MESSAGE.INFO.MENSAJE_MASCOTA.MASCOTA_GUARDADA.TITULO,
            message: MESSAGE.INFO.MENSAJE_MASCOTA.MASCOTA_GUARDADA.MENSAJE,
            backdropDismiss: true,
            buttons: [
              {
                text: MESSAGE.INFO.MENSAJE_TICKET.OK,
                role: "cancel",
                handler: () => {
                  this.cerrar_modal();
                }
              },
            ],
          });
        })
        .catch((error) => {
          console.log("guardar_mascota error 251", error);
          this.controlService.mostrar_alert({
            header: MESSAGE.INFO.MENSAJE_MASCOTA.MASCOTA_NO_GUARDADA.TITULO,
            message: error,
            backdropDismiss: true,
            buttons: [
              {
                text: MESSAGE.INFO.MENSAJE_TICKET.OK,
                role: "cancel",
              },
            ],
          });
        })
        .finally(() => {
          console.log("guardar_mascota error 265");
          this.controlService.ocultar_loading();
        });
    });
  }

  async openDatePicker() {
    if (this.datePickerIsOpen === true) return;

    const datePickerModal = await this.modalCtrl.create({
      component: Ionic4DatepickerModalComponent,
      cssClass: 'li-ionic4-datePicker',
      componentProps: {
        'objConfig': this.datePickerObj,
        'selectedDate': this.selectedDate
      }
    });
    this.datePickerIsOpen = true;
    await datePickerModal.present();

    datePickerModal.onDidDismiss().then(({ data }) => {
      if (!data || data.date === 'Invalid date' || data.date === 'Fecha inválida') {
        this.datePickerIsOpen = false;
        return;
      }

      this.datepickerValue = moment(data.date).format('DD/MM/YYYY');
      this.selectedDate = data.date;
      this.formGroup.controls["fechaVencimientoVacuna"].setValue(data.date);
      this.datePickerIsOpen = false;
    });

    return;
  }

  clearDatepicker() {
    this.selectedDate = null;
    this.datepickerValue = null;
    this.formGroup.controls["fechaVencimientoVacuna"].setValue(null);
  }


  toggleDeclaration(): void {
    this.declaration = !this.declaration;
  }

  disableSubmitBtn(): boolean {
    if (this.mascota !== null) {
      return !this.formGroup.value.fechaVencimientoVacuna || !this.vacunaCertificadoImage || !this.declaration;
    } else {
      return this.formGroup.invalid || !this.mascotaImage || !this.declaration || this.showVacunaAlert();
    }
  }

  showVacunaAlert(): boolean {
    //Si tiene imagen pero no fecha de vencimiento entonces muestro
    if (this.vacunaCertificadoImage && !this.formGroup.value.fechaVencimientoVacuna) return true;
    //Si tiene  fecha de vencimiento pero no imagen entonces muestro
    if (this.formGroup.value.fechaVencimientoVacuna && !this.vacunaCertificadoImage) return true;

    return false;
  }
}
