import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

// Clases
import {
  TelefonoContacto,
  Lote,
  ResponseApp,
} from "src/app/models/entities/entity.index";

// Enums
import { EnumTipoContacto } from "src/app/models/enums/tipo.enum";

// Constantes
import { MESSAGE, PAGINATOR, POPOVER_STYLES, TIEMPO_MENSAJE } from "src/app/constants/constants.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { MascotaService } from "src/app/services/business/mascota.service";
import { IAdvancedSearchFilters, IModal, IPagination } from "src/app/models/interfaces/interfaces.index";
import { AgregarMascotaPage } from "./agregar/agregar.page";
import Mascota, { MascotaColor, MascotaEspecie, MascotaTamano } from "src/app/models/entities/business/mascota.entity";
import { SbcService } from "src/app/services/common/sbc.service";
import { AdvancedSearchComponent } from "src/app/components/mobile/component.mobile.index";
import * as moment from "moment";

@Component({
  selector: "app-mascotas",
  templateUrl: "./mascotas.page.html",
  styleUrls: ["./mascotas.page.scss"],
})
export class MascotasPage implements OnInit {
  paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };
  formGroup: FormGroup;
  mascotas: Mascota[] = [];
  loteSeleccionado: Lote;
  lotes: Lote[];
  mostrarInfiniteScrollEn = PAGINATOR.SIZE;
  tamanos: MascotaTamano[] = [];
  colores: MascotaColor[] = [];
  especies: MascotaEspecie[] = [];
  icono: string = null;

  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private userService: UserService,
    private mascotaService: MascotaService,
    private sbcService: SbcService
  ) { }

  async ngOnInit() {
    this.resolver_icono();
    this.lotes = this.userService.user.Lotes;
    this.configurar_formulario();
    await this.getFormData();
    this.cargar_mascotas();
  }


  resolver_icono() {
    this.sbcService.resolver_icono().subscribe(
      (icono: string) => {
        this.icono = icono;
        console.log(this.icono);
      },
      (error) => {
        this.controlService.mostrar_toast_con_error(error, false);
      }
    );
  }

  open_advanced_search() {
    const componentProps: { filters: IAdvancedSearchFilters } = {
      filters: {
        showLoteFilter: true,
        selectedLote: this.loteSeleccionado.Id
      },
    };
    this.controlService
      .mostrarPopOver({
        component: AdvancedSearchComponent,
        componentProps,
        cssClass: POPOVER_STYLES.ADVANZED_SEARCH_MODAL,
        backdropDismiss: false,
      })
      .then((data) => {
        if (data) {
          this.formGroup.controls["lotes"].setValue(data.lotes);
          this.filtrar();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async getFormData() {
    this.colores = await this.mascotaService.obtenerColores();
    this.tamanos = await this.mascotaService.obtenerMascotaTamanos();
    this.especies = await this.mascotaService.obtenerEspecies();
  }

  filtrar() {
    this.limpiar_filtro();
    this.setear_lote_seleccionado();
    this.cargar_mascotas();
  }

  limpiar_filtro() {
    this.mascotas = [];
    this.mascotaService.limpiar();
  }

  cerrar_modal() {
    this.controlService.cerrar_modal();
  }

  doRefresh(event) {
    //this.limpiar_filtro();
    this.mascotaService.limpiar();
    this.mascotaService
      .obtenerMascotasByLote(this.loteSeleccionado)
      .then((resp: Mascota[]) => {
        if (resp) {
          this.mascotas = resp;
          event.target.complete();
        }
      })
      .catch((error) => {
        event.target.complete();
      });
  }

  async mostrar_agregar_mascota(mascota: Mascota = null) {
    const titulo = mascota ? 'Renovar vacunas' : 'Nueva mascota';

    const modal: IModal = { component: AgregarMascotaPage, returnData: true, componentProps: { mascota, titulo } };

    this.controlService.mostrar_modal(modal).then((info: any) => {
      this.filtrar();
    });
  }

  private cargar_mascotas(event?: any) {
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.setear_lote_seleccionado();
      this.mascotaService
        .obtenerMascotasByLote(this.loteSeleccionado, this.paginacion)
        .then((resp: Mascota[]) => {
          if (resp == null ||
            this.mascotaService.paginacionActual.cantidad === this.mascotas.length) {
            this.controlService.habilitar_infinite_scroll(event, false);
            this.controlService.mostrar_toast(
              MESSAGE.ALERT.MENSAJE_SIN_DATOS,
              TIEMPO_MENSAJE
            );
          } else {
            console.log("resp", resp);
            this.mascotas = resp;
            this.controlService.completar_infinite_scroll(event);
          }
        })
        .catch((error) => {
          this.controlService.mostrar_toast_con_error(error, false);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    });
  }

  getColor(idColor: string): string {
    return this.colores.find(item => item.Id == idColor).Color;
  }

  getSize(idTamano: number): string {
    return this.tamanos.find(item => item.Id == idTamano).Nombre;
  }

  getRaza(idRaza: string): string {
    const especie = this.especies.find(item => item.Razas.some(raza => raza.Id === idRaza));
    return especie.Razas.find(raza => raza.Id === idRaza).Raza;
  }

  getFechaVencimientoVacuna(fecha: string) {
    return moment(fecha).format('DD/MM/YYYY');
  }

  private configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      lotes: [this.lotes[0].Id, Validators.required],
    });
  }

  private setear_lote_seleccionado() {
    this.loteSeleccionado = this.lotes.filter(
      (lote: Lote) => lote.Id === this.formGroup.value.lotes
    )[0];
  }

  resolver_vista_mascota(event?) {
    if (event) {
      this.paginacion.pagina += 1;
      this.cargar_mascotas(event);
    } else {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.cargar_mascotas(event);
      });
    }
  }
}
