import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "truncateText",
})
export class TruncateTextPipe implements PipeTransform {
  transform(
    value: string,
    limit: number = 20,
    cantidadCaracteres: number = 20,
    agregarEllipses = true
  ): string {
    let result = "";
    const palabras = value.split(" ");

    palabras.forEach((palabra: string) => {
      if (palabra.length > limit) {
        result += " " + palabra.slice(0, cantidadCaracteres);
        if (agregarEllipses) {
          result += "...";
        }
      } else {
        result += " " + palabra;
      }
    });

    return result.trim();
  }
}
