import { Injectable } from "@angular/core";

// Servicios
import { NoticiaService } from "../business/noticia.service";
import { EncuestaService } from "../business/encuesta.service";
import { DocumentoService } from "../business/documento.service";
import { TicketService } from "../business/ticket.service";
import { ExpensaService } from "../business/expensa.service";
import { MultaService } from "../business/multa.service";
import { NotificacionService } from "../business/notificacion.service";
import { AccesoService } from "../business/acceso.service";
import { AutorizadoService } from "../business/autorizado.service";
import { CamaraService } from "../business/camara.service";
import { SegmentService } from "src/app/services/common/segment.service";
import { TiposService } from "../business/tipos.service";

@Injectable({
  providedIn: "root",
})
export class DisposeService {
  constructor(
    private noticiaService: NoticiaService,
    private encuestaService: EncuestaService,
    private documentoService: DocumentoService,
    private ticketService: TicketService,
    private expensaService: ExpensaService,
    private multaService: MultaService,
    private notificacionService: NotificacionService,
    private accesoService: AccesoService,
    private autorizadoService: AutorizadoService,
    private camaraService: CamaraService,
    private segmentService: SegmentService,
    private tiposService: TiposService
  ) {}

  dispose() {
    this.noticiaService.limpiar();
    this.encuestaService.limpiar_encuestas();
    this.documentoService.limpiar();
    this.ticketService.limpiar();
    this.expensaService.limpiar();
    this.multaService.limpiar();
    this.notificacionService.limpiar();
    this.accesoService.limpiar();
    this.autorizadoService.limpiar();
    this.camaraService.limpiar();
    this.segmentService.limpiar();
    this.tiposService.limpiar_todo();
  }
}
