import { Component, OnInit, Input } from "@angular/core";
import { Subscription } from "rxjs";
import { ControlService } from "src/app/services/common/control.service";
import { Notificacion } from "src/app/models/entities/entity.index";
import { UtilService } from "src/app/services/common/util.service";
import { NotificacionService } from "src/app/services/business/notificacion.service";

@Component({
  selector: "app-notification-detail",
  templateUrl: "./notification-detail.page.html",
  styleUrls: ["./notification-detail.page.scss"],
})
export class NotificationDetailPage implements OnInit {
  @Input() notification: Notificacion;
  @Input("isNotification") isNotification = false;
  showHTMLContentNotification = false;

  constructor(
    private controlService: ControlService,
    private utilService: UtilService,
    private notificacionService: NotificacionService
  ) {}

  ngOnInit() {}

  cerrar_modal() {
    this.controlService.cerrar_modal();
  }

  public set_Html_Iframe(dataHTML: String) {
    var iframe = document.getElementById("iframeID") as any;
    iframe =
      iframe.contentWindow ||
      iframe.contentDocument.document ||
      iframe.contentDocument;

    iframe.document.open();
    iframe.document.write(dataHTML);
    iframe.document.close();
  }

  openDocument(url: string) {
    this.utilService.openNewTab(url);
  }

  showMoreInformation() {
    this.notificacionService
      .get_notification(this.notification.Id)
      .then((notification: Notificacion) => {
        this.notification.Descripcion = notification.Descripcion;
        this.showHTMLContentNotification = true;
      });
  }
}
