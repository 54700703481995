import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import {
  DomSanitizer,
  SafeUrl,
  SafeResourceUrl,
} from "@angular/platform-browser";

// Servicios
import { EstadoService } from "../business/estado.service";
import { TiposService } from "../business/tipos.service";
import { CatalogoService } from "../business/catalogo.service";
import { UtilService } from "./util.service";
import { StorageService } from "./storage.service";
import { ConfigService } from "./config.service";
import { SecurityService } from "../security/security.service";
import { DeviceService } from "./device.service";
import { StyleService } from "./style.service";
import { FileService } from "./file.service";
import { ControlService } from "./control.service";
import { MenuService } from "./menu.service";
import { UserService } from "../security/user.service";
import { CategoriaService } from "../business/categoria.service";

// Clases
import {
  Estado,
  Item,
  ResponseApp,
  Categoria,
} from "src/app/models/entities/entity.index";
import { VersionApp } from "src/app/models/entities/business/version.entity";
import { SplashIcon } from "src/app/models/entities/common/splashIcon.entity";
import { SettingApp } from "src/app/models/entities/common/setting.entity";

// Constantes
import { STORAGE } from "src/app/constants/storage.constant";
import { PATH_CONFIG } from "src/app/constants/url.constant";
import {
  MESSAGE,
  environment,
  TIEMPO_MENSAJE,
  MENU,
} from "src/app/constants/constants.index";

// Enums
import {
  EnumTipoConfig,
  EnumTipoMenu,
  EnumTipoSegmento,
} from "src/app/models/enums/tipo.enum";
import { EnumTipoImg, EnumContentType } from "../../models/enums/tipo.enum";

// Interfaces
import { IIdentity } from "src/app/models/interfaces/common/identity.interface";

@Injectable({
  providedIn: "root",
})
export class SbcService {
  icono: string | SafeUrl = null;
  splash: string | SafeResourceUrl = null;
  menu: IIdentity[] = [];
  estados: Estado[] = [];
  tiposRelaciones: Item[] = [];
  tiposAutorizaciones: Item[] = [];
  tiposCanal: Item[] = [];
  tiposCategoriasNoticia: Categoria[] = [];
  versionInfo: VersionApp;

  constructor(
    private estadoService: EstadoService,
    private tiposService: TiposService,
    private catalogoService: CatalogoService,
    private utilService: UtilService,
    private storageService: StorageService,
    private configService: ConfigService,
    private securityService: SecurityService,
    private deviceService: DeviceService,
    private styleService: StyleService,
    private fileService: FileService,
    private domSanitizer: DomSanitizer,
    private controlService: ControlService,
    private menuService: MenuService,
    private userService: UserService,
    private categoriaService: CategoriaService
  ) { }

  async cargar_datos_preliminares_aplicacion() {
    await this.configService.cargar_configuracion_app();
    await this.configService.cargar_configuracion_app_server();
    this.versionInfo = this.configService.configServer.Shell;
    this.resolver_disenio();
  }

  async cargar_datos_aplicacion() {
    return Promise.all([
      this.estadoService.obtener_estados(),
      this.tiposService.obtener_tipos_de_relacion(),
      this.tiposService.obtener_tipos_de_autorizacion(),
      this.tiposService.obtener_tipos_de_canal(),
      this.categoriaService.obtener_catagorias(),
    ]).then((values) => {
      this.estados = values[0] as Estado[];
      if (this.estados.length === 0) {
        this.storageService
          .cargar(STORAGE.KEY.DATA.ESTADOS)
          .then((value: string) => {
            this.estados = JSON.parse(this.storageService.currentValue);
          });
      }
      this.tiposRelaciones = values[1] as Item[];
      this.tiposAutorizaciones = values[2] as Item[];
      this.tiposCanal = values[3] as Item[];
      this.tiposCategoriasNoticia = values[4] as Categoria[];
    });
  }

  resolver_disenio() {
    console.log("entró a resolver_dinsenio 120");
    const result = new Observable<void | string>((observer) => {
      this.catalogoService
        .cargar_catalogo_barrio(this.securityService.codigoBarrio)
        .then((resp: ResponseApp) => {
          if (typeof resp.Status === "boolean" && resp.Status) {
            this.styleService.resolver_vista();

            let navbar = document.getElementById("navbar");
            if (navbar) navbar.removeAttribute("style");

            let content = document.getElementById("content");
            if (content) content.removeAttribute("style");

            observer.next();
          } else {
            observer.next(MESSAGE.ERROR.MENSAJE_ERROR_DISENIO);
          }
        })
        .catch((error) => {
          console.log(error);
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
        });
    });

    return result;
  }

  /**
   * Resuelve el icono de la app
   * Para el caso mobile devuelve url segura
   * Retorna un observable que devuelve la url del icono
   */
  resolver_icono(): Observable<string | SafeUrl> {
    const icono = new Observable<string | SafeUrl>((observer) => {
      if (this.icono == null) {
        this.configService.cargar_configuracion_app().then((config: any) => {
          this.cargar_splash_icon_por_config(EnumTipoImg.Icon).then(
            (resp: SplashIcon[]) => {
              const results = resp.filter(
                (splashIcon: SplashIcon) => splashIcon.Type === EnumTipoImg.Icon
              );
              if (results.length > 0) {
                const icon = results[0];

                console.log("antes del if 163 sbcService, icon: ", icon);
                if (icon.Config === EnumTipoConfig.Local) {
                  console.log("entró al if 166 sbcService, icon: ", icon);
                  this.icono = this.resolver_icono_local(icon);
                  observer.next(this.icono);
                } else {
                  console.log("entró al if 171 sbcService, icon: ", icon);
                  this.icono = this.domSanitizer.bypassSecurityTrustUrl(
                    icon.Url
                  );
                  observer.next(this.icono);
                  /*this.resolver_icono_remoto(icon)
                      .then((iconBase64: string) => {

                        this.icono = this.domSanitizer.bypassSecurityTrustUrl(iconBase64);
                        observer.next(this.icono);
                      });*/
                }
              }
            }
          );
        });
      } else {
        observer.next(this.icono);
      }
    });

    return icono;
  }

  resolver_splash() {
    if (this.deviceService.isWeb) {
      return;
    }

    const splash = new Observable<string | SafeResourceUrl>((observer) => {
      if (this.splash == null) {
        this.configService.cargar_configuracion_app().then((config: any) => {
          this.cargar_splash_icon_por_config(EnumTipoImg.Splash).then(
            (resp: SplashIcon[]) => {
              const results = resp.filter(
                (splashIcon: SplashIcon) =>
                  splashIcon.Type === EnumTipoImg.Splash
              );
              if (results.length > 0) {
                const splashSetting = results[0];
                if (splashSetting.Config === EnumTipoConfig.Local) {
                  this.splash = this.resolver_splash_local(splashSetting);
                  observer.next(this.splash);
                } else {
                  this.splash =
                    this.domSanitizer.bypassSecurityTrustResourceUrl(
                      splashSetting.Url
                    );
                  observer.next(this.splash);

                  // this.resolver_splash_remoto(splashSetting)
                  //   .then((splashUrl: string) => {

                  //     this.splash = this.domSanitizer.bypassSecurityTrustResourceUrl(splashUrl);
                  //     observer.next(this.splash);
                  //   });
                }
              }
            }
          );
        });
      } else {
        observer.next(this.splash);
      }
    });

    return splash;
  }

  /**
   * Resuelve el menu de la app.
   * Retorna un observable que devuelve un array con la configuración del menú a aplicar para cada perfil
   * @param tipoMenu tipo de menú a resolver (preholder, home)
   * @param property (Opcional) propiedad de la que se quiere obtener el menú (sirve para los casos de menues como Mobile anidados)
   */
  resolver_menu(
    tipoMenu: EnumTipoMenu,
    property?: EnumTipoSegmento
  ): Observable<IIdentity[] | string> {
    const result = new Observable<IIdentity[] | string>((observer) => {
      this.catalogoService
        .cargar_catalogo_barrio(this.securityService.codigoBarrio)
        .then((resp: ResponseApp) => {
          console.log(resp);
          if (typeof resp.Status === "boolean" && resp.Status) {
            this.configService
              .cargar_configuracion_app_server()
              .then((config: SettingApp) => {
                this.menuService
                  .extraer_menu_setting(config)
                  .then((menuSetting: any) => {
                    observer.next(
                      this.menuService.obtener_menu_por_tipo(
                        menuSetting,
                        tipoMenu,
                        property
                      )
                    );
                  });
              })
              .catch((err) => {
                // Si hay error para obtener menú, me quedo con la configuración del mismo local
                const menuLocal = {
                  MENU: this.deviceService.isWeb
                    ? { WEB: MENU.WEB }
                    : { MOBILE: MENU.MOBILE },
                };
                observer.next(
                  this.menuService.obtener_menu_por_tipo(
                    menuLocal,
                    tipoMenu,
                    property
                  )
                );
              });
          } else {
            observer.next(MESSAGE.ERROR.MENSAJE_ERROR_MENU);
          }
        });
    });

    return result;
  }

  /**
   * Refresca en caché los datos del usuario y de su sesión
   */
  guardar_datos_en_cache() {
    this.storageService.cargar(STORAGE.KEY.PERFIL).then((value: string) => {
      this.userService.user = JSON.parse(value);
    });

    this.storageService
      .cargar(STORAGE.KEY.DATA.ESTADOS)
      .then((value: string) => {
        this.estados = JSON.parse(value);
      });

    this.storageService
      .cargar(STORAGE.KEY.DATA.TIPO_AUTORIZADO)
      .then((value: string) => {
        this.tiposAutorizaciones = JSON.parse(value);
      });

    this.storageService
      .cargar(STORAGE.KEY.DATA.TIPO_RELACION)
      .then((value: string) => {
        this.tiposRelaciones = JSON.parse(value);
      });
  }

  private resolver_icono_local(icon: SplashIcon) {
    const config = this.configService.config;
    const codigoBarrio = this.deviceService.isWeb
      ? config.CODIGO_BARRIO
      : this.securityService.codigoBarrio;
    const aplicarIcono = this.obtenerBarrio(codigoBarrio);

    this.icono =
      icon.Url && icon.Enabled && aplicarIcono
        ? icon.Url.replace(config.KEY.CODIGO, codigoBarrio.toLowerCase())
        : PATH_CONFIG.ICON_DEFAULT_URL;

    return this.icono;
  }

  private resolver_splash_local(splash: SplashIcon) {
    const config = this.configService.config;
    const codigoBarrio = this.securityService.codigoBarrio;
    const aplicarSplash = this.obtenerBarrio(codigoBarrio);

    this.splash =
      splash.Url && splash.Enabled && aplicarSplash
        ? splash.Url.replace(config.KEY.CODIGO, codigoBarrio.toLowerCase())
        : PATH_CONFIG.SPLASH_DEFAULT_URL;

    return this.splash;
  }

  /** Se encarga de sincronizar el icono de la app de manera remota.
   * Si este ya se encuentra en el dispositivo, no lo vuelve a descargar
   * La configuración del icono se toma desde el server
   * La única manera que se actualice el archivo es cambiando el nombre del icono desde el server
   */
  private async resolver_icono_remoto(icon: SplashIcon) {
    this.icono =
      icon.Url && icon.Enabled ? icon.Url : PATH_CONFIG.ICON_DEFAULT_URL;

    if (this.deviceService.isWeb || environment.APP.MOCK_MOBILE) {
      this.icono =
        icon.Url && icon.Enabled ? icon.Url : PATH_CONFIG.ICON_DEFAULT_URL;
    } else {
      const fileName = this.utilService.get_last_value(icon.Url);
      const existe = await this.fileService.file_existe(fileName);

      if (existe) {
        console.log("Mantiene el mismo icono");
        // this.icono = await this.fileService.obtener_url_relativa(fileName);
      } else {
        await this.fileService.download_native(icon.Url, EnumContentType.PNG);
        //this.icono = await this.fileService.obtener_url_relativa(fileName);
        console.log("NO Mantiene el mismo icono");
      }
    }

    return this.icono;
  }

  /** Se encarga de sincronizar el splash de la app de manera remota.
   * Si este ya se encuentra en el dispositivo, no lo vuelve a descargar
   * La configuración del splash se toma desde el server
   * La única manera que se actualice el archivo es cambiando el nombre del splash desde el server
   */
  private async resolver_splash_remoto(splash: SplashIcon) {
    if (this.deviceService.isWeb || environment.APP.MOCK_MOBILE) {
      this.splash =
        splash.Url && splash.Enabled
          ? splash.Url
          : PATH_CONFIG.SPLASH_DEFAULT_URL;
    } else {
      const fileName = this.utilService.get_last_value(splash.Url);
      const existe = await this.fileService.file_existe(fileName);

      if (existe) {
        console.log("Mantiene el mismo splash");
        this.splash = await this.fileService.obtener_url_relativa(fileName);
      } else {
        await this.fileService.download_native(splash.Url, EnumContentType.XML);
        //this.splash = await this.fileService.obtener_url_relativa(fileName);
        console.log("NO Mantiene el mismo splash");
      }
    }

    return this.splash;
  }

  private async cargar_splash_icon_por_config(tipoImg: EnumTipoImg) {
    if (!this.configService.configServer) {
      await this.configService.cargar_configuracion_app_server();
    }

    const splashIcons: SplashIcon[] = [];
    return new Promise<SplashIcon[]>((resolve, reject) => {
      if (tipoImg === EnumTipoImg.Splash) {
        splashIcons.push(
          this.resolver_configuracion(this.configService.configServer.Splash)
        );
      } else if (tipoImg === EnumTipoImg.Icon) {
        splashIcons.push(
          this.resolver_configuracion(this.configService.configServer.Icon)
        );
      } else {
        splashIcons.push(
          this.resolver_configuracion(this.configService.configServer.Splash)
        );
        splashIcons.push(
          this.resolver_configuracion(this.configService.configServer.Icon)
        );
      }

      resolve(splashIcons);
    });
  }

  private obtenerBarrio(codigoBarrio: string): string {
    return this.configService.config.BARRIOS.filter(
      (codigo: string) => codigo.toLowerCase() === codigoBarrio.toLowerCase()
    )[0];
  }

  private resolver_configuracion(sIcon: SplashIcon): SplashIcon {
    const config = this.configService.config;
    if (sIcon.Config === EnumTipoConfig.Local) {
      if (sIcon.Type === EnumTipoImg.Splash) {
        sIcon.Url = config.SPLASH.Url;
        sIcon.Enabled = config.SPLASH.Enabled;
      } else if (sIcon.Type === EnumTipoImg.Icon) {
        sIcon.Url = config.ICON.Url;
        sIcon.Enabled = config.ICON.Enabled;
      }
    }

    return sIcon;
  }
}
