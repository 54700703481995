import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Servicios
import { RestService } from "../common/rest.service";
import { CatalogoService } from "./catalogo.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";
import { SecurityService } from "../security/security.service";

// Constantes
import { IPagination } from "src/app/models/interfaces/interfaces.index";
import { IDictionary } from "src/app/models/interfaces/interfaces.index";

// Clases
import { Noticia, ResponseApp } from "src/app/models/entities/entity.index";
import { ItemElement } from "src/app/models/clases/ItemElement";

// Enums
import { EnumTipoCanal } from "src/app/models/enums/tipo.enum";

@Injectable({
  providedIn: "root",
})

export class NoticiaService {
  private paginacion: IPagination = { pagina: 1, cantidad: 10 };
  private itemElement = new ItemElement<Noticia>();

  noticias: Noticia[] = [];
  paginacionActual: IPagination = { pagina: 1, cantidad: 10 };

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private exceptionService: ExceptionService,
    private securityService: SecurityService
  ) { }

  /**
   * Obtiene las noticias del barrio para la portada de la web / app estando autenticado.
   * No distingue entre tipo de aplicación
   * @param paginacion Datos de paginación
   */
  async obtener_publicaciones(
    paginacion: IPagination = this.paginacion
  ): Promise<Noticia[]> {
    const url = `${this.catalogoService.catalogo}ObtenerPublicacionesHome/`;
    const options: IDictionary[] = [
      { key: "{pagina}", value: paginacion.pagina.toString() },
      { key: "{cantidad}", value: paginacion.cantidad.toString() },
    ];

    return this.obtener_resultado_noticias(url, true, true, options);
  }

  async marcarPublicacionLeida(publicacionId: string) {
    const url = `${this.catalogoService.catalogo}MarcarPublicacionLeida/`;
    const options: IDictionary[] = [
      { key: "{token}", value: this.securityService.token },
      { key: "{idPublicacion}", value: publicacionId },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, false, false, options)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
   * Obtiene las noticias del barrio para la portada de la web / app estando autenticado
   * @param canal Indicador web / app
   * @param paginacion datos de paginación
   */
  async obtener_publicaciones_por_canal(
    data: { texto?: string; categoriaId?: string },
    canal: EnumTipoCanal,
    paginacion: IPagination = this.paginacion
  ): Promise<Noticia[] | string> {
    const url = `${this.catalogoService.catalogo}ObtenerPublicacionesPorFiltros/`;
    const options: IDictionary[] = [
      { key: "{canal}", value: canal.toString() },
      { key: "{texto}", value: data.texto ? data.texto : "%20" },
      { key: "{idCategoria}", value: data.categoriaId !== "todos" ? data.categoriaId.toString() : null },
      { key: "{pagina}", value: paginacion.pagina.toString() },
      { key: "{cantidad}", value: paginacion.cantidad.toString() },
    ];

    return this.obtener_resultado_noticias(url, true, true, options);
  }

  async marcarTodoLeido() {
    const token = this.securityService.token;
    const entidad = "Publicacion";
    const body = {
      token,
      entidad
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(`${this.catalogoService.catalogo}MarcarTodoLeidoParaEntidad`, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
   * Obtiene las noticias del barrio para la portada de la web / app sin estar autenticado
   * @param canal Indicador web / app
   * @param paginacion datos de paginación
   */
  async obtener_publicaciones_pre_holder_por_canal(
    canal: EnumTipoCanal,
    paginacion: IPagination = this.paginacion
  ): Promise<Noticia[] | string> {
    const url = `${this.catalogoService.catalogo}ObtenerPublicacionesHomePorCanal_v1/`;
    const options: IDictionary[] = [
      { key: "{canal}", value: canal.toString() },
      { key: "{pagina}", value: paginacion.pagina.toString() },
      { key: "{cantidad}", value: paginacion.cantidad.toString() },
    ];

    return this.obtener_resultado_noticias(url, true, true, options);
  }

  /**
   * Crea o actualiza una noticia
   * @param noticia Noticia a persistir
   */
  async guardar_noticia(noticia: Noticia) {
    const url = noticia.Id
      ? `${this.catalogoService.catalogo}ActualizarPublicacion`
      : `${this.catalogoService.catalogo}CrearPublicacion`;

    const body = {
      token: this.securityService.token,
      publicacion: noticia,
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            console.log(resp);
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
   * Elimina una noticia
   * @param id Identificador de la noticia
   */
  async eliminar_noticia(id: string) {
    const url = `${this.catalogoService.catalogo}EliminarPublicacion`;

    const body = {
      token: this.securityService.token,
      idPublicacion: id,
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async get_noticia(idPublicacion: string) {
    const url = `${this.catalogoService.catalogo}ObtenerPublicacion/`;
    const options: IDictionary[] = [
      { key: "{idPublicacion}", value: idPublicacion },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, true, options)
        .pipe(
          map<any, Noticia>((resp: any) => {
            this.exceptionService.resolver_error(resp);
            const noticia = this.utilService.mapear_propiedades(
              resp.Data,
              new Noticia()
            );
            noticia.UrlImagen = this.utilService.resolver_url_imagen(
              noticia.UrlImagen
            );
            return noticia;
          })
        )
        .subscribe(
          (data: Noticia) => {
            resolve(data);
          },
          (error) => {
            console.log("error en get_noticia 193", error);
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log("error en get_noticia 198", error);
      throw error;
    });
  }

  limpiar() {
    this.noticias = [];
  }

  private async obtener_resultado_noticias(
    url: string,
    token: boolean,
    idBarrio: boolean,
    options: IDictionary[]
  ) {
    return new Promise<Noticia[]>((resolve, reject) => {
      this.restService
        .get(url, token, idBarrio, options)
        .pipe(
          map<any, Noticia[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;

            const noticias = this.utilService.mapear_propiedades(
              resp.Data,
              new Noticia()
            );
            noticias.forEach((noticia: Noticia) => {
              const pathUrl = this.utilService.resolver_url_imagen(
                noticia.UrlImagen
              );
              noticia.UrlImagen = this.versionarImagen(
                pathUrl,
                noticia.FechaHoraModificacion
              );
              if (noticia.ContenidoIsHTML === 0) {
                noticia.Contenido =
                  noticia.Contenido.split("\\r\\n").join("<br>");
              }
            });

            this.noticias = this.itemElement.refrescar(noticias, this.noticias);
            return this.noticias;
          })
        )
        .subscribe(
          (data: Noticia[]) => {
            resolve(data);
          },
          (error) => {
            this.limpiar();
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  //este metodo le agrega un parametro de version para que no quede en cache la imagen
  //ya que la modifican desde el backend pero la url es la misma.
  private versionarImagen(
    pathUrl: string,
    fechaModificacion: Date | number = null
  ) {
    try {
      if (!pathUrl || !fechaModificacion) {
        return pathUrl;
      }

      const hasParams = pathUrl.indexOf("?") >= 0;
      let separador = "&";
      if (!hasParams) {
        //la mayoria tiene parametros
        separador = "?";
      }

      return pathUrl + separador + "ver=" + fechaModificacion;
    } catch (error) {
      console.log(error);
      return pathUrl;
    }
  }
}
