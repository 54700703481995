import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Clases
import { Item } from "src/app/models/entities/entity.index";

// Servicios
import { CatalogoService } from "./catalogo.service";
import { RestService } from "../common/rest.service";
import { UtilService } from "../common/util.service";
import { StorageService } from "../common/storage.service";
import { ExceptionService } from "../common/exception.service";

// Constantes
import { STORAGE } from "src/app/constants/constants.index";
import { EnumTipoCanal } from "src/app/models/enums/tipo.enum";

@Injectable({
  providedIn: "root",
})
export class TiposService {
  tiposRelaciones: Item[] = [];
  tiposAutorizaciones: Item[] = [];
  tiposCanal: Item[] = [];

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private storageService: StorageService,
    private exceptionService: ExceptionService
  ) { }

  // obtenerTipoRelacion
  async obtener_tipos_de_relacion(): Promise<Item[]> {
    const url = `${this.catalogoService.catalogo}ObtenerTipoRelacionByUser/`;
    return new Promise<Item[]>((resolve, reject) => {
      this.restService
        .get(url, true)
        .pipe(
          map<any, Item[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);
            this.tiposRelaciones = this.utilService.mapear_propiedades(
              resp.Data,
              new Item()
            );
            this.storageService.guardar({
              key: STORAGE.KEY.DATA.TIPO_RELACION,
              value: JSON.stringify(this.tiposRelaciones),
            });
            return this.tiposRelaciones;
          })
        )
        .subscribe(
          (data: Item[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  // obtenerTipoAutorizado
  async obtener_tipos_de_autorizacion(): Promise<Item[]> {
    const url = `${this.catalogoService.catalogo}ObtenerTipoAutorizado`;
    return new Promise<Item[]>((resolve, reject) => {
      this.restService
        .get(url)
        .pipe(
          map<any, Item[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);
            this.tiposAutorizaciones = this.utilService.mapear_propiedades(
              resp.Data,
              new Item()
            );
            this.storageService.guardar({
              key: STORAGE.KEY.DATA.TIPO_AUTORIZADO,
              value: JSON.stringify(this.tiposAutorizaciones),
            });
            return this.tiposAutorizaciones;
          })
        )
        .subscribe(
          (data: Item[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
   * Obtiene los tipos de canales disponibles (Web, Mobile, Todos)
   */
  async obtener_tipos_de_canal(): Promise<Item[]> {
    return new Promise<Item[]>((resolve, reject) => {
      //vaciamos siempre la list antes de cargar los tipos de canal
      this.tiposCanal = [];
      Object.keys(EnumTipoCanal).forEach((key: string) => {
        if (typeof EnumTipoCanal[key] === "string") {
          this.tiposCanal.push({
            Id: Number(key),
            Nombre: EnumTipoCanal[key].toString(),
          });
        }
      });

      resolve(this.tiposCanal);
    });
  }

  limpiar_todo() {
    this.limpiar_tipos_relaciones();
    this.limpiar_tipos_autorizaciones();
    this.limpiar_tipos_canal();
  }

  limpiar_tipos_relaciones() {
    this.tiposRelaciones = [];
  }

  limpiar_tipos_autorizaciones() {
    this.tiposAutorizaciones = [];
  }

  limpiar_tipos_canal() {
    this.tiposCanal = [];
  }
}
