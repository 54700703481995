export default class Mascota {
  Id: string;
  IdLote: string;
  Nombre: string;
  Identificacion: string;
  Tamano: number;
  UrlImagen: string;
  VacunaUrlImagen: string;
  VacunaVencimiento: string;
  IdColor?: string;
  IdEspecie?: string;
  IdRaza?: string;
  VacunaFotoBase64?: string;
  FotografiaBase64?: string = null;
}

class Raza {
  Id: string;
  Raza: string;
}
export class MascotaEspecie {
  Id: string;
  Especie: string;
  Razas: Raza[]
};

export class MascotaColor {
  Id: string;
  Color: string;
}

export class MascotaTamano {
  Id: number;
  Nombre: string;
}