import { Component, OnInit, Input } from "@angular/core";
import { Coordinates } from "@ionic-native/geolocation/ngx";
import { IEmergencyType } from "src/app/models/interfaces/interfaces.index";
import { ControlService } from "src/app/services/common/control.service";

@Component({
  selector: "app-countdown",
  templateUrl: "./countdown.component.html",
  styleUrls: ["./countdown.component.scss"],
})
export class CountdownComponent implements OnInit {
  @Input("emergencyType") emergencyType: IEmergencyType;
  countdownValue = 6;
  timer;

  constructor(private controlService: ControlService) {}

  ngOnInit() {
    this.updateClock();
  }

  updateClock() {
    if (this.countdownValue === 0) {
      clearTimeout(this.timer);
      this.controlService.cerrarPopOver(this.emergencyType);
    } else {
      this.countdownValue -= 1;
      this.timer = setTimeout(() => this.updateClock(), 1000);
    }
  }

  cancelEmergency() {
    const cancelOption: IEmergencyType = {
      id: -1,
      description: "cancel",
    };
    clearTimeout(this.timer);
    this.controlService.cerrarPopOver(cancelOption);
  }
}
