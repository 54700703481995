import { Component, OnInit, Input } from "@angular/core";

// Servicios
import { ControlService } from "src/app/services/common/control.service";


@Component({
  selector: "app-confirmar-reserva-modal",
  templateUrl: "./confirmar-reserva-modal.page.html",
  styleUrls: ["./confirmar-reserva-modal.page.scss"],
})
export class ConfirmarReservaModalPage implements OnInit {
  @Input() reservationObject: {
    lote: string,
    persons: string,
    sentido: string,
    busStop: string,
    date: string,
    schedule: string,
  };

  constructor(
    private controlService: ControlService,
  ) { }
  ngOnInit() {
  }


  closeModal(confirm: boolean) {
    this.controlService.cerrar_modal({ confirm });
  }

}
