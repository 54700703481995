import { Documento } from "./documento.entity";

export class Notificacion {
  Id: string = null;
  Nombre: string = null;
  Descripcion: string = null;
  Numero: string = null;
  FechaAlta: Date | any;
  FechaVisualizacion: Date | any;
  Estado: number = 0;
  EstadoDescripcion: string = null;
  DescripcionIsHTML: number = 0;
  Documentos: Documento[];
}

export class NotificacionConfig {
  MobileNotificationAccess: boolean = false;
  MobileNotificationNews: boolean = false;
  MobileAuthorizationOneClick: boolean = false;
  AccessMailSend: boolean = false;
}

export class NotificacionOneSignal {

  notificationId: string = null;
  title: string = null;
  body: string = null;
  additionalData: any = null;
}

export class AditionalPayLoad {
  id: string = null;
  fecha: any = null;
  autorizado: any = null;
  documentoAutorizado: any = null;
  tipoAutorizado: any = null;
  puesto: any = null;
  idAutorizacion: any = null;
  urlImagen: any = null;
  ttl: any = null;
  vencimiento: any = null;
  tipoMensaje: string = null;
}
