import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Clases
import { Expensa, Lote } from "src/app/models/entities/entity.index";
import { ItemElement } from "src/app/models/clases/ItemElement";

// Interfaces
import {
  IPagination,
  IDictionary,
} from "src/app/models/interfaces/interfaces.index";

// Servicios
import { CatalogoService } from "./catalogo.service";
import { RestService } from "../common/rest.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";

@Injectable({
  providedIn: "root",
})
export class ExpensaService {
  private paginacion: IPagination = { pagina: 1, cantidad: 10 };
  private itemElement = new ItemElement<Expensa>();

  expensas: Expensa[] = [];
  paginacionActual: IPagination = { pagina: 1, cantidad: 10 };

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private exceptionService: ExceptionService
  ) {}

  // obtenerExpensas
  async obtener_expensas(
    lote: Lote,
    paginacion: IPagination = this.paginacion
  ) {
    const url = `${this.catalogoService.catalogo}ObtenerExpensas/`;
    const options: IDictionary[] = [
      { key: "{idLote}", value: lote.Id },
      { key: "{pagina}", value: paginacion.pagina.toString() },
      { key: "{cantidad}", value: paginacion.cantidad.toString() },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Expensa[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;

            const expensas = this.utilService.mapear_propiedades(
              resp.Data,
              new Expensa()
            );
            this.expensas = this.itemElement.refrescar(expensas, this.expensas);

            console.log("expensas", this.expensas);
            return this.expensas;
          })
        )
        .subscribe(
          (data: Expensa[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  limpiar() {
    this.expensas = [];
  }
}
