import { NgModule } from "@angular/core";
import { RouteReuseStrategy } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

// Módulos
import { ComponentsMobileModule } from "./components/components-mobile.module";
import { ComponentsWebModule } from "./components/components-web.module";
import { DirectiveModule } from "./directives/directive.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ForgotMyPasswordPageModule } from "./pages/mobile/login/forgot-my-password/forgot-my-password.module";
import { EmergencyPageModule } from "./pages/mobile/emergency/emergency.module";
import { OnboardingPageModule } from "./pages/mobile/onboarding/onboarding.module";
import { EncuestaAbiertaModalPageModule } from "src/app/pages/mobile/home/encuesta/encuesta-abierta-modal/encuesta-abierta-modal.module";
import { EncuestaCerradaModalPageModule } from "src/app/pages/mobile/home/encuesta/encuesta-cerrada-modal/encuesta-cerrada-modal.module";
import { AgregarTicketPageModule } from "src/app/pages/mobile/mensaje/ticket/agregar/agregar.module";
import { DetalleTicketPageModule } from "src/app/pages/mobile/mensaje/ticket/detalle/detalle.module";
import { DetalleMultaPageModule } from "src/app/pages/mobile/mensaje/multa/detalle/detalle.module";
import { ImagenModalMultaPageModule } from "src/app/pages/mobile/mensaje/multa/imagen-modal/imagen-modal.module";
import { AgregarAutorizadoPageModule } from "src/app/pages/mobile/seguridad/autorizado/agregar/agregar-autorizado.module";
import { DetalleAutorizadoPageModule } from "src/app/pages/mobile/seguridad/autorizado/detalle/detalle-autorizado.module";
import { ObservacionAutorizadoPageModule } from "src/app/pages/mobile/seguridad/autorizado/observacion/observacion-autorizado.module";
import { CamaraModalPageModule } from "src/app/pages/mobile/seguridad/camara/camara-modal/camara-modal.module";
import { PerfilPageModule } from "src/app/pages/mobile/perfil/perfil.module";
import { PushNotificationPageModule } from "src/app/pages/mobile/push-notification/push-notification.module";
import { NotificationDetailPageModule } from "src/app/pages/mobile/notification-detail/notification-detail.module";
import { SplashPageModule } from "src/app/pages/mobile/splash/splash.module";
import { ChangePasswordPageModule } from "src/app/pages/mobile/perfil/change-password/change-password.module";
import { ContactsPageModule } from "src/app/pages/mobile/perfil/contacts/contacts.module";
import { MascotasPageModule } from "src/app/pages/mobile/perfil/mascotas/mascotas.module";
import { AgregarMascotaPageModule } from "src/app/pages/mobile/perfil/mascotas/agregar/agregar.module";
import { PreNoticiaPageModule } from "src/app/pages/mobile/pre-holder/pre-noticia/pre-noticia.module";
import { AgregarNoticiaPageModule } from "src/app/pages/mobile/home/noticia/agregar/agregar.module";
import { AgregarReservaCamionetaPageModule } from "src/app/pages/mobile/home/camioneta/agregar/agregar.module";
import { CamionetaPageModule } from "src/app/pages/mobile/home/camioneta/camioneta.module";
import { DetailsReservaCamionetaPageModule } from "src/app/pages/mobile/home/camioneta/details/details.module";
import { ConfirmarReservaModalPageModule } from "src/app/pages/mobile/home/camioneta/confirmar-reserva-modal/confirmar-reserva-modal.module";
import { AgregarInvitacionPageModule } from "./pages/mobile/seguridad/invitacion/agregar/agregar-invitacion.module";
import { DetalleInvitacionPageModule } from "./pages/mobile/seguridad/invitacion/detalle/detalle-invitacion.module";
import { DetalleNoticiaPageNotificationModule } from "./pages/mobile/home/detalle-noticia/detalle-noticia.module";
import { LotePageModule } from "./pages/mobile/seguridad/lote/lote.module";
import { BuscadorPageModule } from "./pages/mobile/seguridad/buscador/buscador.module";

// Páginas
import { ForgotMyPasswordPage } from "src/app/pages/mobile/login/forgot-my-password/forgot-my-password.page";
import { EmergencyPage } from "src/app/pages/mobile/emergency/emergency.page";
import { EncuestaAbiertaModalPage } from "src/app/pages/mobile/home/encuesta/encuesta-abierta-modal/encuesta-abierta-modal.page";
import { EncuestaCerradaModalPage } from "src/app/pages/mobile/home/encuesta/encuesta-cerrada-modal/encuesta-cerrada-modal.page";
import { AgregarTicketPage } from "src/app/pages/mobile/mensaje/ticket/agregar/agregar.page";
import { AgregarMascotaPage } from "src/app/pages/mobile/perfil/mascotas/agregar/agregar.page";
import { DetalleTicketPage } from "src/app/pages/mobile/mensaje/ticket/detalle/detalle.page";
import { DetalleNoticiaPageNotification } from "src/app/pages/mobile/home/detalle-noticia/detalle-noticia.page";
import { DetalleMultaPage } from "src/app/pages/mobile/mensaje/multa/detalle/detalle.page";
import { ImagenModalMultaPage } from "src/app/pages/mobile/mensaje/multa/imagen-modal/imagen-modal.page";
import { AgregarAutorizadoPage } from "src/app/pages/mobile/seguridad/autorizado/agregar/agregar-autorizado.page";
import { DetalleAutorizadoPage } from "src/app/pages/mobile/seguridad/autorizado/detalle/detalle-autorizado.page";
import { ObservacionAutorizadoPage } from "src/app/pages/mobile/seguridad/autorizado/observacion/observacion-autorizado.page";
import { CamaraModalPage } from "src/app/pages/mobile/seguridad/camara/camara-modal/camara-modal.page";
import { PerfilPage } from "src/app/pages/mobile/perfil/perfil.page";
import { PushNotificationPage } from "src/app/pages/mobile/push-notification/push-notification.page";
import { NotificationDetailPage } from "src/app/pages/mobile/notification-detail/notification-detail.page";
import { SplashPage } from "src/app/pages/mobile/splash/splash.page";
import { ChangePasswordPage } from "src/app/pages/mobile/perfil/change-password/change-password.page";
import { ContactsPage } from "src/app/pages/mobile/perfil/contacts/contacts.page";
import { MascotasPage } from "src/app/pages/mobile/perfil/mascotas/mascotas.page";
import { PreNoticiaPage } from "src/app/pages/mobile/pre-holder/pre-noticia/pre-noticia.page";
import { AgregarNoticiaPage } from "src/app/pages/mobile/home/noticia/agregar/agregar.page";
import { AgregarReservaCamionetaPage } from "src/app/pages/mobile/home/camioneta/agregar/agregar.page";
import { CamionetaPage } from "src/app/pages/mobile/home/camioneta/camioneta.page";
import { DetailsReservaCamionetaPage } from "src/app/pages/mobile/home/camioneta/details/details.page";

import { ConfirmarReservaModalPage } from "src/app/pages/mobile/home/camioneta/confirmar-reserva-modal/confirmar-reserva-modal.page";
import { AgregarInvitacionPage } from "./pages/mobile/seguridad/invitacion/agregar/agregar-invitacion.page";
import { DetalleInvitacionPage } from "./pages/mobile/seguridad/invitacion/detalle/detalle-invitacion.page";
import { LotePage } from "src/app/pages/mobile/seguridad/lote/lote.page";
import { BuscadorPage } from "./pages/mobile/seguridad/buscador/buscador.page";

// Componentes
import { AccountComponent } from "src/app/components/shared/components.shared.index";
import { SegmentToolbarComponent } from "src/app/components/shared/components.shared.index";
import { FullNewComponent } from "src/app/components/mobile/component.mobile.index";
import { AdvancedSearchComponent } from "src/app/components/mobile/component.mobile.index";
import { DashboardModalComponent } from "src/app/components/mobile/component.mobile.index";
import { CountdownComponent } from "src/app/pages/mobile/emergency/components/countdown/countdown.component";

// Plugins

import { File } from "@ionic-native/file/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Market } from "@ionic-native/market/ngx";
import { UniqueDeviceID } from "@ionic-native/unique-device-id/ngx";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { Base64 } from "@ionic-native/base64/ngx";
import { Base64ToGallery } from "@ionic-native/base64-to-gallery/ngx";
import { WebView } from "@ionic-native/ionic-webview/ngx";
import { Camera } from "@ionic-native/camera/ngx";
import { ImagePicker } from "@ionic-native/image-picker/ngx";
import { Badge } from "@ionic-native/badge/ngx";
import { FingerprintAIO } from "@ionic-native/fingerprint-aio/ngx";
import { Device } from "@ionic-native/device/ngx";
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { Environment, GoogleMaps } from "@ionic-native/google-maps";
import { LocationAccuracy } from "@ionic-native/location-accuracy/ngx";
import { IonicStorageModule } from "@ionic/storage";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";
//import { BarcodeScanner } from "@ionic-native/barcode-scanner/ngx";
import { HTTP } from '@ionic-native/http/ngx';

import { Ionic4DatepickerModule } from
  '@logisticinfotech/ionic4-datepicker';
import { ComoLlegoPageModule } from "./pages/mobile/home/comoLlego/comoLlego.module";
import { ComoLlegoPage } from "./pages/mobile/home/comoLlego/comoLlego.page";

@NgModule({
  declarations: [AppComponent],
  entryComponents: [
    AccountComponent,
    FullNewComponent,
    AdvancedSearchComponent,
    DashboardModalComponent,
    CountdownComponent,
    SegmentToolbarComponent,
    EncuestaAbiertaModalPage,
    ForgotMyPasswordPage,
    EmergencyPage,
    EncuestaCerradaModalPage,
    AgregarTicketPage,
    AgregarMascotaPage,
    DetalleTicketPage,
    DetalleNoticiaPageNotification,
    DetalleMultaPage,
    ImagenModalMultaPage,
    AgregarAutorizadoPage,
    DetalleAutorizadoPage,
    ObservacionAutorizadoPage,
    CamaraModalPage,
    PerfilPage,
    LotePage,
    BuscadorPage,
    PushNotificationPage,
    NotificationDetailPage,
    SplashPage,
    ChangePasswordPage,
    PreNoticiaPage,
    AgregarNoticiaPage,
    AgregarReservaCamionetaPage,
    CamionetaPage,
    ConfirmarReservaModalPage,
    DetailsReservaCamionetaPage,
    ContactsPage,
    MascotasPage,
    PreNoticiaPage,
    AgregarInvitacionPage,
    DetalleInvitacionPage,
    ComoLlegoPage,
  ],
  imports: [
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ComponentsMobileModule,
    ComponentsWebModule,
    DirectiveModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    EncuestaAbiertaModalPageModule,
    ForgotMyPasswordPageModule,
    EmergencyPageModule,
    OnboardingPageModule,
    EncuestaCerradaModalPageModule,
    AgregarTicketPageModule,
    DetalleTicketPageModule,
    DetalleNoticiaPageNotificationModule,
    DetalleMultaPageModule,
    ImagenModalMultaPageModule,
    AgregarAutorizadoPageModule,
    DetalleAutorizadoPageModule,
    ObservacionAutorizadoPageModule,
    CamaraModalPageModule,
    PerfilPageModule,
    LotePageModule,
    BuscadorPageModule,
    PushNotificationPageModule,
    NotificationDetailPageModule,
    SplashPageModule,
    ChangePasswordPageModule,
    PreNoticiaPageModule,
    AgregarNoticiaPageModule,
    CamionetaPageModule,
    AgregarReservaCamionetaPageModule,
    DetailsReservaCamionetaPageModule,
    ConfirmarReservaModalPageModule,
    ComoLlegoPageModule,
    ContactsPageModule,
    MascotasPageModule,
    AgregarMascotaPageModule,
    PreNoticiaPageModule,
    AgregarInvitacionPageModule,
    DetalleInvitacionPageModule,
    Ionic4DatepickerModule
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    File,
    Environment,
    GoogleMaps,
    InAppBrowser,
    AppVersion,
    Market,
    UniqueDeviceID,
    AndroidPermissions,
    FileOpener,
    Base64,
    Base64ToGallery,
    WebView,
    Camera,
    ImagePicker,
    Badge,
    FingerprintAIO,
    Device,
    Geolocation,
    LocationAccuracy,
    SocialSharing,
    //BarcodeScanner,
    HTTP,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
