import { Component, Input } from "@angular/core";
import { DatePipe } from "@angular/common";

// Services
import { ControlService } from "src/app/services/common/control.service";

// Constantes
import { POPOVER_STYLES } from "src/app/constants/constants.index";
import { IIdentity } from "src/app/models/interfaces/interfaces.index";
import { SegmentService } from "src/app/services/common/segment.service";
import { DashboardModalComponent } from "src/app/components/mobile/component.mobile.index";
import { Noticia } from "src/app/models/entities/entity.index";
import { TicksToDatePipe } from "src/app/pipes/pipes.index";
import { UtilService } from "src/app/services/common/util.service";
import { NoticiaService } from "src/app/services/business/noticia.service";
import { ActivatedRoute } from "@angular/router";
import { EnumTipoSegmento } from "src/app/models/enums/tipo.enum";

@Component({
  selector: "app-detalle-noticia",
  templateUrl: "./detalle-noticia.page.html",
  styleUrls: ["./detalle-noticia.page.scss"],
  providers: [DatePipe],
})
export class DetalleNoticiaPageNotification {
  @Input("noticia") noticia: Noticia;
  @Input("isNotification") isNotification: boolean = false;
  icono: string;
  showHTMLContentNotification = false;

  constructor(
    private controlService: ControlService,
    private ticksToDatePipe: TicksToDatePipe,
    private utilService: UtilService,
    private noticiaService: NoticiaService,
    private route: ActivatedRoute
  ) {}

  ionViewWillEnter() {
    this.route.queryParams.subscribe((params) => {
      if (params["noticia"]) {
        this.noticia = JSON.parse(params["noticia"]);
      }

      if (params["icono"]) {
        this.icono = JSON.parse(params["icono"]);
      }
    });
  }

  closePopover() {
    if (this.isNotification) {
      this.controlService.cerrar_modal();
    }
  }

  ticksToDate(ticks: number | Date) {
    return this.ticksToDatePipe.transform(ticks);
  }

  public set_Html_Iframe(dataHTML: String) {
    var iframe = document.getElementById("iframeID") as any;
    iframe =
      iframe.contentWindow ||
      iframe.contentDocument.document ||
      iframe.contentDocument;

    iframe.document.open();
    iframe.document.write(dataHTML);
    iframe.document.close();
  }

  openDocument(url: string) {
    this.utilService.openNewTab(url);
  }

  async showMoreInformation() {
    this.noticiaService
      .get_noticia(this.noticia.Id)
      .then((noticia: Noticia) => {
        this.noticia.Contenido = noticia.Contenido;
        this.showHTMLContentNotification = true;
      });
  }
}
