import { Component, ViewChild, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IonContent, IonInfiniteScroll } from "@ionic/angular";

// Interfaces
import {
  IPagination,
  IModal,
  IAdvancedSearchFilters,
} from "src/app/models/interfaces/interfaces.index";

// Constantes
import {
  PAGINATOR,
  SCROLL_FAB_HEIGHT,
  ENABLED_SCROLL_EVENT,
  FECHA,
} from "src/app/constants/config.constant";
import {
  MESSAGE,
  POPOVER_STYLES,
  TIEMPO_MENSAJE,
} from "src/app/constants/constants.index";

// Clases
import { AutorizadoDelLote, Lote } from "src/app/models/entities/entity.index";
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { AutorizadoService } from "src/app/services/business/autorizado.service";
import { DeviceService } from "src/app/services/common/device.service";

// Enums
import { EnumTipoNotificacion } from "src/app/models/enums/tipo.enum";

// Páginas
import { DetalleAutorizadoPage } from "./detalle/detalle-autorizado.page";
import { AgregarAutorizadoPage } from "src/app/pages/mobile/seguridad/autorizado/agregar/agregar-autorizado.page";
import { AdvancedSearchComponent } from "src/app/components/mobile/component.mobile.index";

@Component({
  selector: "app-autorizado",
  templateUrl: "./autorizado.page.html",
  styleUrls: ["./autorizado.page.scss"],
})
export class AutorizadoPage implements OnInit {
  private paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  mostrarFabButton = false;
  mostrarFabButtonApartirDe = SCROLL_FAB_HEIGHT;
  habilitarEventosScroll = ENABLED_SCROLL_EVENT;
  mostrarInfiniteScrollEn = PAGINATOR.SIZE;

  formatoFechaControl: string = FECHA.FORMATO_VISUAL;
  mostrarButtonLimpiar = false;
  mostrarFiltro = false;
  formGroup: FormGroup;
  mostrarMensajeSinItem = false;
  autorizados: AutorizadoDelLote[] = [];
  expandidos: any[] = [];

  loteSeleccionado: Lote;
  lotes: Lote[];

  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private userService: UserService,
    private autorizadoService: AutorizadoService,
    private deviceService: DeviceService
  ) {
    this.lotes = this.userService.user.Lotes;
    this.setear_lote_seleccionado();
  }

  ngOnInit() {

    this.configurar_formulario();
    this.filtrar();
  }

  filtrar() {
    this.limpiar_filtro();
    this.resolver_vista_acceso();
    this.mostrarButtonLimpiar = true;
  }

  resolver_vista_acceso(event?) {
    if (event) {
      this.paginacion.pagina += 1;
      this.cargar_autorizados(event);
    } else {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.cargar_autorizados(event);
      });
    }
  }

  limpiar_filtro() {
    this.autorizados = [];
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.autorizadoService.limpiar();
    this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    this.mostrarMensajeSinItem = false;
    this.mostrarButtonLimpiar = false;
  }

  informar_scroll(event) {
    if (this.deviceService.isIos) {
      return;
    }

    this.content
      .getScrollElement()
      .then(
        (el) =>
        (this.mostrarFabButton =
          el.scrollTop > this.mostrarFabButtonApartirDe)
      );
  }

  volver_al_inicio() {
    this.content.scrollToTop();
  }

  notificacion_con_aviso(autorizado: AutorizadoDelLote) {
    return (
      autorizado.DescripcionTipoAutorizado === EnumTipoNotificacion.ConAviso
    );
  }

  async mostrar_detalle(autorizado: AutorizadoDelLote) {
    const modal: IModal = {
      component: DetalleAutorizadoPage,
      componentProps: { lote: this.loteSeleccionado, autorizado: autorizado },
      returnData: true,
    };

    this.controlService.mostrar_modal(modal).then((info: any) => {
      this.filtrar();
    });
  }

  async mostrar_agregar_autorizado() {
    const modal: IModal = {
      component: AgregarAutorizadoPage,
      componentProps: { lote: this.loteSeleccionado, autorizado: null },
      returnData: true,
    };

    this.controlService.mostrar_modal(modal).then((info: any) => {
      this.filtrar();
    });
  }

  setear_lote_seleccionado() {
    this.loteSeleccionado = this.lotes[0];
  }

  doRefresh(event) {
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.autorizadoService.limpiar();
    this.autorizadoService
      .obtener_autorizados(
        this.loteSeleccionado,
        this.formGroup.value.nombreContiene,
        this.paginacion
      )
      .then((resp: AutorizadoDelLote[]) => {
        this.autorizados = resp;
        this.controlaFindePagina();
        event.target.complete();
      })
      .catch((error) => {
        console.log("entró al error", error);
        event.target.complete();
      });
  }

  private cargar_autorizados(event?) {
    this.autorizadoService
      .obtener_autorizados(
        this.loteSeleccionado,
        this.formGroup.value.nombreContiene,
        this.paginacion
      )
      .then((resp: AutorizadoDelLote[]) => {
        if (
          resp == null ||
          this.autorizadoService.paginacionActual.cantidad === 0
        ) {
          this.controlService.habilitar_infinite_scroll(event, false);
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_SIN_DATOS,
            TIEMPO_MENSAJE
          );
        } else {
          this.autorizados = resp;
          this.controlService.completar_infinite_scroll(event);
        }
      })
      .catch((error) => {
        this.controlService.habilitar_infinite_scroll(event, false);
        this.controlService.mostrar_toast_con_error(error, false);
      })
      .finally(() => {
        this.controlaFindePagina();
        this.mostrarMensajeSinItem = this.autorizados.length === 0;
        if (event == null) {
          this.controlService.ocultar_loading();
        }
      });
  }

  private configurar_formulario() {
    this.loteSeleccionado = this.lotes[0];
    this.formGroup = this.formBuilder.group({
      lote: [this.lotes[0].Id, Validators.required],
      nombreContiene: [null, Validators.required],
    });
  }

  open_advanced_search() {
    const componentProps: { filters: IAdvancedSearchFilters } = {
      filters: {
        showLoteFilter: true,
        showNombreContieneFilter: true,
      },
    };
    this.controlService
      .mostrarPopOver({
        component: AdvancedSearchComponent,
        componentProps,
        cssClass: POPOVER_STYLES.ADVANZED_SEARCH_MODAL,
        backdropDismiss: false,
      })
      .then((data) => {
        if (data) {
          this.loteSeleccionado = this.lotes.find(
            (lote) => lote.Id === data.lotes
          );
          this.formGroup.controls["lote"].setValue(data.lotes);
          this.formGroup.controls["nombreContiene"].setValue(
            data.nombreContiene
          );

          this.filtrar();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  controlaFindePagina() {
    let maxPages = Math.ceil(
      this.autorizadoService.paginacionActual.cantidad /
      this.paginacion.cantidad
    );
    if (maxPages == this.paginacion.pagina)
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, false);
    else
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
  }
}
