import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "ticksToDate",
})
export class TicksToDatePipe implements PipeTransform {
  transform(value: any): any {
    let fecha: Date;
    if (value) {
      let hoy: Date = new Date();
      let dato: number = value;

      dato -= 621355968000000000;
      dato += hoy.getTimezoneOffset() * 600000000;

      dato = Math.floor(dato / 10000);

      fecha = new Date(dato);
    }

    return fecha;
  }
}
