import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Servicios
import { RestService } from "../common/rest.service";
import { CatalogoService } from "./catalogo.service";
import { ExceptionService } from "../common/exception.service";
import { SecurityService } from "../security/security.service";

// Clases
import { ResponseApp } from "../../models/entities/entity.index";

@Injectable({
  providedIn: "root",
})
export class EmergencyService {
  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private exceptionService: ExceptionService,
    private securityService: SecurityService
  ) {}

  //**Crear emergencia */
  public async registerEmergency(
    idLote: string,
    latitud: string,
    longitud: string,
    tipoEmergencia: string
  ) {
    const url = `${this.catalogoService.catalogo}RegistrarEmergencia`;

    const body = {
      token: this.securityService.token,
      idLote,
      latitud,
      longitud,
      tipoEmergencia,
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
