import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Clases
import { Usuario, ResponseApp } from "src/app/models/entities/entity.index";

// Servicios
import { DeviceService } from "../common/device.service";
import { UtilService } from "../common/util.service";
import { SecurityService } from "./security.service";
import { StorageService } from "../common/storage.service";
import { CatalogoService } from "../business/catalogo.service";
import { UserService } from "./user.service";
import { RestService } from "../common/rest.service";
import { DisposeService } from "../common/dispose.service";
import { ExceptionService } from "../common/exception.service";

// Constantes
import { STORAGE } from "src/app/constants/constants.index";
import { environment } from "src/app/constants/constants.index";
import { DispositivoService } from "../business/dispositivo.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(
    private deviceService: DeviceService,
    private utilService: UtilService,
    private securityService: SecurityService,
    private storageService: StorageService,
    private catalogoService: CatalogoService,
    private userService: UserService,
    private restService: RestService,
    private disposeService: DisposeService,
    private exceptionService: ExceptionService,
    private dispositivoService: DispositivoService
  ) { }

  login(usuario: Usuario): Promise<string | ResponseApp> {
    // if (usuario.Documento) {
    // return this.login_by_document(usuario);
    // }
    // else {
    return this.login_by_username(usuario);
    // }
  }

  // Recuperar contraseña

  recovery_password(datosRecovery: []) {
    const url = `${this.catalogoService.catalogo}RecoveryPassword`;
    const body = {
      userName: datosRecovery["userName"],
      documento: `${datosRecovery["documento"]}`,
      email: datosRecovery["email"],
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .put(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              //this.exceptionService.resolver_error( resp );
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  logout() {
    this.limpiar_storage();
    this.limpiar_app();
    this.dispositivoService
      .desvincular_dispositivo(this.deviceService.uuid)
      .then((resp) => {
        console.log("this.deviceService.uuid", this.deviceService.uuid);
        console.log(
          "resp this.dispositivoService.desvincular_dispositivo",
          resp
        );
      });
  }

  async getUserData(catalogo: string, token: string) {
    const action = `MobileUserGetByToken/${token}`;
    const url = `${catalogo}${action}`;

    return new Promise<ResponseApp>((resolve, reject) => {
      this.restService
        .get(url)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            console.log("request body " + JSON.stringify(resp));
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            this.userService.user = resp.Data;
            Promise.all([
              this.cargar_token(resp.Data.Token, resp.Data.Vencimiento),
              this.userService.cargar_datos_perfil(resp.Data),
            ]).then(() => {
              resolve(resp);
            });
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async estaAutenticado(): Promise<boolean> {
    console.log("entró a estaAutenticado()");
    return new Promise<boolean>((resolve, reject) => {
      this.cargar_token().then((resultToken) => {
        console.log("result token: ", resultToken);
        console.log("this.securityService.token", this.securityService.token);
        console.log("this.securityService.token.length", this.securityService.token.length);
        if (this.securityService.token.length < 2) {
          console.log("entró al if this.securityService.token.length < 2, 142");
          resolve(false);
        }
        this.storageService
          .cargar(STORAGE.KEY.EXPIRA)
          .then((result: string) => {
            console.log("result key.EXPIRA", result);
            if (result) {
              console.log("entró a if 150");
              console.log("this.storageService.currentValue", this.storageService.currentValue);
              const expira = this.utilService.convert_ticks_to_date(
                Number(this.storageService.currentValue)
              );
              console.log("expira", expira);
              const vencimiento = new Date();
              vencimiento.setTime(expira.getTime());

              console.log(`vencimiento > new Date(): ${vencimiento} > ${new Date()}`);
              console.log(vencimiento > new Date());
              resolve(vencimiento > new Date());
            } else {
              console.log("entró a else");
              resolve(false);
            }
          });
      });
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async cargar_token(token?: string, expira?: number): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.storageService.cargar(STORAGE.KEY.TOKEN).then((result: string) => {
        if (token) {
          this.securityService.token = token;
          this.guardar_token(token, expira);
          resolve(true);
          return;
        }

        if (result) {
          this.securityService.token = result;
          resolve(true);
        } else {
          resolve(false);
        }
      });
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  private login_by_username(usuario: Usuario): Promise<ResponseApp | string> {
    const action =
      this.deviceService.isWeb || environment.APP.MOCK_MOBILE
        ? "LoginUsuarioWebByUsername_v1"
        : "LoginUsuarioByUsername_v1";
    let body: object = null;

    if (this.deviceService.isWeb || environment.APP.MOCK_MOBILE) {
      body = {
        username: usuario.UserName,
        password: usuario.Password,
      };
    } else {
      body = {
        username: usuario.UserName,
        password: usuario.Password,
        uuid: usuario.OneSignalId,
        deviceId: usuario.DeviceId,
      };
    }

    const url = `${this.catalogoService.catalogo}${action}`;
    return new Promise<ResponseApp>((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            console.log("request body " + JSON.stringify(resp));
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            this.userService.user = resp.Data;
            Promise.all([
              this.cargar_token(resp.Data.Token, resp.Data.Vencimiento),
              this.userService.cargar_datos_perfil(resp.Data),
            ]).then(() => {
              resolve(resp);
            });
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  private login_by_document(usuario: Usuario): Promise<ResponseApp | string> {
    const action =
      this.deviceService.isWeb || environment.APP.MOCK_MOBILE
        ? "LoginUsuarioWebByDocumento"
        : "LoginUsuarioByDocumento";

    let body: Object = null;
    if (this.deviceService.isWeb || environment.APP.MOCK_MOBILE) {
      body = {
        documento: usuario.Documento,
        password: usuario.Password,
      };
    } else {
      body = {
        documento: usuario.Documento,
        password: usuario.Password,
        uuid: usuario.OneSignalId,
        deviceId: usuario.DeviceId,
      };
    }

    const url = `${this.catalogoService.catalogo}${action}`;
    return new Promise<ResponseApp>((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            console.log(resp);
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            Promise.all([
              this.cargar_token(resp.Data.Token, resp.Data.Vencimiento),
              this.userService.cargar_datos_perfil(resp.Data),
            ]).then(() => {
              resolve(resp);
            });
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  private guardar_token(token: string, expira: number) {
    this.securityService.token = token;
    this.storageService.guardar({
      key: STORAGE.KEY.TOKEN,
      value: this.securityService.token,
    });
    this.storageService.guardar({
      key: STORAGE.KEY.EXPIRA,
      value: expira.toString(),
    });
  }

  private limpiar_storage() {
    if (this.deviceService.isWeb) {
      this.storageService.limpiar();
    } else {
      const keys: string[] = [];
      keys.push(STORAGE.KEY.TOKEN);
      keys.push(STORAGE.KEY.PERFIL);
      keys.push(STORAGE.KEY.EXPIRA);
      keys.push(STORAGE.KEY.DEVICE_ID);
      keys.push(STORAGE.KEY.ONESIGNAL_ID);
      keys.push(STORAGE.KEY.DATA.ESTADOS);
      keys.push(STORAGE.KEY.DATA.TIPO_RELACION);
      keys.push(STORAGE.KEY.DATA.TIPO_AUTORIZADO);

      this.storageService.borrar(keys);
    }
  }

  private limpiar_app() {
    this.disposeService.dispose();
  }
}
