import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

// Clases
import { Multa, ResponseApp } from "src/app/models/entities/entity.index";

// Servicios
import { MultaService } from "src/app/services/business/multa.service";
import { ControlService } from "src/app/services/common/control.service";

// Enums
import { EnumEstadoLeido } from "src/app/models/enums/estado.enum";

// Constantes
import {
  MESSAGE,
  TIEMPO_MENSAJE,
  ALERT,
} from "src/app/constants/constants.index";

// Interfaces
import { IModal } from "src/app/models/interfaces/interfaces.index";

// Páginas
import { ImagenModalMultaPage } from "../imagen-modal/imagen-modal.page";

@Component({
  selector: "app-detalle-multa",
  templateUrl: "./detalle.page.html",
  styleUrls: ["./detalle.page.scss"],
})
export class DetalleMultaPage implements OnInit {
  @Input() multa: Multa;
  puedeEditar = true;
  formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private multaService: MultaService,
    private controlService: ControlService
  ) {}

  ngOnInit() {
    this.puedeEditar = this.multa.PermiteNuevoComentario === 1;
    this.configurar_formulario();

    if (this.multa.Leida === EnumEstadoLeido.NoLeida) {
      this.actualizar_multa_como_leida();
    }
  }

  agregar_comentario() {
    if (this.formGroup.valid) {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.multaService
          .agregar_comentario(this.multa, this.formGroup.value.comentario)
          .then((resp: ResponseApp) => {
            this.controlService.mostrar_alert({
              header: MESSAGE.INFO.MENSAJE_MULTA.COMENTARIO_GUARDADO.TITULO,
              message: MESSAGE.INFO.MENSAJE_MULTA.COMENTARIO_GUARDADO.MENSAJE,
              backdropDismiss: false,
              buttons: [
                {
                  text: ALERT.BUTTONS.ACEPTAR,
                  handler: () => {
                    this.controlService.cerrar_modal();
                  },
                },
              ],
            });
          })
          .catch((error) => {
            this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
          })
          .finally(() => {
            this.controlService.ocultar_loading();
          });
      });
    }
  }

  cerrar_modal() {
    this.controlService.cerrar_modal(this.multa);
  }

  mostrar_modal_imagenes(urlImagen: string) {
    const modal: IModal = {
      component: ImagenModalMultaPage,
      componentProps: { urlImagen: urlImagen },
      returnData: false,
    };

    this.controlService.mostrar_modal(modal);
  }

  actualizar_multa_como_leida() {
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.multaService
        .marcar_multa_como_leida(this.multa.Id)
        .then((resp: ResponseApp) => {
          this.multa.Leida = EnumEstadoLeido.Leida;
        })
        .catch((error) => {
          this.controlService.mostrar_toast_con_error(error);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    });
  }

  private configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      comentario: ["", Validators.required],
    });
  }
}
