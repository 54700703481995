// Se usa para la parte de Accesos
export class InfoACobrarByLote {
  CobrosEnProceso: CobroEnProceso[] = [];
  ExpensaDelMes: number | string = null;
  IdLote: string = null;
  NroLote: number = null;
  SaldoPendiente: number | string = null;
}

export class CobroEnProceso {
  Fecha: Date | number = null;
  Id: string = null;
  IdTransaccion: string = null;
  Lote: string = null;
  Monto: number = null;
  Status: string = null;
}

export class MPPaymentMethod {
  Id: string = null;
  Descripcion: string = null;
}

export class MPPaymentMethodIssuer {
  Id: number = null;
  Descripcion: string = null;
}

export class loteCobranzaMedioPagoMobile {
  TarjetaId = null;
  TarjetaNumero: string = null;
  TarjetaCodigoSeguridad: string = null;
  TarjetaVencimientoAnio: number = null;
  TarjetaVencimientoMes: number = null;
  TarjetaTitular: string = null;
  Importe: number = null;
  Cuotas: number = 1;
  MedioPagoId: string = null;
  MedioPagoEmisorId: number = null;
  DescripcionOperacion: string = "Prueba de pago por postman";
}
