import { Component, OnInit } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LoadingController, Platform } from '@ionic/angular';
import { ComoLlegoService } from './service/como-llego.service';
import { InAppBrowser, InAppBrowserOptions } from '@ionic-native/in-app-browser/ngx';
import { ControlService } from 'src/app/services/common/control.service';
import { TIEMPO_MENSAJE } from 'src/app/constants/message.constant';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-como-llego',
  templateUrl: './comoLlego.page.html',
  styleUrls: ['./comoLlego.page.scss'],
})
export class ComoLlegoPage implements OnInit {
  formGroup: FormGroup;
  constructor(
    private geolocation: Geolocation,
    private loadingController: LoadingController,
    private comoLlegoService: ComoLlegoService,
    private iab: InAppBrowser,
    private controlService: ControlService,
    private locationAccuracy: LocationAccuracy,
    private platform: Platform,
    private androidPermissions: AndroidPermissions,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      lote: [null, [Validators.required]]
    });
  }

  get lote(): AbstractControl {
    return this.formGroup.get("lote") as AbstractControl;
  }

  async submitForm(): Promise<void> {
    const loading = await this.loadingController.create();
    try {
      loading.present();
      const coords: { latitud: number, longitud: number } = await this.getPosition();
      if (coords == undefined) return;
      const formData = {
        lote: this.lote.value,
        latitud: coords.latitud,
        longitud: coords.longitud
      }
      const url = await this.comoLlegoService.comollego(formData)
      const options: InAppBrowserOptions = {
        zoom: 'no',
        location: 'yes',
        toolbar: 'yes',
      };
      this.iab.create(url, '_system', options)
      //window.open(url, "_blank");

    } catch (error) {
      console.log("error", error);
      this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
    } finally {
      loading.dismiss();
    }
  }

  async requestLocation(): Promise<boolean> {
    try {
      const hasPermission = await this.checkGPSPermission();
      if (hasPermission) {
        return await this.askToTurnOnGPS();
      } else {
        const permission = await this.requestGPSPermission();
        if (permission === "CAN_REQUEST" || permission === "GOT_PERMISSION") {
          return await this.askToTurnOnGPS();
        }
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async askToTurnOnGPS(): Promise<boolean> {
    try {
      const canRequest = await this.locationAccuracy.canRequest();
      if (!canRequest) return false;
      if (canRequest) {
        await this.locationAccuracy.request(
          this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY
        );
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async checkGPSPermission(): Promise<boolean> {
    try {
      if (this.platform.is("android")) {
        const permissions = await this.androidPermissions.checkPermission(
          this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION
        );
        if (permissions && permissions.hasPermission) return true;
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async requestGPSPermission(): Promise<string> {
    try {
      const canRequest = await this.locationAccuracy.canRequest();
      if (canRequest) {
        return "CAN_REQUEST";
      } else {
        const permissions = await this.androidPermissions.requestPermission(
          this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION
        );
        if (permissions && permissions.hasPermission) return "GOT_PERMISSION";
        return "DENIED_PERMISSION";
      }
    } catch (error) {
      console.log(error);
      return "DENIED_PERMISSION";
    }
  }

  async getPosition(): Promise<{ latitud: number, longitud: number } | undefined> {
    try {
      const currentPosition = await this.geolocation.getCurrentPosition(
        { timeout: 10000, enableHighAccuracy: true, maximumAge: 3600 }
      );
      const ubicacion = {
        latitud: currentPosition.coords.latitude,
        longitud: currentPosition.coords.longitude,
      };

      return ubicacion;
    } catch (error) {
      this.controlService.mostrar_alert({
        header: "Atención",
        message:
          "Por favor, active el GPS para que podamos acceder a su ubicación correctamente",
        backdropDismiss: false,
        buttons: [
          {
            text: "Aceptar",
            role: "cancel",
          },
        ],
      });
    }
    return undefined;
  }

  ionViewWillLeave(): void {
    this.formGroup.get("lote").setValue(null);
  }
}
