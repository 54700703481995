import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Clases
import { TelefonoContacto } from "src/app/models/entities/business/contacto.entity";
import { Lote, ResponseApp } from "src/app/models/entities/entity.index";

// Interfaces
import { IDictionary } from "src/app/models/interfaces/interfaces.index";

// Servicios
import { CatalogoService } from "./catalogo.service";
import { RestService } from "../common/rest.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";
import { SecurityService } from "../security/security.service";

@Injectable({
  providedIn: "root",
})
export class ContactoService {
  telefonos: TelefonoContacto[] = [];

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private exceptionService: ExceptionService,
    private securityService: SecurityService
  ) {}

  /**
   * Obtiene un listado de los contactos telefónicos de un lote
   * @param lote Lote perteneciente a los contactos
   */
  async obtener_telefonos(lote: Lote): Promise<TelefonoContacto[]> {
    const url = `${this.catalogoService.catalogo}ObtenerOrdenContactosTelefonicos/`;
    const options: IDictionary[] = [{ key: "{idLote}", value: lote.Id }];

    return new Promise<TelefonoContacto[]>((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, TelefonoContacto[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.telefonos = this.utilService.mapear_propiedades(
              resp.Data,
              new TelefonoContacto()
            );

            return this.telefonos;
          })
        )
        .subscribe(
          (data: TelefonoContacto[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  /**
   *
   * @param lote Lote perteneciente a los contactos
   * @param contactosOrdenados listado de los teléfonos con el orden a actualizar
   */
  async actualizar_orden_contactos(
    lote: Lote,
    contactosOrdenados: TelefonoContacto[]
  ) {
    contactosOrdenados = this.setear_orden(contactosOrdenados);
    const url = `${this.catalogoService.catalogo}ActualizarOrdenContactosTelefonicos`;
    const body = {
      token: this.securityService.token,
      idLote: lote.Id,
      ordenes: contactosOrdenados,
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);

            this.telefonos = contactosOrdenados;
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  limpiar() {
    this.telefonos = [];
  }

  /**
   * Setea la propiedad orden del 1 al N, ya que el backend espera que le especifique un orden a pesar de ordenarselo
   */
  private setear_orden(telefonos: TelefonoContacto[]) {
    this.telefonos.forEach((telefono: TelefonoContacto, index: number) => {
      telefonos[index].Orden = index + 1;
    });

    return telefonos;
  }
}
