import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Servicios
import { RestService } from "../common/rest.service";
import { CatalogoService } from "./catalogo.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";
import { SecurityService } from "../security/security.service";

// Constantes
import { IDictionary } from "../../models/interfaces/interfaces.index";

// Clases
import {
  ResponseApp,
  Lote,
  ReservaTurno,
  ReservaDestino,
  ReservaDestinoInstalacion,
} from "../../models/entities/entity.index";
import { ItemElement } from "../../models/clases/ItemElement";

// Enums
import { EnumTipoCanal } from "../../models/enums/tipo.enum";

@Injectable({
  providedIn: "root",
})
export class ReservaService {
  private itemElement = new ItemElement<ReservaDestino>();
  private itemElementReserva = new ItemElement<ReservaTurno>();

  destinosReservables: ReservaDestino[] = [];
  turnosReservables: ReservaTurno[] = [];
  misReservas: ReservaTurno[] = [];

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private exceptionService: ExceptionService,
    private securityService: SecurityService
  ) { }

  public async obtener_destinos_reservas(): Promise<ReservaDestino[]> {
    const url = `${this.catalogoService.catalogo}ObtenerDestinosReservables/`;

    return this.obtener_resultado_reservas(url, true, false);
  }

  private async obtener_resultado_reservas(
    url: string,
    token: boolean,
    idBarrio: boolean
  ) {
    return new Promise<ReservaDestino[]>((resolve, reject) => {
      this.restService
        .get(url, token, idBarrio)
        .pipe(
          map<any, ReservaDestino[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            const destinosReservables = this.utilService.mapear_propiedades(
              resp.Data,
              new ReservaDestino()
            );

            this.destinosReservables = this.itemElement.refrescar(
              destinosReservables,
              this.destinosReservables
            );

            return this.destinosReservables;
          })
        )
        .subscribe(
          (data: ReservaDestino[]) => {
            resolve(data);
          },
          (error) => {
            //this.limpiar();
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      throw error;
    });
  }

  obtener_turnos_reservables(
    fecha: string,
    idReservaDestino: string,
    idReservaDestinoInstalacion: string
  ) {
    const url = `${this.catalogoService.catalogo}ObtenerTurnosReservables/`;

    const options: IDictionary[] = [
      { key: "{fecha}", value: fecha },
      { key: "{idReservaDestino}", value: idReservaDestino },
      {
        key: "{idReservaDestinoInstalacion}",
        value: idReservaDestinoInstalacion,
      },
    ];

    return this.obtener_resultado_turnos_reservables(url, true, false, options);
  }

  private async obtener_resultado_turnos_reservables(
    url: string,
    token: boolean,
    idBarrio: boolean,
    options: IDictionary[]
  ) {
    return new Promise<ReservaTurno[]>((resolve, reject) => {
      this.restService
        .get(url, token, idBarrio, options)
        .pipe(
          map<any, ReservaTurno[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            const turnosReservables = this.utilService.mapear_propiedades(
              resp.Data,
              new ReservaTurno()
            );

            //Corregir modelo
            //tiene como objetivo copiar el valor de Cupo a CupoTotal ya que desde el backend lo usan con dos propósitos diferentes
            turnosReservables.forEach((item) => {
              item.CupoTotal = item.Cupo;
              item.Cupo = 1; //valor por defecto
            });

            this.turnosReservables = this.itemElementReserva.refrescar(
              turnosReservables,
              this.turnosReservables
            );

            return this.turnosReservables;
          })
        )
        .subscribe(
          (data: ReservaTurno[]) => {
            resolve(data);
          },
          (error) => {
            //this.limpiar();
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      //console.log( error );
      throw error;
    });
  }


  async handleLuminaria(reserva: ReservaTurno, encender: boolean) {
    const url = `${this.catalogoService.catalogo}${encender ? 'ReservaInstalacionLuminariaEncender' : 'ReservaInstalacionLuminariaApagar'}`;
    const body = {
      token: this.securityService.token,
      idReservaTurnoMobile: reserva.Luminaria.Id,
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      //console.log(error);
      //throw error;
    }
  }

  ObtenerTurnosReservadosByLote(idLote: string) {
    const url = `${this.catalogoService.catalogo}ObtenerTurnosReservadosByLote/`;

    const options: IDictionary[] = [{ key: "{idLote}", value: idLote }];

    return this.obtener_resultado_turnos_reservas(url, true, false, options);
  }

  obtener_resultado_turnos_reservas(
    url: string,
    token: boolean,
    idBarrio: boolean,
    options: IDictionary[]
  ) {
    return new Promise<ReservaTurno[]>((resolve, reject) => {
      this.restService
        .get(url, token, idBarrio, options)
        .pipe(
          map<any, ReservaTurno[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            const misReservas = this.utilService.mapear_propiedades(
              resp.Data,
              new ReservaTurno()
            );

            this.misReservas = this.itemElementReserva.refrescar(
              misReservas,
              this.misReservas
            );

            //console.log( this.turnosReservables );
            return this.misReservas;
          })
        )
        .subscribe(
          (data: ReservaTurno[]) => {
            resolve(data);
          },
          (error) => {
            //this.limpiar();
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      throw error;
    });
  }

  //**Cancelar Reserva */
  async cancelar_reserva(reserva: ReservaTurno) {
    const url = `${this.catalogoService.catalogo}CancelarReservaInstalacion`;
    const body = {
      token: this.securityService.token,
      idReservaTurnoMobile: reserva.Id,
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      //console.log(error);
      //throw error;
    }
  }

  //**Crear reserva */
  async crear_reserva(reserva: ReservaTurno, lote: string) {
    const url = `${this.catalogoService.catalogo}GenerarReservaInstalacion`;

    const body = {
      token: this.securityService.token,
      idLote: lote,
      reservaTurno: reserva,
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      //console.log(error);
      //throw error;
    }
  }

  async ObtenerMensajePreCancelacion(reserva: ReservaTurno): Promise<ResponseApp> {
    const url = `${this.catalogoService.catalogo}ObtenerMensajePreCancelacionTurnoReservado/`;
    const options: IDictionary[] = [
      { key: "{idReservaTurnoMobile}", value: reserva.Id },
    ];

    try {
      return new Promise<ResponseApp>((resolve, reject) => {
        this.restService
          .get(url, true, false, options)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {

              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      //console.log(error);
      //throw error;
    }
  }

  limpiarMisReservas() {
    this.misReservas = [];
  }

  limpiarTurnosReservables() {
    this.turnosReservables = [];
  }

  limpiarDestinosReservables() {
    this.destinosReservables = [];
  }
}
