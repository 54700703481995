import { Component } from "@angular/core";
import { SafeResourceUrl } from "@angular/platform-browser";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { SecurityService } from "src/app/services/security/security.service";
import { SbcService } from "src/app/services/common/sbc.service";

@Component({
  selector: "app-splash",
  templateUrl: "./splash.page.html",
  styleUrls: ["./splash.page.scss"],
})
export class SplashPage {
  splashUrl: string | SafeResourceUrl = null;

  constructor(
    private controlService: ControlService,
    private securityService: SecurityService,
    private sbcService: SbcService
  ) {}

  ionViewWillEnter() {
    if (this.securityService.codigoBarrio) {
      this.sbcService
        .resolver_splash()
        .subscribe((splash: string | SafeResourceUrl) => {
          this.splashUrl = splash;
          setTimeout(() => {
            this.controlService.cerrar_modal();
            let navbar = document.getElementById("navbar");
            if (navbar) navbar.removeAttribute("style");
            let content = document.getElementById("content");
            if (content) content.removeAttribute("style");
          }, 4000);
        });
    }
  }
}
