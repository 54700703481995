import { Injectable } from "@angular/core";

// Servicios
import { DeviceService } from "./device.service";
import { UtilService } from "./util.service";

// Plugins
import {
  File,
  Entry,
  DirectoryEntry,
  FileEntry,
  Flags,
} from "@ionic-native/file/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { Base64 } from "@ionic-native/base64/ngx";
import {
  Base64ToGallery,
  Base64ToGalleryOptions,
} from "@ionic-native/base64-to-gallery/ngx";
import { WebView } from "@ionic-native/ionic-webview/ngx";

// Constantes
import { PATH_ANDROID } from "src/app/constants/url.constant";
import { environment, IMG_APP } from "src/app/constants/constants.index";

// Enums
import { EnumContentType } from "src/app/models/enums/enums.index";

// Clases
import { FileItem } from "src/app/models/entities/common/file.entity";
import { Observable, Observer } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FileService {
  constructor(
    private utilService: UtilService,
    private deviceService: DeviceService,
    private file: File,
    private iab: InAppBrowser,
    private fileOpener: FileOpener,
    private base64: Base64,
    private base64ToGallery: Base64ToGallery,
    private webview: WebView
  ) { }

  /** Habilita la descarga de un archivo hosteado en la url */
  download_file(url: string) {
    if (this.deviceService.isWeb) {
      window.open(url, "Download");
    } else if (this.deviceService.isAndroid && url.indexOf(".pdf") > 0) {
      window.open(
        `${PATH_ANDROID.VIEWER.replace("{0}", url)}`,
        "_blank",
        "location=yes"
      );
    } else {
      this.iab.create(url);
    }
  }

  /**
   * Le agrega a la url en base 64 el prefijo y la devuelve
   * @param url Url en base 64 de la imagen sin el prefijo que indica el formato del mismo
   */
  obtener_urlBase64_con_prefijo(url: string) {
    return "data:image/jpeg;base64," + url;
  }

  /**
   * Codifica el archivo en base64.
   * @param file Archivo a convertir
   * @param quitarBase Indicador para determinar si se debe agregar al string resultante la base como prefijo
   */
  async convert_to_base64(file: FileItem, quitarBase: boolean = false) {
    const archivo = file.archivo;
    const reader = new FileReader();
    reader.readAsDataURL(archivo);

    return new Promise<string>((resolve, reject) => {
      let url = "";
      reader.onload = () => {
        url = reader.result as string;

        // Si se supera el límite de bytes en la imagen, hay que reducir la calidad
        if (
          IMG_APP.CALIDAD.RESIZE_QUALITY &&
          archivo.size >= IMG_APP.CALIDAD.MAXBYTES
        ) {
          this.resizeGetBase64Image(
            url,
            IMG_APP.CALIDAD.RESIZE.WIDTH,
            IMG_APP.CALIDAD.RESIZE.HEIGHT,
            quitarBase
          ).then((urlBaseResize: string) => {
            resolve(urlBaseResize);
          });
        } else {
          url = quitarBase
            ? url.replace("data:image/*;charset=utf-8;base64,", "")
            : url;
          url = quitarBase ? url.replace("data:image/jpeg;base64,", "") : url;
          url = quitarBase
            ? url.replace(/^data:image\/(png|jpg|jpeg);base64,/, "")
            : url;
          resolve(url);
        }
      };

      reader.onerror = (error) => {
        console.log("Error: ", error);
        reject(error);
      };
    });
  }

  /** Codifica el archivo en base64.
   * @param fileName Nombre del archivo a convertir
   * @param quitarBase Indicador para determinar si se debe agregar al string resultante la base como prefijo
   * ATENCIÓN: este servicio está basado para trabajar únicamente con el dispositivo mobile (no apto para web).
   * Si se desea convertir imágenes en la web usar el método convert_to_base64
   */
  async convertir_archivo_en_base64(
    fileName: string,
    quitarBase: boolean = false
  ) {
    if (this.deviceService.isWeb || environment.APP.MOCK_MOBILE) {
      return "";
    }

    const path = this.obtener_directorio_app();
    const result = await this.base64.encodeFile(path + fileName).then(
      (base64File: string) => {
        return quitarBase
          ? base64File.replace("data:image/*;charset=utf-8;base64,", "")
          : base64File;
      },
      (error) => {
        console.log(JSON.stringify(error));
        return "";
      }
    );

    return result;
  }

  /** Guardar el archivo en base64 en la galeria del dispositivo */
  async guardar_archivo_en_base64(fileName: string) {
    const base64 = await this.convertir_archivo_en_base64(fileName);
    const options: Base64ToGalleryOptions = {
      prefix: "_img",
      mediaScanner: false,
    };
    this.base64ToGallery
      .base64ToGallery(
        base64.replace("data:image/*;charset=utf-8;base64,", ""),
        options
      )
      .then(
        (res) => console.log("Saved image to gallery ", JSON.stringify(res)),
        (err) =>
          console.log("Error saving image to gallery ", JSON.stringify(err))
      );
  }

  /**
   * Crea y obtiene un File Object desde la Url
   * @param url Url de donde se extraerá el recurso
   * @param fileName Nombre del archivo resultante
   */
  async create_object_file_of_url(url: string, fileName: string) {
    return new Promise<File>((resolve, reject) => {
      let blob = null;
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob"; // force the HTTP response, response-type header to be blob
      xhr.onload = () => {
        blob = xhr.response; // xhr.response is now a blob object
        const archivo: File = this.convert_blob_To_File(blob, fileName);

        resolve(archivo);
      };

      xhr.onerror = (err) => {
        reject(err);
      };

      xhr.send();
    });
  }

  /**
   * Convierte un blob en un File
   * @param blob Blob
   * @param fileName Nombre del archivo resultante
   */
  convert_blob_To_File(blob: Blob, fileName: string): File {
    const b: any = blob;
    b.lastModifiedDate = new Date();
    b.name = fileName;

    return b as File;
  }

  /**
   * Obtiene de la url del recurso, la url en base64 del mismo
   * @param url Url del recurso
   */
  getBase64ImageFromURL(url: string) {
    return new Observable((observer: Observer<string>) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;

      if (!img.complete) {
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };

        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  /**
   * Devuelve una imagen en formato base64, reducida en calidad
   * @param base64 Url en formato base64 de la imagen que se desea ajustar el tamaño
   * @param width Ancho de la imagen resultante
   * @param height Alto de la imagen resultante
   * @param quitarBase Indicador para determinar si se debe agregar al string resultante la base como prefijo
   */
  resizeGetBase64Image(
    base64: string,
    width: number,
    height: number,
    quitarBase: boolean = false
  ) {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, width, height);

    const img = new Image();
    img.src = base64;
    img.width = width;
    img.height = height;

    return new Promise<string>((resolve, reject) => {
      img.onload = () => {
        ctx.drawImage(img, 0, 0, width, height);
        let dataURL = canvas.toDataURL("image/png");

        if (quitarBase) {
          dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
        }

        resolve(dataURL);
      };
    });
  }

  /**
   * Obtiene la url en base64 del elemento img del DOM
   * @param img Elemento Img del DOM
   */
  getBase64Image(img: HTMLImageElement) {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    const dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  /** Obtiene el directorio files de la app para guardar archivos */
  obtener_directorio_app() {
    if (this.deviceService.isWeb || environment.APP.MOCK_MOBILE) {
      return;
    }

    return this.deviceService.isAndroid
      ? this.file.dataDirectory
      : this.file.documentsDirectory;
  }

  /** Descarga en el directorio files de la app el archivo */
  async download_native(
    url: string,
    contentType: EnumContentType = EnumContentType.PDF,
    openDocument: boolean = false
  ): Promise<any> {
    const fileName = this.utilService.get_last_value(url);
    const path = this.obtener_directorio_app();

    return new Promise((resolve, reject) => {
      (<any>window).resolveLocalFileSystemURL(
        path,
        (directoryEntry) => {
          directoryEntry.getFile(
            fileName,
            { create: true, exclusive: false },
            (fileEntry) => {
              const xmlHttpRequest = new XMLHttpRequest();
              xmlHttpRequest.responseType = "blob";
              xmlHttpRequest.onload = () => {
                const file = xmlHttpRequest.response;
                if (file) {
                  fileEntry.createWriter(
                    (writer) => {
                      writer.onerror = (error) => {
                        console.log("Write failed: " + error.toString());
                        reject();
                      };

                      let written = 0;
                      const BLOCK_SIZE = 1 * 1024 * 1024; // write 1M every time of write
                      const writeNext = (cbFinish) => {
                        writer.onwrite = () => {
                          if (written < file.size) writeNext(cbFinish);
                          else cbFinish();
                        };
                        if (written) writer.seek(writer.length);
                        writer.write(
                          file.slice(
                            written,
                            written + Math.min(BLOCK_SIZE, file.size - written)
                          )
                        );
                        written += Math.min(BLOCK_SIZE, file.size - written);
                      };
                      writeNext(() => {
                        if (openDocument) {
                          this.fileOpener.open(
                            fileEntry,
                            contentType.toString()
                          );
                          resolve(fileEntry.fullPath);
                        }
                      });
                    },
                    (error) => {
                      console.error(error);
                      reject();
                    }
                  );
                }
              };
              xmlHttpRequest.open("GET", url, true);
              xmlHttpRequest.send(null);
            },
            (error) => {
              console.error("error getting file! ", error);
              reject();
            }
          );
        },
        (error) => {
          console.error("error getting data directory! ", error);
          reject();
        }
      );
    });
  }

  /** Indica si el archivo existe en directorio files de la app */
  async file_existe(fileName: string) {
    const directorio = this.obtener_directorio_app();
    return this.file.listDir(directorio, "").then((values: Entry[]) => {
      return (
        values.filter((value: Entry) => value.name === fileName).length > 0
      );
    });
  }

  /** Obtiene información relativa al archivo */
  async obtener_archivo(fileName: string): Promise<Entry> {
    const directorio = this.obtener_directorio_app();
    return this.file.listDir(directorio, "").then((values: Entry[]) => {
      return values.filter((value: Entry) => value.name === fileName)[0];
    });
  }

  /** Obtiene el contenido del archivo */
  async obtener_contenido_archivo(fileName: string) {
    const directorio = this.obtener_directorio_app();
    return this.file.readAsText(directorio, fileName);
  }

  /** Obtiene la url nativa del archivo, es decir, con el protocolo cdvfile:// */
  async obtener_url_nativa(fileName: string) {
    const existe = await this.file_existe(fileName);
    let urlNativa = "";

    if (existe) {
      const directorio = this.obtener_directorio_app();
      await this.file.listDir(directorio, "").then((values: Entry[]) => {
        values.forEach((value: Entry, index: number) => {
          if (value.name === fileName) {
            urlNativa = value.toURL();
            return false;
          }
        });
      });
    }

    return urlNativa;
  }

  /** Obtien la url relativa del archivo que representa al archivo dentro del directorio files */
  async obtener_url_relativa(fileName: string) {
    return new Promise<string>((resolve, reject) => {
      const path = this.obtener_directorio_app();
      this.file
        .resolveDirectoryUrl(path)
        .then((directory: DirectoryEntry) => {
          this.file
            .getFile(directory, fileName, { create: false })
            .then((file: FileEntry) => {
              const url = this.webview.convertFileSrc(file.toURL());
              resolve(url);
            });
        })
        .catch((error) => {
          console.error(JSON.stringify(error));
          reject(error);
        });
    });
  }
}
