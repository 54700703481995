import { Component, ViewChild, OnInit } from "@angular/core";
import { IonContent, IonInfiniteScroll } from "@ionic/angular";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

// Interfaces
import {
  IAdvancedSearchFilters,
  IModal,
  IPagination,
} from "src/app/models/interfaces/interfaces.index";

// Constantes
import {
  SCROLL_FAB_HEIGHT,
  ENABLED_SCROLL_EVENT,
  MODULOS,
  PAGINATOR,
} from "src/app/constants/config.constant";

// Clases
import {
  Notificacion,
  Lote,
  Item,
  Estado,
  ResponseApp,
} from "src/app/models/entities/entity.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { SbcService } from "src/app/services/common/sbc.service";
import { NotificacionService } from "src/app/services/business/notificacion.service";
import { DeviceService } from "src/app/services/common/device.service";

// Constantes
import {
  MESSAGE,
  POPOVER_STYLES,
  TIEMPO_MENSAJE,
} from "src/app/constants/constants.index";

// Enums
import { EnumEstadoNotificacionLeido } from "src/app/models/enums/estado.enum";
import { AdvancedSearchComponent } from "src/app/components/mobile/component.mobile.index";
import { UtilService } from "src/app/services/common/util.service";

@Component({
  selector: "app-notificacion",
  templateUrl: "./notificacion.page.html",
  styleUrls: ["./notificacion.page.scss"],
})
export class NotificacionPage implements OnInit {
  private paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  mostrarFabButton = false;
  mostrarFabButtonApartirDe = SCROLL_FAB_HEIGHT;
  habilitarEventosScroll = ENABLED_SCROLL_EVENT;
  mostrarInfiniteScrollEn = PAGINATOR.SIZE;

  mostrarFiltro = false;
  mostrarButtonLimpiar = false;
  formGroup: FormGroup;
  mostrarMensajeSinItem = false;
  notificaciones: Notificacion[] = [];
  expandidos: any[] = [];

  loteSeleccionado: Lote;
  lotes: Lote[];

  estadoSeleccionado: Item;
  estados: Item[];

  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private userService: UserService,
    private sbcService: SbcService,
    private notificacionService: NotificacionService,
    private deviceService: DeviceService,
    private utilService: UtilService
  ) {}

  ngOnInit() {
    this.lotes = this.userService.user.Lotes;
    this.sbcService.estados.forEach((estado: Estado) => {
      if (estado.Modulo === MODULOS.NOTIFICACION) {
        this.estados = estado.Estados;
        return false;
      }
    });

    this.configurar_formulario();
    this.filtrar();
  }

  filtrar() {
    this.limpiar_filtro();
    this.setear_lote_seleccionado();
    this.setear_estado_seleccionado();
    this.resolver_vista_notificacion();
    this.mostrarButtonLimpiar = true;
  }

  resolver_vista_notificacion(event?) {
    if (event) {
      this.paginacion.pagina += 1;
      this.cargar_notificaciones(event);
    } else {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.cargar_notificaciones(event);
      });
    }
  }

  openInNewTab(url: string): void {
    this.utilService.openNewTab(url);
  }

  limpiar_filtro() {
    this.notificaciones = [];
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.notificacionService.limpiar();
    this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    this.mostrarButtonLimpiar = false;
    this.mostrarMensajeSinItem = false;
  }

  informar_scroll(event) {
    if (this.deviceService.isIos) {
      return;
    }

    this.content
      .getScrollElement()
      .then(
        (el) =>
          (this.mostrarFabButton =
            el.scrollTop > this.mostrarFabButtonApartirDe)
      );
  }

  volver_al_inicio() {
    this.content.scrollToTop();
  }

  notificacion_leida(notificacion: Notificacion) {
    return notificacion.Estado === EnumEstadoNotificacionLeido.Leida;
  }

  mostrar_detalle(notificacion: Notificacion) {
    if (notificacion.Estado === EnumEstadoNotificacionLeido.NoLeida) {
      this.actualizar_notificacion_como_leida(notificacion);
    }
  }

  actualizar_notificacion_como_leida(notificacion: Notificacion) {
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.notificacionService
        .marcar_notificacion_como_leida(notificacion.Id)
        .then((resp: ResponseApp) => {
          notificacion.Estado = EnumEstadoNotificacionLeido.Leida;
        })
        .catch((error) => {
          this.controlService.mostrar_toast_con_error(error);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    });
  }

  toggle(exceptuando: number) {
    this.expandidos.forEach((item, index: number) => {
      if (index === exceptuando) {
        this.expandidos[index].expandido = !this.expandidos[index].expandido;
      } else {
        this.expandidos[index].expandido = false;
      }
    });
  }

  html_a_texto(mensaje) {
    let htmlCode = mensaje;
    //get rid of scripts
    htmlCode = htmlCode.replace(
      /<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi,
      ""
    );
    //Title and list bullets
    htmlCode = htmlCode.replace(/<title>/gi, " ");
    htmlCode = htmlCode.replace(/<link/gi, "<llink");
    htmlCode = htmlCode.replace(/<\/li><li>/gi, "</li>\n<li>");
    htmlCode = htmlCode.replace(/<li/gi, "\n• <li");
    htmlCode = htmlCode.replace(/<llink/gi, "<link");
    htmlCode = htmlCode.replace(/<\/li>\n/gi, "</li>");

    //remove tags
    document.getElementById("tHide").innerHTML = htmlCode;
    htmlCode = document.getElementById("tHide").textContent;
    document.getElementById("tHide").style.display = "none";
    //line break shenanigans
    htmlCode = htmlCode.replace(/(\n\r|\n|\r)/gm, "\n");
    htmlCode = htmlCode.replace(/(\n \n)/gm, "\n\n");
    htmlCode = htmlCode.replace(/(\n	\n)/gm, "\n\n");
    htmlCode = htmlCode.replace(/(\n\n\n\n\n\n\n)/gm, "\n\n");
    htmlCode = htmlCode.replace(/(\n\n\n\n\n\n)/gm, "\n\n");
    htmlCode = htmlCode.replace(/(\n\n\n\n\n)/gm, "\n\n");
    htmlCode = htmlCode.replace(/(\n\n\n\n)/gm, "\n\n");
    htmlCode = htmlCode.replace(/(\n\n\n)/gm, "\n\n");
    htmlCode = htmlCode.trim();
  }

  doRefresh(event) {
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.notificacionService.limpiar();
    this.notificacionService
      .obtener_notificaciones(
        this.loteSeleccionado,
        this.estadoSeleccionado,
        this.paginacion
      )
      .then((resp: Notificacion[]) => {
        this.notificaciones = resp;
        this.controlaFindePagina();
        event.target.complete();
      })
      .catch((error) => {
        event.target.complete();
      });
  }

  private cargar_notificaciones(event?) {
    this.notificacionService
      .obtener_notificaciones(
        this.loteSeleccionado,
        this.estadoSeleccionado,
        this.paginacion
      )
      .then((resp: Notificacion[]) => {
        if (
          resp == null ||
          this.notificacionService.paginacionActual.cantidad === 0
        ) {
          this.controlService.habilitar_infinite_scroll(event, false);
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_SIN_DATOS,
            TIEMPO_MENSAJE
          );
        } else {
          this.notificaciones = resp;
          this.controlService.completar_infinite_scroll(event);
          this.sincronizar_notificaciones_expandidas();
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.controlService.habilitar_infinite_scroll(event, false);
        this.controlService.mostrar_toast_con_error(error, false);
      })
      .finally(() => {
        this.controlaFindePagina();
        this.mostrarMensajeSinItem = this.notificaciones.length === 0;
        if (event == null) {
          this.controlService.ocultar_loading();
        }
      });
  }

  private base64_to_html(notification: Notificacion) {
    if (notification.DescripcionIsHTML === 1) {
      notification.Descripcion = window.atob(notification.Descripcion);
    }
  }

  public set_Html_Iframe(dataHTML: String) {
    var iframe = document.getElementById("iframeID") as any;
    iframe =
      iframe.contentWindow ||
      iframe.contentDocument.document ||
      iframe.contentDocument;

    iframe.document.open();
    iframe.document.write(dataHTML);
    iframe.document.close();
  }

  private configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      lotes: [this.lotes[0].Id, Validators.required],
      estados: [this.obtener_estado_default().Id, Validators.required],
    });
  }

  private setear_lote_seleccionado() {
    this.loteSeleccionado = this.lotes.filter(
      (lote: Lote) => lote.Id === this.formGroup.value.lotes
    )[0];
  }

  private setear_estado_seleccionado() {
    this.estadoSeleccionado = this.estados.filter(
      (estado: Item) => estado.Id === this.formGroup.value.estados
    )[0];
  }

  private sincronizar_notificaciones_expandidas() {
    this.notificaciones.forEach((notificacion: Notificacion) => {
      this.expandidos.push({ expandido: false });
    });
  }

  private obtener_estado_default(): Item {
    return this.estados.filter(
      (estado: Item) => estado.Id === EnumEstadoNotificacionLeido.NoLeida
    )[0];
  }

  open_advanced_search() {
    const componentProps: { filters: IAdvancedSearchFilters } = {
      filters: {
        showLoteFilter: true,
        showEstadoFilter: true,
        moduloEstado: MODULOS.NOTIFICACION,
      },
    };
    this.controlService
      .mostrarPopOver({
        component: AdvancedSearchComponent,
        componentProps,
        cssClass: POPOVER_STYLES.ADVANZED_SEARCH_MODAL,
        backdropDismiss: false,
      })
      .then((data) => {
        if (data) {
          this.formGroup.controls["lotes"].setValue(data.lotes);
          this.formGroup.controls["estados"].setValue(data.estados);
          this.filtrar();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  //controlamos si llegó al final de la paginación para deshabilitar el Infinite Scroll
  controlaFindePagina() {
    let maxPages = Math.ceil(
      this.notificacionService.paginacionActual.cantidad /
        this.paginacion.cantidad
    );
    if (maxPages == this.paginacion.pagina)
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, false);
    else
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    //console.log('total en db:' + this.notificacionService.paginacionActual.cantidad +', maxPages: ' + maxPages + ' , paginaActual: ' + this.paginacion.pagina)
  }

  markAllAsRead(): void{
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.notificacionService
        .marcarTodoLeido()
        .then(() => {
          this.paginacion.pagina = PAGINATOR.PAGE_INIT;
          this.notificacionService.limpiar();

          this.notificacionService
            .obtener_notificaciones(
              this.loteSeleccionado,
              this.estadoSeleccionado,
              this.paginacion
            )
            .then((resp: Notificacion[]) => {
              this.notificaciones = resp;
              this.controlaFindePagina();
            });
        })
        .catch((error) => {
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    });
  }
}
