import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { ItemElement } from "src/app/models/clases/ItemElement";
import { Invitacion } from "src/app/models/entities/business/invitacion.entity";
import { Lote, ResponseApp } from "src/app/models/entities/entity.index";
import {
  IDictionary,
  IPagination,
} from "src/app/models/interfaces/interfaces.index";
import { ExceptionService } from "../common/exception.service";
import { RestService } from "../common/rest.service";
import { SecurityService } from "../security/security.service";
import { CatalogoService } from "./catalogo.service";
import { InvitacionDetalle } from "../../models/entities/business/invitacion.entity";
import { UtilService } from "../common/util.service";

@Injectable({
  providedIn: "root",
})
export class InvitacionService {
  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private securityService: SecurityService,
    private exceptionService: ExceptionService,
    private utilService: UtilService
  ) { }

  private paginacion: IPagination = { pagina: 1, cantidad: 10 };
  private itemElement = new ItemElement<InvitacionDetalle>();
  invitaciones: InvitacionDetalle[] = [];
  paginacionActual: IPagination = { pagina: 1, cantidad: 10 };

  async CrearInvitacion(invitacion: Invitacion, lote: Lote, isForNautica = false) {
    const url = `${this.catalogoService.catalogo}${isForNautica ? 'AccesoInvitacionNauticaGenerar' : 'AccesoInvitacionGenerar'}`;
    const body = {
      token: this.securityService.token,
      idLote: lote.Id,
      idBarrio: this.securityService.codigoBarrio,
      desde: invitacion.FechaDesde,
      hasta: invitacion.FechaHasta,
      Comentario: invitacion.Comentario,
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  public async obtener_invitaciones(
    lote: Lote,
    paginacion: IPagination = this.paginacion,
    estado: string = "0",
    isForNautica = false
  ) {
    const url = `${this.catalogoService.catalogo}${isForNautica ? 'AccesoInvitacionesNauticasObtener' : 'AccesoInvitacionesObtener'}`;
    const body = {
      token: this.securityService.token,
      accesoInvitacionMobileFilter: {
        IdLote: lote.Id,
        Estado: estado,
        FechaDesde: "",
        FechaHasta: "",
        Comentario: null,
        Documento: null,
        Nombre: null,
        Apellido: null,
      },
      paginaActual: paginacion.pagina.toString(),
      cantxPagina: paginacion.cantidad.toString(),
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<any, InvitacionDetalle[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;
            this.invitaciones = [];

            let invitaciones = this.utilService.mapear_propiedades(
              resp.Data,
              new InvitacionDetalle()
            );
            invitaciones = this.itemElement.refrescar(
              invitaciones,
              this.invitaciones
            );

            invitaciones.forEach((ticket) => {
              if (this.invitaciones.length <= this.paginacionActual.cantidad) {
                this.invitaciones.push(ticket);
              }
            });

            return this.invitaciones;
          })
        )
        .subscribe(
          (data: Invitacion[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }
  async get_invitacion(
    catalogo: string,
    token: string,
    idAccesoInvitacion: string
  ) {
    const url = `${catalogo}ObtenerTicket/${token}/`;
    const options: IDictionary[] = [
      { key: "{idAccesoInvitacion}", value: idAccesoInvitacion },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, false, false, options)
        .pipe(
          map<any, InvitacionDetalle>((resp: any) => {
            this.exceptionService.resolver_error(resp);
            const invitacion: InvitacionDetalle =
              this.utilService.mapear_propiedades(
                resp.Data,
                new InvitacionDetalle()
              );
            return invitacion;
          })
        )
        .subscribe(
          (data: InvitacionDetalle) => {
            resolve(data);
          },
          (error) => {
            console.log("error en get_invitacion 123", error);
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log("error en get_invitacion 128", error);
      throw error;
    });
  }
  limpiar() {
    this.invitaciones = [];
  }

  async obtenerMensajePreAprobacion(invitacion: InvitacionDetalle) {
    const url = `${this.catalogoService.catalogo}AccesoInvitacionObtenerPreguntaPreAprobacion/`;
    const options: IDictionary[] = [
      { key: "{idAccesoInvitacion}", value: invitacion.Id },
    ];

    try {
      return new Promise<ResponseApp>((resolve, reject) => {
        this.restService
          .get(url, true, false, options)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      console.log(error);
      //throw error;
    }
  }

  async aprobar_invitacion(
    invitacion: InvitacionDetalle,
    cambiarVigencia: string = "1"
  ) {
    const url = `${this.catalogoService.catalogo}AccesoInvitacionAprobar`;
    const body = {
      token: this.securityService.token,
      idAccesoInvitacion: invitacion.Id,
      cambiarVigencia: cambiarVigencia,
    };
    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async eliminar_invitacion(invitacion: InvitacionDetalle) {
    const url = `${this.catalogoService.catalogo}AccesoInvitacionEliminar`;
    const body = {
      token: this.securityService.token,
      idAccesoInvitacion: invitacion.Id,
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
