import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

// Constantes
import { environment } from "src/app/constants/constants.index";

// Enums
import { EnumTipoApp } from "./models/enums/tipo.enum";

const routes: Routes =
  environment.APP.TYPE_APP === EnumTipoApp.Mobile
    ? [
        {
          path: "",
          loadChildren: "./pages/mobile/tabs/tabs.module#TabsPageModule",
        },
        {
          path: "home",
          loadChildren: "./pages/mobile/home/home.module#HomePageModule",
        },
        {
          path: "bloqueo",
          loadChildren:
            "./pages/mobile/bloqueo/bloqueo.module#BloqueoPageModule",
        },
        {
          path: "login",
          loadChildren: "./pages/mobile/login/login.module#LoginPageModule",
        },
        {
          path: "barrio",
          loadChildren:
            "./pages/mobile/login/barrio/barrio.module#BarrioPageModule",
        },
        {
          path: "onboarding",
          loadChildren:
            "./pages/mobile/onboarding/onboarding.module#OnboardingPageModule",
        },
        { path: "**", redirectTo: "tabs" },
      ]
    : [
        {
          path: "index",
          loadChildren: "./pages/web/index/index.module#IndexPageModule",
        },
        {
          path: "login-web",
          loadChildren: "./pages/web/login/login.module#LoginPageWebModule",
        },
        // { path: 'pre-noticia-web', loadChildren: './pages/web/pre-holder/pre-noticia/pre-noticia.module#PreNoticiaPageModule' },
        { path: "**", redirectTo: "index" },
      ];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
