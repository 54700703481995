export enum EnumTipoAcceso {
  Ingreso = "Ingreso",
  Egreso = "Egreso",
}

export enum EnumTipoNotificacion {
  ConAviso = "Con aviso",
  SinAviso = "Sin aviso",
}

export enum EnumTipoApp {
  Web = "Web",
  Mobile = "Mobile",
}

export enum EnumTipoCanal {
  Todos = 0,
  Web = 1,
  Mobile = 2,
}

export enum EnumTipoConfig {
  Local = "Local",
  Remoto = "Remoto",
}

export enum EnumTipoImg {
  Splash = "Splash",
  Icon = "Icon",
  All = "All",
}

export enum EnumTipoMenu {
  PreHolder = 0,
  Login = 1,
  Home = 2,
}

export enum EnumEmergencyTypes {
  cancelAlert = -1,
  fireAlert = 1,
  securityAlert = 2,
  medicAlert = 3,
  genreViolence = 4,
}

export enum EnumNotificationTypes {
  ticket = "ticket",
  noticia = "publicacion",
  notificacion = "notificacion",
  documentoDelLote = "loteDocumento",
  acceso = "acceso",
  invitacion = "accesoInvitacion",
}

export enum EnumTipoSegmento {
  Home = "HOME",
  Mensaje = "MENSAJE",
  Seguridad = "SEGURIDAD",
  Perfil = "PERFIL",
  Tabs = "TABS",
}

export enum EnumTipoContacto {
  TelefonoFijo = "TF",
  TelefonoMovil = "TM",
}

export enum EnumFormaAcceso {
  Local = "Local",
  Celular = "Celular",
  FaceId = "FaceId",
  Tarjeta = "Tarjeta",
  Preautorizado = "Preautorizado"
}

export enum EnumTipoPlazoInquilinos {
  Temporal = 1,
  NoTemporal = 0,
}

// Url de donde sacar más tipos: https://www.freeformatter.com/mime-types-list.html
export enum EnumContentType {
  PDF = "application/pdf",
  CSS = "text/css",
  PNG = "image/png",
  OTF = "application/x-font-otf",
  WOFF = "application/x-font-woff",
  HTML = "text/html",
  XML = "application/xml",
  JSON = "application/json",
}
