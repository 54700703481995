import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";

// Páginas
import { DetailsReservaCamionetaPage } from "./details.page";

// Módulos
import { ComponentsMobileModule } from "src/app/components/components-mobile.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ComponentsMobileModule,
  ],
  declarations: [DetailsReservaCamionetaPage],
})
export class DetailsReservaCamionetaPageModule { }
