import { Component, ViewChild, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IonContent, IonInfiniteScroll } from "@ionic/angular";

// Interfaces
import {
  IPagination,
  IModal,
  IAdvancedSearchFilters,
} from "src/app/models/interfaces/interfaces.index";

// Constantes
import {
  PAGINATOR,
  SCROLL_FAB_HEIGHT,
  ENABLED_SCROLL_EVENT,
  FECHA,
} from "src/app/constants/config.constant";
import {
  MESSAGE,
  POPOVER_STYLES,
  TIEMPO_MENSAJE,
} from "src/app/constants/constants.index";

// Clases
import { ControlService } from "src/app/services/common/control.service";
import { DeviceService } from "src/app/services/common/device.service";

// Páginas
import { DetalleInvitacionPage } from "./detalle/detalle-invitacion.page";
import { AgregarInvitacionPage } from "src/app/pages/mobile/seguridad/invitacion/agregar/agregar-invitacion.page";
import { InvitacionDetalle } from "../../../../models/entities/business/invitacion.entity";
import { InvitacionService } from "src/app/services/business/invitacion.service";

//Components
import { AdvancedSearchComponent } from "src/app/components/mobile/component.mobile.index";
import { Lote } from "src/app/models/entities/entity.index";
import { UserService } from "src/app/services/security/user.service";

@Component({
  selector: "app-invitacion",
  templateUrl: "./invitacion.page.html",
  styleUrls: ["./invitacion.page.scss"],
})
export class InvitacionPage implements OnInit {
  private paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  mostrarFabButton = false;
  mostrarFabButtonApartirDe = SCROLL_FAB_HEIGHT;
  habilitarEventosScroll = ENABLED_SCROLL_EVENT;
  mostrarInfiniteScrollEn = PAGINATOR.SIZE;

  formatoFechaControl: string = FECHA.FORMATO_VISUAL;
  mostrarButtonLimpiar = false;
  mostrarFiltro = false;
  formGroup: FormGroup;
  mostrarMensajeSinItem = false;
  invitaciones: InvitacionDetalle[] = [];
  expandidos: any[] = [];

  loteSeleccionado: Lote;
  lotes: Lote[];

  invitacionConfirmada: string = "1";

  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private invitacionService: InvitacionService,
    private deviceService: DeviceService,
    private userService: UserService
  ) {}

  ngOnInit() {}

  ionViewWillEnter() {
    this.lotes = this.userService.user.Lotes;
    this.configurar_formulario();
    this.filtrar();
  }

  filtrar() {
    this.limpiar_filtro();
    this.resolver_vista_acceso();
    this.mostrarButtonLimpiar = true;
  }

  resolver_vista_acceso(event?) {
    if (event) {
      this.paginacion.pagina += 1;
      this.cargar_invitaciones(event);
    } else {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.cargar_invitaciones(event);
      });
    }
  }

  private configurar_formulario() {
    this.loteSeleccionado = this.lotes[0];
    this.formGroup = this.formBuilder.group({
      lote: [this.loteSeleccionado.Id, Validators.required],
    });
  }

  limpiar_filtro() {
    this.invitaciones = [];
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.invitacionService.limpiar();
    this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    this.mostrarMensajeSinItem = false;
    this.mostrarButtonLimpiar = false;
  }

  informar_scroll(event) {
    if (this.deviceService.isIos) {
      return;
    }

    this.content
      .getScrollElement()
      .then(
        (el) =>
          (this.mostrarFabButton =
            el.scrollTop > this.mostrarFabButtonApartirDe)
      );
  }

  volver_al_inicio() {
    this.content.scrollToTop();
  }

  async mostrar_detalle(invitacion: InvitacionDetalle) {
    const modal: IModal = {
      component: DetalleInvitacionPage,
      componentProps: { invitacion: invitacion },
      returnData: true,
    };
    this.controlService.mostrar_modal(modal).then((info: any) => {
      this.filtrar();
    });
  }

  open_advanced_search() {
    const componentProps: { filters: IAdvancedSearchFilters } = {
      filters: {
        showLoteFilter: true,
      },
    };
    this.controlService
      .mostrarPopOver({
        component: AdvancedSearchComponent,
        componentProps,
        cssClass: POPOVER_STYLES.ADVANZED_SEARCH_MODAL,
        backdropDismiss: false,
      })
      .then((data) => {
        if (data) {
          this.loteSeleccionado = this.lotes.find(
            (lote) => lote.Id === data.lotes
          );
          this.formGroup.controls["lote"].setValue(data.lotes);
          this.filtrar();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  doRefresh(event) {
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.invitacionService.limpiar();
    this.invitacionService
      .obtener_invitaciones(
        this.loteSeleccionado,
        this.paginacion,
        this.invitacionConfirmada
      )
      .then((resp: InvitacionDetalle[]) => {
        this.invitaciones = resp;
        this.controlaFindePagina();
        event.target.complete();
      })
      .catch((error) => {
        event.target.complete();
      });
  }

  private cargar_invitaciones(event?) {
    this.invitacionService
      .obtener_invitaciones(
        this.loteSeleccionado,
        this.paginacion,
        this.invitacionConfirmada
      )
      .then((resp: InvitacionDetalle[]) => {
        if (
          resp == null ||
          this.invitacionService.paginacionActual.cantidad === 0
        ) {
          this.controlService.habilitar_infinite_scroll(event, false);
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_SIN_DATOS,
            TIEMPO_MENSAJE
          );
        } else {
          this.invitaciones = resp;
          this.controlService.completar_infinite_scroll(event);
        }
      })
      .catch((error) => {
        this.controlService.habilitar_infinite_scroll(event, false);
        this.controlService.mostrar_toast_con_error(error, false);
      })
      .finally(() => {
        this.controlaFindePagina();
        this.mostrarMensajeSinItem = this.invitaciones.length === 0;
        if (event == null) {
          this.controlService.ocultar_loading();
        }
      });
  }

  controlaFindePagina() {
    let maxPages = Math.ceil(
      this.invitacionService.paginacionActual.cantidad /
        this.paginacion.cantidad
    );
    if (maxPages == this.paginacion.pagina)
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, false);
    else
      this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
  }

  async agregar_invitacion() {
    const modal: IModal = {
      component: AgregarInvitacionPage,
      componentProps: { lote: this.loteSeleccionado },
    };
    this.controlService.mostrar_modal(modal).then((info: any) => {
      this.filtrar();
    });
  }
}
