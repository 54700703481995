export class Acceso {
  Autorizado: Autorizado = null;
  Fecha: Date | any = null;
  FormaAcceso: string = null;
  NombreAutorizante: string = null;
  NumeroTarjeta: string = null;
  Puesto: string = null;
  Tipo: string = null;
}

export class Autorizado {
  Apellido: string = null;
  DocumentoNumero: string = null;
  ExisteComoAutorizado: number = 0;
  Nombre: string = null;
  Tipo: string = null;
  Vehiculo: string = null;
}
