import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
} from "@angular/router";

// Servicios
import { AuthService } from "../services/security/auth.service";
import { SbcService } from "../services/common/sbc.service";
import { RouteService } from "../services/common/route.service";
import { ControlService } from "../services/common/control.service";
import { MenuService } from "../services/common/menu.service";

// Constantes
import { MESSAGE, TIEMPO_MENSAJE } from "../constants/message.constant";

// Enums
import { EnumTipoMenu } from "../models/enums/tipo.enum";

// Interfaces
import { IIdentity } from "../models/interfaces/interfaces.index";
import { MENU } from "../constants/menu.constant";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  static lastUrl = "";

  constructor(
    private authService: AuthService,
    private sbcService: SbcService,
    private routeService: RouteService,
    private controlService: ControlService,
    private menuService: MenuService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | Promise<boolean> {
    AuthGuard.lastUrl = state.url;
    console.log("authGuard 44", AuthGuard.lastUrl);
    return new Promise<boolean>((resolve, reject) => {
      console.clear();
      let itemLocal = this.menuService.obtener_opcion_menu_por_propiedad(
        state.url,
        "path"
      );
      console.log("ItemLocal 50", itemLocal);
      this.sbcService
        .resolver_menu(EnumTipoMenu.Home)
        .subscribe((config: string | IIdentity[]) => {
          if (typeof config !== "string") {
            if (itemLocal) {
              console.log("config 56", config);
              const itemConfig =
                this.menuService.obtener_opcion_menu_por_propiedad(
                  itemLocal.id,
                  "id",
                  config
                );
              console.log("itemConfig 62", itemConfig);

              console.log("itemLocal 64", itemLocal);
              itemLocal = this.menuService.habilitar_acceso_por_item(
                itemLocal,
                itemConfig
              );
              console.log("itemLocal 69", itemLocal);

              if (!itemLocal.hide) {
                console.log(72);
                this.validar_autenticacion(resolve);
              } else {
                console.log(75);
                this.resolver_redireccion_por_menu_disponible(config);
              }
            } else {
              this.resolver_redireccion_por_menu_disponible(config);
            }
          }
        });
    });
  }

  private validar_autenticacion(resolve) {
    this.authService.estaAutenticado().then((result: boolean) => {
      // Si no está autenticado, lo redirecciono al login
      if (!result) {
        this.routeService.resolver_redireccion_con_token_expirado();
        this.controlService.mostrar_toast(
          MESSAGE.ALERT.MENSAJE_LOGIN,
          TIEMPO_MENSAJE
        );
      } else {
        this.sbcService.guardar_datos_en_cache();
      }

      resolve(result);
      return result;
    });
  }

  /**
   * Resuelve a qué ruta redirigir la web para los casos en que esté restringido por perfil;
   * resolviendo el primer menú accesible para redirigir
   * @param menuConfig Configuración del menú
   */
  private resolver_redireccion_por_menu_disponible(menuConfig: IIdentity[]) {
    console.log("aquí resolver_redireccion_por_menu_disponible 109");
    menuConfig = this.menuService.reemplazar_menu(MENU.WEB.INDEX, menuConfig);
    const menuAccecible: IIdentity =
      this.menuService.obtener_primer_menu_disponible(menuConfig);
    let routes = "";
    if (menuAccecible.opciones && menuAccecible.opciones.length > 0) {
      routes = menuAccecible.opciones[0].path;
    } else {
      routes = menuAccecible.path;
    }

    this.routeService.redireccionar(routes);
  }
}
