import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Servicios
import { RestService } from "../common/rest.service";
import { CatalogoService } from "./catalogo.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";
import { SecurityService } from "../security/security.service";

// Constantes
import { IDictionary } from "../../models/interfaces/interfaces.index";

// Clases
import {
  ResponseApp,
  ReservaDestino,
} from "../../models/entities/entity.index";

// Enums
import { Horario, Parada, Persona, ReservaCamioneta, TransporteViajeIniciado } from "src/app/models/entities/business/reserva.entity";
import * as moment from "moment";

@Injectable({
  providedIn: "root",
})
export class CamionetaService {

  destinosReservables: ReservaDestino[] = [];
  turnosReservables: ReservaCamioneta[] = [];
  misReservas: ReservaCamioneta[] = [];

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private exceptionService: ExceptionService,
    private securityService: SecurityService
  ) { }

  public async obtener_paradas_disponibles(sentido: number): Promise<Parada[]> {
    const url = `${this.catalogoService.catalogo}TIObtenerParadas/`;
    const options: IDictionary[] = [
      { key: "{sentido}", value: sentido.toString() },
    ];
    return this.obtener_resultado_paradas_disponibles(url, options);
  }

  private async obtener_resultado_paradas_disponibles(url: string, options: IDictionary[]) {
    return new Promise<Parada[]>((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Parada[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            const paradasDisponibles = this.utilService.mapear_propiedades(
              resp.Data,
              new Parada()
            );

            return paradasDisponibles;
          })
        )
        .subscribe(
          (data: Parada[]) => {
            resolve(data);
          },
          (error) => {
            //this.limpiar();
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      throw error;
    });
  }


  //----------------------------------------------------------------
  public async obtener_horarios_disponibles(fecha: string, idParada: string, sentido: number, plazasRequeridas: number): Promise<Horario[]> {
    const url = `${this.catalogoService.catalogo}TIObtenerHorariosDisponibles/`;
    const options: IDictionary[] = [
      { key: "{fecha}", value: fecha },
      { key: "{idParada}", value: idParada },
      { key: "{sentido}", value: sentido.toString() },
      { key: "{plazasRequeridas}", value: plazasRequeridas.toString() },
    ];
    return this.obtener_resultado_horarios_disponibles(url, options);

  }

  private async obtener_resultado_horarios_disponibles(url: string, options: IDictionary[]) {
    return new Promise<Horario[]>((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Horario[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            const horariosDisponibles = this.utilService.mapear_propiedades(
              resp.Data,
              new Horario()
            );

            return horariosDisponibles;
          })
        )
        .subscribe(
          (data: Horario[]) => {
            resolve(data);
          },
          (error) => {
            //this.limpiar();
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      throw error;
    });
  }

  public async getPersonasByLote(loteId): Promise<Persona[]> {
    const url = `${this.catalogoService.catalogo}TIObtenerPersonasByLote/`;

    const options: IDictionary[] = [
      { key: "{loteId}", value: loteId },
    ];
    return this.obtener_resultado_personas_by_lote(url, options);
  }

  obtener_resultado_personas_by_lote(url: string, options: IDictionary[]) {
    return new Promise<Persona[]>((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Persona[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            const turnosReservables = this.utilService.mapear_propiedades(
              resp.Data,
              new Persona()
            );

            return turnosReservables;
          })
        )
        .subscribe(
          (data: Persona[]) => {
            resolve(data);
          },
          (error) => {
            //this.limpiar();
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      //console.log( error );
      throw error;
    });
  }

  obtener_mis_reservas() {
    const url = `${this.catalogoService.catalogo}TIObtenerReservas/`;

    return this.obtener_resultado_mis_reservas(url);
  }

  private async obtener_resultado_mis_reservas(
    url: string
  ) {
    return new Promise<ReservaCamioneta[]>((resolve, reject) => {
      this.restService
        .get(url, true, false)
        .pipe(
          map<any, ReservaCamioneta[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            const misReservas = this.utilService.mapear_propiedades(
              resp.Data,
              new ReservaCamioneta()
            );

            misReservas.forEach((turno: ReservaCamioneta) => {
              turno.Fecha = moment(turno.Fecha).format('DD/MM/YYYY');
            });

            return misReservas;
          })
        )
        .subscribe(
          (data: ReservaCamioneta[]) => {
            resolve(data);
          },
          (error) => {
            //this.limpiar();
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      //console.log( error );
      throw error;
    });
  }


  //**Cancelar Reserva */
  async cancelar_reserva(reserva: ReservaCamioneta) {
    const url = `${this.catalogoService.catalogo}TICancelarReserva`;
    const body = {
      token: this.securityService.token,
      numero: reserva.Numero,
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      //console.log(error);
      //throw error;
    }
  }

  //**Crear reserva */
  async crear_reserva(data: any) {
    const url = `${this.catalogoService.catalogo}TIGenerarReserva`;

    const body = {
      token: this.securityService.token,
      ...data,
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      //console.log(error);
      //throw error;
    }
  }


  public obtenerReservaTransporteAsignado(reservaId: string): Promise<TransporteViajeIniciado> {
    const url = `${this.catalogoService.catalogo}TIObtenerReservaTransporteAsignado/`;
    const options: IDictionary[] = [
      { key: "{numero}", value: reservaId },
    ];
    return this.obtener_resultado_transporte_asignado(url, options);
  }

  private async obtener_resultado_transporte_asignado(url: string, options: IDictionary[]) {
    return new Promise<TransporteViajeIniciado>((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, TransporteViajeIniciado>((resp: any) => {

            this.exceptionService.resolver_error(resp);

            const transporte = this.utilService.mapear_propiedades(
              resp.Data,
              new TransporteViajeIniciado()
            );

            return transporte;
          })
        )
        .subscribe(
          (data: TransporteViajeIniciado) => {
            resolve(data);
          },
          (error) => {
            //this.limpiar();
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      throw error;
    });
  }
}
