import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Platform } from "@ionic/angular";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { LocationAccuracy } from "@ionic-native/location-accuracy/ngx";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Geolocation, } from "@ionic-native/geolocation/ngx";
import { HttpClient, } from "@angular/common/http";
import { SegmentService } from "src/app/services/common/segment.service";
import { EnumTipoSegmento } from "src/app/models/enums/tipo.enum";
import { ControlService } from "src/app/services/common/control.service";
import { STORAGE } from "src/app/constants/constants.index";
import { StorageService } from "src/app/services/common/storage.service";
import { ExceptionService } from "src/app/services/common/exception.service";
import { ResponseApp } from "src/app/models/entities/entity.index";

@Component({
  selector: "app-lote",
  templateUrl: "./lote.page.html",
  styleUrls: ["./lote.page.scss"],
})
export class LotePage implements OnInit {
  catalogo: string = null;
  codigoSeguridad: string = null;
  formGroup: FormGroup;
  segmentTypes = EnumTipoSegmento;

  ubicacion = { latitud: 0, longitud: 0 };

  constructor(
    private route: ActivatedRoute,
    private controlService: ControlService,
    private storageService: StorageService,
    private formBuilder: FormBuilder,
    private geolocation: Geolocation,
    private http: HttpClient,
    private router: Router,
    private platform: Platform,
    private locationAccuracy: LocationAccuracy,
    private androidPermissions: AndroidPermissions,
    private segmentService: SegmentService,
    private exceptionService: ExceptionService
  ) { }

  async ngOnInit() {
    this.configurar_formulario();

    if (
      !this.platform.is("cordova") ||
      this.platform.is("ios") ||
      this.platform.is("ipad")
    ) {
      const options = { timeout: 10000, enableHighAccuracy: true, maximumAge: 3600 };
      await this.geolocation
        .getCurrentPosition(options)
        .then(
          (currentPosition) => {
            console.log("posición obtenida", currentPosition);
            this.ubicacion = {
              latitud: currentPosition.coords.latitude,
              longitud: currentPosition.coords.longitude,
            }
          }
        )
        .catch((error) => {
          this.showGPSAlert();
          console.log(error);
        });
    } else {
      await this.requestLocation();
    }

    this.route.queryParams.subscribe((params) => {
      if (params && params.catalogo) {
        this.catalogo = JSON.parse(params.catalogo);
      }
    });
  }

  async requestLocation(): Promise<boolean> {
    try {
      const hasPermission = await this.checkGPSPermission();
      if (hasPermission) {
        return await this.askToTurnOnGPS();
      } else {
        const permission = await this.requestGPSPermission();
        if (permission === "CAN_REQUEST" || permission === "GOT_PERMISSION") {
          return await this.askToTurnOnGPS();
        }
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async askToTurnOnGPS(): Promise<boolean> {
    try {
      const canRequest = await this.locationAccuracy.canRequest();
      if (!canRequest) return false;
      if (canRequest) {
        await this.locationAccuracy.request(
          this.locationAccuracy.REQUEST_PRIORITY_HIGH_ACCURACY
        );
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async checkGPSPermission(): Promise<boolean> {
    try {
      if (this.platform.is("android")) {
        const permissions = await this.androidPermissions.checkPermission(
          this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION
        );
        if (permissions && permissions.hasPermission) return true;
        return false;
      } else {
        return true;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  async requestGPSPermission(): Promise<string> {
    try {
      const canRequest = await this.locationAccuracy.canRequest();
      if (canRequest) {
        return "CAN_REQUEST";
      } else {
        const permissions = await this.androidPermissions.requestPermission(
          this.androidPermissions.PERMISSION.ACCESS_FINE_LOCATION
        );
        if (permissions && permissions.hasPermission) return "GOT_PERMISSION";
        return "DENIED_PERMISSION";
      }
    } catch (error) {
      console.log(error);
      return "DENIED_PERMISSION";
    }
  }

  showGPSAlert() {
    this.controlService.mostrar_alert({
      header: "Emergencia",
      message:
        "Por favor, active el GPS para que podamos acceder a su ubicación correctamente",
      backdropDismiss: false,
      buttons: [
        {
          text: "Aceptar",
          role: "cancel",
        },
      ],
    });
  }


  configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      lote: ["", Validators.required],
      codigoSeguridad: ["", Validators.required],
    });
  }

  cargar_lote() {
    this.storageService
      .cargar(STORAGE.KEY.CATALOGO)
      .then(async (response: string) => {
        this.catalogo = response;
        let coords;

        const options = { timeout: 10000, enableHighAccuracy: true, maximumAge: 3600 };
        try {
          const currentPosition = await this.geolocation.getCurrentPosition(options);
          console.log("ubicación antes de enviar", currentPosition);
          coords = currentPosition.coords;
          this.ubicacion = {
            latitud: currentPosition.coords.latitude,
            longitud: currentPosition.coords.longitude,
          };
        } catch (error) {
          console.log(error);
          this.ubicacion = {
            latitud: null,
            longitud: null,
          };
        }

        const url = `${this.catalogo}LoteLocalizacionUpdate `;

        let request = {
          securityCode: this.formGroup.value.codigoSeguridad,
          loteLocalizacion: {
            Numero: this.formGroup.value.lote,
            LatitudLongitud: `${this.ubicacion.latitud} , ${this.ubicacion.longitud}`,
          },
        };

        this.http.post(url, request).subscribe((resp: ResponseApp) => {
          if (resp["Status"] != "ERROR") {
            this.presentToast("Registrado correctamente");
          } else {
            this.presentToast(resp["StatusDescription"]);
            this.exceptionService.resolver_error(resp);
          }
        });
      });
  }

  async presentToast(message: any) {
    this.controlService.mostrar_alert({
      message: message,
      backdropDismiss: false,
      buttons: [
        {
          text: "OK",
          handler: () => {
            this.formGroup.get("lote").reset();
          },
        },
      ],
    });
  }

  backToHome() {
    let option = this.segmentService.segmentosHome[0];
    this.router.navigate([option.path]);
    setTimeout(() => {
      this.segmentService.actualSegment.next({
        type: this.segmentTypes.Home,
        segment: option,
      });
    }, 1000);
  }
}
