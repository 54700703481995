import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Interfaces
import {
  IPagination,
  IDictionary,
} from "src/app/models/interfaces/interfaces.index";

// Servicios
import { CatalogoService } from "./catalogo.service";
import { RestService } from "../common/rest.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";

// Clases
import { ItemElement } from "src/app/models/clases/ItemElement";
import { Acceso, Lote, FormModel } from "src/app/models/entities/entity.index";

@Injectable({
  providedIn: "root",
})
export class AccesoService {
  private paginacion: IPagination = { pagina: 1, cantidad: 10 };
  private itemElement = new ItemElement<Acceso>();

  accesos: Acceso[] = [];
  paginacionActual: IPagination = { pagina: 1, cantidad: 10 };

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private exceptionService: ExceptionService
  ) {}

  // obtenerAccesos
  async obtener_accesos(
    lote: Lote,
    form: FormModel,
    paginacion: IPagination = this.paginacion
  ) {
    const url = `${this.catalogoService.catalogo}ObtenerAccesos_v1/`;
    const options: IDictionary[] = [
      { key: "{idLote}", value: lote.Id },
      { key: "{fecha_desde}", value: form.FechaDesde },
      { key: "{fecha_hasta}", value: form.FechaHasta },
      { key: "{nombre}", value: form.Nombre },
      { key: "{apellido}", value: form.Apellido },
      { key: "{numero_documento}", value: form.Dni },
      { key: "{pagina}", value: paginacion.pagina.toString() },
      { key: "{cantidad}", value: paginacion.cantidad.toString() },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Acceso[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);
            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;

            const accesos = this.utilService.mapear_propiedades(
              resp.Data,
              new Acceso()
            );
            this.accesos = this.itemElement.refrescar(
              accesos,
              this.accesos,
              "Fecha"
            );

            this.accesos.forEach((element) => {
              let temp = element.Puesto.split("(");
              element.Puesto = temp[0];
            });

            return this.accesos;
          })
        )
        .subscribe(
          (data: Acceso[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  limpiar() {
    this.accesos = [];
  }
}
