import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Constantes
import { PATH_CONFIG } from "src/app/constants/url.constant";

// Clases
import { SettingApp } from "src/app/models/entities/entity.index";

// Enums
import { EnumTipoApp } from "src/app/models/enums/tipo.enum";

// Interfaces
import { IDictionary } from "src/app/models/interfaces/interfaces.index";

// Servicios
import { RestService } from "./rest.service";
import { DeviceService } from "./device.service";
import { CatalogoService } from "../business/catalogo.service";
import { UtilService } from "./util.service";
import { ExceptionService } from "./exception.service";

@Injectable({
  providedIn: "root",
})
export class ConfigService {
  config: any;
  configServer: SettingApp;

  constructor(
    private restService: RestService,
    private deviceService: DeviceService,
    private catalogoService: CatalogoService,
    private utilService: UtilService,
    private exceptionService: ExceptionService
  ) { }

  /**
   * Obtiene las configuraciones propias del archivo config.json que es local a la app
   */
  async cargar_configuracion_app() {
    if (this.config) {
      return new Promise<any>((resolve) => resolve(this.config));
    }

    return new Promise<any>((resolve, reject) => {
      this.restService.get(PATH_CONFIG.CONFIG_URL).subscribe((data) => {
        this.config = data;
        resolve(data);
      });
    });
  }

  /**
   * Obtiene del api rest las configuraciones que debe respetar la app (icon, shell, splash, styles)
   */
  async cargar_configuracion_app_server() {
    if (this.configServer) {
      return new Promise<SettingApp>((resolve) => resolve(this.configServer));
    }

    return this.catalogoService.cargar_catalogo_barrio().then(() => {
      const typeApp = this.deviceService.isWeb
        ? EnumTipoApp.Web
        : EnumTipoApp.Mobile;
      const url = `${this.catalogoService.catalogo}ObtenerSettings/`;
      const options: IDictionary[] = [{ key: "{typeApp}", value: typeApp }];

      return new Promise<SettingApp>((resolve, reject) => {
        this.restService
          .get(url, false, false, options)
          .pipe(
            map<any, SettingApp>((resp: any) => {
              this.exceptionService.resolver_error(resp);

              this.configServer = this.utilService.mapear_propiedades(
                resp.Data,
                new SettingApp()
              );

              console.log("configServer", this.configServer);
              return this.configServer;
            })
          )
          .subscribe(
            (data: SettingApp) => {
              resolve(data);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      }).catch((error) => {
        console.log(error);
        throw error;
      });
    });
  }
}
