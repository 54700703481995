import { Component, OnInit, Input } from "@angular/core";
import { interval, Subscription } from "rxjs";

// Clases
import {
  NotificacionOneSignal,
  ResponseApp,
} from "src/app/models/entities/entity.index";

// Constantes
import {
  MESSAGE,
  TIEMPO_MENSAJE,
  INTERVAL,
} from "src/app/constants/constants.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UtilService } from "src/app/services/common/util.service";
import { AutorizadoService } from "src/app/services/business/autorizado.service";
import { SolicitudAcceso } from "src/app/models/entities/business/autorizado.entity";
import { DeviceService } from "src/app/services/common/device.service";
import { Badge } from "@ionic-native/badge/ngx";

@Component({
  selector: "app-push-notification",
  templateUrl: "./push-notification.page.html",
  styleUrls: ["./push-notification.page.scss"],
})
export class PushNotificationPage implements OnInit {
  private clock: Subscription;
  @Input() pushNotification: NotificacionOneSignal;
  expiro: boolean;
  mostrarImagen = false;
  tiempoPorExpirar: number;

  constructor(
    private badge: Badge,
    private controlService: ControlService,
    private utilService: UtilService,
    private deviceService: DeviceService,
    private autorizadoService: AutorizadoService
  ) {}

  ngOnInit() {
    this.expiro = this.pushNotification.additionalData.ttl <= 0;
    this.pushNotification.additionalData.fecha =
      this.utilService.convert_ticks_to_date(
        this.pushNotification.additionalData.fecha
      );
    this.pushNotification.additionalData.urlImagen =
      this.utilService.resolver_url_imagen(
        this.pushNotification.additionalData.urlImagen
      );

    try {
      if (!this.badge.hasPermission()) {
        this.badge.requestPermission();
      }
      this.badge.increase(1);
    } catch (e) {
      console.error(e);
    }
    this.iniciar_conteo();
  }

  autorizar_acceso() {
    const solicitud: SolicitudAcceso = new SolicitudAcceso();
    solicitud.idAutorizacion =
      this.pushNotification.additionalData.idAutorizacion;
    solicitud.deviceId = this.deviceService.uuid;
    solicitud.autoriza = true;

    this.responder_solicitud(solicitud);
  }

  rechazar_acceso() {
    const solicitud: SolicitudAcceso = new SolicitudAcceso();
    solicitud.idAutorizacion =
      this.pushNotification.additionalData.idAutorizacion;
    solicitud.deviceId = this.deviceService.uuid;
    solicitud.autoriza = false;

    this.responder_solicitud(solicitud);
  }

  cerrar_modal() {
    if (this.clock && !this.clock.closed) {
      this.clock.unsubscribe();
    }

    this.controlService.cerrar_modal();
  }

  mostrar_boton_foto() {
    return (
      this.pushNotification &&
      this.pushNotification.additionalData &&
      this.pushNotification.additionalData.urlImagen &&
      !this.mostrarImagen
    );
  }

  private responder_solicitud(solicitud: SolicitudAcceso) {
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.autorizadoService
        .responder_solicitud_acceso(solicitud)
        .then((resp: ResponseApp) => {
          this.controlService.ocultar_loading();

          const msg = solicitud.autoriza
            ? MESSAGE.ALERT.MENSAJE_AUTORIZADO.AUTORIZAR_SOLICITUD.MENSAJE
            : MESSAGE.ALERT.MENSAJE_AUTORIZADO.RECHAZAR_SOLICITUD.MENSAJE;

          this.controlService.mostrar_toast(msg, TIEMPO_MENSAJE);
        })
        .catch((error) => {
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
          this.controlService.ocultar_loading();
        })
        .finally(() => this.cerrar_modal());
    });
  }

  private iniciar_conteo() {
    // Si no expiró la solicitud
    if (!this.expiro) {
      const tiempo: number = this.pushNotification.additionalData.ttl - 1;
      this.tiempoPorExpirar = tiempo;
      this.clock = interval(INTERVAL.SOLICITUD_ACCESO).subscribe((contador) => {
        if (this.tiempoPorExpirar === 0) {
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_TIEMPO_EXPIRADO,
            TIEMPO_MENSAJE
          );
          this.clock.unsubscribe();
          this.cerrar_modal();
        } else {
          this.tiempoPorExpirar = tiempo - contador;
        }
      });
    } else {
      this.controlService.mostrar_toast(
        MESSAGE.ALERT.MENSAJE_TIEMPO_EXPIRADO,
        TIEMPO_MENSAJE
      );
    }
  }
}
