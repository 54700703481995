import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";

// Componentes
import {
  AccountComponent,
  SegmentToolbarComponent,
  AccordionComponent,
} from "src/app/components/shared/components.shared.index";

@NgModule({
  declarations: [AccountComponent, SegmentToolbarComponent, AccordionComponent],
  exports: [AccountComponent, SegmentToolbarComponent, AccordionComponent],
  imports: [CommonModule, IonicModule],
})
export class ComponentsSharedModule {}
