import { Injectable } from "@angular/core";
import * as moment from "moment";

// Interfaces
import { IDictionary } from "src/app/models/interfaces/common/dictionary.interface";

// Servicios
import { DeviceService } from "./device.service";

// Constantes
import {
  FECHA,
  environment,
  REPLACE_IMG,
} from "src/app/constants/constants.index";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  constructor(
    private deviceService: DeviceService,
    private iab: InAppBrowser
  ) {}

  interpolar(value: string, options: IDictionary[]): string {
    if (options == null || options.length === 0) {
      return value;
    }

    options.forEach((option: IDictionary) => {
      value = value.replace(option.key, option.value);
    });

    return value;
  }

  agregar_template(
    value: string,
    templates: string[],
    separator: string = "/",
    includeLast: boolean = false
  ): string {
    if (templates == null || templates.length === 0) {
      return value;
    }

    templates.forEach((template: string, index: number) => {
      // cuando sea el último elemento, preguntar si incluyo al final el separador
      if (templates.length - 1 === index && !includeLast) {
        value += `${template}`;
        return false;
      }

      value += `${template}${separator}`;
    });

    return value;
  }

  mapear_propiedades(
    origen: any,
    tipoDestino: any,
    incluirPropiedadesDeOrigen: boolean = true
  ) {
    if (Array.isArray(origen)) {
      const array = [];
      origen.forEach((item) => {
        const destino = Object.assign({}, tipoDestino);
        array.push(
          this.mapear_propiedad(item, destino, incluirPropiedadesDeOrigen)
        );
      });

      return array;
    }

    return this.mapear_propiedad(
      origen,
      tipoDestino,
      incluirPropiedadesDeOrigen
    );
  }

  agrupar_array(arr: any, tamano: number) {
    let nuevoArreglo = [];
    for (let i = 0; i < arr.length; i += tamano) {
      nuevoArreglo.push(arr.slice(i, i + tamano));
    }

    return nuevoArreglo;
  }

  get_last_value(value: string, separador: string = "/") {
    const lastIndex = value.split(separador).length - 1;

    return value.split(separador)[lastIndex];
  }

  compare(
    a: number | string | Date,
    b: number | string | Date,
    isAsc: boolean
  ) {
    if (a == null || b == null) {
      return;
    }

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  convert_ticks_to_date(ticks: number): Date {
    let fecha: Date;
    if (ticks) {
      let hoy: Date = new Date();
      let dato: number = ticks;

      //console.log(dato);
      dato -= 621355968000000000;
      dato += hoy.getTimezoneOffset() * 600000000;

      //console.log(dato);
      dato = Math.floor(dato / 10000);

      //console.log(dato);
      fecha = new Date(dato);
    }

    return fecha;
  }

  convert_ticks_to_date_ISO(ticks: number): string {
    const fechaResultado =
      ticks !== 0 ? this.convert_ticks_to_date(ticks) : new Date();

    return this.deviceService.isWeb || environment.APP.MOCK_MOBILE
      ? moment(fechaResultado, FECHA.FORMATO_VISUAL).toISOString()
      : fechaResultado.toISOString();
  }

  obtener_fecha_diferencial(
    fechaReferencia: Date,
    ticksADescontar: number = 0
  ): string {
    const fechaResultado: Date = fechaReferencia;
    fechaResultado.setHours(fechaResultado.getHours() - ticksADescontar);

    return this.deviceService.isWeb || environment.APP.MOCK_MOBILE
      ? moment(fechaResultado, FECHA.FORMATO_VISUAL).toISOString()
      : fechaResultado.toISOString();
  }

  convertir_fecha_a_formato_yyyy_MM_dd(fecha: Date) {
    if (this.deviceService.isWeb || environment.APP.MOCK_MOBILE) {
      fecha = moment(fecha, "DD/MM/YYYY").toDate();
    }

    return (
      fecha.getFullYear() +
      "" +
      this.pad(fecha.getMonth() + 1, 2) +
      "" +
      this.pad(fecha.getDate(), 2)
    );
  }

  convertir_fecha_a_formato_dd_MM_yyyy(fecha: Date, separador: string = "") {
    if (this.deviceService.isWeb || environment.APP.MOCK_MOBILE) {
      fecha = moment(fecha, "DD/MM/YYYY").toDate();
    }

    return `${this.pad(fecha.getDate(), 2)}${separador}${this.pad(
      fecha.getMonth() + 1,
      2
    )}${separador}${fecha.getFullYear()}`;
  }

  convertir_date_a_ticks(fecha: Date | string): number {
    if (typeof fecha === "string") {
      fecha = new Date(fecha);
    }

    fecha.setHours(fecha.getHours() + fecha.getTimezoneOffset() / 60);

    // Se le suman 1970 años y se le resta el timezone
    return (
      fecha.getTime() * 10000 +
      621355968000000000 -
      fecha.getTimezoneOffset() * 600000000
    );
  }

  convertir_fecha_moment(fecha: any) {
    if (typeof fecha === "string") {
      return moment(fecha);
    }

    return moment(new Date());
  }

  capitalizar(valor: string) {
    let resultado = "";
    let valores = valor.split(" ");

    for (let val of valores) {
      resultado += val.charAt(0).toUpperCase() + val.substr(1).toLowerCase();
      resultado += " ";
    }

    return resultado;
  }

  isNumber(value: string | number): boolean {
    return !isNaN(Number(value.toString()));
  }

  resolver_url_imagen(urlImage: string) {
    return urlImage.replace(REPLACE_IMG.INICIAL, REPLACE_IMG.FINAL);
  }

  refresh_imagen(urlImage: string) {
    const urlNueva = urlImage.split("random=")[0];
    return `${urlNueva}random=${Math.random()}`;
  }

  equals(valor1: any, valor2: any): boolean {
    if (typeof valor1 === "string" && typeof valor2 === "string") {
      return valor1 === valor2;
    }

    return false;
  }

  private pad(n, width) {
    let z = "0";
    n = n + "";
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
  }

  private mapear_propiedad(
    origen: any,
    destino: any,
    incluirPropiedadesDeOrigen: boolean = true
  ) {
    for (const i in origen) {
      if (incluirPropiedadesDeOrigen) {
        if (origen.hasOwnProperty(i)) {
          destino[i] = origen[i];
        }
      } else {
        if (destino.hasOwnProperty(i)) {
          destino[i] = origen[i];
        }
      }
    }

    return destino;
  }

  openNewTab(url: string) {
    if (this.deviceService.isWeb || this.deviceService.isAndroid) {
      window.open(url, "_blank");
    } else {
      this.iab.create(url, "_blank");
    }
  }

  //este metodo le agrega un parametro de version para que no quede en cache la imagen
  //ya que la la modifican desde el backend pero la url es la misma.
  versionarImagen(pathUrl: string, fechaModificacion: Date | number = null) {
    try {
      if (!pathUrl || !fechaModificacion) {
        return pathUrl;
      }

      const hasParams = pathUrl.indexOf("?") >= 0;
      let separador = "&";
      if (!hasParams) {
        //la mayoria tiene parametros
        separador = "?";
      }

      return pathUrl + separador + "ver=" + fechaModificacion;
    } catch (error) {
      console.log(error);
      return pathUrl;
    }
  }
}
