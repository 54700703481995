import { Directive, Input, OnInit, ElementRef, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appScrollHide]",
  host: {
    "(ionScroll)": "onScroll($event)",
    "(ionScrollStart)": "onScrollStart($event)",
    "(ionScrollEnd)": "onScrollEnd($event)",
  },
})
export class ScrollDirective implements OnInit {
  @Input("appScrollHide") ocultar = false;

  constructor(private element: ElementRef, private renderer: Renderer2) {
    console.log(element);
  }

  ngOnInit() {
    console.log(this.ocultar);

    let content = document.querySelector("ion-content");
    content.scrollEvents = true;
  }

  onScroll($event) {
    console.log($event);
  }

  onScrollStart($event) {
    console.log($event);
  }

  onScrollEnd($event) {
    console.log($event);
  }
}
