import { Injectable } from "@angular/core";

// Clases
import { ResponseApp } from "../../models/entities/common/response.entity";

// Servicios
import { RouteService } from "./route.service";

// Constantes
import { MESSAGE } from "src/app/constants/constants.index";

@Injectable({
  providedIn: "root",
})
export class ExceptionService {
  constructor(private routeService: RouteService) {}

  obtener_mensaje(resp: ResponseApp | any): string {
    if (resp === null) {
      return null;
    }

    if (resp.StatusDescription) {
      return resp.StatusDescription;
    }

    return resp;
  }

  obtener_status(resp: ResponseApp | any): number {
    if (resp.Status) {
      return Number(resp.Status);
    }

    return Number(resp);
  }

  /**
   * Resuelve el comportamiento de la app en caso de haber error
   * @param resp Objeto respuesta
   */
  resolver_error(resp: ResponseApp) {
    if (resp.Status === "ERROR") {
      if (resp.StatusDescription.toUpperCase().indexOf("TOKEN") >= 0) {
        this.routeService.resolver_redireccion_con_token_expirado();
        throw MESSAGE.ALERT.MENSAJE_LOGIN;
      }
      throw resp.StatusDescription;
    }
  }

  /**
   * Resuelve el mensaje de la excepción
   * @param error Error atrapado por la excepción
   */
  resolver_mensaje_excepcion(error: any): ResponseApp | string {
    if (typeof error === "string") {
      return error;
    }

    if (error.message) {
      return error.message;
    }

    const resp = new ResponseApp();
    resp.Status = error.status;
    resp.StatusDescription = error.message;

    return resp;
  }
}
