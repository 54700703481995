import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
  Validators,
  FormBuilder,
  FormControl,
} from "@angular/forms";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { UtilService } from "src/app/services/common/util.service";
import { NotificacionService } from "src/app/services/business/notificacion.service";

// Clases
import { UserModel } from "src/app/models/entities/common/form.entity";
import {
  Lote,
  ResponseApp,
  NotificacionConfig,
  Perfil,
  Persona,
  FileItem,
} from "src/app/models/entities/entity.index";
import {
  ALERT,
  MESSAGE,
  TIEMPO_MENSAJE,
} from "src/app/constants/constants.index";
import { PersonaService } from "../../../services/business/persona.service";

import { Camera, CameraOptions, Direction } from "@ionic-native/camera/ngx";
import { FileService } from "src/app/services/common/file.service";

@Component({
  selector: "app-perfil",
  templateUrl: "./perfil.page.html",
  styleUrls: ["./perfil.page.scss"],
})
export class PerfilPage implements OnInit {
  userModel: UserModel;
  formGroup: FormGroup;
  lotes: Lote[];
  persona: Persona;
  validUrlBase64: string = null;
  esGuardia = false;
  esAdministrativo = false;

  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private userService: UserService,
    private utilService: UtilService,
    private notificacionService: NotificacionService,
    private personaService: PersonaService,
    private camera: Camera,
    private fileService: FileService,
    private util: UtilService
  ) {
    this.esGuardia = this.userService.user.Profiles.includes("Guardia");
    this.esAdministrativo = this.userService.user.Profiles.includes("Administrativo");
  }

  ngOnInit() {
    console.log("this.userService.user 66", this.userService.user);
    this.cargarPersonaInfo();
    console.log("this.userService.user 68", this.userService.user);
    this.userModel = this.utilService.mapear_propiedades(
      this.userService.user,
      new UserModel()
    );
    this.userModel.ImageToUpdate = null;
    this.userService.user.ImageToUpdate = null;
    console.log("userModel en ngOnInit", this.userModel);
    console.log("this.userService.user. en ngOnInit", this.userService.user);

    this.userModel.Vencimiento = this.utilService.convert_ticks_to_date(
      this.userModel.Vencimiento
    );
    this.lotes = this.userModel.Lotes;

    this.configurar_formulario();
    this.obtener_configuraciones();
  }

  cerrar_modal() {
    this.controlService.cerrar_modal();
  }

  obtener_configuraciones() {
    const loteSeleccionado = this.obtener_lote_seleccionado();
    if (!loteSeleccionado) return;
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.notificacionService
        .obtener_configuracion_de_notificaciones(loteSeleccionado)
        .then((config: NotificacionConfig) => {
          this.sincronizar_notificaciones_con_modelo(config);
        })
        .catch((error) => {
          this.controlService.mostrar_toast_con_error(error);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    });
  }

  comprobarDatosCompletos() {
    let codAreaTelefono = (
      this.formGroup.controls["codAreaTelefono"] as FormControl
    ).value;

    if (codAreaTelefono) {
      this.guardar();
    } else {
      this.controlService.mostrar_alert({
        header: MESSAGE.ALERT.PERFIL.DATOS_INCOMPLETOS.TITULO,
        message: MESSAGE.ALERT.PERFIL.DATOS_INCOMPLETOS.MENSAJE,
        backdropDismiss: false,
        buttons: [
          {
            text: MESSAGE.ALERT.PERFIL.OK,
            handler: () => { },
          },
        ],
      });
    }
  }

  guardar() {
    console.log("this.userModel previo", this.userModel);
    this.obtener_modelo_actualizado();
    console.log("this.userModel actualizado", this.userModel);
    const perfil: Perfil = this.utilService.mapear_propiedades(
      this.userModel,
      new Perfil(),
      true
    );
    console.log("perfil a enviar", perfil);

    perfil.Vencimiento = this.utilService.convertir_date_a_ticks(
      this.userModel.Vencimiento
    );
    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.userService
        .guardar(perfil)
        .then((resp: ResponseApp) => {
          this.userModel.ImageToUpdate = null;
          this.userService.user.ImageToUpdate = null;
          this.controlService.ocultar_loading();
          this.controlService.mostrar_toast(
            MESSAGE.INFO.MENSAJE_CAMBIOS_GUARDADOS,
            TIEMPO_MENSAJE
          );
          if (this.userService.user.Profiles.includes("Habitante")) {
            this.guardar_configuracion_notificaciones();
          }
        })
        .catch((error) => {
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
          this.controlService.ocultar_loading();
        });
    });
  }

  private guardar_configuracion_notificaciones() {
    const loteSeleccionado = this.obtener_lote_seleccionado();
    const config: NotificacionConfig = new NotificacionConfig();
    config.MobileNotificationAccess = this.userModel.Accesos;
    config.MobileAuthorizationOneClick = this.userModel.AutorizacionesOneClick;
    config.MobileNotificationNews = this.userModel.Novedades;
    config.AccessMailSend = this.userModel.NotificacionesEmail;

    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.notificacionService
        .guardar_configuracion(config, loteSeleccionado)
        .then((resp: ResponseApp) => {
          this.controlService.mostrar_toast(
            MESSAGE.INFO.MENSAJE_CAMBIOS_GUARDADOS,
            TIEMPO_MENSAJE
          );
        })
        .catch((error) => {
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    });
  }

  private configurar_formulario() {
    const codArea = this.userModel.TelefonoMovilSegmentado.Segmento1;
    const cod = this.userModel.TelefonoMovilSegmentado.Segmento2;
    const telefono = this.userModel.TelefonoMovilSegmentado.Segmento3;

    let loteHandler = { value: "", validator: [] };
    if (this.userModel.Lotes.length) {
      loteHandler.value = this.userModel.Lotes[0].Id;
      loteHandler.validator = [Validators.required];
    }

    this.formGroup = this.formBuilder.group({
      codAreaTelefono: [codArea, [Validators.required]],
      cod: [cod, [Validators.required]],
      telefono: [telefono],
      telefonoFijo: [this.userModel.TelefonoFijo],
      codAreaTelefonoFijo: ["", [Validators.required]],
      email: [this.userModel.Email, [Validators.email, Validators.required]],
      lote: [loteHandler.value, loteHandler.validator],
      acceso: [this.userModel.Accesos, [Validators.required]],
      autorizacionOneClick: [
        this.userModel.AutorizacionesOneClick,
        [Validators.required],
      ],
      novedades: [this.userModel.Novedades, [Validators.required]],
    });
  }

  private sincronizar_notificaciones_con_modelo(config: NotificacionConfig) {
    this.userModel.Accesos = config.MobileNotificationAccess;
    this.userModel.AutorizacionesOneClick = config.MobileAuthorizationOneClick;
    this.userModel.Novedades = config.MobileNotificationNews;
    this.userModel.NotificacionesEmail = config.AccessMailSend;

    (this.formGroup.controls["acceso"] as FormControl).setValue(
      this.userModel.Accesos
    );
    (this.formGroup.controls["autorizacionOneClick"] as FormControl).setValue(
      this.userModel.AutorizacionesOneClick
    );
    (this.formGroup.controls["novedades"] as FormControl).setValue(
      this.userModel.Novedades
    );
  }

  private obtener_lote_seleccionado(): Lote {
    return this.lotes.filter(
      (lote: Lote) =>
        (this.formGroup.controls["lote"] as FormControl).value === lote.Id
    )[0];
  }

  private obtener_modelo_actualizado(): UserModel {
    let codAreaTelefono = (
      this.formGroup.controls["codAreaTelefono"] as FormControl
    ).value;
    let cod = (this.formGroup.controls["cod"] as FormControl).value;
    let telefono = (this.formGroup.controls["telefono"] as FormControl).value;
    this.userModel.Telefono = `${codAreaTelefono}${cod}${telefono}`;

    let codAreaTelefonoFijo = (
      this.formGroup.controls["codAreaTelefonoFijo"] as FormControl
    ).value;
    let telefonoFijo = (this.formGroup.controls["telefonoFijo"] as FormControl)
      .value;
    telefonoFijo = Number(telefonoFijo);
    telefonoFijo = telefonoFijo.toString();
    this.userModel.TelefonoFijo = `${codAreaTelefonoFijo}${telefonoFijo}`;

    this.userModel.Email = (
      this.formGroup.controls["email"] as FormControl
    ).value;
    this.userModel.Accesos = (
      this.formGroup.controls["acceso"] as FormControl
    ).value;
    this.userModel.AutorizacionesOneClick = (
      this.formGroup.controls["autorizacionOneClick"] as FormControl
    ).value;
    this.userModel.Novedades = (
      this.formGroup.controls["novedades"] as FormControl
    ).value;

    this.userModel.TelefonoMovilSegmentado = {
      Segmento1: codAreaTelefono,
      Segmento2: cod,
      Segmento3: telefono
    }

    return this.userModel;
  }

  async cargarPersonaInfo() {
    if (this.userService.user.Documento) {
      let persona = await this.personaService.obtener_persona(
        this.userService.user.Documento
      );
      this.persona = persona;
      this.persona.UrlImagen =
        this.validUrlBase64 ||
        this.util.versionarImagen(
          this.persona.UrlImagen,
          this.persona.FechaHoraModificacion
        );
    }
  }

  async camara() {
    //en primer lugar validamos que esté habilitado a tomar la selfie;
    await this.controlService.mostrarLoading(
      MESSAGE.LOADING.SOLICITANDO_PERMISOS
    );
    try {
      console.log("Obteniendo datos de la persona");
      await this.cargarPersonaInfo();
    } catch (error) {
      this.controlService.ocultar_loading();
      this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
      return;
    } finally {
      this.controlService.ocultar_loading();
    }

    console.log(
      "this.persona.PuedeModificarImagen: " + this.persona.PuedeModificarImagen
    );
    if (!this.persona.PuedeModificarImagen) {
      this.controlService.mostrar_alert({
        header: MESSAGE.ALERT.PERFIL.FOTO_NO_HABILITADA.TITULO,
        message: MESSAGE.ALERT.PERFIL.FOTO_NO_HABILITADA.MENSAJE,
        backdropDismiss: false,
        buttons: [
          {
            text: MESSAGE.ALERT.PERFIL.OK,
            handler: () => { },
          },
        ],
      });
      return;
    }

    //configurando parametros de camara
    const options: CameraOptions = {
      quality: 50,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true,
      sourceType: this.camera.PictureSourceType.CAMERA,
      cameraDirection: Direction.BACK,
    };
    this.procesarImagen(options);
  }
  async procesarImagen(options: CameraOptions) {
    console.log("entró a procesar imagen");
    this.camera
      .getPicture(options)
      .then((imageData) => {
        const urlBase64 =
          this.fileService.obtener_urlBase64_con_prefijo(imageData);
        if (this.persona.ValidarImagen) {
          //validamos la imagen contra el backend
          this.controlService.mostrarLoading(
            MESSAGE.LOADING.VALIDANDO_IMAGE
          );
          this.userService
            .ImageIsBiometricValid(imageData)
            .then(() => {
              this.controlService.ocultar_loading();
              this.persona.UrlImagen = urlBase64;
              this.validUrlBase64 = urlBase64;
              console.log("línea 371");
              this.userModel.ImageToUpdate = imageData;
              this.controlService.mostrar_alert({
                header: MESSAGE.ALERT.PERFIL.MENSAJE_PERFIL_UFACE_OK.TITULO,
                message:
                  MESSAGE.ALERT.PERFIL.MENSAJE_PERFIL_UFACE_OK.MENSAJE,
                backdropDismiss: false,
                buttons: [
                  { text: MESSAGE.ALERT.PERFIL.OK, handler: () => { } },
                ],
              });
            })
            .catch((error) => {
              this.controlService.ocultar_loading();
              this.controlService.mostrar_alert({
                header:
                  MESSAGE.ALERT.PERFIL.MENSAJE_PERFIL_UFACE_ERROR.TITULO,
                message: error,
                backdropDismiss: false,
                buttons: [
                  { text: MESSAGE.ALERT.PERFIL.OK, handler: () => { } },
                ],
              });
            });
        } else {
          this.persona.UrlImagen = urlBase64;
          this.userModel.ImageToUpdate = imageData;
          this.validUrlBase64 = urlBase64;
          this.controlService.mostrar_alert({
            header: MESSAGE.ALERT.PERFIL.MENSAJE_PERFIL_SIN_UFACE.TITULO,
            message: MESSAGE.ALERT.PERFIL.MENSAJE_PERFIL_SIN_UFACE.MENSAJE,
            backdropDismiss: false,
            buttons: [{ text: MESSAGE.ALERT.PERFIL.OK, handler: () => { } }],
          });
        }
        /*this.fileService
          .create_object_file_of_url(
            urlBase64,
            "selfie_" + this.userModel.Documento
          )
          .then(async (archivo: any) => {
            const archivoNuevo = new FileItem(archivo);
            const imgBase64 = await this.fileService.convert_to_base64(
              archivoNuevo,
              true
            );

            if (this.persona.ValidarImagen) {
              //validamos la imagen contra el backend
              this.controlService.mostrarLoading(
                MESSAGE.LOADING.VALIDANDO_IMAGE
              );
              this.userService
                .ImageIsBiometricValid(imgBase64)
                .then(() => {
                  this.controlService.ocultar_loading();
                  this.persona.UrlImagen = urlBase64;
                  this.validUrlBase64 = urlBase64;
                  console.log("línea 371");
                  this.userModel.ImageToUpdate = imgBase64;
                  this.controlService.mostrar_alert({
                    header: MESSAGE.ALERT.PERFIL.MENSAJE_PERFIL_UFACE_OK.TITULO,
                    message:
                      MESSAGE.ALERT.PERFIL.MENSAJE_PERFIL_UFACE_OK.MENSAJE,
                    backdropDismiss: false,
                    buttons: [
                      { text: MESSAGE.ALERT.PERFIL.OK, handler: () => { } },
                    ],
                  });
                })
                .catch((error) => {
                  this.controlService.ocultar_loading();
                  this.controlService.mostrar_alert({
                    header:
                      MESSAGE.ALERT.PERFIL.MENSAJE_PERFIL_UFACE_ERROR.TITULO,
                    message: error,
                    backdropDismiss: false,
                    buttons: [
                      { text: MESSAGE.ALERT.PERFIL.OK, handler: () => { } },
                    ],
                  });
                });
            } else {
              this.persona.UrlImagen = urlBase64;
              console.log("línea 397");
              this.userModel.ImageToUpdate = imgBase64;
              this.validUrlBase64 = urlBase64;
              this.controlService.mostrar_alert({
                header: MESSAGE.ALERT.PERFIL.MENSAJE_PERFIL_SIN_UFACE.TITULO,
                message: MESSAGE.ALERT.PERFIL.MENSAJE_PERFIL_SIN_UFACE.MENSAJE,
                backdropDismiss: false,
                buttons: [{ text: MESSAGE.ALERT.PERFIL.OK, handler: () => { } }],
              });
            }
          });*/
      })
      .catch((error) => {
        if (error === "No Image Selected") {
          return;
        }
        this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
        console.log("ERROR EN CAMARA", JSON.stringify(error));
      });
  }

  validEmail(): boolean {
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailRegex.test(this.formGroup.value.email);
  }
}
