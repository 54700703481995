import { Pipe, PipeTransform } from "@angular/core";
import {
  IMG_EXTENSION_SOPORTADA,
  REPLACE_IMG,
} from "src/app/constants/config.constant";

@Pipe({
  name: "noimage",
})
export class NoimagePipe implements PipeTransform {
  transform(images: any, tunearUrl?: boolean): any {
    if (!images || images.length === 0) {
      return "assets/img/general/noimage.png";
    }

    let extensionSoportada = false;
    IMG_EXTENSION_SOPORTADA.forEach((extension: string) => {
      if ((<string>images).indexOf(extension) > 0) {
        extensionSoportada = true;
        return false;
      }
    });

    if (!extensionSoportada) {
      return "assets/img/general/noimage.png";
    }

    if (tunearUrl) {
      images = images.replace(REPLACE_IMG.INICIAL, REPLACE_IMG.FINAL);
      return images.replace("\u0026", "&");
    } else {
      return images;
    }
  }
}
