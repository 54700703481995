import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

// Plugins
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";

// Constantes
import {
  TIEMPO_MENSAJE,
  PATH_URL,
  MESSAGE,
  ALERT,
  APP,
  environment,
  STORAGE,
} from "src/app/constants/constants.index";

// Clases
import { ResponseApp, Usuario } from "src/app/models/entities/entity.index";

// Interfaces
import { IModal } from "src/app/models/interfaces/interfaces.index";
import { IIdentity } from "src/app/models/interfaces/common/identity.interface";

// Páginas
import { SplashPage } from "src/app/pages/mobile/page.mobile.index";

// Enums
import { EnumTipoMenu } from "src/app/models/enums/tipo.enum";

// Servicios
import { SecurityService } from '../security/security.service';
import { CatalogoService } from '../business/catalogo.service';
import { SbcService } from './sbc.service';
import { ValidationService } from './validation.service';
import { ExceptionService } from './exception.service';
import { ControlService } from './control.service';
import { AuthService } from '../security/auth.service';
import { UserService } from '../security/user.service';
import { DeviceService } from './device.service';
import { PushNotificationService } from './push-notification.service';
import { MenuService } from './menu.service';
import { DispositivoService } from '../business/dispositivo.service';
import { SegmentService } from './segment.service';
import { StorageService } from './storage.service';
import { BehaviorSubject } from 'rxjs';
import { UtilService } from "./util.service";


@Injectable({
  providedIn: "root",
})
export class MobileService {

  firstTotalLoad = false;

  constructor(
    private splashScreen: SplashScreen,
    private router: Router,
    private securityService: SecurityService,
    private catalogoService: CatalogoService,
    private sbcService: SbcService,
    private validationService: ValidationService,
    private exceptionService: ExceptionService,
    private controlService: ControlService,
    private authService: AuthService,
    private userService: UserService,
    private deviceService: DeviceService,
    private dispositivoService: DispositivoService,
    private menuService: MenuService,
    private pushNotificactionService: PushNotificationService,
    private androidPermissions: AndroidPermissions,
    private iab: InAppBrowser,
    private segmentService: SegmentService,
    private storageService: StorageService,
    private utilService: UtilService,
  ) { }

  async ready() {
    console.log('APP.APP_ID.IOS', APP.APP_ID.IOS);
    console.log('APP.APP_ID.ANDROID', APP.APP_ID.ANDROID);
    this.iniciar_app();
    this.cargar_datos_mobile();
    this.validar_permisos_dispositivo().then((result: boolean) => {
      if (result) {
        this.deviceService.cargar_id_dispositivo();
      }
    });

  }

  async checkVersionAppWithStorage() {
    console.log("entró a checkVersionAppWithStorage");
    const deleteCache = await this.storageService.cargar(STORAGE.KEY.DELETE_CACHE);
    if (deleteCache && typeof (deleteCache) === "string") {
      console.log("entró al if 94");
      const storageVersion = JSON.parse(deleteCache);
      const appVersion = await this.validationService.getAppVersion();

      console.log("storageVersion", storageVersion);
      console.log("appVersion", appVersion);

      console.log(`storageVersion(${parseInt(storageVersion)}) < appVersion(${parseInt(appVersion)})`);

      if (parseInt(storageVersion) < parseInt(appVersion)) {
        console.log("entró al if 104");
        await this.deleteAllCache();
        try {
          this.updateStorageUserData();
        } catch (error) {
          console.log("error en updateStorageUserData", error);
        }
      }

    } else {
      console.log("entró al else 109");

      await this.deleteAllCache();
      try {
        this.updateStorageUserData();
      } catch (error) {
        console.log("error en updateStorageUserData", error);
      }
      const appVersion = await this.validationService.getAppVersion();

      if (appVersion) {
        console.log("entró al if 115");
        this.storageService.guardar({ key: STORAGE.KEY.DELETE_CACHE, value: JSON.stringify(appVersion) });
      }
    }
  }

  private async updateStorageUserData(): Promise<void> {
    let storageToken = await this.storageService.cargar(STORAGE.KEY.TOKEN);
    let catalogo = await this.storageService.cargar(STORAGE.KEY.CATALOGO);

    if (storageToken && typeof (storageToken) === "string" &&
      catalogo && typeof (catalogo) === "string") {

      await this.authService.getUserData(catalogo, storageToken);
    }
  }

  private async deleteAllCache() {
    const keys: string[] = [];
    keys.push(STORAGE.KEY.PRIMERA_INSTALACION);
    keys.push(STORAGE.KEY.DEVICE_ID);
    keys.push(STORAGE.KEY.ONESIGNAL_ID);
    keys.push(STORAGE.KEY.DATA.ESTADOS);
    keys.push(STORAGE.KEY.DATA.TIPO_RELACION);
    keys.push(STORAGE.KEY.DATA.TIPO_AUTORIZADO);
    keys.push(STORAGE.KEY.NEIGHBORHOOD_ICON);

    await this.storageService.borrar(keys);
  }

  reanudar_app() {
    console.log("entró a reanudar app");
    if (this.securityService.contiene_codigo_barrio()) {
      this.cargar_datos_app();
    } else {
      console.log("entró a redireccionar_pagina_inicial");
      this.redireccionar_pagina_inicial();
    }
  }

  private async iniciar_app() {
    //await this.resolver_splash();

    this.checkVersionAppWithStorage();
    this.cargar_datos_barrio().then((resp: ResponseApp) => {
      if (resp.Status) {
        /*this.dispositivoService.desvincular_dispositivo( this.deviceService.uuid ).then(( resp: ResponseApp ) => {
          console.log("resp del desvincular: ", resp); 
          console.log("desvincular dispositivo linea 133");
          //this.cargar_datos_app();
           // return resp;
        })
        .catch(( error ) => {

          this.controlService.mostrar_toast_con_error( error, false );
        });*/
        /*this.desvincular_dispositivo().then((resp: ResponseApp) => {
          console.log("desvincular dispositivo linea 73");

          if (resp.Status) {

            this.validar_condiciones_app()
              .then((result: boolean | void) => {

                if (result && typeof (result) === 'boolean') {

                  this.cargar_datos_app();
                }
              });
          }
        });*/
        console.log("iniciar_app 123");
        this.cargar_datos_app();
        //this.desvincular_dispositivo();
      } else {
        // Redirect a barrio
        console.log("redireccionar_pagina_inicial 128");
        this.redireccionar_pagina_inicial();
      }
    });
  }

  private async validar_condiciones_app(): Promise<boolean | void> {
    return new Promise<boolean | void>((resolve, reject) => {
      this.sbcService
        .cargar_datos_preliminares_aplicacion()
        .then(() => {
          this.validationService
            .validar_condiciones_app()
            .then((resp: ResponseApp) => {
              // Si no tengo redirección
              if (resp.Data == null) {
                resolve(true);
              } else {
                this.router.navigateByUrl(resp.Data);
                resolve(false);
              }
            });
        })
        .catch((error) => {
          this.controlService.mostrar_toast_con_error(error, false);
        });
    });
  }

  private desvincular_dispositivo(): Promise<ResponseApp | void> {
    return this.dispositivoService
      .desvincular_dispositivo(this.deviceService.uuid)
      .then((resp: ResponseApp) => {
        return resp;
      })
      .catch((error) => {
        this.controlService.mostrar_toast_con_error(error, false);
      });
  }

  private resolver_splash() {
    return new Promise((resolve) => {
      this.catalogoService
        .cargar_catalogo_barrio()
        .then((resp: ResponseApp) => {
          // Si ya está cargado el código de barrio
          if (resp.Status) {
            const modal: IModal = { component: SplashPage };
            this.controlService.mostrar_modal(modal).then(() => {
              this.splashScreen.hide();
              resolve(true);
            });
          } else {
            this.splashScreen.hide();
            resolve(true);
          }
        })
        .catch((error) => {
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
        });
    });
  }

  private async cargar_datos_barrio() {
    return new Promise<ResponseApp>((resolve, reject) => {
      this.catalogoService
        .cargar_catalogo_barrio(this.securityService.codigoBarrio)
        .then((resp: ResponseApp) => {
          resolve(resp);
        })
        .catch((error) => {
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
        });
    });
  }

  private cargar_datos_app() {
    console.log("entró a cargar_datos_app");
    this.sbcService
      .cargar_datos_preliminares_aplicacion()
      .then(() => {
        this.validationService
          .validar_condiciones_app()
          .then((resp: ResponseApp) => {
            // Si no tengo redirección
            if (resp.Data == null) {
              console.log("this.inicializar_info()");
              this.inicializar_info();
            } else {
              console.log("resp.Data", resp.Data);
              this.router.navigateByUrl(resp.Data);
            }
          });
      })
      .catch((error: ResponseApp | string) => {
        const mensaje = this.exceptionService.obtener_mensaje(error);
        this.controlService.mostrar_toast(mensaje, TIEMPO_MENSAJE);
      });
  }

  private inicializar_info() {
    this.authService.estaAutenticado().then((autenticado: boolean) => {
      if (autenticado) {
        this.controlService
          .mostrarLoading(MESSAGE.LOADING.DEFAULT)
          .then(async () => {
            await this.userService.cargar_datos_perfil();
            this.sbcService.cargar_datos_aplicacion();
            this.segmentService.actualizar_configuración_segmentos();
            console.log("entró a redireccionar_app 235");
            this.redireccionar_app();
          })
          .finally(() => {
            this.controlService.ocultar_loading();
          });
      } else {
        console.log("entró a redireccionar_pagina_inicial 242");
        this.redireccionar_pagina_inicial();
      }
    });
  }

  private async redireccionar_pagina_inicial() {
    console.log("entró a redireccionar_pagina_inicial 249");

    if (this.securityService.contiene_codigo_barrio()) {
      this.router.navigate([PATH_URL.MOBILE.LOGIN.INDEX]);
    } else {
      this.router.navigate([PATH_URL.MOBILE.LOGIN.DEFAULT]);
    }
  }

  private redireccionar_app() {
    console.log("entró a redireccionar_app 259");

    this.sbcService
      .resolver_menu(EnumTipoMenu.Home)
      .subscribe((menuConfig: IIdentity[]) => {
        const primerMenuAccesible =
          this.menuService.obtener_primer_menu_disponible(menuConfig);
        const route = primerMenuAccesible
          ? primerMenuAccesible.path
          : PATH_URL.MOBILE.LOGIN.INDEX;

        this.router.navigate([route]);
      });
  }

  private async cargar_datos_mobile() {
    this.pushNotificactionService.inicializar_oneSignal();
    this.pushNotificactionService.cargar_id_onesignal();
  }

  private validar_permisos_dispositivo() {
    return new Promise<boolean>((resolve, reject) => {
      if (environment.APP.MOCK_MOBILE) {
        resolve(true);
      } else if (this.deviceService.isAndroid) {
        this.androidPermissions
          .checkPermission(this.androidPermissions.PERMISSION.READ_PHONE_STATE)
          .then((resultado) => {
            if (resultado.hasPermission) {
              resolve(true);
            } else {
              this.controlService.mostrar_alert({
                header: MESSAGE.ALERT.PERMISOS.TITULO,
                message: MESSAGE.ALERT.PERMISOS.MENSAJE,
                backdropDismiss: false,
                buttons: [
                  {
                    text: ALERT.BUTTONS.ACEPTAR,
                    handler: () => {
                      this.androidPermissions
                        .requestPermission(
                          this.androidPermissions.PERMISSION.READ_PHONE_STATE
                        )
                        .then(() => {
                          this.validar_permisos_dispositivo().then(
                            (result: boolean) => resolve(result)
                          );
                        });

                      this.controlService.cerrar_modal();
                    },
                  },
                  {
                    text: ALERT.BUTTONS.POLITICAS_PRIVACIDAD,
                    handler: () => {
                      const browser = this.iab.create(APP.POLITICA_PRIVACIDAD);
                      browser.show();
                      resolve(false);
                      return false;
                    },
                  },
                ],
              });
            }
          });
      } else {
        resolve(true);
      }
    });
  }
}
