import { EnumTipoCanal } from "../../enums/tipo.enum";
import { Categoria } from "../entity.index";
import { Documento } from "./documento.entity";

export class DashboardItem {
  Descripcion: string;
  Icon: string;
  Path: string;
  Valor: 1;
}
