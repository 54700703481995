import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ScrollDirective } from "./directives.index";
import { DropFilesDirective } from "./directives.index";

@NgModule({
  declarations: [ScrollDirective, DropFilesDirective],
  exports: [ScrollDirective, DropFilesDirective],
  imports: [CommonModule],
})
export class DirectiveModule {}
