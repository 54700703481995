import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Interfaces
import {
  IPagination,
  IDictionary,
} from "src/app/models/interfaces/interfaces.index";

// Clases
import {
  Bandeja,
  Ticket,
  Lote,
  ResponseApp,
  Item,
} from "src/app/models/entities/entity.index";
import { ItemElement } from "src/app/models/clases/ItemElement";

// Servicios
import { CatalogoService } from "./catalogo.service";
import { RestService } from "../common/rest.service";
import { UtilService } from "../common/util.service";
import { SecurityService } from "../security/security.service";
import { ExceptionService } from "../common/exception.service";

@Injectable({
  providedIn: "root",
})
export class TicketService {
  private paginacion: IPagination = { pagina: 1, cantidad: 10 };
  private itemElement = new ItemElement<Ticket>();

  tickets: Ticket[] = [];
  paginacionActual: IPagination = { pagina: 1, cantidad: 10 };

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private securityService: SecurityService,
    private exceptionService: ExceptionService
  ) { }

  async marcarTodoLeido(){
    const body = {
      token: this.securityService.token,
      entidad: "Ticket",
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(`${this.catalogoService.catalogo}MarcarTodoLeidoParaEntidad`, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  // obtenerTickets
  async obtener_tickets(
    lote: any,
    estado: Item,
    paginacion: IPagination = this.paginacion,
    bandeja?: Bandeja
  ) {
    let bandejaNombre = null;
    if (bandeja) {
      if (bandeja.Nombre != "Todos") {
        bandejaNombre = bandeja.Nombre;
      }
    }
    let loteId;
    if (lote) {
      console.log(lote);
      console.log(typeof lote);
      if (typeof lote === "string") {
        loteId = lote;
      } else {
        loteId = lote.Id;
      }
    }

    const url = `${this.catalogoService.catalogo}ObtenerTickets_v1`;
    const body = {
      token: this.securityService.token,
      idBarrio: this.securityService.codigoBarrio,
      ticketMobileFilter: {
        IdLote: loteId,
        IdBandeja: null,
        LoteNumero: null,
        BandejaNombre: bandejaNombre,
        IdEstado: estado.Id,
        Titulo: "",
        FechaCreacionDesde: "",
        FechaCreacionHasta: "",
      },
      paginaActual: paginacion.pagina.toString(),
      cantxPagina: paginacion.cantidad.toString(),
    };

    console.log(body);
    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<any, Ticket[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;
            this.tickets = [];

            let tickets = this.utilService.mapear_propiedades(
              resp.Data,
              new Ticket()
            );
            tickets = this.itemElement.refrescar(tickets, this.tickets);

            tickets.forEach((ticket) => {
              ticket.Comentarios.forEach((comentario) => {
                comentario.UrlImagen = this.utilService.resolver_url_imagen(
                  comentario.UrlImagen
                );
              });
              if (this.tickets.length <= this.paginacionActual.cantidad) {
                this.tickets.push(ticket);
              }
            });

            return this.tickets;
          })
        )
        .subscribe(
          (data: Ticket[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async get_ticket(
    catalogo: string,
    token: string,
    idBarrio: string,
    idTicket: string
  ) {
    const url = `${catalogo}ObtenerTicket/${token}/${idBarrio}/`;
    const options: IDictionary[] = [{ key: "{idTicket}", value: idTicket }];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, false, false, options)
        .pipe(
          map<any, Ticket>((resp: any) => {
            this.exceptionService.resolver_error(resp);
            const ticket: Ticket = this.utilService.mapear_propiedades(
              resp.Data,
              new Ticket()
            );
            return ticket;
          })
        )
        .subscribe(
          (data: Ticket) => {
            resolve(data);
          },
          (error) => {
            console.log("error en get_ticket 123", error);
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log("error en get_ticket 128", error);
      throw error;
    });
  }

  // ticketMobileSetComentario
  async agregar_comentario(
    ticket: Ticket,
    comentario: string,
    comentarioPrivado: boolean = false,
    image: string = null
  ) {
    const url = `${this.catalogoService.catalogo}TicketMobileSetComentario_v1`;
    const body = {
      token: this.securityService.token,
      idTicket: ticket.Id,
      comentario,
      privado: comentarioPrivado ? 1 : 0,
      imagen: image,
      idBandeja: null,
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            console.log(resp);
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  //**agregarTicket */
  async guardar_ticket(ticket: Ticket, lotes, privado = false) {
    const url = `${this.catalogoService.catalogo}TicketMobileCreate_v1`;
    const body = {
      Id: ticket.Id ? ticket.Id : null,
      token: this.securityService.token,
      lotes: lotes,
      titulo: ticket.Titulo,
      comentario: ticket.Contenido,
      privado: privado ? 1 : 0,
      imagen: ticket.Imagen,
      latitud: ticket.latitud,
      longitud: ticket.longitud,
      idBandeja: ticket.IdBandeja ? ticket.IdBandeja : null,
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(url, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  async marcar_ticket_como_leido(ticketId: string) {
    const url = `${this.catalogoService.catalogo}MarcarTicketLeido/`;
    const options: IDictionary[] = [
      { key: "{token}", value: this.securityService.token },
      { key: "{idTicket}", value: ticketId },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, false, false, options)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  limpiar() {
    this.tickets = [];
  }
}
