import { IMG_APP } from "src/app/constants/config.constant";
import { Lote } from "./lote.entity";

export interface TelefonoMovilSegmentado {
  Segmento1: string,
  Segmento2: string,
  Segmento3: string,
}

export class Perfil {
  Id: string = null;
  Nombre: string = null;
  Apellido: string = null;
  Documento: string = null;
  Telefono: string = null;
  TelefonoFijo: string = null;
  TelefonoMovilSegmentado: TelefonoMovilSegmentado;
  Email: string = null;
  Vencimiento: Date | any = null;
  Profiles: string[] = [];
  ImageToUpdate: string = null;
}

export class Usuario extends Perfil {
  UserName: string = null;
  Password: string = null;
  Token: string = null;
  UrlImage: string = IMG_APP.PERFIL;
  EmergenciasHabilitadas: boolean = true;
  OneSignalId?: string = null;
  DeviceId?: string = null;
  PrimerIngreso?: boolean = null;
  Lotes: Lote[] = [];
  FechaHoraModificacion: Date | number = null;
}
