import { IMG_APP } from "src/app/constants/config.constant";

export class FileItem {
  private quitarBase: boolean;
  public archivo: File;
  public nombreArchivo: string;
  public urlBase64: string;
  public estaSubiendo: boolean;
  public progreso: number;

  constructor(archivo: File, quitarBase: boolean = false) {
    this.quitarBase = quitarBase;
    this.archivo = archivo;
    this.nombreArchivo = archivo.name;
    this.convert_to_base64(quitarBase).then((urlBase64: string) => {
      // Si se supera el límite de bytes en la imagen, hay que reducir la calidad
      if (
        IMG_APP.CALIDAD.RESIZE_QUALITY &&
        archivo.size >= IMG_APP.CALIDAD.MAXBYTES
      ) {
        this.resizeGetBase64Image(
          urlBase64,
          IMG_APP.CALIDAD.RESIZE.WIDTH,
          IMG_APP.CALIDAD.RESIZE.HEIGHT
        ).then((urlBaseResize: string) => {
          this.urlBase64 = urlBaseResize;
        }).catch((error) => {
          console.log("error resizeGetBase64Image 31: " + JSON.stringify(error));
        });
      } else {
        this.urlBase64 = urlBase64;
      }
    }).catch((error) => {
      console.log("error convert_to_base64: ", error);
    });

    this.estaSubiendo = false;
    this.progreso = 0;
  }

  /**
   * Codifica el archivo de la instancia actual en base64.
   * @param quitarBase Indicador para determinar si se debe agregar al string resultante la base como prefijo
   */
  async convert_to_base64(quitarBase: boolean = false) {
    const archivo = this.archivo;
    const reader = new FileReader();
    reader.readAsDataURL(archivo);

    const result = await this.readerFile(reader, quitarBase);
    return result;
  }

  readerFile(reader: FileReader, quitarBase: boolean): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      let url = "";

      reader.onload = () => {
        url = reader.result as string;
        url = quitarBase
          ? url.replace("data:image/*;charset=utf-8;base64,", "")
          : url;
        url = quitarBase ? url.replace("data:image/jpeg;base64,", "") : url;
        url = quitarBase
          ? url.replace(/^data:image\/(png|jpg|jpeg);base64,/, "")
          : url;

        resolve(url);
      };

      reader.onerror = (error) => {
        console.log("Error: ", error);
        reject(error);
      };
    });
  }

  /**
   * Devuelve una imagen en formato base64, reducida en calidad
   * @param base64 Url en formato base64 de la imagen que se desea ajustar el tamaño
   * @param width Ancho de la imagen resultante
   * @param height Alto de la imagen resultante
   */
  resizeGetBase64Image(base64: string, width: number, height: number) {
    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;

    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, width, height);

    const img = new Image();
    img.src = base64;
    img.width = width;
    img.height = height;

    return new Promise<string>((resolve, reject) => {
      img.onload = () => {
        ctx.drawImage(img, 0, 0, width, height);
        let dataURL = canvas.toDataURL("image/png");

        if (this.quitarBase) {
          dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
        }

        resolve(dataURL);
      };
    });
  }
}
