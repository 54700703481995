import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { MenuController } from "@ionic/angular";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Geolocation } from "@ionic-native/geolocation/ngx";

// Constantes
import { MESSAGE, TIEMPO_MENSAJE } from "src/app/constants/constants.index";
import { PATH_URL } from "src/app/constants/url.constant";
import { STORAGE } from "src/app/constants/storage.constant";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { CatalogoService } from "src/app/services/business/catalogo.service";
import { SecurityService } from "src/app/services/security/security.service";
import { DeviceService } from "src/app/services/common/device.service";
import { DispositivoService } from "src/app/services/business/dispositivo.service";
import { StorageService } from "src/app/services/common/storage.service";
import { ExceptionService } from "src/app/services/common/exception.service";
import { ValidationService } from "src/app/services/common/validation.service";
import { SbcService } from "src/app/services/common/sbc.service";

// Clases
import { ResponseApp } from "src/app/models/entities/common/response.entity";

@Component({
  selector: "app-barrio",
  templateUrl: "./barrio.page.html",
  styleUrls: ["./barrio.page.scss"],
})
export class BarrioPage {
  formGroup: FormGroup;
  neighborhoodCodeIsInvalid = false;

  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private securityService: SecurityService,
    private catalogoService: CatalogoService,
    private deviceService: DeviceService,
    private dispositivoService: DispositivoService,
    private storageService: StorageService,
    private validationService: ValidationService,
    private sbcService: SbcService,
    private router: Router,
    private menuController: MenuController,
    private geolocation: Geolocation
  ) {
    this.configurar_formulario();
  }

  ionViewWillEnter() {
    this.menuController.enable(false);
  }

  cargar_catalogo() {
    if (this.formGroup.valid) {
      this.neighborhoodCodeIsInvalid = false;
      this.controlService
        .mostrarLoading(MESSAGE.LOADING.DEFAULT)
        .then(() => {
          const codigoBarrio = this.formGroup.value.codigoBarrio.trim();
          this.securityService
            .cargar_codigo_barrio(codigoBarrio)
            .then((result: boolean) => {
              if (result) {
                this.cargar_catalogo_barrio(codigoBarrio).then(
                  (result2: boolean) => {
                    if (result2) {
                      this.validar_condiciones_app().then(
                        (result: boolean | void) => {
                          if (result && typeof result === "boolean") {
                            this.router.navigate([PATH_URL.MOBILE.LOGIN.INDEX]);
                          }
                        }
                      );
                    } else {
                      this.neighborhoodCodeIsInvalid = true;
                    }
                  }
                );
              }
            })
            .catch((error) => {
              this.neighborhoodCodeIsInvalid = true;
              this.controlService.mostrar_toast_con_error(error);
            });
        })
        .catch((error) => {
          this.neighborhoodCodeIsInvalid = true;
          this.controlService.mostrar_toast_con_error(error);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
        });
    }
  }

  private configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      codigoBarrio: ["", [Validators.required]],
    });

    this.geolocation
      .getCurrentPosition()
      .then((resp) => { })
      .catch((error) => {
        console.log("Error getting location", error);
      });
  }

  private cargar_catalogo_barrio(codigoBarrio: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.catalogoService
        .cargar_catalogo_barrio(codigoBarrio)
        .then((resp: ResponseApp) => {
          resolve(Boolean(resp.Status));
        })
        .catch((error: ResponseApp) => {
          this.storageService.borrar([STORAGE.KEY.CODIGO_BARRIO]);
          this.controlService.mostrar_toast(
            MESSAGE.ERROR.MENSAJE_ERROR_CATALOGO,
            TIEMPO_MENSAJE
          );
          resolve(false);
        });
    });
  }

  private desvincular_dispositivo(): Promise<ResponseApp | void> {
    return this.dispositivoService
      .desvincular_dispositivo(this.deviceService.uuid)
      .then((resp: ResponseApp) => {
        console.log("desvincular dispositivo linea 133");
        return resp;
      })
      .catch((error) => {
        this.controlService.mostrar_toast_con_error(error, false);
      });
  }

  private async validar_condiciones_app(): Promise<boolean | void> {
    return new Promise<boolean | void>((resolve, reject) => {
      this.sbcService
        .cargar_datos_preliminares_aplicacion()
        .then(() => {
          this.validationService
            .validar_condiciones_app()
            .then((resp: ResponseApp) => {
              // Si no tengo redirección
              if (resp.Data == null) {
                resolve(true);
              } else {
                this.router.navigateByUrl(resp.Data);
                resolve(false);
              }
            });
        })
        .catch((error) => {
          this.controlService.mostrar_toast_con_error(error, false);
        });
    });
  }
}
