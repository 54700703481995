import { Component, OnInit, Input } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
} from "@angular/forms";

// Clases
import {
  Noticia,
  ResponseApp,
  Lote,
} from "src/app/models/entities/entity.index";

// Constantes
import { FECHA, RANGO_FECHA } from "src/app/constants/config.constant";
import { MESSAGE, TIEMPO_MENSAJE } from "src/app/constants/constants.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { CamionetaService } from "src/app/services/business/camioneta.service";
import { Horario, Parada, Persona } from "src/app/models/entities/business/reserva.entity";
import { ModalController } from "@ionic/angular";
import { Ionic4DatepickerModalComponent } from '@logisticinfotech/ionic4-datepicker';
import * as moment from "moment";
import { IModal } from "src/app/models/interfaces/interfaces.index";
import { ConfirmarReservaModalPage } from "../confirmar-reserva-modal/confirmar-reserva-modal.page";
import { ExceptionService } from "src/app/services/common/exception.service";

interface IResetFormDataProps {
  resetLote: boolean;
  resetPersons: boolean;
  resetDirection: boolean;
  resetBusStop: boolean;
  resetScheduleDate: boolean;
  resetSchedule: boolean;
}

@Component({
  selector: "app-agregar",
  templateUrl: "./agregar.page.html",
  styleUrls: ["./agregar.page.scss"],
})
export class AgregarReservaCamionetaPage implements OnInit {
  @Input() noticia: Noticia;
  @Input() titulo = "";
  lotes: Lote[] = [];
  personas: Persona[] = [];
  busStops: Parada[] = [];
  schedules: Horario[] = [];
  selectedLote = { id: null, description: null };
  selectedScheduleDate = { id: null, description: null };
  selectedBusStop = { id: null, description: null };
  selectedSchedule = { id: null, description: null };
  selectedDirection = { id: null, description: null };
  selectedPersons: string[] = [];

  formGroup: FormGroup;
  anioMin = new Date().toISOString();
  anioMax: number = RANGO_FECHA.ACCESO.NUEVO.ANIO_MAXIMO;
  formatoFechaControl: string = FECHA.FORMATO_VISUAL;

  selectedDate = null;

  datePickerObj: any = {
    inputDate: new Date(), // default new Date()
    fromDate: null, // default null
    toDate: null, // default null
    showTodayButton: true, // default true
    closeOnSelect: true, // default false
    disableWeekDays: [], // default []
    mondayFirst: true, // default false
    setLabel: 'Confirmar',  // default 'Set'
    todayLabel: 'Hoy', // default 'Today'
    closeLabel: 'Cerrar', // default 'Close'
    disabledDates: [], // default []
    titleLabel: '¿En qué fecha?', // default null
    monthsList: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    weeksList: ["D", "L", "M", "X", "J", "V", "S"],
    dateFormat: 'YYYY-MM-DD', // default DD MMM YYYY
    clearButton: false, // default true
    momentLocale: 'pt-US', // Default 'en-US'
    yearInAscending: true, // Default false
    btnCloseSetInReverse: true, // Default false
    btnProperties: {
      expand: 'block', // Default 'block'
      fill: '', // Default 'solid'
      size: '', // Default 'default'
      disabled: '', // Default false
      strong: '', // Default false
      color: '' // Default ''
    },
    arrowNextPrev: {
      nextArrowSrc: 'assets/images/arrow_right.svg',
      prevArrowSrc: 'assets/images/arrow_left.svg'
    }, // This object supports only SVG files.
    highlightedDates: [],// Default [],
    isSundayHighlighted: {} // Default {}
  };


  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private controlService: ControlService,
    private camionetaService: CamionetaService,
    private modalCtrl: ModalController,
    private exceptionService: ExceptionService
  ) {
    this.lotes = this.userService.user.Lotes;
  }
  cerrar_modal() {
    this.controlService.cerrar_modal();
  }

  ngOnInit() {
    this.configurar_formulario();
  }


  configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      loteId: [null, Validators.required],
      fechaReserva: [null, Validators.required],
      reserveFor: [[], Validators.required],
      busStop: ['', Validators.required],
      schedule: ['', Validators.required],
      direction: ['', Validators.required]
    });
  }

  async openDatePicker() {
    const datePickerModal = await this.modalCtrl.create({
      component: Ionic4DatepickerModalComponent,
      cssClass: 'li-ionic4-datePicker',
      componentProps: {
        'objConfig': this.datePickerObj,
        'selectedDate': this.selectedDate
      }
    });
    await datePickerModal.present();

    const { data } = await datePickerModal.onDidDismiss();
    if (!data || data.date === 'Invalid date' || data.date === 'Fecha inválida') {
      this.selectedDate = null;
      return;
    }
    this.selectedDate = data.date;
    moment.locale('es');
    let dateForDisplay = moment(this.selectedDate).format("dddd, DD [de] MMMM [de] YYYY");
    this.selectedScheduleDate = { id: 'date', description: dateForDisplay };

    const dateForService = moment(this.selectedDate).format('YYYYMMDD');
    this.formGroup.controls["fechaReserva"].setValue(dateForService);

    this.schedules = await this.camionetaService.obtener_horarios_disponibles(
      dateForService,
      this.selectedBusStop.id,
      this.selectedDirection.id,
      this.selectedPersons.length
    );
  }

  async setLote(loteId: string) {
    const lote = this.lotes.find(({ Id }) => Id === loteId);
    this.selectedLote = { id: lote.Id, description: lote.Numero };

    this.personas = await this.camionetaService.getPersonasByLote(lote.Id);
    this.personas.forEach((persona) => persona.Selected = false);
    this.formGroup.controls["loteId"].setValue(lote.Id);
  }

  selectPerson(personId) {
    let person = this.personas.find(({ Id }) => Id === personId);
    person.Selected = !person.Selected;
  }

  getPersonsLabel(): string | null {
    if (this.selectedPersons.length === 0) {
      return null;
    }

    return this.selectedPersons.join(", ");
  }

  async setBusStop(busStop: Parada) {
    const busStopItem = this.busStops.find(({ Id }) => Id === busStop.Id);
    this.selectedBusStop = { id: busStopItem.Id, description: busStopItem.Descripcion };
    this.formGroup.controls["busStop"].setValue(busStop.Id);

    this.openDatePicker();
  }

  setSchedule(scheduleId: string) {
    const schedule = this.schedules.find(({ IdRecorridoParada }) => IdRecorridoParada === scheduleId);
    if (!schedule.Disponible) return;
    this.selectedSchedule = { id: scheduleId, description: `${schedule.HoraDesde} a ${schedule.HoraHasta}` };
    this.formGroup.controls["schedule"].setValue(scheduleId);
    this.showConfirmReservationModal();
  }

  async setDirection(direction: number) {
    // 0 => Ida
    // 1 => Vuelta

    this.selectedDirection = { id: direction, description: direction === 0 ? 'Ida' : 'Vuelta' };
    this.busStops = await this.camionetaService.obtener_paradas_disponibles(direction);
    this.formGroup.controls["direction"].setValue(direction);
  }

  confirmPersons() {
    const selectedPersons = this.personas.filter(({ Selected }) => Selected === true);
    this.selectedPersons = selectedPersons.map((person) => person.Nombre);
  }

  showConfirmReservationModal() {
    const reservationObject = {
      lote: this.selectedLote.description,
      persons: this.getPersonsLabel(),
      sentido: this.selectedDirection.id === 0 ? 'Ida' : 'Vuelta',
      busStop: this.selectedBusStop.description,
      date: this.selectedScheduleDate.description,
      schedule: this.selectedSchedule.description
    };

    const modal: IModal = {
      component: ConfirmarReservaModalPage,
      componentProps: { reservationObject },
      returnData: true,
    };

    this.controlService.mostrar_modal(modal).then(({ data }) => {
      if (data.confirm) {
        this.confirmReservation();
      } else {
        this.cerrar_modal();
      }
    });
  }

  changeItem(item: string) {
    switch (item) {
      case 'lote':
        this.resetFormData({
          resetLote: true,
          resetPersons: true,
          resetDirection: true,
          resetBusStop: true,
          resetScheduleDate: true,
          resetSchedule: true
        });
        break;
      case 'persons':
        this.resetFormData({
          resetLote: false,
          resetPersons: true,
          resetDirection: true,
          resetBusStop: true,
          resetScheduleDate: true,
          resetSchedule: true
        });
        break;
      case 'direction':
        this.resetFormData({
          resetLote: false,
          resetPersons: false,
          resetDirection: true,
          resetBusStop: true,
          resetScheduleDate: true,
          resetSchedule: true
        });
        break;
      case 'busStop':
        this.resetFormData({
          resetLote: false,
          resetPersons: false,
          resetDirection: false,
          resetBusStop: true,
          resetScheduleDate: true,
          resetSchedule: true
        });
        break;
      case 'scheduleDate':
        this.resetFormData({
          resetLote: false,
          resetPersons: false,
          resetDirection: false,
          resetBusStop: false,
          resetScheduleDate: true,
          resetSchedule: true
        });
        this.openDatePicker();
        break;
      case 'schedule':
        this.resetFormData({
          resetLote: false,
          resetPersons: false,
          resetDirection: false,
          resetBusStop: false,
          resetScheduleDate: false,
          resetSchedule: true
        });
        break;
      default:
        break;
    }
  }

  resetFormData(resetProps: IResetFormDataProps) {
    if (resetProps.resetLote) this.selectedLote = { id: null, description: null };
    if (resetProps.resetPersons) {
      this.selectedPersons = [];
      this.personas.forEach((persona) => persona.Selected = false);
    }
    if (resetProps.resetDirection) this.selectedDirection = { id: null, description: null };
    if (resetProps.resetBusStop) this.selectedBusStop = { id: null, description: null };
    if (resetProps.resetScheduleDate) this.selectedScheduleDate = { id: null, description: null };
    if (resetProps.resetSchedule) this.selectedSchedule = { id: null, description: null };
  }

  confirmReservation() {
    const persons = this.personas.filter(({ Selected }) => Selected === true).map((person) => person.Id);
    const body = {
      idLote: this.selectedLote.id,
      fecha: this.formGroup.value.fechaReserva,
      idPersonas: persons,
      idRecorridoParada: this.selectedSchedule.id
    }

    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.camionetaService.crear_reserva(body)
        .then((resp: ResponseApp) => {
          this.controlService.mostrar_toast(
            MESSAGE.INFO.MENSAJE_CAMBIOS_GUARDADOS,
            TIEMPO_MENSAJE
          );
        })
        .catch((error) => {
          const msg = this.exceptionService.obtener_mensaje(error);
          this.controlService.mostrar_toast(msg, TIEMPO_MENSAJE);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
          this.cerrar_modal();
        });
    });
  }

}
