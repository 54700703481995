import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { HttpClientModule } from "@angular/common/http";

import { IonicModule } from "@ionic/angular";

import { BuscadorPageRoutingModule } from "./buscador-routing.module";

import { BuscadorPage } from "./buscador.page";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    BuscadorPageRoutingModule,
    HttpClientModule,
  ],
  declarations: [BuscadorPage],
})
export class BuscadorPageModule {}
