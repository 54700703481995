import { Component, ViewChild, isDevMode, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { IonContent, IonInfiniteScroll } from "@ionic/angular";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { EncuestaService } from "src/app/services/business/encuesta.service";

// Interfaces
import {
  IPagination,
  IModal,
} from "src/app/models/interfaces/interfaces.index";

// Clases
import {
  Lote,
  Encuesta,
  Item,
  Estado,
} from "src/app/models/entities/entity.index";

// Constantes
import { MESSAGE, TIEMPO_MENSAJE } from "src/app/constants/constants.index";

// Páginas
import { EncuestaAbiertaModalPage } from "src/app/pages/mobile/home/encuesta/encuesta-abierta-modal/encuesta-abierta-modal.page";
import { EncuestaCerradaModalPage } from "src/app/pages/mobile/home/encuesta/encuesta-cerrada-modal/encuesta-cerrada-modal.page";

// Constantes
import {
  SCROLL_FAB_HEIGHT,
  ENABLED_SCROLL_EVENT,
  MODULOS,
  PAGINATOR,
} from "src/app/constants/config.constant";

// Servicios
import { SbcService } from "src/app/services/common/sbc.service";
import { DeviceService } from "src/app/services/common/device.service";

@Component({
  selector: "app-encuesta",
  templateUrl: "./encuesta.page.html",
  styleUrls: ["./encuesta.page.scss"],
})
export class EncuestaPage implements OnInit {
  private paginacion: IPagination = {
    pagina: PAGINATOR.PAGE_INIT,
    cantidad: PAGINATOR.SIZE,
  };

  @ViewChild(IonContent) content: IonContent;
  @ViewChild(IonInfiniteScroll) infiniteScroll: IonInfiniteScroll;
  mostrarFabButton = false;
  mostrarFabButtonApartirDe = SCROLL_FAB_HEIGHT;
  habilitarEventosScroll = ENABLED_SCROLL_EVENT;
  mostrarInfiniteScrollEn = PAGINATOR.SIZE;

  mostrarFiltro = false;
  mostrarButtonLimpiar = false;
  formGroup: FormGroup;
  mostrarMensajeSinItem = false;
  encuestas: Encuesta[] = [];

  loteSeleccionado: Lote;
  lotes: Lote[];

  estadoSeleccionado: Item;
  estados: Item[];

  constructor(
    private formBuilder: FormBuilder,
    private controlService: ControlService,
    private userService: UserService,
    private encuestaService: EncuestaService,
    private sbcService: SbcService,
    private deviceService: DeviceService
  ) {}

  ngOnInit() {
    this.lotes = this.userService.user.Lotes;
    this.sbcService.estados.forEach((estado: Estado) => {
      if (estado.Modulo === MODULOS.ENCUESTA) {
        this.estados = estado.Estados;
        return false;
      }
    });

    this.configurar_formulario();
    this.filtrar();
  }

  filtrar() {
    this.limpiar_filtro();
    this.setear_lote_seleccionado();
    this.setear_estado_seleccionado();
    this.resolver_vista_encuesta();
    this.mostrarButtonLimpiar = true;
  }

  resolver_vista_encuesta(event?) {
    if (event) {
      this.paginacion.pagina += 1;
      this.cargar_encuestas(event);
    } else {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.cargar_encuestas(event);
      });
    }
  }

  limpiar_filtro() {
    this.encuestas = [];
    this.paginacion.pagina = PAGINATOR.PAGE_INIT;
    this.encuestaService.limpiar_encuestas();
    this.controlService.habilitar_infinite_scroll(this.infiniteScroll, true);
    this.mostrarButtonLimpiar = false;
    this.mostrarMensajeSinItem = false;
  }

  async mostrar_modal(encuesta: Encuesta) {
    const componente =
      encuesta.Resultado == null
        ? EncuestaAbiertaModalPage
        : EncuestaCerradaModalPage;
    const modal: IModal = {
      component: componente,
      componentProps: { lote: this.loteSeleccionado, encuesta: encuesta },
      returnData: true,
    };

    this.controlService.mostrar_modal(modal).then((info: any) => {
      this.filtrar();
    });
  }

  informar_scroll(event) {
    if (this.deviceService.isIos) {
      return;
    }

    this.content
      .getScrollElement()
      .then(
        (el) =>
          (this.mostrarFabButton =
            el.scrollTop > this.mostrarFabButtonApartirDe)
      );
  }

  volver_al_inicio() {
    this.content.scrollToTop();
  }

  doRefresh(event) {
    const paginaInicial = PAGINATOR.PAGE_INIT;
    const paginaActual = this.encuestaService.paginacionActual.pagina;
    for (let pagina = paginaInicial; pagina <= paginaActual; pagina++) {
      this.paginacion.pagina = pagina;
      this.encuestaService
        .obtener_encuestas(
          this.loteSeleccionado,
          this.estadoSeleccionado,
          this.paginacion
        )
        .then((resp: Encuesta[]) => {
          if (pagina === paginaActual) {
            this.encuestas = resp;
            event.target.complete();
          }
        })
        .catch((error) => {
          event.target.complete();
        });
    }
  }

  private cargar_encuestas(event?) {
    this.encuestaService
      .obtener_encuestas(
        this.loteSeleccionado,
        this.estadoSeleccionado,
        this.paginacion
      )
      .then((resp: Encuesta[]) => {
        if (
          resp == null ||
          this.encuestaService.paginacionActual.cantidad === 0
        ) {
          this.controlService.habilitar_infinite_scroll(event, false);
          this.controlService.mostrar_toast(
            MESSAGE.ALERT.MENSAJE_SIN_DATOS,
            TIEMPO_MENSAJE
          );
        } else {
          this.encuestas = resp;
          this.controlService.completar_infinite_scroll(event);
        }
      })
      .catch((error) => {
        this.controlService.habilitar_infinite_scroll(event, false);
        this.controlService.mostrar_toast_con_error(error, false);
      })
      .finally(() => {
        this.mostrarMensajeSinItem = this.encuestas.length === 0;
        if (event == null) {
          this.controlService.ocultar_loading();
        }
      });
  }

  private configurar_formulario() {
    this.formGroup = this.formBuilder.group({
      lotes: [this.lotes[0].Id, Validators.required],
      estados: [this.estados[0].Id, Validators.required],
    });
  }

  private setear_lote_seleccionado() {
    this.loteSeleccionado = this.lotes.filter(
      (lote: Lote) => lote.Id === this.formGroup.value.lotes
    )[0];
  }

  private setear_estado_seleccionado() {
    this.estadoSeleccionado = this.estados.filter(
      (estado: Item) => estado.Id === this.formGroup.value.estados
    )[0];
  }
}
