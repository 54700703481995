export class Estado {
  Modulo: string = null;
  Descripcion: string = null;
  Estados: Item[] = [];
}

export class Item {
  Id: number = 0;
  Nombre: string = null;
}
