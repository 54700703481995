import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from "@angular/forms";

// Servicios
import { RestService } from "../common/rest.service";
import { CatalogoService } from "./catalogo.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";
import { SecurityService } from "../security/security.service";

// Constantes
import { IPagination } from "../../models/interfaces/interfaces.index";
import { IDictionary } from "../../models/interfaces/interfaces.index";

// Clases
import {
  Observacion,
  Lote,
  ResponseApp,
  AutorizadoDelLote,
} from "../../models/entities/entity.index";
import { ItemElement } from "../../models/clases/ItemElement";

// Enums
import { EnumTipoCanal } from "../../models/enums/tipo.enum";

@Injectable({
  providedIn: "root",
})
export class ObservacionService {
  private paginacion: IPagination = { pagina: 1, cantidad: 10 };
  private itemElement = new ItemElement<Observacion>();

  observaciones: Observacion[] = [];
  paginacionActual: IPagination = { pagina: 1, cantidad: 10 };

  constructor(
    private formBuilder: FormBuilder,
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private exceptionService: ExceptionService,
    private securityService: SecurityService
  ) {}

  /**
   * Obtiene las noticias del barrio para la portada de la web / app estando autenticado.
   * No distingue entre tipo de aplicación
   * @param paginacion Datos de paginación
   *

  
  /** Obtiene las observaciones según el autorizado seleccionado */
  async obtener_observaciones(
    lote: Lote,
    autorizado: AutorizadoDelLote,
    paginacion: IPagination = this.paginacion
  ): Promise<Observacion[]> {
    const url = `${this.catalogoService.catalogo}ObtenerLotePersonaObservaciones/`;
    const options: IDictionary[] = [
      { key: "{idLote}", value: lote.Id },
      { key: "{idBarrio}", value: this.securityService.codigoBarrio },
      { key: "{idPersona}", value: autorizado.IdAutorizado },
      { key: "{vigenciaDesde}", value: "%20" },
      { key: "{vigenciaHasta}", value: "%20" },
      { key: "{observacion}", value: "%20" },
      { key: "{pagina}", value: paginacion.pagina.toString() },
      { key: "{cantidad}", value: paginacion.cantidad.toString() },
    ];

    return this.obtener_resultado_observaciones(url, true, false, options);
  }

  private async obtener_resultado_observaciones(
    url: string,
    token: boolean,
    idBarrio: boolean,
    options: IDictionary[]
  ) {
    return new Promise<Observacion[]>((resolve, reject) => {
      this.restService
        .get(url, token, idBarrio, options)
        .pipe(
          map<any, Observacion[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;

            const observaciones = this.utilService.mapear_propiedades(
              resp.Data,
              new Observacion()
            );
            //noticias.forEach( (observacion: Observacion) => {

            //  //noticia.UrlImagen = this.utilService.resolver_url_imagen( noticia.UrlImagen );
            //});

            this.observaciones = this.itemElement.refrescar(
              observaciones,
              this.observaciones
            );

            console.log(this.observaciones);
            return this.observaciones;
          })
        )
        .subscribe(
          (data: Observacion[]) => {
            resolve(data);
          },
          (error) => {
            this.limpiar();
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  //**agregarObservacion */
  async crear_observacion(observacion) {
    const url = observacion.Id
      ? `${this.catalogoService.catalogo}ActualizarLotePersonaObservacion`
      : `${this.catalogoService.catalogo}CrearLotePersonaObservacion`;
    const body = {
      token: this.securityService.token,
      lotePersonaObservacion: observacion,
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  //**borrarObservacion */
  async eliminar_observacion(observacion: Observacion) {
    const url = `${this.catalogoService.catalogo}EliminarLotePersonaObservacion`;
    const body = {
      token: this.securityService.token,
      idLotePersonaObservacion: observacion.Id,
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  async actualizar_observacion(observacion: Observacion) {
    // Eliminamos propiedades que no deben viajar en el request
    //autorizacion = this.eliminar_propiedades_para_actualizar( autorizacion );

    const url = `${this.catalogoService.catalogo}ActualizarLotePersonaObservacion`;
    const body = {
      token: this.securityService.token,
      lotePersonaObservacion: observacion,
    };

    try {
      return new Promise((resolve, reject) => {
        this.restService
          .post(url, body)
          .pipe(
            map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
              this.exceptionService.resolver_error(resp);
              return resp;
            })
          )
          .subscribe(
            (resp: ResponseApp) => {
              resolve(resp);
            },
            (error) => {
              reject(this.exceptionService.resolver_mensaje_excepcion(error));
              return;
            }
          );
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  limpiar() {
    this.observaciones = [];
  }
}
