export class Documento {
  Id: string = null;
  Numero: string = null;
  Descripcion: string = null;
  TextoAdicional: string = null;
  Url: string = null;
  Tipo: string = null;
}

export class DocumentoAF extends Documento {
  Tipo: string = null;
  Fecha: Date | number;
  Leido: boolean = false;
}
