import { Pipe, PipeTransform } from "@angular/core";
import * as moment from "moment";

@Pipe({
  name: "iso8601ToDate",
})
export class Iso8601ToDatePipe implements PipeTransform {
  transform(value: any): any {
    let fechaFormateada: Date;
    if (value) {
      const fecha = value;
      fechaFormateada = new Date(fecha.slice(0, 4), (fecha[4] + fecha[5]) - 1, fecha[6] + fecha[7]);
    }

    return fechaFormateada;
  }
}
