import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Clases
import { Estado } from "src/app/models/entities/entity.index";

// Servicios
import { CatalogoService } from "./catalogo.service";
import { RestService } from "../common/rest.service";
import { UtilService } from "../common/util.service";
import { StorageService } from "../common/storage.service";
import { ExceptionService } from "../common/exception.service";

// Constantes
import { STORAGE } from "../../constants/storage.constant";

@Injectable({
  providedIn: "root",
})
export class EstadoService {
  estados: Estado[] = [];

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private utilService: UtilService,
    private storageService: StorageService,
    private exceptionService: ExceptionService
  ) {}

  // obtenerEstados
  async obtener_estados(): Promise<Estado[]> {
    const url = `${this.catalogoService.catalogo}ObtenerEstados_v1`;
    return new Promise<Estado[]>((resolve, reject) => {
      this.restService
        .get(url)
        .pipe(
          map<any, Estado[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.estados = this.utilService.mapear_propiedades(
              resp.Data,
              new Estado()
            );
            this.storageService.guardar({
              key: STORAGE.KEY.DATA.ESTADOS,
              value: JSON.stringify(this.estados),
            });

            return this.estados;
          })
        )
        .subscribe(
          (data: Estado[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  limpiar() {
    this.estados = [];
  }
}
