import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";

// Plugins
import { UniqueDeviceID } from "@ionic-native/unique-device-id/ngx";
import { Device } from "@ionic-native/device/ngx";

// Constantes
import { STORAGE } from "src/app/constants/constants.index";
import { environment } from "src/app/constants/constants.index";

// Servicios
import { StorageService } from "./storage.service";

@Injectable({
  providedIn: "root",
})
export class DeviceService {
  isWeb: boolean = false;
  isAndroid: boolean = false;
  isIos: boolean = false;
  uuid: string = "";

  constructor(
    private platform: Platform,
    private uniqueDeviceID: UniqueDeviceID,
    private storageService: StorageService,
    private device: Device
  ) {
    if (environment.APP.MOCK_MOBILE) {
      this.isWeb = false;
    } else {
      this.isWeb = !this.platform.is("cordova");
    }

    this.isAndroid = this.platform.is("android");
    this.isIos = this.platform.is("ios");

    this.uuid = this.device.uuid;
    this.storageService.guardar({
      key: STORAGE.KEY.DEVICE_ID,
      value: this.uuid,
    });
  }

  async cargar_id_dispositivo() {
    return new Promise((resolve, reject) => {
      if (this.isWeb || environment.APP.MOCK_MOBILE) {
        resolve(true);
        return;
      }
      this.storageService
        .cargar(STORAGE.KEY.DEVICE_ID)
        .then((result: string) => {
          if (result) {
            this.uuid = this.storageService.currentValue;
            resolve(true);
          } else {
            this.resolver_device_id(resolve, reject);
          }
        })
        .catch((error) => {});
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  private resolver_device_id(resolve, reject) {
    this.uuid = this.getInfoDevice();
    this.storageService.guardar({
      key: STORAGE.KEY.DEVICE_ID,
      value: this.uuid,
    });
    resolve(true);
  }
  getInfoDevice() {
    return this.device.uuid;
  }
}
