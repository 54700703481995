import { Component, Input, OnInit } from "@angular/core";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";

// Clases
import { Autorizado } from "src/app/models/entities/business/acceso.entity";
import {
  Lote,
  FormModel,
  Item,
  Persona,
  LoteAutorizado,
  DiaAutorizado,
  ResponseApp,
  AutorizadoDelLote,
  Dia,
} from "src/app/models/entities/entity.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { SbcService } from "src/app/services/common/sbc.service";
import { PersonaService } from "src/app/services/business/persona.service";
import { UtilService } from "src/app/services/common/util.service";
import { ValidationService } from "src/app/services/common/validation.service";
import { AutorizadoService } from "src/app/services/business/autorizado.service";

// Constantes
import {
  RANGO_FECHA,
  FECHA,
  INTERVAL,
} from "src/app/constants/config.constant";
import { MESSAGE, TIEMPO_MENSAJE } from "src/app/constants/constants.index";

@Component({
  selector: "app-agregar-autorizado",
  templateUrl: "./agregar-autorizado.page.html",
  styleUrls: ["./agregar-autorizado.page.scss"],
})
export class AgregarAutorizadoPage implements OnInit {
  private intervalInputDni: any;

  @Input() lote: Lote;
  @Input() autorizado: Autorizado;
  anioMin: number = RANGO_FECHA.ACCESO.NUEVO.ANIO_MINIMO;
  anioMax: number = RANGO_FECHA.ACCESO.NUEVO.ANIO_MAXIMO;
  formatoFechaControl: string = FECHA.FORMATO_VISUAL;
  formGroup: FormGroup;
  modelo: FormModel;
  lotes: Lote[];
  relaciones: Item[];
  tiposAutorizados: Item[];
  persona: Persona;
  autorizacionDelLote: AutorizadoDelLote;
  edicion: boolean = true;
  vigente: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private autorizadoService: AutorizadoService,
    private controlService: ControlService,
    private userService: UserService,
    private sbcService: SbcService,
    private personaService: PersonaService,
    private utilService: UtilService,
    private validationService: ValidationService
  ) {}

  ngOnInit() {
    this.edicion = this.autorizado != null;
    this.lotes = this.userService.user.Lotes;
    this.relaciones = this.sbcService.tiposRelaciones;
    this.tiposAutorizados = this.sbcService.tiposAutorizaciones;
    this.configurar_formulario();
  }

  ionViewWillEnter() {
    // Si tengo el autorizado, se trata de una edición
    if (this.edicion) {
      this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
        this.cargar_persona();
      });
    } else {
      this.setear_lote_seleccionado();
    }
  }

  cerrar_modal() {
    this.controlService.cerrar_modal(this.autorizado);
  }

  limpiar_fecha_desde() {
    (this.formGroup.controls["desde"] as FormControl).setValue("");
  }

  limpiar_fecha_hasta() {
    (this.formGroup.controls["hasta"] as FormControl).setValue("");
  }

  agregar_autorizado() {
    this.cargar_valores_del_formulario_al_modelo();
    this.validar().then((result: boolean) => {
      if (result) {
        // Si no encontré una persona, la debo crear
        if (!(this.persona && this.persona.Id)) {
          this.controlService
            .mostrarLoading(MESSAGE.LOADING.DEFAULT)
            .then(() => {
              this.crear_persona()
                .then(() => {
                  this.crear_autorizacion();
                })
                .catch((error) => {
                  this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
                });
            });
        } else {
          if (this.autorizacionDelLote) {
            this.actualizar_autorizado();
          } else {
            this.crear_autorizacion();
          }
        }
      } else {
        this.controlService.mostrar_toast(
          MESSAGE.ALERT.MENSAJE_VALIDACIONES_INCORRECTAS,
          TIEMPO_MENSAJE
        );
      }
    });
  }

  resolver_persona(event, sinIntervalo: boolean = false) {
    if (this.intervalInputDni != null) {
      clearInterval(this.intervalInputDni);
    }
    if (event.target.value == null || event.target.value === "") {
      return;
    }

    this.intervalInputDni = setInterval(
      () => {
        this.autorizado = new Autorizado();
        this.autorizado.DocumentoNumero = event.target.value;
        clearInterval(this.intervalInputDni);

        this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
          this.persona = null;
          this.cargar_persona();
        });
      },
      sinIntervalo ? 0 : INTERVAL.AUTOCOMPLETE
    );
  }

  deshabilitarGrabar() {
    const result = this.formGroup.invalid;
    return result;
  }

  limpiar_datos_principales(evento) {
    this.modelo.Lote = this.setear_lote_seleccionado().Id;
    this.configurar_formulario(false);
  }

  setear_lote_seleccionado(): Lote {
    this.lote = this.lotes.filter(
      (lote: Lote) => lote.Id === this.formGroup.value.lote
    )[0];

    return this.lote;
  }

  private crear_autorizacion() {
    const autorizacion: LoteAutorizado = this.mapear_autorizacion();
    const lote = new Lote();
    lote.Id = this.modelo.Lote;

    this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT).then(() => {
      this.autorizadoService
        .crear_autorizacion(autorizacion, lote)
        .then((resp: ResponseApp) => {
          this.controlService.mostrar_toast(
            MESSAGE.INFO.MENSAJE_CAMBIOS_GUARDADOS,
            TIEMPO_MENSAJE
          );
        })
        .catch((error) => {
          this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
        })
        .finally(() => {
          this.controlService.ocultar_loading();
          this.cerrar_modal();
        });
    });
  }

  private mapear_autorizacion(): LoteAutorizado {
    const autorizacion = new LoteAutorizado();
    autorizacion.IdAutorizado = this.persona.Id;
    autorizacion.IdTipoAutorizado = this.modelo.TipoAutorizacion.Id;
    autorizacion.IdTipoRelacion = this.modelo.TipoRelacion.Id;
    autorizacion.FechaDesde = this.modelo.FechaDesde;
    autorizacion.FechaHasta = this.modelo.FechaHasta;
    autorizacion.Nombre = this.modelo.Nombre;
    autorizacion.Apellido = this.modelo.Apellido;

    for (let i = 0; i < 8; i++) {
      const diaAutorizado = new DiaAutorizado();
      diaAutorizado.Dia = i + 1;
      diaAutorizado.HoraDesde = "000000";
      diaAutorizado.HoraHasta = "235959";
      diaAutorizado.Permitido = true;
      autorizacion.Dias.push(diaAutorizado);
    }

    return autorizacion;
  }

  private mapear_autorizado(): AutorizadoDelLote {
    this.cargar_valores_del_formulario_al_modelo();
    if (this.autorizacionDelLote) {
      this.autorizacionDelLote.IdTipoRelacion = this.modelo.TipoRelacion.Id;
      this.autorizacionDelLote.IdTipoAutorizado =
        this.modelo.TipoAutorizacion.Id;
      this.autorizacionDelLote.FechaDesde = this.modelo.FechaDesde;
      this.autorizacionDelLote.FechaHasta = this.modelo.FechaHasta;
    }

    return this.autorizacionDelLote;
  }

  async actualizar_autorizado() {
    let actualizar = true;
    if (this.vigente) {
      //si existe autorización vigente debemos solicitar confirmación al usuario
      await this.controlService.mostrar_alert({
        returnData: true,
        header: MESSAGE.ALERT.MENSAJE_AUTORIZACION.CONFIRMA_MODIFICACION.TITULO,
        message:
          MESSAGE.ALERT.MENSAJE_AUTORIZACION.CONFIRMA_MODIFICACION.PREGUNTA,
        backdropDismiss: false,
        buttons: [
          {
            text: MESSAGE.ALERT.MENSAJE_AUTORIZACION.CONFIRMA_MODIFICACION
              .BUTTONS.CANCELAR,
            role: "cancel",
            handler: () => (actualizar = false),
          },
          {
            text: MESSAGE.ALERT.MENSAJE_AUTORIZACION.CONFIRMA_MODIFICACION
              .BUTTONS.ACEPTAR,
            handler: () => (actualizar = true),
          },
        ],
      });
    }
    if (!actualizar) return;

    await this.controlService.mostrarLoading(MESSAGE.LOADING.DEFAULT);
    const autorizado = this.mapear_autorizado();
    this.autorizadoService
      .actualizar_autorizacion(autorizado, this.lote)
      .then((resp: ResponseApp) => {
        this.controlService.mostrar_toast(
          MESSAGE.INFO.MENSAJE_CAMBIOS_GUARDADOS,
          TIEMPO_MENSAJE
        );
      })
      .catch((error) => {
        this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
      })
      .finally(() => {
        this.controlService.ocultar_loading();
        this.cerrar_modal();
      });
  }

  private configurar_formulario(resetValues = true) {
    if (resetValues) {
      this.inicializar_controles_formulario();
    }

    this.formGroup = this.formBuilder.group({
      lote: [this.modelo.Lote, Validators.required],
      tipoRelacion: [this.modelo.TipoRelacion.Id, Validators.required],
      tipoAutorizado: [this.modelo.TipoAutorizacion.Id, Validators.required],
      desde: [this.modelo.FechaDesde],
      hasta: [this.modelo.FechaHasta],
    });

    if (!this.edicion) {
      this.formGroup.addControl(
        "nombre",
        new FormControl(this.modelo.Nombre, [Validators.required])
      );
      this.formGroup.addControl(
        "apellido",
        new FormControl(this.modelo.Apellido, [Validators.required])
      );
      this.formGroup.addControl(
        "numeroDocumento",
        new FormControl(this.modelo.Dni, [Validators.required])
      );
    }

    this.formGroup.controls["desde"].setValidators([Validators.required]);
    this.formGroup.controls["hasta"].setValidators([
      Validators.required,
      this.validar_rango_fecha.bind(this.formGroup, this.validationService),
    ]);
  }

  private inicializar_controles_formulario() {
    const fechaDesde = this.utilService.obtener_fecha_diferencial(
      new Date(),
      RANGO_FECHA.ACCESO.NUEVO.DESCUENTO_HORAS_DESDE
    );
    const fechaHasta = this.utilService.obtener_fecha_diferencial(
      new Date(),
      RANGO_FECHA.ACCESO.NUEVO.DESCUENTO_HORAS_HASTA
    );

    this.modelo = new FormModel();
    this.modelo.Lote = this.lote.Id;
    this.modelo.TipoRelacion = this.relaciones[0];
    this.modelo.TipoAutorizacion = this.tiposAutorizados[1];
    this.modelo.FechaDesde = fechaDesde;
    this.modelo.FechaHasta = fechaHasta;
    this.modelo.Nombre = this.edicion ? this.autorizado.Nombre : "";
    this.modelo.Apellido = this.edicion ? this.autorizado.Apellido : "";
    this.modelo.Dni = this.edicion ? this.autorizado.DocumentoNumero : "";
  }

  private cargar_valores_del_formulario_al_modelo() {
    this.modelo.Lote = this.formGroup.value.lote;
    this.modelo.TipoRelacion = this.relaciones.filter(
      (relacion: Item) => relacion.Id === this.formGroup.value.tipoRelacion
    )[0];
    this.modelo.TipoAutorizacion = this.tiposAutorizados.filter(
      (tiposAutorizaciones: Item) =>
        tiposAutorizaciones.Id === this.formGroup.value.tipoAutorizado
    )[0];
    this.modelo.FechaDesde = this.utilService.convertir_date_a_ticks(
      this.formGroup.value.desde
    );
    this.modelo.FechaHasta = this.utilService.convertir_date_a_ticks(
      this.formGroup.value.hasta
    );
  }

  private crear_persona() {
    this.persona = new Persona();
    this.persona.Nombre = this.formGroup.value.nombre;
    this.persona.Apellido = this.formGroup.value.apellido;
    this.persona.Documento = this.formGroup.value.numeroDocumento;
    delete this.persona.Id;
    delete this.persona.UrlImagen;

    return this.personaService
      .crear_persona(this.persona, this.lote)
      .then((idAutorizado: string) => {
        this.persona.Id = idAutorizado;
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        this.controlService.ocultar_loading();
      });
  }

  private cargar_persona() {
    this.personaService
      .obtener_persona(this.autorizado.DocumentoNumero)
      .then((persona: Persona) => {
        this.persona = persona;
        if (!this.edicion) {
          this.formGroup.controls["nombre"].setValue(this.persona.Nombre, [
            Validators.required,
          ]);
          this.formGroup.controls["apellido"].setValue(this.persona.Apellido, [
            Validators.required,
          ]);
          this.deshabilitar_datos_personales(persona);
          this.cargar_autorizacion_persona();
        }
      })
      .catch((error) => {
        this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);

        if (!this.edicion) {
          this.formGroup.controls["nombre"].setValue("", [Validators.required]);
          this.formGroup.controls["apellido"].setValue("", [
            Validators.required,
          ]);
          this.deshabilitar_datos_personales(null);
        }
      })
      .finally(() => {
        this.controlService.ocultar_loading();
      });
  }

  private cargar_autorizacion_persona() {
    const lote = this.setear_lote_seleccionado();
    this.autorizadoService
      .obtener_autorizacion_por_documento(lote, this.autorizado.DocumentoNumero)
      .then((personaAutorizada: AutorizadoDelLote) => {
        // Si la persona ya tiene una autorización para el lote seleccionado, cargo sus datos actuales de autorización
        this.autorizacionDelLote = personaAutorizada;
        if (personaAutorizada) {
          this.persona.Id = personaAutorizada.Id;
          this.persona.Nombre = personaAutorizada.Nombre;
          this.persona.Apellido = personaAutorizada.Apellido;
          this.persona.Documento = personaAutorizada.NumeroDocumento;
          const fechaDesde = this.utilService.convert_ticks_to_date(
            Number(personaAutorizada.FechaDesde)
          );
          const fechaHasta = this.utilService.convert_ticks_to_date(
            Number(personaAutorizada.FechaHasta)
          );
          this.vigente = fechaHasta >= new Date();

          this.formGroup.controls["tipoRelacion"].setValue(
            personaAutorizada.IdTipoRelacion,
            [Validators.required]
          );
          this.formGroup.controls["tipoAutorizado"].setValue(
            personaAutorizada.IdTipoAutorizado,
            [Validators.required]
          );
          if (this.vigente) {
            this.formGroup.controls["desde"].setValue(fechaDesde.toJSON(), [
              Validators.required,
            ]);
            this.formGroup.controls["hasta"].setValue(fechaHasta.toJSON(), [
              Validators.required,
              this.validar_rango_fecha.bind(
                this.formGroup,
                this.validationService
              ),
            ]);
            this.controlService.mostrar_alert({
              header: MESSAGE.ALERT.MENSAJE_AUTORIZACION.INFORMATIVO.TITULO,
              message: MESSAGE.ALERT.MENSAJE_AUTORIZACION.INFORMATIVO.MENSAJE,
              backdropDismiss: false,
              buttons: [
                {
                  text: MESSAGE.ALERT.MENSAJE_RESERVA.INFORMATIVO.BUTTONS
                    .ACEPTAR,
                },
              ],
            });
          }
        }
      })
      .catch((error) => {
        this.controlService.mostrar_toast(error, TIEMPO_MENSAJE);
      })
      .finally(() => {
        this.controlService.ocultar_loading();
      });
  }

  private deshabilitar_datos_personales(persona: Persona) {
    const deshabilitarCampos = persona != null;
    const ctrlNombre = <FormControl>this.formGroup.controls["nombre"];
    const ctrlApellido = <FormControl>this.formGroup.controls["apellido"];

    if (deshabilitarCampos) {
      ctrlNombre.disable();
      ctrlApellido.disable();
    } else {
      ctrlNombre.enable();
      ctrlApellido.enable();
    }
  }

  private validar_rango_fecha(
    validationService: ValidationService,
    control: FormControl
  ): { [s: string]: boolean } {
    const form: any = this;
    const fechaDesde = form.controls["desde"];
    const fechaHasta = form.controls["hasta"];

    if (
      !validationService.rango_fecha_correcto(
        fechaDesde.value,
        fechaHasta.value
      )
    ) {
      return { range: true };
    }

    return null;
  }

  private validar() {
    const promesa = new Promise((resolve, reject) => {
      const fechaDesde = this.formGroup.value.desde;
      const fechaHasta = this.formGroup.value.hasta;

      // Valido que los campos fechas tengas algún valor
      if (fechaDesde !== "" && fechaHasta !== "") {
        // Valido rango de fechas
        if (
          this.validationService.rango_fecha_correcto(fechaDesde, fechaHasta)
        ) {
          resolve(true);
          return;
        }
      }

      this.controlService.mostrar_toast(
        MESSAGE.ERROR.MENSAJE_ERROR_RANGO_FECHAS,
        TIEMPO_MENSAJE
      );
      resolve(false);
    });

    return promesa;
  }
}
