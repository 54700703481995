import { Component, OnInit } from "@angular/core";
import {
  FormGroup,
} from "@angular/forms";

import { ControlService } from "src/app/services/common/control.service";
import { FECHA, MESSAGE, RANGO_FECHA, TIEMPO_MENSAJE } from "src/app/constants/constants.index";
import { Lote } from "src/app/models/entities/entity.index";
import { DatePipe } from "@angular/common";
import { UserService } from "src/app/services/security/user.service";
import { IModal } from "src/app/models/interfaces/interfaces.index";
import { AgregarReservaCamionetaPage } from "./agregar/agregar.page";
import { ReservaCamioneta } from "src/app/models/entities/business/reserva.entity";
import { CamionetaService } from "src/app/services/business/camioneta.service";
import { DetailsReservaCamionetaPage } from "./details/details.page";
import { GoogleMapsService } from "src/app/services/common/google-maps.service";
import * as moment from "moment";
import { TIViajeEstado } from "src/app/models/enums/estado.enum";

@Component({
  selector: "app-camioneta",
  templateUrl: "./camioneta.page.html",
  styleUrls: ["./camioneta.page.scss"],
  providers: [DatePipe],
})
export class CamionetaPage implements OnInit {

  lotes: Lote[] = [];
  misReservas: ReservaCamioneta[] = [];

  formGroup: FormGroup;
  anioMin = new Date().toISOString();
  anioMax: number = RANGO_FECHA.ACCESO.NUEVO.ANIO_MAXIMO;
  formatoFechaControl: string = FECHA.FORMATO_VISUAL;
  viajeEstados = TIViajeEstado;

  constructor(
    private controlService: ControlService,
    private userService: UserService,
    private camionetaService: CamionetaService,
  ) {
    this.lotes = this.userService.user.Lotes;
  }

  ngOnInit() {
    this.getReservations();
  }

  async getReservations() {
    this.misReservas = await this.camionetaService.obtener_mis_reservas();
  }

  getShortDateLabel(key: string, date: Date): string {
    moment.locale('es');
    const momentDate = moment(date, 'DD/MM/YYYY');
    switch (key) {
      case 'month':
        return momentDate.format('MMM').toUpperCase();
      case 'day-number':
        return momentDate.format('D').toUpperCase();
      case 'day':
        return momentDate.format('ddd').toUpperCase();
        break
      default:
        return '';
    }

  }

  showCreateReservationModal() {
    const modal: IModal = {
      component: AgregarReservaCamionetaPage,
      componentProps: { titulo: 'Crear reserva' },
      returnData: true,
    };

    this.controlService.mostrar_modal(modal).then(() => {
      this.controlService.mostrarLoading().then(async () => {
        try {
          await this.getReservations();
        } catch (error) {
          console.log("error", error);
        } finally {

          this.controlService.ocultar_loading();
        }
      });
    });
  }

  getPersonsLabel(reserva: ReservaCamioneta): string | null {
    return reserva.Pasajeros.map((persona) => `${persona.Nombre} ${persona.Apellido}`).join(', <br>');
  }

  openDetails(reserva: ReservaCamioneta) {

    const modal: IModal = {
      component: DetailsReservaCamionetaPage,
      componentProps: { id: 'Detalle de reserva', reserva },
      cssClass: 'li-ionic4-datePicker',
      returnData: false,
    };

    this.controlService.mostrar_modal(modal);
  }

  deleteReservation(reservation: ReservaCamioneta) {
    this.controlService.mostrar_alert({
      header: MESSAGE.ALERT.MENSAJE_RESERVA_CAMIONETA.ELIMINAR_RESERVA.TITULO,
      message: MESSAGE.ALERT.MENSAJE_RESERVA_CAMIONETA.ELIMINAR_RESERVA.PREGUNTA,
      backdropDismiss: false,
      buttons: [
        {
          text: MESSAGE.ALERT.MENSAJE_RESERVA_CAMIONETA.ELIMINAR_RESERVA.BUTTONS.ACEPTAR,
          handler: () => {
            this.controlService
              .mostrarLoading(MESSAGE.LOADING.DEFAULT)
              .then(() => {
                this.camionetaService.cancelar_reserva(reservation).then(() => {
                  this.controlService.ocultar_loading();
                  this.controlService.mostrar_toast("Reserva cancelada", TIEMPO_MENSAJE);
                  this.misReservas = this.misReservas.filter(({ Numero }) => Numero !== reservation.Numero);
                });
              });
          },
        },
        {
          text: MESSAGE.ALERT.MENSAJE_NOTICIA.ELIMINAR_NOTICIA.BUTTONS.CANCELAR,
        },
      ],
    });
  }

  async doRefresh(event) {
    try {
      await this.getReservations();
    } catch (error) {
      console.log('error al obtener mis reservas', error);
    } finally {
      event.target.complete();
    }
  }

}
