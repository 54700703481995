import { Component, ViewChild } from "@angular/core";
import { IonSlides } from "@ionic/angular";
import { Router } from "@angular/router";
import { STORAGE } from "src/app/constants/storage.constant";
import { StorageService } from "src/app/services/common/storage.service";
import { ControlService } from "src/app/services/common/control.service";
import { SegmentService } from "src/app/services/common/segment.service";
import { EnumTipoSegmento } from "src/app/models/enums/tipo.enum";
import { UserService } from "src/app/services/security/user.service";

@Component({
  selector: "app-onboarding",
  templateUrl: "./onboarding.page.html",
  styleUrls: ["./onboarding.page.scss"],
})
export class OnboardingPage {
  @ViewChild("onboardingSlides") slider: IonSlides;
  currentSlide = 0;

  constructor(
    private router: Router,
    private userService: UserService,
    private storageService: StorageService,
    private controlService: ControlService,
    private segmentService: SegmentService
  ) {}

  async getCurrentSlide() {
    if (this.slider) {
      this.currentSlide = await this.slider.getActiveIndex();
    }
  }

  async nextSlide() {
    if (this.slider) {
      const sliderLength = await this.slider.length();
      if (this.currentSlide === sliderLength - 1) {
        this.goToLogin();
      } else {
        this.slider.slideNext();
      }
    }
  }

  enableEmergencyButton(): boolean {
    return this.userService.user.EmergenciasHabilitadas;
  }

  async goToLogin() {
    await this.storageService.guardar({
      key: STORAGE.KEY.PRIMERA_INSTALACION,
      value: JSON.stringify(false),
    });

    this.router.navigate([this.segmentService.segmentosHome[0].path]);
    this.controlService.cerrar_modal();
    setTimeout(() => {
      this.segmentService.actualSegment.next({
        type: EnumTipoSegmento.Home,
        segment: this.segmentService.segmentosHome[0],
      });
    }, 1000);
  }
}
