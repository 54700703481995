import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { IonicModule } from "@ionic/angular";

import { NotificationDetailPage } from "./notification-detail.page";

// Módulos
import { ComponentsMobileModule } from "src/app/components/components-mobile.module";
import { PipesModule } from "src/app/pipes/pipes.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsMobileModule,
    PipesModule,
  ],
  declarations: [NotificationDetailPage],
})
export class NotificationDetailPageModule {}
