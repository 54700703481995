import { Component, ViewChild, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { IonInput, IonInfiniteScroll, IonSelect, AlertController } from '@ionic/angular';

// Clases
import {
  MPPaymentMethod,
  MPPaymentMethodIssuer,
  Lote,
  InfoACobrarByLote,
  loteCobranzaMedioPagoMobile,
} from "src/app/models/entities/entity.index";

// Servicios
import { ControlService } from "src/app/services/common/control.service";
import { UserService } from "src/app/services/security/user.service";
import { PagoExpensasService } from "src/app/services/business/pago-expensas.service";

// Constantes
import { MESSAGE, TIEMPO_MENSAJE } from "src/app/constants/constants.index";

@Component({
  selector: "app-pagoExpensas",
  templateUrl: "./pago-expensas.page.html",
  styleUrls: ["./pago-expensas.page.scss"],
})
export class PagoExpensasPage implements OnInit {
  @ViewChild("importe") inputImporte: IonInput;
  @ViewChild("selectLote") selectLote: IonSelect;

  infoACobrar: InfoACobrarByLote;
  metodosPaymentMPP: MPPaymentMethod[] = [];
  metodosPaymentMPPIssuer: MPPaymentMethodIssuer[] = [];
  card;
  deshabilitarPago = true;
  loteSeleccionado: string;
  metodoPaymentSeleccionado: string;
  metodoPaymentIssuerSeleccionado: number;
  lotes: Lote[];
  botones = [];
  btnOtroimporte = { "Description": "Otro Importe", "Estado": false };
  importeTotal = 0;
  mostrarOtroImporte = false;
  otroImporte = 0;

  constructor(
    private controlService: ControlService,
    private userService: UserService,
    private pagoExpensasService: PagoExpensasService
  ) {
    this.botones = [{ "Id": "SaldoPendiente", "Estado": false, "Description": "Saldo adeudado", "Value": 0 },
    { "Id": "Expensa", "Estado": false, "Description": "Expensa del mes", "Value": 0 }];
    this.lotes = this.userService.user.Lotes;
    this.loteSeleccionado = this.lotes[0].Id;
  }
  ngOnInit() {
    this.obtener_info_a_cobrar(this.loteSeleccionado);
  }


  obtener_info_a_cobrar(loteSeleccionado: string) {
    this.pagoExpensasService.obtener_info_a_cobrar(loteSeleccionado).then((resp: InfoACobrarByLote) => {

      this.infoACobrar = resp;
      this.botones[0].Value = resp != null ? resp.ExpensaDelMes : 0;
      this.botones[1].Value = resp != null ? resp.SaldoPendiente : 0;


    }).catch((error) => {

      this.controlService.habilitar_infinite_scroll(event, false);
    })
  }

  _seleccionarBoton(idButton: string) {
    this.mostrarOtroImporte = false;
    this.btnOtroimporte.Estado = false;
    this.botones.forEach(boton => {
      if (boton.Id == idButton) {
        boton.Estado = !boton.Estado;
        this.importeTotal = boton.Estado ? boton.Value : 0;
      } else {
        boton.Estado = false;
      }
    });
  }

  _mostrarOtroImporte() {
    this.inputImporte.setFocus();
    this.mostrarOtroImporte = !this.mostrarOtroImporte;
    this.importeTotal = 0;
    this.btnOtroimporte.Estado = !this.btnOtroimporte.Estado;
    this.botones.map(boton => boton.Estado = false);
  }

  cambiar_lote_actual() {
    this.loteSeleccionado = this.selectLote.value;
    this.obtener_info_a_cobrar(this.loteSeleccionado);
  }

  _calcularImporte() {
    this.importeTotal = 0;
    this.importeTotal = Number(this.inputImporte.value);
  }

  _confirmarPago() {
    this.controlService.mostrar_alert(
      {
        header: "Debitaremos de su tarjeta",
        subHeader: this.card.cardType + " " + this.card.cardNumber,
        message: "La suma de: $" + this.importeTotal,
        buttons: [
          {
            text: "Cancelar",
            role: 'cancel'
          },
          {
            text: "Confirmar",
            handler: () => {
              this.pagar();
            }
          }
        ]
      });
  }

  pagar() {
    let loteCobranza = new loteCobranzaMedioPagoMobile();
    loteCobranza.TarjetaNumero = this.card.cardNumber;
    loteCobranza.TarjetaCodigoSeguridad = this.card.cvv;
    loteCobranza.TarjetaVencimientoAnio = this.card.expiryYear;
    loteCobranza.TarjetaVencimientoMes = this.card.expiryMonth;
    loteCobranza.TarjetaTitular = this.card.cardholderName;
    loteCobranza.Importe = this.importeTotal;
    loteCobranza.MedioPagoId = "";
    loteCobranza.MedioPagoEmisorId = null;

    console.log(loteCobranza);
    this.controlService.mostrarLoading("Procesando...");
    this.generar_Lote_Cobranza(loteCobranza);
  }

  generar_Lote_Cobranza(loteCobranza: loteCobranzaMedioPagoMobile) {
    this.pagoExpensasService.crear_LoteCobranza(loteCobranza, this.loteSeleccionado).then(resp => {
      console.log(resp);
      this.controlService.ocultar_loading();
      this.controlService.mostrar_alert({
        header: "Pago de expensas",
        subHeader: "Registramos su pago exitosamente!",
        message: "Su número de operación es: #" + resp["Data"].IdTransaccion,
        buttons: [
          {
            text: "OK",
            role: 'cancel'
          }
        ]
      });
    }).catch((error) => {

      this.controlService.ocultar_loading();

      this.controlService.mostrar_alert({
        header: "Pago de expensas",
        message: "No hemos podido utilizar su tarjeta. Por favor intente más tarde.",
        buttons: [
          {
            text: "OK",
            role: 'cancel'
          }
        ]
      });
    });
  }

}
