import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

// Servicios
import { CatalogoService } from "./catalogo.service";
import { RestService } from "../common/rest.service";
import { SecurityService } from "../security/security.service";
import { UtilService } from "../common/util.service";
import { ExceptionService } from "../common/exception.service";

// Interfaces
import {
  IDictionary,
  IPagination,
} from "src/app/models/interfaces/interfaces.index";

// Clases
import {
  ResponseApp,
  Multa,
  Lote,
  Adjunto,
  Item,
} from "src/app/models/entities/entity.index";
import { ItemElement } from "src/app/models/clases/ItemElement";

@Injectable({
  providedIn: "root",
})
export class MultaService {
  private paginacion: IPagination = { pagina: 1, cantidad: 10 };
  private itemElement = new ItemElement<Multa>();

  multas: Multa[] = [];
  paginacionActual: IPagination = { pagina: 1, cantidad: 10 };

  constructor(
    private catalogoService: CatalogoService,
    private restService: RestService,
    private securityService: SecurityService,
    private utilService: UtilService,
    private exceptionService: ExceptionService
  ) {}

  async marcarTodoLeido(){
    const token = this.securityService.token;
    const entidad = "Multa";
    const body = {
      token,
      entidad
    };

    return new Promise((resolve, reject) => {
      this.restService
        .post(`${this.catalogoService.catalogo}MarcarTodoLeidoParaEntidad`, body)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  //**marcarMultaLeida */
  async marcar_multa_como_leida(multaId: string) {
    const url = `${this.catalogoService.catalogo}MarcarMultaLeida/`;
    const options: IDictionary[] = [{ key: "{multa}", value: multaId }];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);

            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  // obtenerMultas
  async obtener_multas(
    lote: Lote,
    estado: Item,
    paginacion: IPagination = this.paginacion
  ) {
    const url = `${this.catalogoService.catalogo}ObtenerMultas/`;
    const options: IDictionary[] = [
      { key: "{idLote}", value: lote.Id },
      { key: "{codigoBarrio}", value: this.securityService.codigoBarrio },
      { key: "{idEstado}", value: estado.Id.toString() },
      { key: "{pagina}", value: paginacion.pagina.toString() },
      { key: "{cantidad}", value: paginacion.cantidad.toString() },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<any, Multa[]>((resp: any) => {
            this.exceptionService.resolver_error(resp);

            this.paginacionActual.pagina = resp.PaginaActual;
            this.paginacionActual.cantidad = resp.CntTotal;

            let multas = this.utilService.mapear_propiedades(
              resp.Data,
              new Multa()
            );
            multas.forEach((multa: Multa) => {
              multa.Adjuntos.forEach((adjunto: Adjunto, index: number) => {
                multa.Adjuntos[index].UrlImagen =
                  this.utilService.resolver_url_imagen(adjunto.UrlImagen);
              });
            });

            this.multas = this.itemElement.refrescar(multas, this.multas);

            console.log("multa", this.multas);
            return this.multas;
          })
        )
        .subscribe(
          (data: Multa[]) => {
            resolve(data);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  // enviarDescargo
  async agregar_comentario(multa: Multa, comentario: string) {
    const url = `${this.catalogoService.catalogo}EnviarDescargo/`;
    const options: IDictionary[] = [
      { key: "{idMulta}", value: multa.Id },
      { key: "{comentario}", value: comentario },
    ];

    return new Promise((resolve, reject) => {
      this.restService
        .get(url, true, false, options)
        .pipe(
          map<ResponseApp, ResponseApp>((resp: ResponseApp) => {
            this.exceptionService.resolver_error(resp);
            return resp;
          })
        )
        .subscribe(
          (resp: ResponseApp) => {
            resolve(resp);
          },
          (error) => {
            reject(this.exceptionService.resolver_mensaje_excepcion(error));
            return;
          }
        );
    }).catch((error) => {
      console.log(error);
      throw error;
    });
  }

  limpiar() {
    this.multas = [];
  }
}
