import { EnumTipoApp } from "src/app/models/enums/tipo.enum";

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: "web dev",

  // **----------------------------------------------->  APP */
  APP: {
    TYPE_APP: EnumTipoApp.Web,
    MOCK_MOBILE: null,
  },

  /** ---------------------------------------------------------> REST */
  PATH_REST: {
    REST: {
      HOST: "https://sbc.netkey.com.ar/CatalogoBarrios/Services/BarrioCatalogoService.svc/rest/ObtenerCatalogo/",
      CATALOGO: "http://localhost/SBC/Services/MobileService.svc/rest/",
      CUSTOM_URL: false,
    },
  },

  ONE_SIGNAL_APP_ID: "b5a72ae2-bd54-4746-96e2-6d52866ec0ce",
  APP_ID_ANDROID: "",
  APP_ID_IOS: "",
  NEIGHBORHOOD_CODE: "",
};

//local_properties,json
//63864f24e78885d547c820e8 -> cordova 11
//63ce8315e78885c6294d4d20 -> cordova 12

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
