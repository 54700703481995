export class Invitacion {
  FechaDesde: Date | number = 0;
  FechaHasta: Date | number = 0;
  Comentario: string = "";
}

export class InvitacionDetalle {
  Id: string = null;
  IdLote: string = null;
  FechaDesde: Date | number = 0;
  FechaHasta: Date | number = 0;
  Comentario: string = "";
  Documento: string = "";
  Nombre: string = "";
  Apellido: string = "";
  Estado: string = "";
  Email: string = "";
  EstadoDescripcion: string = "";
  MotivoRechazo: string = "";
}
