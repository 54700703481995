import { TelefonoMovilSegmentado } from "../business/usuario.entity";
import { Item, Lote, Persona } from "../entity.index";
import { IMG_APP } from "src/app/constants/config.constant";

export class FormModel {
  Lote?: string = null;
  Estado?: string = null;
  FechaDesde?: Date | any = null;
  FechaHasta?: Date | any = null;
  Nombre?: string = null;
  Apellido?: string = null;
  Dni?: string = null;
  TipoRelacion?: Item = null;
  TipoAutorizacion?: Item = null;
}

export class ObservacionModel {
  Id: string = null;
  IdLote: string = null;
  Observacion: string = null;
  Persona: Persona = null;
  VigenciaDesde: Date | any = null;
  VigenciaHasta: Date | any = null;
}

export class FormAbmNoticiaModel {
  Id: string = null;
  Numero: string = null;
  Titulo: string = null;
  FechaPublicacion: Date | any = null;
  FechaCierre?: Date | any = null;
  Categoria: string = null;
  Canal: number = null;
  Contenido: string = null;
  VisibleInHome: boolean | number = false;
  UrlImagen: string = null;
  Imagen: string = null;
}

export class UserModel {
  Id: string = null;
  Nombre: string = null;
  Apellido: string = null;
  Token: string = null;
  Documento: string = null;
  Telefono: string = null;
  TelefonoFijo: string = null;
  Email: string = null;
  Vencimiento: Date | any = null;
  UrlImage: string = IMG_APP.PERFIL;

  Accesos: boolean = false;
  Novedades: boolean = false;
  AutorizacionesOneClick: boolean = false;
  NotificacionesEmail: boolean = false;
  Lotes: Lote[] = [];
  FechaHoraModificacion: Date | number = null;
  ImageToUpdate: string = null;
  TelefonoMovilSegmentado: TelefonoMovilSegmentado;
}

export class InvitacionModel {
  Lote?: string = null;
  FechaDesde?: Date | any = null;
  FechaHasta?: Date | any = null;
  Comentario?: string = null;
}
export class InvitacionDetalleModel {
  Lote?: string = null;
  Estado?: string = null;
  FechaDesde?: Date | any = null;
  FechaHasta?: Date | any = null;
  Nombre?: string = null;
  Apellido?: string = null;
  Documento?: string = null;
  Comentario?: string = null;
}
