import {
  Component,
  ViewChild,
  ChangeDetectorRef,
  AfterContentChecked,
} from "@angular/core";
import { NavigationExtras, Router } from "@angular/router";
import { IonSlides, NavController, Platform } from "@ionic/angular";
import { Subscription } from "rxjs";

// Interfaces
import { IIdentity } from "src/app/models/interfaces/interfaces.index";

// Servicios
import { SegmentService } from "src/app/services/common/segment.service";
import { MenuService } from "src/app/services/common/menu.service";
import {
  EnumTipoCanal,
  EnumTipoMenu,
  EnumTipoSegmento,
} from "src/app/models/enums/tipo.enum";
import { SbcService } from "src/app/services/common/sbc.service";
import { DashboardService } from "src/app/services/business/dashboard.service";
import { UserService } from "src/app/services/security/user.service";
import { NoticiaService } from "src/app/services/business/noticia.service";
import { ControlService } from "src/app/services/common/control.service";

@Component({
  selector: "app-home",
  templateUrl: "home.page.html",
  styleUrls: ["home.page.scss"],
})
export class HomePage implements AfterContentChecked {
  @ViewChild("slides") slider: IonSlides;
  segments: IIdentity[] = [];
  private backButtonSubscription: Subscription;

  constructor(
    private changeDedectionRef: ChangeDetectorRef,
    public segmentService: SegmentService,
    private menuService: MenuService,
    private router: Router,
    private sbcService: SbcService,
    private navCtrl: NavController,
    private dashboardService: DashboardService,
    private noticiaService: NoticiaService,
    private userService: UserService,
    private controlService: ControlService,
    private platform: Platform
  ) { }

  ngOnInit() {
    this.backButtonSubscription = this.platform.backButton.subscribe(() => {
      console.log("entró al backButtonSubscription 54");
      this.navCtrl.pop();
    });
    this.segmentService.actualSegment.subscribe(async (selector: any) => {
      if (selector && selector.type && selector.segment) {
        if (selector.type === EnumTipoSegmento.Home) {
          if (selector.segment.id === "dashboard") {
            try {
              this.dashboardService.get_dashboard_items(
                this.userService.user.Lotes[0].Id
              );
              this.noticiaService.obtener_publicaciones_pre_holder_por_canal(
                EnumTipoCanal.Mobile,
                { pagina: 1, cantidad: 5 }
              );
            } catch (error) {
              console.log("error", error);
            }
            this.actualizar_segmento_seleccionado(
              selector.segment,
              selector.data
            );
          } else {
            this.actualizar_segmento_seleccionado(
              selector.segment,
              selector.data
            );
          }
        }
      }
    });
  }

  ngOnDestroy() {
    this.backButtonSubscription.unsubscribe();
  }

  ngAfterContentChecked(): void {
    this.changeDedectionRef.detectChanges();
  }

  ionViewDidEnter() {
    this.segmentService.actualSegment.subscribe((selector: any) => {
      console.log(selector);
    });
    this.sbcService
      .resolver_menu(EnumTipoMenu.Home, EnumTipoSegmento.Home)
      .subscribe((resp: string | IIdentity[]) => {
        if (typeof resp !== "string") {
          console.log("aquí HOME");

          this.segments = this.menuService.reemplazar_menu(
            this.segmentService.segmentosHome,
            resp
          );
          this.segments = this.menuService.obtener_menu_sin_opciones_ocultas(
            this.segments
          );
          this.slider.update();
          this.segmentService.cargar_segmentos_home();
        }
      });
  }

  actualizar_segmento_seleccionado(
    segmentoSeleccionado: IIdentity,
    data?: any
  ) {
    this.segmentService.segmentoHomeSeleccionado = segmentoSeleccionado.id;
    const sliderActual =
      this.segmentService.segmentosHome.indexOf(segmentoSeleccionado);

    this.slider.slideTo(sliderActual);

    let navigationExtras: NavigationExtras = {
      queryParams: {
        ...data,
      },
    };
    this.navCtrl.navigateForward([segmentoSeleccionado.path], navigationExtras);
  }

  sincronizar_vista(event) {
    this.slider.getActiveIndex().then((index: number) => {
      const segmentoSeleccionado =
        this.segmentService.seleccionar_segmento_home(index);
      this.router.navigate([segmentoSeleccionado.path]);
    });
  }

  ocultar_slide(segmento: string) {
    const menu = this.menuService.buscar_item_por_propiedad(
      this.segmentService.segmentosHome,
      segmento,
      "id"
    );
    return menu && !menu.hide;
  }
}
